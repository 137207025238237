import axios from 'axios'
import { GetServerBaseUrl } from '../../env'

export const apiWrapper = (params: {
  token: string
  method: 'get' | 'post' | 'put' | 'delete'
  server: 'django' | 'lara'
  version: 'v2' | 'v3'
  url: string
  body?: any
}): Promise<any> => {
  const { version, server, token, method, url, body = {} } = params
  const fullUrl = `${GetServerBaseUrl(version, server)}/${url}`
  const config = {
    headers: { Authorization: server === 'django' ? `Bearer ${btoa(token)}` : `Bearer ${token}` },
  }

  if (method === 'post') {
    return axios
      .post(fullUrl, body, config)
      .then((response) => response)
      .catch((error) => {
        throw error
      })
  }
  if (method === 'put') {
    return axios
      .put(fullUrl, body, config)
      .then((response) => response)
      .catch((error) => {
        throw error
      })
  }
  if (method === 'delete') {
    return axios
      .delete(fullUrl, config)
      .then((response) => response)
      .catch((error) => {
        throw error
      })
  }
  return axios
    .get(fullUrl, config)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
