import { ProductFilters } from './interfaces'

export const LOADING_PRODUCTS = 'loading-products'

export const LineKindOptions = {
  extension: 'extension',
  color: 'color',
}

export const EMPTY_PRODUCT_FILTERS: ProductFilters = {
  searchText: null,
  productName: null,
  lineName: null,
  vendorName: null,
  categoryName: null,
  productSize: null,
  pricingFilter: null,
  onHandFilter: null,
  targetFilter: null,
  stockLevel: null,
  lineOrType: null,
}

export const GROUPED_CATEGORIES = ['bleach', 'additive', 'bonder', 'bondor', 'miscellaneous']
