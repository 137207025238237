import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Container, Spinner } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { generatePath, Link } from "react-router-dom"
import { Gap } from "../../mini-lib/gap/Gap"
import { UseQueryParams } from "../../mini-lib/utils/basic"
import { getLocalStorageItem } from "../../core/localStorage"
import { useDispatch } from "react-redux";
import { dispatchConnectSquare } from "../../data/settings/api";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { useAppSelector } from "../../hooks";
import { selectLoadingState } from "../../core/loading/slice";
import { ROUTES } from "../../appRoutes";
import { LOADING_CONNECT_SQUARE } from "../../data/integrations/constants";
import { BASE_STYLES } from "../../styles";


// todo: probably move this to a utils file
export const UseSquareOAuthParams = () => {
    const params = UseQueryParams()

    const authState = getLocalStorageItem('square-auth-state')
    const code = params.get('code')
    const error = params.get('error')
    const errorDescription = params.get('error_description')

    return { code, error, errorDescription, authState }
}

export const SquareOAuthCallbackPage = () => {

    const {user} = UseBaseApiParams()
    // todo: consider getting salon id from local storage
    // would require setting it in local storage earlier - probably from settings page when they click auth with square
    const salonId = user?.currentSalonContext?.salonId || -1

    return <SquareOAuthCallback token={user?.token} salonId={salonId}/>
}

export const SquareOAuthCallback = (params: {salonId: number, token: string}) => {
    const {salonId, token} = params
    const squareParams = UseSquareOAuthParams()
    const {authorizationCode} = authResultFromParams(squareParams)

    const isLoading = useAppSelector((state) => selectLoadingState(state, LOADING_CONNECT_SQUARE))
    const dispatch = useDispatch()
    const {user} = UseBaseApiParams()
    const squareExists = !!user?.currentSquareContext?.merchantId
    useEffect(() => {
        if (salonId === undefined || token === undefined) return

        if (!!authorizationCode) {
            dispatch(dispatchConnectSquare({token, userId: user?.userId, salonId, authorizationCode, loadingKey: LOADING_CONNECT_SQUARE}))
        }
    }, [dispatch, user?.userId, authorizationCode, salonId, token])
    return (
        <Container h='100vh' justifyContent='center' centerContent>
            {isLoading && <Spinner/>}
            {!isLoading && squareExists && <SquareAuthSuccessView />}
            {!isLoading && !squareExists && <SquareAuthErrorView error={unknownError} />}

            <Gap />

            {!isLoading && salonId && !squareExists && (
              <Link to={generatePath(ROUTES.salonSettings, { salonId })}>
              <Button variant='link'>Back to Settings</Button>
            </Link>
            )}

            {!isLoading && salonId && squareExists && (
              <Link to={generatePath(ROUTES.squareConnectTeam, { salonId })}>
                <Button variant="round" colorScheme="brand.midnight">
                  Setup My team
                </Button>
              </Link>
            )}
        </Container>
    )
}

// eslint-disable-next-line
const mockSquareConnect = async (
    _props: {authorizationCode: string, salonId: number, token: string}
): Promise<boolean> => {
    return true;
}

export const SquareAuthErrorView = ({ error }: { error: SquareAuthError }) => (
    <Alert
        status='error'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
        borderRadius={BASE_STYLES.borderSquare}
    >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>{error.name}</AlertTitle>
        <AlertDescription maxWidth='sm'>{error.message}</AlertDescription>
    </Alert>
)

export const SquareAuthSuccessView = () => (
    <Alert
        status='success'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
        borderRadius={BASE_STYLES.borderSquare}
    >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>Connection Successful</AlertTitle>
        <AlertDescription maxWidth='sm'>SalonScale + Square = 💜</AlertDescription>
    </Alert>
)


export interface SquareAuthError {
    name: string;
    message: string;
}

const authResultFromParams = (
    params: { code: string, error: string, errorDescription: string, authState: string }
) => {
    const { code, error, errorDescription, authState } = params
    const localState = getLocalStorageItem('square-auth-state')
    if (authState !== localState) {
        return { authorizationCode: null, authError: { name: 'Authorization failed', message: 'Invalid state parameter' } }
    }

    const authorizationCode = code
    if (authorizationCode !== null) {
        return { authorizationCode, authError: null }
    } else {
        const name = error
        const message = errorDescription

        if (name === 'access_denied' && message === 'user_denied') {
            return { authorizationCode: null, authError: { name: 'Authorization denied', message: 'You chose to deny access to SalonScale' } }
        }

        if (name !== null && message !== null) {
            return { authorizationCode: null, authError: { name, message } }
        }

        return { authorizationCode: null, authError: { name: 'Unknown parameters', message: 'Expected parameters were not returned' } }
    }
}

const unknownError: SquareAuthError = { name: 'Something went wrong', message: 'Please try again later' }

