import { Button, Flex, Text } from '@chakra-ui/react'
import { COLORS } from '../../mini-lib/theme/colors'
import { useAppSelector } from '../../hooks'
import { selectActiveSalonContext } from '../../data/salon/slice'
import { generatePath, Link } from 'react-router-dom'
import React from 'react'
import { ROUTES } from '../../appRoutes'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { UseViewSize } from '../../core/UseViewSize'
import { getTrialDaysLeft, getTrialDaysLeftText } from "./utils";

export const TrialDaysRemaining = () => {
  const salon = useAppSelector(selectActiveSalonContext)
  const { isMobile } = UseViewSize()
  const daysLeft = salon?.trialExpiryDate ? getTrialDaysLeft(salon.trialExpiryDate) : null
  const daysLeftText = getTrialDaysLeftText(daysLeft)

  const trialActive = daysLeft !== null && daysLeft >= 0
  return (
    <Flex
      justify="space-between"
      align="center"
      bg={daysLeft === null ? '' : trialActive ? COLORS.lavender_100 : COLORS.peach_100}
      borderRadius="16px"
      p="12px 24px"
      fontWeight="bold"
      gridGap="12px"
      wrap={isMobile ? 'wrap' : 'nowrap'}
      minH="72px"
    >
      {daysLeft !== null && (
        <>
          <Flex align="center" gridGap="8px">
            {!trialActive && <MaterialIcon name="warning" colorhex={COLORS.danger} />}
            <Text fontStyle="italic">
              {daysLeftText}
            </Text>
          </Flex>
          <Link
            to={generatePath(ROUTES.checkoutSubscription, { salonId: salon?.id || 0 })}
            style={{ width: isMobile ? '100%' : '' }}
          >
            <Button
              w={isMobile ? '100%' : ''}
              variant="round-border"
              borderColor={trialActive ? '' : 'black'}
              color={trialActive ? '' : 'black'}
            >
              Upgrade Now
            </Button>
          </Link>
        </>
      )}
    </Flex>
  )
}
