import { LABOR_SERVICE_TYPES, LaborItem, LaborService, LaborServiceOption, LaborTier } from './interfaces'

export const buildMockLaborTier = (id = 1, name = 'Tier 1'): LaborTier => {
  return { id, name }
}

export const buildMockLaborService = (id = 1, name = 'Service 1', type = LABOR_SERVICE_TYPES.hourly): LaborService => {
  return { id, name, type }
}

export const buildMockLaborItem = (id: number, tier: LaborTier, service: LaborService, price = 500): LaborItem => {
  return {
    id,
    priceDollars: price,
    tier,
    service,
  }
}

export const DEFAULT_TIER_NAME = 'New Tier'
export const DEFAULT_SERVICE_NAME = 'New Service'

export const initialServiceOptions: LaborServiceOption[] = [
  { id: 1, label: 'Single Process Color' },
  { id: 2, label: 'Double Process Color' },
  { id: 3, label: 'Highlights' },
  { id: 4, label: 'Hull Head Highlights' },
  { id: 5, label: 'Highlights with Base' },
  { id: 6, label: 'Treatment' },
  { id: 7, label: 'Blow out' },
  { id: 8, label: `Woman's Cut` },
  { id: 9, label: `Men's Cut` },
]
