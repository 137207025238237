import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Badge, Box, Button, Circle, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex } from '@chakra-ui/react'
import { UseSignOut } from '../../core/hooks/UseSignOut'
import { redirectToAngularAdmin } from '../../data/user/utils'
import { COLORS } from '../theme/colors'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectSidenavVisible, setNavVisible } from './slice'
import { UseViewSize } from '../../core/UseViewSize'
import { SALONSCALE_LOGO_URL, SIDENAV_WIDTH } from '../../data/constants'
import { SalonScaleIcon } from '../icons/SalonScaleIcon'
import { ReleaseMultiLocation } from '../flags/Release'
import { MultiSalonButton } from '../../pages/multi-salon/MultiSalonSideSheet'

export const Sidenav = (props: {
  header: any
  content: any
  showLogout: boolean
  showStopImpersonating: boolean
  children: any
}) => {
  const dispatch = useDispatch()
  const navVisible = useAppSelector(selectSidenavVisible)
  const { children, header, content, showLogout, showStopImpersonating } = props
  const { showSideNavAsSheet } = UseViewSize()
  const offsetMargin = showSideNavAsSheet ? '' : SIDENAV_WIDTH
  return (
    <>
      {/* mobile nav */}
      <Drawer placement="left" onClose={() => dispatch(setNavVisible(false))} isOpen={navVisible}>
        <DrawerOverlay />
        <DrawerContent padding={0}>
          <DrawerBody padding={0}>
            <SidenavContent
              header={header}
              content={content}
              showLogout={showLogout}
              showStopImpersonating={showStopImpersonating}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* desktop nav */}
      <Box position="fixed" left={0} top={0} zIndex='2'>
        {!showSideNavAsSheet ? (
          <Box
            bg="white"
            w={SIDENAV_WIDTH}
            minH="100vh"
            h="100%"
            borderRadius="0 35px 0 0"
            boxSizing="border-box"
            border="1px solid rgba(0,0,0,.12)"
          >
            <SidenavContent
              header={header}
              content={content}
              showLogout={showLogout}
              showStopImpersonating={showStopImpersonating}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box marginLeft={offsetMargin}>{children}</Box>
    </>
  )
}

export const SidenavContent = (props: {
  header: any
  content: any
  showLogout: boolean
  showStopImpersonating: boolean
}) => {
  const { user } = UseBaseApiParams()
  const { header, content, showLogout, showStopImpersonating } = props
  return (
    <Flex flex="1" direction="column" justify="space-between" h="100vh" overflowX="scroll" autoFocus={false}>
      <Box>
        {header}
        {content}
      </Box>
      <Box>
        {showLogout && (
          <Flex m="24px" align="end" justify="center" autoFocus={true}>
            <Button variant="ghost" colorScheme="brand.lavender" autoFocus={true} onClick={UseSignOut}>
              Sign Out
            </Button>
          </Flex>
        )}
        {/* todo when we update user tokens change this */}
        {showStopImpersonating && (
          <Flex mt="12px" align="end" justify="center" autoFocus={true} bg={COLORS.lavender_100}>
            <Button w="100%" variant="ghost" onClick={() => redirectToAngularAdmin(user.token, user.userId)}>
              Stop Impersonating
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}
export const SidenavHeader = () => {
  const releaseMultiLocation = ReleaseMultiLocation()
  const { user } = UseBaseApiParams()
  return (
    <>
      {releaseMultiLocation && <MultiSalonButton />}
      {!releaseMultiLocation && (
        <>
          <Flex justify="center" align="center" m="24px 0">
            <Flex justify="center" align="center" w="48px" h="48px" p="4px" borderRadius="12px" bg="brand.lavender.500">
              <img alt="logo" style={{ height: '100%', width: '100%', padding: '4px' }} src={SALONSCALE_LOGO_URL} />
            </Flex>
          </Flex>

          <Flex justify="center" align="center" m="24px 0 12px 0">
            <Circle size="89px" bg={COLORS.lavender_100} border="2px solid" borderColor="brand.lavender.500">
              {user && user.photoUrl ? (
                <img
                  alt="stylist logo"
                  src={user.photoUrl}
                  style={{ height: '100%', width: '100%', borderRadius: '50%', margin: '2px' }}
                />
              ) : (
                <i style={{ height: '100%', width: '100%', fontSize: '85px' }} className="material-icons-round">
                  account_circle
                </i>
              )}
            </Circle>
          </Flex>
          <Flex m="0 0 24px 0" direction="column" align="center" justify="center">
            <div style={{ fontSize: '20px' }}>
              {user?.firstName} {user?.lastName}
            </div>
            <Box style={{ fontSize: '16px' }} color={COLORS.text_secondary}>
              Account Owner
            </Box>
          </Flex>
        </>
      )}
    </>
  )
}

const isSelectedStyle = {
  color: COLORS.lavender_500,
  background: COLORS.lavender_100,
  borderRadius: '0 80px 80px 0',
  marginRight: '12px',
}

export const SidenavTile = (props: { iconName?: string; badgeName?: string; label: string; isSelected: boolean }) => {
  const { iconName, badgeName, label, isSelected } = props
  return (
    <Flex
      _hover={{ bg: COLORS.hover }}
      align="center"
      p="12px 24px"
      gridGap="12px"
      style={isSelected ? isSelectedStyle : {}}
    >
      {iconName ? <SalonScaleIcon name={iconName} /> : <Box w="12px" />}
      {label} {badgeName && <Badge colorScheme="brand.lavender">{badgeName}</Badge>}
    </Flex>
  )
}
