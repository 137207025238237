import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import { APIUpdateSession, Session, SessionItem } from '../../../data/sessions/interfaces'
import { reduceIsExtensionSheetVisible, selectIsExtensionSheetVisible } from '../../../data/sessions/slice'
import { assign, filter, find, findIndex } from 'lodash'
import { mapSessionItemsToAPISessionItems } from '../../../data/sessions/mappers'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { ExtensionSelectSheetLegacy } from './ExtensionSelectSheetLegacy'
import { Extension } from '../../../data/extensions/interfaces'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'

export const SessionExtensionsSheetLegacy = ( props: { session: Session; updateSession: ( u: Partial<APIUpdateSession>) => void }) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const isExtensionSheetVisible = useAppSelector(selectIsExtensionSheetVisible)
  const { session, updateSession } = props
  const [updatedExtensions, setUpdatedExtensions] = useState(session.sessionExtensions)
  const [showExtensionSheet, setShowExtensionSheet] = useState(false)

  const updateExtensions = (extension: SessionItem, paramsToUpdate: Partial<SessionItem>) => {
    const updatedSessionItem: SessionItem = assign({}, extension, paramsToUpdate)
    const extensionIndex = findIndex(updatedExtensions, (item) => item.id === extension.id)
    const extensionsToUpdate: SessionItem[] = [...updatedExtensions]
    if (extensionIndex >= 0) {
      extensionsToUpdate[extensionIndex] = updatedSessionItem
    }
    setUpdatedExtensions(extensionsToUpdate)
  }
  const deleteExtension = (extension: Partial<Extension> | any) => {
    if (extension && extension.product) {
      const toUpdate = filter([...updatedExtensions], (ext) => ext.product.id !== extension.product.id)
      setUpdatedExtensions(toUpdate)
    }
  }

  const updateSelectedExtensions = (extensions: Extension[]) => {
    // note: session items that no longer have products associated with them will implicitly be deleted
    // because we use replace_session_items=true by default
    const updated: SessionItem[] = []
    extensions.forEach((extension) => {
      const existingSessionItemForExtension: SessionItem | undefined = find(
        updatedExtensions,
        (item) => item.product.id === extension.id,
      )
      if (existingSessionItemForExtension) {
        updated.push(existingSessionItemForExtension)
      } else {
        const newSessionItem: SessionItem = {
          amount: 1,
          waste: 0,
          bowl: 1,
          product: extension,
          displayUnit: 'g',
        }
        updated.push(newSessionItem)
      }
      setUpdatedExtensions(updated)
    })
  }

  const onSave = () => {
    const apiUpdatedExtensions = mapSessionItemsToAPISessionItems(updatedExtensions)
    updateSession({ extensions: apiUpdatedExtensions })
    dispatch(reduceIsExtensionSheetVisible(false))
  }
  const selectedExtensionIds = updatedExtensions.map((item) => item.product.id)
  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={isExtensionSheetVisible}
      placement="right"
      onClose={() => dispatch(reduceIsExtensionSheetVisible(false))}
    >
      {showExtensionSheet && (
        <ExtensionSelectSheetLegacy
          selectedExtensionIds={selectedExtensionIds}
          show={showExtensionSheet}
          onHide={() => setShowExtensionSheet(false)}
          onExtensionsSelected={(extensions) => {
            updateSelectedExtensions(extensions)
            setShowExtensionSheet(false)
          }}
        />
      )}
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <Text variant="title3">Edit Extensions</Text>
            <Flex align="center" gridGap="12px">
              <Button
                variant="round-ghost-upper"
                color="brand.peach.200"
                borderColor="brand.peach.200"
                minW="100px"
                onClick={() => dispatch(reduceIsExtensionSheetVisible(false))}
              >
                Cancel
              </Button>
              <Button bg="brand.peach.200" variant="round" onClick={onSave} minW="100px">
                Save
              </Button>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <>
            <DisableAutofocus />
            <Box h="24px" />
            {updatedExtensions &&
              updatedExtensions.length > 0 &&
              updatedExtensions.map((extension, index) => {
                return (
                  <ExtensionRow
                    key={index}
                    extension={extension}
                    updateExtensions={updateExtensions}
                    deleteExtension={deleteExtension}
                  />
                )
              })}
            {updatedExtensions.length === 0 && <EmptyBox h="200px" />}
            <Box h="12px" />
            <Flex justify="flex-end">
              <Button
                className="cy-add-extensions"
                variant="round-outline"
                color="brand.peach.200"
                onClick={() => setShowExtensionSheet(true)}
              >
                <MaterialIcon name="add" style={{ marginRight: '4px' }} /> Extensions
              </Button>
            </Flex>
          </>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const ExtensionRow = (props: {
  extension: Partial<SessionItem>
  updateExtensions:
    | any
    | ((extension: Partial<SessionItem>, paramsToUpdate: Partial<SessionItem>) => void)
    | ((extension: SessionItem, paramsToUpdate: Partial<SessionItem>) => void)
  deleteExtension: (extension: Partial<SessionItem>) => void
}) => {
  const { extension, updateExtensions, deleteExtension } = props
  return (
    <Flex key={extension.id} align="center" justify="space-between" p="0 0 24px 0" cursor="pointer">
      <Box>
        <Text variant="title3">
          {extension?.product?.size}
          {'"'} • {extension?.product?.category} • {extension?.product?.type}
        </Text>

        <Text color="text.secondary">{extension?.product?.line.name}</Text>
      </Box>
      <Flex align="center" gridGap="12px">
        <Box>
          <Text color="text.secondary">Quantity</Text>
          <NumberInput
            w="120px"
            defaultValue={extension.amount || 0}
            min={0}
            onChange={(updatedAmount) => updateExtensions(extension, { amount: parseInt(updatedAmount) })}
            borderRadius="100px"
          >
            <NumberInputField borderRadius="100px" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <MaterialIcon mt="16px" name="delete" onClick={() => deleteExtension(extension)} />
      </Flex>
    </Flex>
  )
}
