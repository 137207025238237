import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, groupBy, orderBy, sortBy } from 'lodash'
import { DateRangeType } from '../../mini-lib/dates-and-times/constants'
import { RootState } from '../../store'
import {
  AddonReportAddon,
  AddonReportCSV,
  ClientDetailReportAddon,
  ClientDetailReportCSV,
  ClientDetailReportLara,
  ClientDetailReportLaraCSVRow,
  ClientReportAddon,
  ClientReportCSV,
  ClientReportLara,
  ClientReportLaraCSVRow,
  ExtensionDetailUnusedProductCSVRow,
  ExtensionDetailUsedProductCSVRow,
  ExtensionOrProductReportDetailsLara,
  ExtensionReportDetailsLaraCSVRow,
  ExtensionReportLaraCSVRow,
  ProductReport,
  StylistDetailReportAddon,
  StylistDetailReportCSV,
  StylistDetailReportLara,
  StylistDetailReportLaraCSVRow,
  StylistReport,
  StylistReportAddon,
  StylistReportCSV,
  StylistReportLaraCSVRow,
} from './interfaces'
import {
  mapAddonsClientDetailToCSV,
  mapAddonsStylistDetailToCSV,
  mapAddonsStylistToCSV,
  mapAddonsToCSV,
  mapClientDetailLaraToCSV,
  mapClientSessionDetailToCSV,
  mapClientSessionToCSV,
  mapExtensionDetailLaraToCSV,
  mapExtensionToCSV,
  mapStylistClientToCSV,
  mapStylistClientToCSVForAddon,
  mapStylistDetailToCSV,
  mapStylistToCSV,
  mapUnusedExtensionDetailToCSV,
  mapUsedExtensionDetailToCSV
} from './mappers'


interface ReportsState {
  extensionReports: ProductReport[] | null
  extensionDetailProductByBrand: ExtensionOrProductReportDetailsLara[] | null
  extensionDetailUnusedProductByBrand: ExtensionOrProductReportDetailsLara[] | null
  clientReportByStylist: { [key: string]: ClientReportLara[] } | null
  clientSessionReport: any
  clientSessionDetailReportByStylist: any
  clientDetailReport: ClientDetailReportLara[] | null
  stylistReport: StylistReport[] | null
  selectedStylistDetailReport: StylistDetailReportLara[] | null
  stylistReportAddon: StylistReportAddon[] | null
  stylistReportDetailAddon: StylistDetailReportAddon[] | null
  clientReportAddonByStylist: { [key: string]: ClientReportAddon[] } | null
  clientSessionReportAddonByStylist: any
  clientSessionDetailReportAddonByStylist: any
  clientDetailReportAddon: ClientDetailReportAddon[] | null
  currentStylist: number
  selectedDateRangeType: DateRangeType
  customStartDate: string
  customEndDate: string
  selectedCSVTitle: string
  selectedCSVType: string
  selectedUnusedCSVData: ExtensionDetailUnusedProductCSVRow[] | null
  selectedUsedCSVData:
  | ClientReportLaraCSVRow[]
  | StylistReportLaraCSVRow[]
  | StylistDetailReportLaraCSVRow[]
  | ExtensionReportLaraCSVRow[]
  | ExtensionDetailUsedProductCSVRow[]
  | ClientReportLaraCSVRow[]
  | ExtensionReportLaraCSVRow[]
  | ExtensionReportDetailsLaraCSVRow[]
  | StylistReportLaraCSVRow[]
  | StylistDetailReportLaraCSVRow[]
  | AddonReportCSV[]
  | StylistReportCSV[]
  | StylistDetailReportCSV[]
  | ClientReportCSV[]
  | ClientDetailReportCSV[]
  | null
  productDetailReportExtension: ExtensionOrProductReportDetailsLara[] | null
  addonReport: AddonReportAddon[] | null
}

const initialState: ReportsState = {
  // undefined is an accident, null is a choice, this lets us know when something is loading

  clientReportByStylist: null,
  stylistReport: null,
  extensionReports: null,
  extensionDetailProductByBrand: null,
  extensionDetailUnusedProductByBrand: null,
  selectedStylistDetailReport: null,
  clientDetailReport: null,
  stylistReportAddon: null,
  stylistReportDetailAddon: null,
  clientDetailReportAddon: null,
  clientReportAddonByStylist: null,
  clientSessionReportAddonByStylist: null,
  clientSessionDetailReportAddonByStylist: null,
  selectedDateRangeType: 'week',
  selectedUsedCSVData: null,
  selectedUnusedCSVData: null,
  selectedCSVType: '',
  selectedCSVTitle: 'report.csv',
  currentStylist: 0,
  customStartDate: '',
  customEndDate: '',
  addonReport: null,
  productDetailReportExtension: null,
  clientSessionReport: null,
  clientSessionDetailReportByStylist: null
}

export const ReportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    reduceListProductReport: (state, action: PayloadAction<ProductReport[]>) => {
      state.extensionReports = action.payload
      state.selectedUsedCSVData = mapExtensionToCSV(action.payload)
      state.selectedCSVTitle = 'products-report.csv'
      state.selectedCSVType = 'used'
    },
    reduceListAddonsReport: (state, action: PayloadAction<AddonReportAddon[]>) => {
      state.addonReport = action.payload
      state.selectedUsedCSVData = mapAddonsToCSV(action.payload)
      state.selectedCSVTitle = 'addon-report.csv'
    },
    reduceListExtensionDetailReportByBrand: (
      state,
      action: PayloadAction<{
        usedProducts: ExtensionOrProductReportDetailsLara[]
        unusedProducts: ExtensionOrProductReportDetailsLara[]
      }>,
    ) => {
      state.extensionDetailProductByBrand = action.payload.usedProducts
      state.extensionDetailUnusedProductByBrand = action.payload.unusedProducts
      state.selectedUsedCSVData = mapUsedExtensionDetailToCSV(action.payload.usedProducts)
      state.selectedUnusedCSVData = mapUnusedExtensionDetailToCSV(action.payload.unusedProducts)
      state.selectedCSVTitle = 'product-report.csv'
    },

    reduceListStylistReportAddon: (state, action: PayloadAction<StylistReportAddon[]>) => {
      state.stylistReportAddon = action.payload
      state.selectedUsedCSVData = mapAddonsStylistToCSV(action.payload)
      state.selectedCSVTitle = 'stylist-report.csv'
    },

    reduceListStylistDetailReportAddon: (state, action: PayloadAction<{ addon: StylistDetailReportAddon[], stylistName: string }>) => {
      const { addon, stylistName } = action.payload
      state.stylistReportDetailAddon = addon
      state.selectedUsedCSVData = mapAddonsStylistDetailToCSV(addon, stylistName)
      state.selectedCSVTitle = 'stylist-detail-report.csv'
    },

    reduceListClientReportAddon: (state, action: PayloadAction<ClientReportAddon[]>) => {
      const ListByStylistId: any = groupBy(action.payload, 'stylistId')
      Object.keys(ListByStylistId).forEach((clientId) => { ListByStylistId[clientId] = groupBy(ListByStylistId[clientId], 'clientId') });
      Object.keys(ListByStylistId).forEach((clientId) => {
        Object.keys(ListByStylistId[clientId]).forEach((sessionId) => {
          ListByStylistId[clientId][sessionId] = groupBy(ListByStylistId[clientId][sessionId], 'sessionId')
        })
      });
      state.clientReportByStylist = ListByStylistId
      state.selectedUsedCSVData = mapStylistClientToCSVForAddon(ListByStylistId)
      state.selectedCSVTitle = 'Client Report.csv'
      state.selectedCSVType = 'used'
    },

    reduceListClientDetailReportAddon: (state, action: PayloadAction<ClientDetailReportAddon[]>) => {
      state.clientDetailReportAddon = action.payload
      state.selectedUsedCSVData = mapAddonsClientDetailToCSV(action.payload)
      state.selectedCSVTitle = 'client-detail-report.csv'
    },

    reduceListProductExtensionDetailReportByBrand: (
      state,
      action: PayloadAction<ExtensionOrProductReportDetailsLara[]>,
    ) => {
      state.productDetailReportExtension = action.payload
      state.selectedUsedCSVData = mapExtensionDetailLaraToCSV(action.payload)
      state.selectedCSVTitle = 'product-detail-report.csv'
    },

    reduceListClientStylistReport: (state, action: PayloadAction<ClientReportLara[]>) => {
      const ListByStylistId: any = groupBy(action.payload, 'stylistId')
      Object.keys(ListByStylistId).forEach((clientId) => { ListByStylistId[clientId] = groupBy(ListByStylistId[clientId], 'clientId') });
      Object.keys(ListByStylistId).forEach((clientId) => {
        Object.keys(ListByStylistId[clientId]).forEach((sessionId) => {
          ListByStylistId[clientId][sessionId] = groupBy(ListByStylistId[clientId][sessionId], 'sessionId')
        })
      });
      state.clientReportByStylist = ListByStylistId
      state.selectedUsedCSVData = mapStylistClientToCSV(ListByStylistId)
      state.selectedCSVTitle = 'Client Report.csv'
      state.selectedCSVType = 'used'
    },

    reduceListClientSessionReport: (state, action: PayloadAction<any>) => {
      const { list: listByStyleId, csvMapper = mapClientSessionToCSV } = action.payload
      const processedList = sortBy(Object.keys(listByStyleId).map((e) => {
        const listBySessionId = listByStyleId[e]
        const profitForASession = listBySessionId.reduce((a, b) => a + b.profit, 0)
        const retailForASession = listBySessionId.reduce((a, b) => a + b.retail, 0)
        const wholesaleForASession = listBySessionId.reduce((a, b) => a + b.wholesale, 0)
        const { clientName, createdAt, sessionId, stylistName } = listBySessionId[0]
        return ({
          clientName,
          stylistName,
          createdAt,
          wholesale: wholesaleForASession,
          retail: retailForASession,
          profit: profitForASession,
          sessionId
        })
      }), 'createdAt')
      state.clientSessionReport = processedList
      state.selectedUsedCSVData = csvMapper(processedList)
      state.selectedCSVTitle = 'Client Report.csv'
      state.selectedCSVType = 'used'
    },

    reduceListClientSessionDetailReport: (state, action: PayloadAction<any>) => {
      const { list, csvMapper = mapClientSessionDetailToCSV } = action.payload
      const sortedList = sortBy(list, 'type')
      state.clientSessionDetailReportByStylist = sortedList ?? null
      state.selectedUsedCSVData = csvMapper(sortedList)
      state.selectedCSVTitle = 'Client Report.csv'
      state.selectedCSVType = 'used'
    },

    reduceListClientDetailReport: (state, action: PayloadAction<ClientDetailReportLara[]>) => {
      state.clientDetailReport = assign({}, action.payload)
      state.selectedUsedCSVData = mapClientDetailLaraToCSV(action.payload)
      state.selectedCSVTitle = 'Client Report.csv'
      state.selectedCSVType = 'used'
    },

    reduceListStylistReport: (state, action: PayloadAction<StylistReport[]>) => {
      const sortedList = sortBy(action.payload, 'stylistName')
      state.stylistReport = sortedList
      state.selectedUsedCSVData = mapStylistToCSV(sortedList)
      state.selectedCSVTitle = 'stylists-report.csv'
      state.selectedCSVType = 'used'
    },
    reduceListStylistDetailReportById: (state, action: PayloadAction<{ products: StylistDetailReportLara[], stylistName: string, csvMapper: any }>) => {
      const { products, stylistName, csvMapper = mapStylistDetailToCSV } = action.payload
      state.selectedStylistDetailReport = products
      state.selectedUsedCSVData = csvMapper(products, stylistName)
      state.selectedCSVTitle = 'stylist-report.csv'
      state.selectedCSVType = 'used'
    },

    reduceUsedOrUnusedCSV: (state, action: PayloadAction<string>) => {
      state.selectedCSVType = action.payload
    },

    reduceSelectedDateRange: (state, action: PayloadAction<DateRangeType>) => {
      state.selectedDateRangeType = action.payload
    },

    reduceCustomStartDate: (state, action: PayloadAction<string>) => {
      state.customStartDate = action.payload
    },

    reduceCustomEndDate: (state, action: PayloadAction<string>) => {
      state.customEndDate = action.payload
    },
    reduceSetCurrentStylist: (state, action: PayloadAction<number>) => {
      state.currentStylist = action.payload
    },
    reduceSetCSVData: (state, action: PayloadAction<any>) => {
      state.selectedUsedCSVData = action.payload
    }
  },
})

export const {
  reduceListProductReport,
  reduceListExtensionDetailReportByBrand,
  reduceListProductExtensionDetailReportByBrand,
  reduceListClientStylistReport,
  reduceListClientDetailReport,
  reduceListStylistReport,
  reduceListStylistDetailReportById,
  reduceListClientDetailReportAddon,
  reduceListClientReportAddon,
  reduceListStylistDetailReportAddon,
  reduceListStylistReportAddon,
  reduceSelectedDateRange,
  reduceUsedOrUnusedCSV,
  reduceSetCurrentStylist,
  reduceCustomStartDate,
  reduceCustomEndDate,
  reduceListAddonsReport,
  reduceListClientSessionReport,
  reduceListClientSessionDetailReport,
  reduceSetCSVData
} = ReportsSlice.actions

// selectors

export const selectProductReportList = (state: RootState): ProductReport[] | null => {
  return state.reports.extensionReports ? state.reports.extensionReports : null
}

export const selectAddonReportsList = (state: RootState): AddonReportAddon[] | null => {
  return state.reports.addonReport ?? null
}

export const selectProductExtensionDetailReportList = (
  state: RootState,
): ExtensionOrProductReportDetailsLara[] | null => {
  if (!state.reports.productDetailReportExtension) {
    return null
  }
  const lineSorter = ( product ) => product.line.toLowerCase()
  const categorySorter = ( product ) => product.category.toLowerCase()
  const nameSorter = ( product ) => product.color.toLowerCase()
  const sorted = orderBy(
      state.reports.productDetailReportExtension,
      [categorySorter, lineSorter, nameSorter],
      ['asc', 'asc', 'asc'],
    )
  return sorted
}

export const selectUsedProductsForBrand = (state: RootState): ExtensionOrProductReportDetailsLara[] | null => {
  return state.reports.extensionDetailProductByBrand ? state.reports.extensionDetailProductByBrand : null
}
export const selectUnusedProductsForBrand = (state: RootState): ExtensionOrProductReportDetailsLara[] | null => {
  return state.reports.extensionDetailUnusedProductByBrand ? state.reports.extensionDetailUnusedProductByBrand : null
}

export const selectStylistReportList = (state: RootState): StylistReport[] | null => {
  return state.reports.stylistReport ? state.reports.stylistReport : null
}

export const selectStylistDetailReportList = (state: RootState): StylistDetailReportLara[] | null => {
  if (!state.reports.selectedStylistDetailReport) {
    return null
  }
  const lineSorter = ( product ) => product.line.toLowerCase()
  // const categorySorter = ( product ) => product.category.toLowerCase()
  const nameSorter = ( product ) => product.color.toLowerCase()
  const sorted = orderBy(
      state.reports.selectedStylistDetailReport,
      [lineSorter, nameSorter],
      ['asc', 'asc'],
    )
  return sorted
}

// note: if you remove the any it breaks a bunch of stuff
// todo: go through and make this null safe everywhere it's used
export const selectClientReportByStylist = (state: RootState): { [key: string]: ClientReportLara[] } | null | any => {
  return state.reports.clientReportByStylist ? state.reports.clientReportByStylist : null
}

export const selectStylistReportForAddon = (state: RootState): StylistReportAddon[] | null => {
  return state.reports.stylistReportAddon ? state.reports.stylistReportAddon : null
}

export const selectStylistDetailReportForAddon = (state: RootState): StylistDetailReportAddon[] | null => {
  return state.reports.stylistReportDetailAddon ? state.reports.stylistReportDetailAddon : null
}

export const selectClientReportForAddonByStylist = (state: RootState): { [key: string]: ClientReportAddon[] } | null => {
  return state.reports.clientReportAddonByStylist ? state.reports.clientReportAddonByStylist : null
}

export const selectClientSessionReportAddonByStylist = (state: RootState): any => state.reports?.clientSessionReportAddonByStylist ?? null

export const selectClientSessionDetailReportAddonByStylist = (state: RootState): any => state.reports?.clientSessionDetailReportAddonByStylist ?? null

export const selectClientDetailReportForAddon = (state: RootState): ClientDetailReportAddon[] | null => {
  return state.reports.clientDetailReportAddon ? state.reports.clientDetailReportAddon : null
}

export const selectReportsDateRangeType = (state: RootState): DateRangeType => state.reports.selectedDateRangeType

export const selectClientSessionReport = (state: RootState): any => state.reports.clientSessionReport

export const selectClientSessionDetailReport = (state: RootState): any => {
  if (!state.reports.clientSessionDetailReportByStylist) {
    return null
  }
  // this is garbage, this should not be called type, this should be called category.
  // for some reason the addon report is accessing this, todo: fix that
  const categorySorter = ( product ) => product?.type?.toLowerCase()
  const lineSorter = ( product ) => product?.line?.toLowerCase()
  const nameSorter = ( product ) => product?.color?.toLowerCase()
  const sorted = orderBy(
    state.reports.clientSessionDetailReportByStylist,
    [categorySorter, lineSorter, nameSorter],
    ['asc', 'asc', 'asc'],
  )
  return sorted
}
export const selectCustomStartDate = (state: RootState): string => state.reports.customStartDate
export const selectCustomEndDate = (state: RootState): string => state.reports.customEndDate

export const selectUsedOrUnusedCSVType = (state: RootState): string => state.reports.selectedCSVType

export const selectCSVData = (
  state: RootState,
):
  | ClientReportLaraCSVRow[]
  | StylistReportLaraCSVRow[]
  | StylistDetailReportLaraCSVRow[]
  | ExtensionReportLaraCSVRow[]
  | ExtensionDetailUsedProductCSVRow[]
  | ClientReportLaraCSVRow[]
  | ClientDetailReportLaraCSVRow[]
  | ExtensionReportLaraCSVRow[]
  | ExtensionReportDetailsLaraCSVRow[]
  | StylistDetailReportLaraCSVRow[]
  | StylistReportLaraCSVRow[]
  | AddonReportCSV[]
  | StylistReportCSV[]
  | StylistDetailReportCSV[]
  | ClientReportCSV[]
  | ClientDetailReportCSV[]
  | null => state.reports.selectedUsedCSVData

export const selectUnusedCSV = (state: RootState): ExtensionDetailUnusedProductCSVRow[] | null => {
  return state.reports.selectedUnusedCSVData
}

export const selectCSVTitle = (state: RootState): string => state.reports.selectedCSVTitle

export default ReportsSlice.reducer
