import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { PageLayout } from "../../mini-lib/layouts/PageLayout";
import { PageHeader } from "../../mini-lib/page-header/PageHeader";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import { UseBuildSignupQueryParams, UseInitializeSignupState } from "../signup/v1/signupHooks";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { UseViewSize } from "../../core/UseViewSize";
import { Gap } from "../../mini-lib/gap/Gap";
import { COLORS } from "../../mini-lib/theme/colors";
import { Scale } from "../../data/signup/interfaces";
import { BLACK_SCALE, LINEN_SCALE } from "../../data/signup/constants";
import { reduceSetSelectedFreeScale } from "../../data/signup/slice";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../appRoutes";

export const CheckoutScalesPage = () => {
  const { isMobile } = UseViewSize()
  const breadcrumbs = []
  UseInitializeSignupState()

  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader title='Claim Your Free Scale' breadcrumbs={breadcrumbs}/>}
        content={
          <Flex justify='space-around' direction='column' align='center'>
            <>
              {isMobile && <Gap/>}
              <Text
                align='center'
                variant={isMobile ? 'largetitle' : 'gigantictext'}
                color="brand.midnight.900"
              >
                You Get a Free Gift, YAY!
              </Text>

              <Gap/>

              <Text maxW='400px' textAlign='center'>
                Your paid plan comes with a free bluetooth scale. Select your free scale color before continuing.
              </Text>

              <Gap s='72px'/>
            </>

            <Flex justify={isMobile ? 'center' : 'space-between'} gridGap='24px' w={isMobile ? '100%' : ''} wrap={isMobile ? 'wrap' : 'nowrap'}>
              <ScaleCard scale={BLACK_SCALE}/>
              <ScaleCard scale={LINEN_SCALE}/>
            </Flex>
          </Flex>
        }
      />
    </>
  )
}

export const ScaleCard = ( props: { scale: Scale } ) => {
  const dispatch = useDispatch()
  const { scale } = props
  const { isMobile } = UseViewSize()
  const {salonId} = UseBaseApiParams()
  const onScaleSelect = () => {
    dispatch(reduceSetSelectedFreeScale(scale))
  }
  return (
    <Flex w={isMobile ? '100%' : ''} justify='center'>
      <Flex
        direction='column'
        justify='space-between'
        w='350px'
        h='400px'
        bg={COLORS.shades_neutral_0}
        border={`1px solid ${COLORS.shades_neutral_300}`}
        borderRadius='16px'
      >
        <Flex justify='center' align='center'>
          <Image src={scale.imgUrl} h={isMobile ? '250px' : '300px'}/>
        </Flex>
        <Flex p='24px' justify='space-between' borderTop={`1px solid ${COLORS.shades_neutral_300}`}>
          <Box>
            <Text fontWeight='bold'>Bluetooth Scale - {scale.label}</Text>
            <Box>
              <Text as='span' textDecoration='line-through'>$60.00</Text>
              {' '}
              <Text as='span' fontWeight='bold' color={COLORS.lavender_500}>FREE</Text>
            </Box>
          </Box>
          <Link
            to={{
              pathname: generatePath(ROUTES.checkoutCart, { salonId }),
              search: UseBuildSignupQueryParams({freeScale: scale}),
            }}
          >
            <Button variant='round-outline' colorScheme='midnight' onClick={onScaleSelect}>SELECT</Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
