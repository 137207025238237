import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { InputControl, SelectControl } from 'formik-chakra-ui'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import './StylistDetailsSheet.scss'
import {
  dispatchCreateStylist,
  dispatchDeleteStylist,
  dispatchSendSalonInviteToken,
  dispatchUpdateStylist,
} from '../../data/user/api'
import {
  numberOfOwnersInSalon,
  reduceSetIsSalonMemberSheetOpen,
  reduceSetSelectedStylist,
  selectLoggedInUserSalonPermissions,
  selectSalonRoleForUser,
  selectSelectedStylist,
  selectSetIsSalonMemberSheetOpen,
} from '../../data/user/slice'
import { SalonMember, SalonRole } from '../../data/user/interfaces'
import { UseViewSize } from '../../core/UseViewSize'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { toast } from 'react-toastify'
import { COLORS } from '../../mini-lib/theme/colors'
import {
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState
} from '../../data/start-guide/slice'
import { ROLE_OPTIONS } from '../../data/salon/constants'
import { EMPTY_SALON_ROLE, EMPTY_STYLIST } from '../../data/user/utils'
import { ReleaseMultiLocation, ReleaseSmsInviteUSA } from '../../mini-lib/flags/Release'
import { Loading } from '../../mini-lib/loading/Loading'
import { HELP_DRAWER_STATES } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { USER_ROLES } from '../../data/user/constants'
import { dispatchUpdateUserChecklistItem } from '../../data/start-guide/api'
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { UseQueryParams } from "../../mini-lib/utils/basic";
import { getCountryCode } from "../../mini-lib/time/utils";
import { MaterialIcon } from "../../mini-lib/icons/MaterialIcon";
import { Gap } from "../../mini-lib/gap/Gap";

const FORM_TYPES = {
  existingUser: 'existingUser',
  inviteByEmail: 'inviteByEmail',
  inviteByPhone: 'inviteByPhone',
}
const telStyles = {
  '&:hover': { border: `1px solid ${COLORS.shades_neutral_200}`, boxShadow: 'none' },
  '&:focus': { border: `1px solid ${COLORS.shades_neutral_200}`, boxShadow: 'none', outline: 'none' },
  borderRadius: '100px',
  border: `1px solid ${COLORS.shades_neutral_200}`,
  padding: '12px 12px 12px 44px',
  width: '100%'
}

export const StylistDetailsSheetButton = () => {
  const dispatch = useDispatch()
  const stylist = useAppSelector(selectSelectedStylist)
  const existingRole = useAppSelector((state) => selectSalonRoleForUser(state))
  return (
    <>
      <StylistDetailsSheet existingRole={existingRole || EMPTY_SALON_ROLE} salonMember={stylist} />

      <Button
        variant="round"
        colorScheme="brand.midnight"
        onClick={() => {
          dispatch(reduceSetSelectedStylist(EMPTY_STYLIST))
          dispatch(reduceSetIsSalonMemberSheetOpen(true))
        }}
      >
        Invite Team
      </Button>
    </>
  )
}
export const StylistDetailsSheet = (props: { salonMember: SalonMember | null; existingRole: SalonRole }) => {
  const dispatch = useDispatch()
  const { salonMember, existingRole } = props
  const isOpen = useAppSelector(selectSetIsSalonMemberSheetOpen)
  const {
    user: { userId, token },
    salonId,
  } = UseBaseApiParams()
  const [formType, setFormType] = useState(FORM_TYPES.inviteByEmail)
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean | null>(null)
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const existingStylistId: string = salonMember && salonMember?.stylistId >= 0 ? `${salonMember.stylistId}` : ''
  const releaseMultiLocation = ReleaseMultiLocation()
  const addStylistStep = useAppSelector((state) => selectChecklistItem(state, CHECKLIST_CODES.addStylist))
  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')

  useEffect(() => {
    if (existingStylistId !== '') {
      setFormType(FORM_TYPES.existingUser)
    } else {
      setFormType(FORM_TYPES.inviteByEmail)
    }
  }, [existingStylistId])

  const emailFormInitialValues = {
    phoneNumber: '',
    countryGode: 'US',
    firstName: salonMember ? salonMember.firstName : '',
    lastName: salonMember ? salonMember.lastName : '',
    email: salonMember ? salonMember.email : '',
    stylistId: salonMember && salonMember.stylistId >= 0 ? salonMember?.stylistId : '',
    roleName: existingRole?.role?.roleName || 'stylist',
  }
  const emailFormValidationSchema = Yup.object({
    firstName:
      (!releaseMultiLocation) && formType === FORM_TYPES.inviteByEmail
        ? Yup.string().required('First Name is required')
        : Yup.string(),
    lastName:
      (!releaseMultiLocation) && formType === FORM_TYPES.inviteByEmail
        ? Yup.string().required('Last Name is required')
        : Yup.string(),
    email: formType === FORM_TYPES.inviteByEmail ? Yup.string().email('Invalid email format').required() : Yup.string(),
    phoneNumber: Yup.string(),
    countryCode: Yup.string(),
    roleName: Yup.string(),
  })

  const onSubmit = (values) => {
    if (releaseMultiLocation && formType === FORM_TYPES.inviteByEmail) {
      // calls new email invite api that allows users to self sign up
      onEmailSubmit(values)
      if (isGuide && !addStylistStep.completed) {
        dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.addStylist }))
      }
    } else if (formType === FORM_TYPES.inviteByPhone) {
      onPhoneNumberSubmit(values)
      if (isGuide && !addStylistStep.completed) {
        dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.addStylist }))
      }
    } else {
      // update stylist
      if (values.stylistId) {
        const params = {
          token,
          user_id: userId,
          existing_user_id: salonMember ? salonMember.userId : -1,
          salon_id: salonId,
          role_name: values.roleName,
          stylist_id: values.stylistId,
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
        }
        dispatch(dispatchUpdateStylist(params))
      }

      // create stylist
      if (!values.stylistId) {
        const params = {
          token: token,
          role_name: values.roleName,
          user_id: userId,
          salon_id: salonId,
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
        }
        dispatch(dispatchCreateStylist(params))
        if (isGuide && !addStylistStep.completed) {
          dispatch(dispatchUpdateUserChecklistItem({ token, checklistItemCode: CHECKLIST_CODES.addStylist }))
        }
      }
    }
    if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
    }
    dispatch(reduceSetSelectedStylist(null))
    dispatch(reduceSetIsSalonMemberSheetOpen(false))
  }

  const onPhoneNumberSubmit = (values) => {
    if (phoneNumberValid) {
      const params = {
        token: token,
        salonId,
        type: 'phone',
        phoneNumber: values.phoneNumber,
        countryCode: values.countryCode.toUpperCase(),
      }
      dispatch(dispatchSendSalonInviteToken(params))
    } else {
      toast.error('Please enter a valid phone number')
    }
  }
  const onEmailSubmit = (values) => {
    if (values.email) {
      const params = {
        token: token,
        salonId,
        type: 'email',
        email: values.email,
      }
      dispatch(dispatchSendSalonInviteToken(params))
    } else {
      toast.error('Please enter a valid phone number')
    }
  }
  const countryCode = getCountryCode();
  const userInCanada = countryCode === 'CA'
  const userInCanadaOrUsa = countryCode === 'CA' || countryCode === 'US'
  const releaseStylistInviteUsa = ReleaseSmsInviteUSA()
  const userInCountryWithSMS = releaseStylistInviteUsa ? userInCanadaOrUsa : userInCanada

  const { isMobile } = UseViewSize()
  const deleteStylist = (stylist: SalonMember) => {
    dispatch(dispatchDeleteStylist({ token, salonId, model: stylist, roleName: existingRole?.role?.roleName }))
    dispatch(reduceSetSelectedStylist(null))
    dispatch(reduceSetIsSalonMemberSheetOpen(false))
  }
  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        dispatch(reduceSetSelectedStylist(null))
        dispatch(reduceSetIsSalonMemberSheetOpen(false))
      }}
    >
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        {salonMember && (existingRole || salonMember.userId === EMPTY_STYLIST.userId) ? (
          <Formik
            onSubmit={onSubmit}
            initialValues={emailFormInitialValues}
            validationSchema={emailFormValidationSchema}
          >
            {({ handleSubmit, values, resetForm, errors, setFieldValue }) => (
              <>
                <DrawerHeader>
                  <Flex justify="space-between" align="center">
                    {values.firstName && values.lastName ? (
                      <Text variant="title1" fontWeight="bold" isTruncated={true}>
                        {values.firstName} {values.lastName}
                      </Text>
                    ) : (
                      <Text variant="title1" fontWeight="bold" isTruncated={true}>
                        Add Stylist
                      </Text>
                    )}
                    {formType === FORM_TYPES.existingUser && salonMember && (
                      <ConfirmPopover
                        title={`This Action is Permanent`}
                        subtitle={`Removing this stylist means they will no longer have access to your salon.`}
                        onConfirm={() => {
                          deleteStylist(salonMember)
                        }}
                      >
                        <Button variant="round-ghost-upper" color="danger">
                          Delete Stylist
                        </Button>
                      </ConfirmPopover>
                    )}
                  </Flex>
                </DrawerHeader>

                {/* existing users can only edit their name and email*/}
                {/* todo: in the future when we have the ability to invite by email this should be extracted to an update form*/}
                <DrawerBody>
                  {formType === FORM_TYPES.existingUser && (
                    <EditStylistForm handleSubmit={handleSubmit} existingRole={existingRole} />
                  )}

                  {formType !== FORM_TYPES.existingUser && userInCountryWithSMS && (
                    <RadioGroup onChange={setFormType} defaultValue={formType}>
                      <Flex gridGap="24px" p="0 0 24px 0" onClick={() => resetForm()}>
                        <Radio colorScheme="brand.lavender" value={FORM_TYPES.inviteByEmail} autoFocus>
                          By Email
                        </Radio>
                        <Radio colorScheme="brand.lavender" value={FORM_TYPES.inviteByPhone}>
                          By Phone
                        </Radio>
                      </Flex>
                    </RadioGroup>
                  )}

                  {(!releaseMultiLocation) && formType === FORM_TYPES.inviteByEmail && (
                    <InviteByEmailFormLegacy handleSubmit={handleSubmit} existingRole={existingRole} />
                  )}
                  {releaseMultiLocation && formType === FORM_TYPES.inviteByEmail && (
                    <InviteByEmailForm handleSubmit={handleSubmit} />
                  )}
                  {formType === FORM_TYPES.inviteByPhone && (
                    <Flex direction="column" onSubmit={handleSubmit as any}>
                      <IntlTelInput
                        telInputProps={{ style: telStyles }}
                        onPhoneNumberBlur={(isValid) => setPhoneNumberValid(isValid)}
                        onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber) => {
                          if (isValid) {
                            setPhoneNumberValid(isValid)
                          }
                          setFieldValue('countryCode', selectedCountryData.iso2)
                          setFieldValue('phoneNumber', value)
                        }}
                        containerClassName="intl-tel-input"
                        inputClassName="form-control"
                      />
                      {phoneNumberValid !== null && !phoneNumberValid && (
                        <Text color="red.500" fontSize="14px">
                          Please enter a valid phone number
                        </Text>
                      )}

                      <Gap/>
                      
                      <Flex p='12px 24px' direction='column' borderRadius='4px' bg={COLORS.linen_50} border={`1px solid ${COLORS.linen_500}`}>
                        <Flex>
                          <MaterialIcon name='warning_amber' colorhex={COLORS.linen_500}/>
                          <Text fontWeight='bold' size='14px'>Only Available to Salons in Canada {releaseStylistInviteUsa && ' or USA'}</Text>
                        </Flex>
                        <Gap s='4px'/>
                        <Text color={COLORS.text_secondary}>If your business is located outside of Canada {releaseStylistInviteUsa && ' or USA'}, please invite team via email.</Text>
                      </Flex>
                    </Flex>
                  )}
                </DrawerBody>

                <DrawerFooter>
                  <Button
                    minW="100px"
                    className="cy-back-button"
                    variant="round-outline"
                    mr={3}
                    onClick={() => {
                      dispatch(reduceSetSelectedStylist(null))
                      dispatch(reduceSetIsSalonMemberSheetOpen(false))
                    }}
                  >
                    Cancel
                  </Button>
                  <Button minW="100px" variant="round" colorScheme="brand.lavender" onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </DrawerFooter>
              </>
            )}
          </Formik>
        ) : (
          <Loading variant="clear" />
        )}
      </DrawerContent>
    </Drawer>
  )
}

const EditStylistForm = (props: { handleSubmit: any; existingRole: SalonRole }) => {
  const { handleSubmit, existingRole } = props
  const userPermissions = useAppSelector(selectLoggedInUserSalonPermissions)
  const ownerCount = useAppSelector(numberOfOwnersInSalon)
  const wontRemoveAllOwnersFromSalon = existingRole.role.roleId !== 1 || ownerCount > 1
  return (
    <>
      <Flex gridGap="12px" direction="column" onSubmit={handleSubmit as any}>
        <InputControl name="firstName" label="First Name" inputProps={{ autoFocus: true, borderRadius: '100px' }} />
        <InputControl name="lastName" label="Last Name" inputProps={{ borderRadius: '100px' }} />
        <InputControl
          inputProps={{ borderRadius: '100px' }}
          isDisabled={true}
          name="email"
          label="Email (cannot be changed)"
        />
        {userPermissions?.role === USER_ROLES.owner && wontRemoveAllOwnersFromSalon && (
          <SelectControl selectProps={{ borderRadius: '100px' }} label="Permission Level" name="roleName">
            {ROLE_OPTIONS.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            })}
          </SelectControl>
        )}
      </Flex>
    </>
  )
}

const InviteByEmailFormLegacy = (props: { handleSubmit: any; existingRole: SalonRole }) => {
  const { handleSubmit, existingRole } = props
  const userPermissions = useAppSelector(selectLoggedInUserSalonPermissions)
  const ownerCount = useAppSelector(numberOfOwnersInSalon)
  const wontRemoveAllOwnersFromSalon = existingRole.role.roleId !== 1 || ownerCount > 1
  return (
    <Flex gridGap="12px" direction="column" onSubmit={handleSubmit as any}>
      <InputControl name="firstName" label="First Name" inputProps={{ autoFocus: true, borderRadius: '100px' }} />
      <InputControl name="lastName" label="Last Name" inputProps={{ borderRadius: '100px' }} />
      <InputControl inputProps={{ borderRadius: '100px' }} name="email" label="Email" />
      {userPermissions?.role === USER_ROLES.owner && wontRemoveAllOwnersFromSalon && (
        <SelectControl selectProps={{ borderRadius: '100px' }} label="Permission Level" name="roleName">
          {ROLE_OPTIONS.map((option) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          })}
        </SelectControl>
      )}
    </Flex>
  )
}

const InviteByEmailForm = (props: { handleSubmit: any }) => {
  const { handleSubmit } = props
  return (
    <Flex gridGap="12px" direction="column" onSubmit={handleSubmit as any}>
      <InputControl inputProps={{ borderRadius: '100px' }} name="email" label="Email" />
    </Flex>
  )
}
