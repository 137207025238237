import { Flex, Tab, TabList, Tabs } from '@chakra-ui/react'
import { UseViewSize } from '../../../core/UseViewSize'
import { generatePath, useHistory } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'

import { ROUTES } from "../../../appRoutes";

export const COLOR_STYLIST_PRODUCTS_DETAILS_REPORTS_TABS = {
  products: 0,
  wasteProducts: 1,
}
export const ColorStylistReportsTabs = (props: { selectedTab: number }) => {
  const { selectedTab } = props
  const { isMobile } = UseViewSize()
  const history = useHistory()
  const { salonId } = UseBaseApiParams()
  const navigateToTab = (newIndex: number) => {
    if (newIndex === 0) {
      history.push(generatePath(ROUTES.colorStylistReports, { salonId }))
    } else if (newIndex === 1) {
      history.push(generatePath(ROUTES.colorStylistWasteReports, { salonId }))
    }
  }
  return (
    <Flex
      w="100%"
      direction={'row'}
      justify="space-between"
      pb="4px"
    >
      <Tabs index={selectedTab} onChange={(index) => navigateToTab(index)} colorScheme="brand.lavender" border="none">
        <TabList overflowX="auto" padding="4px" border="none">
          <Tab minW={isMobile ? '60px' : '200px'}>{isMobile ? 'Useage' : 'Useage'}</Tab>
          <Tab minW={isMobile ? '60px' : '200px'}>{isMobile ? 'Waste' : 'Waste Products'}</Tab>
        </TabList>
      </Tabs>
    </Flex>
  )
}
