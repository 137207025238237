import { Dispatch } from "@reduxjs/toolkit";
import { GetServerBaseUrl } from "../../env";
import axios from "axios";
import { toast } from "react-toastify";
import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";
import { buildDateYYYYMMDD, buildLaraServerDateWithTimeAs0 } from "../../core/dates";
import { reduceDashboardAnalytics, reduceUsersByStartEndInterval } from "./slice";
import { dispatchSetLoadingType } from "../../core/loading/api";


export const apiDeleteDemoData = ( params: { token: string; isDryRun: boolean, salonId: string, userId: number } ): Promise<any> => {
  const { token, isDryRun, salonId, userId } = params
  const url = `${GetServerBaseUrl('v3')}/admin/delete-demo-data/?token=${token}`
  const body = {
    is_dry_run: isDryRun,
    salon_id: salonId,
    user_id: userId
  }
  return axios
    .post(url, body)
    .then(( response: any ) => {
      return response.data
    })
    .catch(( error ) => {
      throw error
    })
}

export const dispatchDeleteDemoData = ( params: { token: string; isDryRun: boolean, salonId: string, userId: number } ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteDemoData(params).then(( resp ) => {
      toast.success(`Deleted demo data`)
    })
  }
}


export const apiGetAdminDashboardAnalytics = ( params: { token: string; interval: string; startDate: Date; endDate: Date; minSessions?: number; useCache?: boolean } ): Promise<any> => {
  const { token, interval= '1 week', startDate, endDate, minSessions = 1, useCache = true } = params
  const config = buildLaraConfig({token})

  const intervalParam = `interval=${interval}`
  const startDateParam = `start_date=${buildLaraServerDateWithTimeAs0(startDate)}`
  const endDateParam = `end_date=${buildLaraServerDateWithTimeAs0(endDate)}`
  const minSessionsParam = `minSessions=${minSessions}`
  const useCacheParam = !useCache ? `&use_cache=false` : ''
  const url = `${GetServerBaseUrl('v3', 'lara')}/admin/analytics?${intervalParam}&${startDateParam}&${endDateParam}&${minSessionsParam}&${useCacheParam}`

  return axios
    .get(url, config)
    .then(( response: any ) => {
      return response.data.data
    })
    .catch(( error ) => {
      throw error
    })
}
export const dispatchGetAdminDashboardAnalytics  = ( params: { loadingName: string; token: string; interval: string; startDate: Date, endDate: Date; minSessions?: number; useCache?: boolean } ) => {
  const startDateString = buildDateYYYYMMDD(params.startDate)
  const endDateString = buildDateYYYYMMDD(params.endDate)
  return ( dispatch: any ) => {
    dispatch(dispatchSetLoadingType({name: params.loadingName, state: true}))
    return apiGetAdminDashboardAnalytics(params).then(( resp ) => {
      dispatch(reduceDashboardAnalytics({startDateString, endDateString, interval: params.interval, analytics: resp}))
      dispatch(dispatchSetLoadingType({name: params.loadingName, state: false}))
    })
  }
}


export const apiGetAdminUserAnalytics = ( params: { token: string; startDate: Date; endDate: Date; } ): Promise<any> => {
  const { token, startDate, endDate } = params
  const config = buildLaraConfig({token})

  const startDateParam = `start_date=${buildLaraServerDateWithTimeAs0(startDate)}`
  const endDateParam = `end_date=${buildLaraServerDateWithTimeAs0(endDate)}`
  const url = `${GetServerBaseUrl('v3', 'lara')}/admin/analytics/users?${startDateParam}&${endDateParam}`

  return axios
    .get(url, config)
    .then(( response: any ) => {
      return response.data.data
    })
    .catch(( error ) => {
      throw error
    })
}

export const dispatchGetAdminUserAnalytics  = ( params: { loadingName: string; token: string; startDate: Date, endDate: Date; } ) => {
  const startDateString = buildDateYYYYMMDD(params.startDate)
  const endDateString = buildDateYYYYMMDD(params.endDate)
  return ( dispatch: any ) => {
    dispatch(dispatchSetLoadingType({name: params.loadingName, state: true}))
    return apiGetAdminUserAnalytics(params).then(( resp ) => {
      dispatch(reduceUsersByStartEndInterval({startDateString, endDateString, analytics: resp}))
      dispatch(dispatchSetLoadingType({name: params.loadingName, state: false}))
    })
  }
}
