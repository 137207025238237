import { Icon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberInput,
  NumberInputField,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { UseViewSize } from '../../../core/UseViewSize'
import {
  APIUpdateProduct,
  Product,
  ProductsByCategoryThenByLineSize,
  ProductsByLineSize,
} from '../../../data/products/interfaces'

import { capitalize, includes, keys, round } from 'lodash'
import { ColorForMode } from '../../../theme'
import { dispatchDeleteProducts, dispatchPatchColors, dispatchUpdateProducts } from '../../../data/products/api'
import { LineDetailsSheet } from './LineDetailsSheet'
import { ConfirmPopover } from '../../../mini-lib/confirm-popover/ConfirmPopover'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { addDollarSign, flattenMapOfListsToList, removeDollarSign, UseQueryParams } from '../../../mini-lib/utils/basic'
import {
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectStepCompleteDrawerState,
} from '../../../data/start-guide/slice'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { useAppSelector } from '../../../hooks'
import { selectLoadingState } from '../../../core/loading/slice'
import { buildLoadingName } from '../../../core/loading/utils'
import { Loading } from '../../../mini-lib/loading/Loading'
import { calculateMarkupNumber, getBulkUpdateInputValue, shouldShowBulkUpdateInput } from '../../../data/products/utils'
import { HELP_DRAWER_STATES } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { GROUPED_CATEGORIES } from '../../../data/products/constants'
import { COLORS } from '../../../mini-lib/theme/colors'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { getHelpStepIndex } from '../../../data/start-guide/utils'
import { reduceSetLineDetailsSheetId, selectLineDetailsSheetId } from '../../../data/products/slice'
import { ReleaseLaraColors, ReleaseUnitsSupport } from '../../../mini-lib/flags/Release'
import { TrialPricingBanner, TrialPricingTooltip } from './TrialPricing'
import { Gap } from '../../../mini-lib/gap/Gap'

interface Props {
  productsByCategoryThenByLineSize: ProductsByCategoryThenByLineSize
}

export const LinesByCategoryAccordion = (props: Props) => {
  const { productsByCategoryThenByLineSize } = props
  // using the expanded indexes to speed things up by hiding the accordion panels
  const [expandedIndexes, setExpandedIndexes] = useState([0])
  return (
    <>
      <Accordion
        allowToggle
        allowMultiple={true}
        defaultIndex={[0]}
        onChange={(indexes: any[]) => {
          setExpandedIndexes(indexes)
        }}
      >
        {keys(productsByCategoryThenByLineSize)
          .sort()
          .map((categoryKey, index) => {
            const productsByLineSize = productsByCategoryThenByLineSize[categoryKey]
            return (
              <LineCategoryAccordionPanel
                expandedIndexes={expandedIndexes}
                index={index}
                categoryName={categoryKey}
                key={categoryKey}
                productsByLineSize={productsByLineSize}
              />
            )
          })}
      </Accordion>
    </>
  )
}

export const LineCategoryAccordionPanel = ({
  expandedIndexes,
  index,
  categoryName,
  productsByLineSize,
}: {
  expandedIndexes: number[]
  index: number
  categoryName: string
  productsByLineSize: ProductsByLineSize
}) => {
  const dispatch = useDispatch()
  const bgColor = ColorForMode('secondary-bg')
  // possibly try to move this into the modal
  const products: Product[] = flattenMapOfListsToList(productsByLineSize)
  const usingTrialPricing = products.some(p => p.isDefaultPrice)
  const { isMobile, isTablet, isDesktop } = UseViewSize()
  const panelVisible = includes(expandedIndexes, index)
  const lineDetailsSheetId = useAppSelector(selectLineDetailsSheetId)
  return (
    <AccordionItem border="none">
      {lineDetailsSheetId === categoryName && (
        <LineDetailsSheet
          products={products}
          isVisible={lineDetailsSheetId === categoryName}
          onClose={() => {
            dispatch(reduceSetLineDetailsSheetId(null))
          }}
        />
      )}
      <AccordionButton
        color="black"
        bg="brand.lavender.100"
        p="12px 12px 12px 0"
        borderRadius="8px"
        mb="8px"
        w="100%"
        maxH="50px"
        border="none"
        _hover={{ bg: 'brand.lavender.200' }}
        _focus={{ border: 'none' }}
      >
        <Flex align="center" p="12px" w="100%" maxW="100%" justify="space-between">
          <Flex align="center" gridGap="12px">
            <SalonScaleProductIcon variant="nobackground" size="28px" name={categoryName.toLowerCase()} />
            <Text variant="title3" w={isMobile ? '225px' : '100%'} fontWeight="normal" isTruncated={true}>
              {capitalize(categoryName)}
            </Text>
          </Flex>
          {/*<Text*/}
          {/*  color="brand.lavender.500"*/}
          {/*  onClick={(e) => {*/}
          {/*    e.preventDefault()*/}
          {/*    dispatch(reduceSetLineDetailsSheetId(categoryName))*/}
          {/*  }}*/}
          {/*>*/}
          {/*  View All {isDesktop && capitalize(categoryName)}*/}
          {/*</Text>*/}
        </Flex>
        {panelVisible ? <MaterialIcon name="arrow_drop_up" /> : <MaterialIcon name="arrow_drop_down" />}
      </AccordionButton>
      {panelVisible && (
        <AccordionPanel p="0 0 4px 0" bg={(index + 1) % 2 === 0 ? bgColor : ''}>
          {usingTrialPricing && <TrialPricingBanner/>}
          <Gap s='12px'/>
          <Flex gridGap="12px" justify="space-between" pb="24px">
            <Box w={getColumnWidth({ size: 'big', isMobile, isTablet, isDesktop })} color="text.secondary">
              line
            </Box>
            {!isMobile && (
              <Box w={getColumnWidth({ size: 'small', isMobile, isTablet, isDesktop })} color="text.secondary">
                subitems
              </Box>
            )}
            <Flex color="text.secondary" w="120px" justify="center">
              cost/unit
            </Flex>
            <Box color="text.secondary" w={isMobile ? '24px' : '200px'}></Box>
          </Flex>
          {keys(productsByLineSize)
            .sort()
            .map((key, index) => {
              return <ProductsInLine key={key} index={index} products={productsByLineSize[key]} />
            })}
        </AccordionPanel>
      )}
    </AccordionItem>
  )
}

export const ProductsInLine = (props: { products: Product[]; index: number }) => {
  const dispatch = useDispatch()
  const { isMobile, isTablet, isDesktop } = UseViewSize()
  const { user, salonId } = UseBaseApiParams()
  const { products, index } = props
  const product = products[0]
  const releaseColors = ReleaseLaraColors()
  const isAutoPrice = product.isDefaultPrice
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const lineDetailsSheetId = useAppSelector(selectLineDetailsSheetId)
  const initialValue = product?.inventory?.cost?.toString() ? product.inventory.cost.toFixed(2) : '0'
  const [value, setValue] = useState<any>(initialValue)
  const loadingName = buildLoadingName(product.id)
  const isLoading = useAppSelector((state) => selectLoadingState(state, loadingName))
  const showBulkUpdateInput = shouldShowBulkUpdateInput(products)
  const bulkInputValue = getBulkUpdateInputValue(products)
  const isGroupedCategory = GROUPED_CATEGORIES.includes(product.category.toLowerCase())
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')
  const uniqId = product.id
  const releaseLaraColors = ReleaseLaraColors()

  useEffect(() => {
    setValue(bulkInputValue)
  }, [bulkInputValue])

  const onUpdateCost = (updatedCost: string) => {
    const defaultMarkup = user.currentSalonContext?.defaultMarkupPercentage || 0
    const wholesalePrice = updatedCost ? round(parseFloat(updatedCost), 2) : 0
    const currentWholesale = products[0].inventory.cost
    const currentMarkup = calculateMarkupNumber(products[0])
    // dont call update api if nothing changed
    if (wholesalePrice === currentWholesale) {
      return
    }
    const updatedProducts: APIUpdateProduct[] = products.map((product) => {
      return {
        product_id: product.id,
        wholesale_price: wholesalePrice,
        is_default_price: false,
        mark_up: currentMarkup ? currentMarkup : defaultMarkup,
      }
    })
    const params = {
      token: user.token,
      user_id: user.userId,
      salon_id: salonId,
      models: updatedProducts,
      loadingName,
    }

    if (releaseColors) {
      dispatch(dispatchPatchColors(params))
    } else {
      dispatch(dispatchUpdateProducts(params))
    }
    if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
    }
  }

  return (
    <>
      <Flex className="cy-line-size-row" justify="space-between" align="center" pb="24px" maxWidth="100%" width="100%">
        {lineDetailsSheetId === uniqId && (
          <LineDetailsSheet
            isEntireCategory={false}
            products={products}
            isVisible={lineDetailsSheetId === uniqId}
            onClose={() => {
              dispatch(reduceSetLineDetailsSheetId(null))
            }}
          />
        )}
        {/* line column */}
        <LineColumn
          width={getColumnWidth({ size: 'big', isMobile, isTablet, isDesktop })}
          products={products}
          product={product}
          isGroupedCategory={isGroupedCategory}
          isMobile={isMobile}
        />

        {/* subitems column */}
        {!isMobile && (
          <Text w={getColumnWidth({ size: 'small', isMobile, isTablet, isDesktop })} variant="callout">
            {products?.length} Items
          </Text>
        )}

        {/* bulk update column */}
        <Flex align="center" w="120px">
          {showBulkUpdateInput && (
            <HelpPopover
              description="Quickly update the cost for all subitems in a product line here"
              stepIndex={getHelpStepIndex(index, 1)}
              guideTypes={[CHECKLIST_CODES.autoPricing]}
            >
              <Box>
                <HelpPopover
                  guideTypes={[CHECKLIST_CODES.addLines, CHECKLIST_CODES.addPricing]}
                  children={
                    <NumberInput
                      w="120px"
                      minW="120px"
                      min={0}
                      precision={2}
                      onChange={(updatedPrice) => setValue(removeDollarSign(updatedPrice))}
                      onBlur={(evt: any) => {
                        const value = removeDollarSign(evt.target.value)
                        onUpdateCost(value)
                      }}
                      value={addDollarSign(value)}
                    >
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <Flex>
                          <NumberInputField textAlign="center" p="0 16px" borderRadius="50px" />
                          {isAutoPrice && <TrialPricingTooltip/>}
                        </Flex>
                      )}
                    </NumberInput>
                  }
                  description="Quickly add a cost for all subitems in a product line here"
                  stepIndex={getHelpStepIndex(index, 0)}
                  placement="left"
                />
              </Box>
            </HelpPopover>
          )}
          {!showBulkUpdateInput && (
            <Flex w="100%" justify="center" fontStyle="italic" color={COLORS.text_secondary}>
              Multiple
            </Flex>
          )}
        </Flex>

        {/* mobile menu column */}
        {isMobile && (
          <Menu>
            <MenuButton w="24px">
              <Icon as={() => <i className="material-icons-round">more_vert</i>} />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  dispatch(reduceSetLineDetailsSheetId(uniqId))
                }}
              >
                View All
              </MenuItem>
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`This will remove ${products.length} products from your salon`}
                onConfirm={() => {
                  dispatch(
                    dispatchDeleteProducts({
                      token: user.token,
                      salonId,
                      models: products,
                      releaseLaraColors
                    }),
                  )
                }}
              >
                <Text p="12px" w="100px" color="danger" variant="ghost" colorScheme="brand.midnight">
                  Delete
                </Text>
              </ConfirmPopover>
            </MenuList>
          </Menu>
        )}

        {/* non mobile action items */}
        {!isMobile && (
          <Flex align="center" w="200px">
            {guideParam === CHECKLIST_CODES.inventorySetTargets ? (
              <HelpPopover
                guideTypes={[CHECKLIST_CODES.inventorySetTargets]}
                children={
                  <Button
                    className="cy-sidesheet-open"
                    onClick={() => {
                      dispatch(reduceSetLineDetailsSheetId(uniqId))
                      dispatch(reduceSetCurrentSubStepIndex(1))
                    }}
                    colorScheme="brand.lavender"
                    variant="round-ghost"
                  >
                    View All
                  </Button>
                }
                description="Click edit to enter targets"
                stepIndex={getHelpStepIndex(index, 0)}
                placement="bottom"
              />
            ) : (
              <HelpPopover
                guideTypes={[CHECKLIST_CODES.autoPricing]}
                description="Click 'edit' to update costs for individual products within a line."
                stepIndex={getHelpStepIndex(index, 2)}
              >
                <Box>
                  <HelpPopover
                    guideTypes={[CHECKLIST_CODES.addLines, CHECKLIST_CODES.addPricing]}
                    children={
                      <Button
                        className="cy-sidesheet-open"
                        onClick={() => {
                          dispatch(reduceSetLineDetailsSheetId(uniqId))
                        }}
                        colorScheme="brand.lavender"
                        variant="round-ghost"
                      >
                        View All
                      </Button>
                    }
                    description="Click 'edit' to update costs for individual products within a line."
                    stepIndex={getHelpStepIndex(index, 2)}
                    placement="bottom"
                  />
                </Box>
              </HelpPopover>
            )}

            {/*</Tooltip>*/}
            <ConfirmPopover
              title={`This Action is Permanent`}
              subtitle={`This will remove ${products.length} products from your salon`}
              onConfirm={() => {
                dispatch(
                  dispatchDeleteProducts({
                    token: user.token,
                    salonId,
                    models: products,
                    releaseLaraColors
                  }),
                )
              }}
            >
              <Button w="100px" colorScheme="brand.lavender" variant="round-ghost">
                Delete
              </Button>
            </ConfirmPopover>
          </Flex>
        )}
      </Flex>
    </>
  )
}

const getColumnWidth = (params: {
  size: 'big' | 'medium' | 'small'
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}): string => {
  const { size, isMobile, isTablet, isDesktop } = params
  if (size === 'big' && isDesktop) {
    return '300px'
  }
  if (size === 'big' && isTablet) {
    return '250px'
  }
  if (size === 'big' && isMobile) {
    return '200px'
  }

  if (size === 'medium' && isDesktop) {
    return '200px'
  }
  if (size === 'medium' && isTablet) {
    return '150px'
  }
  if (size === 'medium' && isMobile) {
    return '100px'
  }

  if (size === 'small' && isDesktop) {
    return '100px'
  }
  if (size === 'small' && isTablet) {
    return '100px'
  }
  if (size === 'small' && isMobile) {
    return '100px'
  }

  return ''
}

const LineColumn = ({ width, product, products, isGroupedCategory, isMobile }) => {
  const releaseUnitsSupport = ReleaseUnitsSupport();
  const staticUnit = isMobile ? 'g/ml' : 'g or ml'
  const units = releaseUnitsSupport ? product?.units ?? staticUnit : staticUnit
  return (
    <Flex align="center" gridGap="12px" w={width} paddingRight="12px">
      <SalonScaleProductIcon name={product?.category?.toLowerCase()} />
      <Flex direction="column" justify="center" isTruncated>
        <Tooltip label={product.line.name}>
          <Box>
            <Text variant="callout">{product.line.name}</Text>
          </Box>
        </Tooltip>
        <Flex gridGap="4px">
          <Text variant="footnote" color={COLORS.text_secondary}>
            {product.vendor.name} •
          </Text>
          {isGroupedCategory && products?.length !== 1 ? (
            <Text variant="footnote" color={COLORS.text_secondary}>
              Multiple Sizes
            </Text>
          ) : (
            <Text variant="footnote" color={COLORS.text_secondary}>
              {product.size}
              {units}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
