import {
  BowlType,
  BowlTypeLara,
  Session,
  SessionColorLara,
  SessionExtensionLara,
  SessionItem,
  SessionMetaLara,
  SessionSupplyLara
} from './interfaces'
import { filter, keys } from 'lodash'
import { sameDay } from '../../core/dates'
import { isDateInRangeInclusive } from "../../mini-lib/utils/dates";
import { Units } from '../../data/reports-v2/interfaces'

export const filterSessions = (
  models: Session[],
  searchText: string,
  clientId: number | null,
  stylistId: number | null,
  selectedDate: Date | null,
): Session[] => {
  const normalizedText = searchText.toLowerCase()
  return filter(models, (model) => {
    if (clientId && stylistId) {
      return model.stylist.stylistId === stylistId && model.client.id === clientId
    }
    if (selectedDate) {
      const modelDate = new Date(model.date)
      return sameDay(modelDate, selectedDate)
    }
    if (clientId) {
      return model.client.id === clientId
    }
    if (stylistId) {
      return model.stylist.stylistId === stylistId
    }
    if (model.stylist.firstName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (model.stylist.lastName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (model.client.firstName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (model.client.lastName.toLowerCase().includes(normalizedText)) {
      return true
    }
    if (model?.date?.toLowerCase().includes(normalizedText)) {
      return true
    }

    return false
  })
}

export const getBowlMap = (sessionItems: SessionItem[]): { [key: number]: SessionItem[] } => {
  const map: { [key: number]: SessionItem[] } = {}
  sessionItems.forEach((item) => {
    const key = item.bowl
    if (map.hasOwnProperty(key)) {
      map[key] = [...map[key], item]
    } else {
      map[key] = [item]
    }
  })
  return map
}

export const getSessionItemsByBowl = (sessionItems: SessionItem[]): { bowls: SessionItem[][]; bowlIds: number[] } => {
  const itemsByBowl: SessionItem[][] = []
  const bowlMap = getBowlMap(sessionItems)
  Object.keys(bowlMap).forEach((bowlKey: any) => {
    itemsByBowl.push(bowlMap[bowlKey])
  })
  return { bowls: itemsByBowl, bowlIds: keys(bowlMap).map((id) => parseInt(id)) }
}

export const getBowlTitle = (item: SessionItem):{ bowlType: string|null, bowlTag: string|null } => {
  if (item?.bowlType?.name && item?.userDefinedBowlName) {
    return ({bowlType: item.bowlType.name, bowlTag: item.userDefinedBowlName })
  }
  if (item?.userDefinedBowlName) {
    return ({bowlType: null, bowlTag: item.userDefinedBowlName })
  }
  if (item?.bowlType?.name) {
    return ({bowlType: item.bowlType.name, bowlTag: null })
  }
  return ({bowlType: `Bowl ${item.bowl}`, bowlTag: null})
}

export const getBowlType = (item: SessionItem): BowlType | null => {
  return item.bowlType ? item.bowlType : null
}

export const getSessionItemCost = (sessionItem: SessionItem, bowlView: 'amount' | 'waste'): number => {
  if (!sessionItem || !sessionItem.product) {
    return 0
  }
  if (sessionItem.displayUnit === 'g') {
    if (bowlView === 'waste') {
      return sessionItem.waste ? Math.round(sessionItem.waste * sessionItem.product.pricing.pricePerG * 100) / 100 : 0
    }
    return Math.round(sessionItem.amount * sessionItem.product.pricing.pricePerG * 100) / 100
  }
  if (bowlView === 'waste') {
    return sessionItem.waste ? Math.round(sessionItem.waste * sessionItem.product.pricing.pricePerOz * 100) / 100 : 0
  }
  return Math.round(sessionItem.amount * sessionItem.product.pricing.pricePerOz * 100) / 100
}

export const getTotalSessionItemCost = (
  sessionItems: SessionItem[],
  bowlView: 'amount' | 'waste' = 'amount',
): number => {
  let total = 0
  sessionItems.forEach((item) => {
    total += getSessionItemCost(item, bowlView)
  })
  return Math.round(total * 100) / 100
}

export const getSessionWasteTotal = (session: Session): number => {
  let total = 0
  session.sessionItems.forEach((item) => {
    total += getSessionItemCost(item, 'waste')
  })
  return Math.round(total * 100) / 100
}

export const getTotalSessionExtensionCost = (session: Session): number => {
  let total = 0
  session.sessionExtensions.forEach((item) => {
    // todo: possibly add pricing.price to response and use that even though pricePerG is normalized for extensions
    total += item.amount * item.product.pricing.pricePerG
  })
  return Math.round(total * 100) / 100
}

export const getTotalSessionSuppliesCost = (session: Session): number => {
  let total = 0
  session.sessionSupplies.forEach((item) => {
    total += item.pricePerUnit * item.quantity
  })
  return Math.round(total * 100) / 100
}

export const getTotalSessionLaborItemsCost = (session: Session): number => {
  let total = 0
  session.sessionLaborItems.forEach((item) => {
    const pricePerSecond = item.pricePerHour / 60
    total += item.durationSeconds * pricePerSecond
  })
  return Math.round(total * 100) / 100
}

export const getColorForSessionStatus = (status: string): string => {
  if (status === 'open') {
    return 'success'
  }
  return 'shades.neutral.400'
}

export const normalizeToGrams = (amount: number, units: 'g' | 'oz') => {
  if (units === 'g') {
    return amount
  }
  // python function round(Types.cast_decimal_safe(weight_oz) * Decimal(28.35), 2)
  return Math.round(amount * 28.35 * 100) / 100
}

// note: the original units will always be grams because the value stored on the session item is always grams
// in the event we start allowing users to select units as well as amounts then we'll need to start doing conversions the other way
//
export const convertDisplayUnits = (amount: number, originalUnits: Units | string, desiredUnits: 'g' | 'oz'| 'unit' | string): number => {
  if ((originalUnits === 'g' && desiredUnits === 'g') || (originalUnits === 'oz' && desiredUnits === 'oz')) {
    return amount
  }
  if (originalUnits === 'oz' && desiredUnits === 'g') {
    return Math.round(amount * 28.35 * 100) / 100
  }
  if (originalUnits === 'g' && desiredUnits === 'oz') {
    return Math.round((amount / 28.35) * 100) / 100
  }
  return amount
}


// lara sessions
//
//
//
export const filterSessionsLara = (params: {
  models: SessionMetaLara[],
  clientId?: number | null,
  userId?: number | null,
  selectedDate?: Date | null,
  startDate?: Date | null,
  endDate?: Date | null,
  status?: string
}): SessionMetaLara[] => {
  const {models, clientId, userId, status, selectedDate, startDate, endDate} = params

  return filter(models, (model) => {
    const hasClient = clientId ? model.client.id === clientId : true
    const hasUser = userId ? model.user.userId === userId : true
    const hasStatus = status ? model.status === status : true
    const hasSelectedDate = selectedDate ? sameDay(new Date(model.date), selectedDate) : true
    const hasDateRange = startDate && endDate ? isDateInRangeInclusive({date: model.date, start: startDate, end: endDate}) : true
    return hasClient && hasUser && hasStatus && hasSelectedDate && hasDateRange
  })
}

export const getSessionSuppliesLaraTotalCost = (sessionSupplies: SessionSupplyLara[]): number => {
  let total = 0
  sessionSupplies.forEach((item) => {
    total += item.priceCents * item.quantity
  })
  return Math.round(total * 100) / 100
}

export const getSessionExtensionsLaraTotalCost = (sessionSupplies: SessionExtensionLara[]): number => {
  let total = 0
  sessionSupplies.forEach((item) => {
    total += item.quantity * item.product.clientPurchasePriceCents
  })
  return Math.round(total * 100) / 100
}


export const getBowlMapLara = (sessionColors: SessionColorLara[]): { [key: number]: SessionColorLara[] } => {
  const map: { [key: number]: SessionColorLara[] } = {}
  sessionColors.forEach((item) => {
    const key = item.bowl
    if (map.hasOwnProperty(key)) {
      map[key] = [...map[key], item]
    } else {
      map[key] = [item]
    }
  })
  return map
}

export const getSessionColorsByBowlLara = (bowlMapLara: {[key: number]: SessionColorLara[]}): SessionColorLara[][]=> {
  const itemsByBowl: SessionColorLara[][] = []
  Object.keys(bowlMapLara).forEach((bowlKey: any) => {
    itemsByBowl.push(bowlMapLara[bowlKey])
  })
  return itemsByBowl
}

export const getBowlTypeLara = (item: SessionColorLara): BowlTypeLara | null => {
  return item.bowlType ? item.bowlType : null
}
export const getBowlTagLara = (item: SessionColorLara): string => {
  return item.bowlNickname ? item.bowlNickname : ''
}

export const getSessionColorCostCentsLara = (sessionColor: SessionColorLara, bowlView: 'amount' | 'waste'): number => {
  if (!sessionColor || !sessionColor.product) {
    return 0
  }
  // we dont need units here because the total will be the same either way
  if (bowlView === 'waste') {
    return sessionColor.wasteGrams ? sessionColor.wasteTotalPriceCents : 0
  }
  return sessionColor.clientTotalPriceCents
}
export const getTotalSessionColorCostLara = (
  sessionItems: SessionColorLara[],
  bowlView: 'amount' | 'waste' = 'amount',
): number => {
  let total = 0
  sessionItems.forEach((item) => {
    total += getSessionColorCostCentsLara(item, bowlView)
  })
  return total
}

export const getBowlTitleLara = (item: SessionColorLara):{ bowlType: string|null, bowlTag: string|null } => {
  if (item?.bowlType?.name && item?.bowlNickname) {
    return ({bowlType: item.bowlType.name, bowlTag: item.bowlNickname })
  }
  if (item?.bowlNickname) {
    return ({bowlType: null, bowlTag: item.bowlNickname })
  }
  if (item?.bowlType?.name) {
    return ({bowlType: item.bowlType.name, bowlTag: null })
  }
  return ({bowlType: `Bowl ${item.bowl}`, bowlTag: null})
}

export const convertDisplayUnitsLara = (amount: number, originalUnits: 'g' | 'oz', desiredUnits: 'g' | 'oz'): number => {
  if ((originalUnits === 'g' && desiredUnits === 'g') || (originalUnits === 'oz' && desiredUnits === 'oz')) {
    return amount
  }
  if (originalUnits === 'oz' && desiredUnits === 'g') {
    return amount * 28.35
  }
  if (originalUnits === 'g' && desiredUnits === 'oz') {
    return amount / 28.35
  }
  return amount
}
