import { Box, Button, Circle, Divider, Flex, Image, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { UseViewSize } from '../../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { selectEmptyBowls } from '../../../data/empty-bowls/slice'
import { dispatchListEmptyBowls } from '../../../data/empty-bowls/api'
import { EmptyBowl } from '../../../data/empty-bowls/interfaces'
import { COLORS } from '../../../mini-lib/theme/colors'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { BOWL_SHEET_VIEW_TYPES, BowlSheetViewType } from '../../../data/sessions/constants'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'

export const SessionBowlEmptyBowlsViewLegacy = ( props: {
  setSelectedEmptyBowl: (bowl: EmptyBowl) => void
  setBowlView: (view: BowlSheetViewType) => void
  onEmptyBowlSelect: (bowl: EmptyBowl) => void
}) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const { salonId, user } = UseBaseApiParams()
  const { setSelectedEmptyBowl, onEmptyBowlSelect, setBowlView } = props
  const emptyBowls = useAppSelector(selectEmptyBowls)
  const emptyBowlsLoaded = !!emptyBowls
  useEffect(() => {
    if (!emptyBowlsLoaded) {
      dispatch(dispatchListEmptyBowls({ token: user.token, salonId }))
    }
  }, [dispatch, user.token, emptyBowlsLoaded, salonId])

  return (
    <>
      <Flex justify="space-between" gridGap="12px">
        <MaterialIcon
          cursor="pointer"
          colorhex={COLORS.lavender_500}
          size="36px"
          name="chevron_left"
          onClick={() => setBowlView(BOWL_SHEET_VIEW_TYPES.waste)}
        />
        <MaterialIcon
          cursor="pointer"
          colorhex={COLORS.lavender_500}
          size="36px"
          name="add"
          onClick={() => setBowlView(BOWL_SHEET_VIEW_TYPES.emptyBowlDetails)}
        />
      </Flex>

      <Box h="12px" />

      <Text variant="largetitle" textTransform="uppercase">
        empty bowls
      </Text>

      <Box h="12px" />

      {emptyBowls?.map((bowl) => {
        return (
          <Box key={bowl.id}>
            <Box h="12px" />
            <Flex key={bowl.id} align="center" gridGap="24px" justify="space-between">
              <Flex align="center" gridGap="24px" onClick={() => onEmptyBowlSelect(bowl)} cursor="pointer">
                <Circle bg={COLORS.shades_neutral_200} overflow="hidden" size={isMobile ? '40px' : '60px'}>
                  {bowl.photoUrl ? (
                    <Image src={bowl.photoUrl} />
                  ) : (
                    <SalonScaleProductIcon name="emptybowl" size={isMobile ? '40px' : '60px'} />
                  )}
                </Circle>
                {bowl.name}
              </Flex>
              <Button
                onClick={() => {
                  setBowlView(BOWL_SHEET_VIEW_TYPES.emptyBowlDetails)
                  setSelectedEmptyBowl(bowl)
                }}
                variant="round-ghost-lower"
              >
                edit
              </Button>
            </Flex>
            <Box h="12px" />
            <Divider />
          </Box>
        )
      })}
      <Box h="24px" />
      {!emptyBowlsLoaded && <Loading />}
      {emptyBowls && emptyBowls.length === 0 && (
        <EmptyBox h="500px" title="No Empty Bowls" content="Please add an empty bowl to continue" />
      )}
    </>
  )
}
