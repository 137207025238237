import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Select,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'

import {
  reduceExtensionFilters,
  selectExtensionFilters,
  selectExtensions,
  selectExtensionsById,
} from '../../../data/extensions/slice'
import { dispatchListExtensions } from '../../../data/extensions/api'
import { Extension, ExtensionFilters } from '../../../data/extensions/interfaces'
import { ColorForMode } from '../../../theme'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { assign, keys, omit } from 'lodash'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { filterExtensions } from '../../../data/extensions/utils'
import { StickySearch } from '../../../mini-lib/search/StickySearch'
import { getCategoryNames } from '../../../data/products/utils'
import { ReleaseLaraColors } from "../../../mini-lib/flags/Release";

export const ExtensionSelectSheetLegacy = ( props: {
  show: boolean
  onHide: () => void
  selectedExtensionIds: number[]
  onExtensionsSelected: (extensions: Extension[]) => void
}) => {
  const dispatch = useDispatch()
  const extensionFilters = useAppSelector(selectExtensionFilters)
  const { show, onHide, onExtensionsSelected, selectedExtensionIds } = props
  return (
    <Drawer isOpen={show} placement="right" size="md" onClose={onHide}>
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent p="0">
        <DrawerBody p="0">
          <Box p="24px">
            <Flex justify="space-between">
              <Text variant="title1" fontWeight="bold">
                extensions
              </Text>
              <Button
                className="cy-extension-sheet-cancel"
                color="brand.peach.200"
                borderColor="brand.peach.200"
                variant="round-outline"
                minW="100px"
                onClick={onHide}
              >
                Cancel
              </Button>
            </Flex>
            <Text>Select extensions to add to bowl</Text>
            <Box h="24px" />
            <Flex gridGap="12px" justify="space-between">
              <StickySearch
                iconColor="brand.peach.200"
                isSticky={false}
                variant="clear"
                placeholder={'Search Extensions'}
                searchText={extensionFilters.searchText || ''}
                setSearchText={(t: string) => {
                  const updatedExtensionFilters: ExtensionFilters = assign({}, extensionFilters, { searchText: t })
                  dispatch(reduceExtensionFilters(updatedExtensionFilters))
                }}
              />
              <CategorySelect />
            </Flex>
          </Box>
          <ExtensionSheetList selectedExtensionIds={selectedExtensionIds} onExtensionsSelected={onExtensionsSelected} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const ExtensionSheetList = (props: {
  selectedExtensionIds: number[]
  onExtensionsSelected: (extensions: Extension[]) => void
}) => {
  const dispatch = useDispatch()
  const extensions = useAppSelector(selectExtensions)
  const extensionsById = useAppSelector(selectExtensionsById)
  const extensionFilters = useAppSelector(selectExtensionFilters)
  const { user, salonId } = UseBaseApiParams()
  const releaseLaraColors = ReleaseLaraColors()
  const extensionsLoaded = !!extensions
  const { selectedExtensionIds, onExtensionsSelected } = props
  const [numExtensionsToShow, setNumExtensionsToShow] = useState(50)
  useEffect(() => {
    if (!extensionsLoaded) {
      dispatch(dispatchListExtensions({ token: user.token, salonId, releaseLaraColors }))
    }
  }, [dispatch, user.token, salonId, extensionsLoaded, releaseLaraColors])

  const initiallySelectedExtensionMap = {}
  selectedExtensionIds.forEach((id) => {
    initiallySelectedExtensionMap[id] = true
  })

  const [selectedExtensionMap, setSelectedExtensionMap] = useState(initiallySelectedExtensionMap)
  const toggleExtensionSelected = (extensionId: number) => {
    if (extensionId in selectedExtensionMap) {
      const updatedMap = omit(selectedExtensionMap, [extensionId])
      setSelectedExtensionMap(updatedMap)
    } else {
      const updatedMap = assign({}, selectedExtensionMap, { [extensionId]: true })
      setSelectedExtensionMap(updatedMap)
    }
  }
  const filteredExtensions: Extension[] = extensions ? filterExtensions(extensions, extensionFilters) : []
  const selectedExtensions = extensionsById
    ? keys(selectedExtensionMap).map((extensionId) => {
        return extensionsById[extensionId.toString()]
      })
    : []
  return (
    <>
      {!extensionsLoaded && <Loading variant="clear" />}
      {extensionsLoaded && extensions.length > 0 && (
        <>
          {filteredExtensions && filteredExtensions.length > 0 ? (
            filteredExtensions.slice(0, numExtensionsToShow).map((extension, index) => {
              return (
                <ExtensionRow
                  toggleExtensionSelected={toggleExtensionSelected}
                  index={index}
                  isSelected={selectedExtensionMap[extension.id] || false}
                  extension={extension}
                  key={extension.id}
                />
              )
            })
          ) : (
            <EmptyBox content="No extensions match that filter" />
          )}
          {numExtensionsToShow < filteredExtensions.length && (
            <Flex justify="center" m="12px 0">
              <Button
                color="brand.peach.200"
                variant="ghost"
                onClick={() => setNumExtensionsToShow(numExtensionsToShow + 50)}
              >
                View More Extensions
              </Button>
            </Flex>
          )}
          <Box h="60px" />
          <Box position="absolute" p="" m="0">
            <Flex position="fixed" bottom="0" justify="center" p="0" w="100%" h="60px">
              <Button
                bg="brand.peach.200"
                variant="round"
                maxW="100%"
                w="350px"
                p="24px"
                onClick={() => onExtensionsSelected(selectedExtensions)}
              >
                <Box w="100%">
                  <Box w="100%">Add Extensions</Box>
                  <Box w="100%">
                    <SelectedFormula extensions={selectedExtensions} />
                  </Box>
                </Box>
              </Button>
            </Flex>
          </Box>
        </>
      )}
      {extensionsLoaded && extensions.length === 0 && (
        <EmptyBox title="You don't have any extensions yet!" content="Please add some extensions and come back" />
      )}
    </>
  )
}

export const ExtensionRow = ({
  index,
  extension,
  isSelected,
  toggleExtensionSelected,
}: {
  index: number
  extension: Extension
  isSelected: boolean
  toggleExtensionSelected: (extensionId: number) => void
}) => {
  return (
    <Box
      className="cy-extension-row"
      p="12px 24px"
      bg={(index + 1) % 2 === 0 ? ColorForMode('secondary-bg') : ''}
      maxW="100%"
      onClick={() => toggleExtensionSelected(extension.id)}
    >
      <Flex align="center" gridGap="12px" cursor="pointer">
        {isSelected ? (
          <MaterialIcon colorhex={COLORS.peach_200} name="check_circle" />
        ) : (
          <MaterialIcon colorhex={COLORS.peach_200} name="radio_button_unchecked" />
        )}
        <Flex align="center" gridGap="12px">
          <Box isTruncated={true}>
            <Text>
              {extension.size}
              {'"'} • {extension.category} • {extension.type}
            </Text>
            <Text>{extension.line.name}</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export const SelectedFormula = (props: { extensions: Extension[] }) => {
  const { extensions } = props
  // many of our types have a + on the end of them, that is confusing here
  const cleanType = (type: string): string => (type.trim().slice(-1) === '+' ? type.slice(0, type.length - 1) : type)
  return (
    <Flex align="center" justify="center" isTruncated={true}>
      {extensions.map((extension, index) => {
        return (
          <Flex key={extension?.id} align="center">
            <Text fontSize="10px">{extension?.type ? cleanType(extension.type) : ''}</Text>
            {index + 1 !== extensions.length && <Box m="0 8px">+</Box>}
          </Flex>
        )
      })}
    </Flex>
  )
}

export const CategorySelect = () => {
  const dispatch = useDispatch()
  const extensionFilters = useAppSelector(selectExtensionFilters)
  const extensions = useAppSelector(selectExtensions)
  const categoryNames = extensions ? getCategoryNames(extensions) : []
  return (
    <Flex justify="flex-end">
      <Select
        value={extensionFilters.categoryName || ''}
        borderRadius="100px"
        placeholder="All Categories"
        onChange={(e: any) => {
          const updatedExtensionFilters: ExtensionFilters = assign({}, extensionFilters, {
            categoryName: e.target.value,
          })
          dispatch(reduceExtensionFilters(updatedExtensionFilters))
        }}
      >
        {categoryNames.map((name: string) => {
          return (
            <option key={name} value={name}>
              {name}
            </option>
          )
        })}
      </Select>
    </Flex>
  )
}
