import {
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import {
  dispatchDeleteOrderLara,
  dispatchGetOrderLara,
  dispatchOrderGeneratePdfLara
} from '../../data/orders/api'
import { OrderItemCSV, OrderItemLara, OrderLara, OrderShippingAddress } from '../../data/orders/interfaces'
import {
  selectOrderLaraForId,
} from '../../data/orders/slice'
import { useAppSelector } from '../../hooks'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { SALONSCALE_LOGO_URL } from '../../data/constants'
import { buildDateYYYYMMDD } from '../../core/dates'
import { COLORS } from '../../mini-lib/theme/colors'
import { UseViewSize } from '../../core/UseViewSize'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import { ReleaseLaraColors } from "../../mini-lib/flags/Release";
import { selectActiveSalonContext } from "../../data/salon/slice";
import { Salon } from '../../data/salon/interfaces'
import { selectProductsById } from "../../data/products/slice";
import { dispatchListProducts } from "../../data/products/api";
import { Product } from "../../data/products/interfaces";
import { formatDollars } from "../../mini-lib/units/money";
import { buildCsv } from "../../mini-lib/csv/buildCsv";
import { calculateExistingOrderTotal, ORDER_PDF } from "../../data/orders/utils";
import { selectLoadingState } from "../../core/loading/slice";
import { ROUTES } from "../../appRoutes";
import { orderBy } from 'lodash'
import { Loading } from "../../mini-lib/loading/Loading";

export const OrderDetailPage = () => {
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    {
      label: 'Orders',
      url: generatePath(ROUTES.orders, { salonId }),
    },
    { label: 'Order Details' },
  ]
  const urlParams: any = useParams()
  const { orderId } = urlParams

  const dispatch = useDispatch()
  const salon: Salon | null = useAppSelector(selectActiveSalonContext)
  const loadingOrderPdf = useAppSelector(state => selectLoadingState(state, ORDER_PDF))
  const orderLara = useAppSelector(state => selectOrderLaraForId(state, orderId))
  const productsById = useAppSelector(selectProductsById)
  const history = useHistory()

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchGetOrderLara({ token, salonId, orderId }))
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, orderId, releaseLaraColors])

  const deleteOrder = () => {
    if (orderLara) {
      dispatch(
        dispatchDeleteOrderLara({
          token,
          salonId,
          orderId,
        }),
      )

    }
    history.push(generatePath(ROUTES.orders, { salonId }))
  }
  const onCsvExport = () => {

    const totals: OrderItemCSV = {
      brand: '',
      category: '',
      type: '',
      unitPrice: '',
      quantity: '',
      total: orderLara ? orderLara.totalOrderedCostDollars : '',
    }
    
    const orderItems = productsById && orderLara?.orderItems.map(orderItem => {
      const product = productsById[orderItem.productId]
      return {...orderItem, product};
    })
   
    const sortedOrderItems = orderBy(
      orderItems,
      ['product.category', 'product.line.name', 'product.type'],
      ['asc', 'asc', 'asc'
    ])

    const formattedModels = productsById && sortedOrderItems
      ? sortedOrderItems.map((orderItem) => {
        const product = productsById[orderItem.productId]
        return ({
          brand: product?.line?.name || 'deleted product',
          category: product?.category || '',
          type: product?.type || '',
          unitPrice: formatDollars(orderItem.unitCostDollars),
          quantity: orderItem.quantity,
          total: orderItem.unitCostDollars * orderItem.quantity,
        })
      })
      : []

    const rows: any = formattedModels ? [...formattedModels, totals] : []
    const headers = [
      'Brand',
      'Category',
      'Type',
      'Price',
      'Quantity',
      'Total',
    ]
    buildCsv(`order-${buildDateYYYYMMDD(orderLara ? orderLara.orderDate : new Date())}`, headers, rows)
  }
  const createPDF = () => {
    dispatch(dispatchOrderGeneratePdfLara({ token, salonId, orderId }))
  }

  const shippingAddress: OrderShippingAddress = orderLara?.shippingAddress ?? {
    address_line_1: salon?.addressLine1 ?? '',
    address_line_2: '',
    city: salon?.city ?? '',
    state: salon?.province ?? '',
    country: salon?.country ?? '',
    zip: salon?.postalCode ?? '',
  }

  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          title="Orders"
          breadcrumbs={breadcrumbs}
          actions={
            <Flex align="center" gridGap="12px" wrap="wrap">
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`This order will be permanently deleted from your salon.`}
                onConfirm={deleteOrder}
              >
                <Button variant="round-ghost-upper" color="danger">
                  DELETE
                </Button>
              </ConfirmPopover>
              <Button onClick={onCsvExport} variant="round" colorScheme='brand.midnight'>
                  SAVE CSV
              </Button>
              <Button variant="round"colorScheme='brand.midnight' onClick={createPDF}>
                {loadingOrderPdf ? <Spinner /> : 'SAVE PDF'}
              </Button>
            </Flex>
          }
        />
      }
      content={
        <>
          <Box h="24px" />

          <Box w={'100%'} border="1px solid" borderColor={COLORS.shades_neutral_200} borderRadius={'15px'} minH="800px">
            {/* first row for purple box and powered by salonscale */}

            {orderLara && (
              <>
              {orderLara && <DetailTopRow order={orderLara}/>}
              {/* Row for order date and address */}

              {orderLara && <DetailMiddleRow order={orderLara} shippingAddress={shippingAddress} />}

              <Box h="35px" />

              {/* Row with table */}

              {orderLara && <DetailBottomRow order={orderLara} />}
              {/* Row with Total */}

              <Box h={'20px'} />

              {orderLara && <DetailTotalRow order={orderLara} />}
              </>
            )}
            {!orderLara && <Loading mt='72px'/>}
          </Box>
        </>
      }
    />
  )
}

export const DetailTopRow = (props: {order: OrderLara}) => {
  const salon = useAppSelector(selectActiveSalonContext)
  const {order} = props
  const { isMobile } = UseViewSize()
  return (
    <>
      {!isMobile ? (
        <>
          <Flex align="center" justify="space-between" marginTop="30px">
            <Flex>
              <Box bg={COLORS.lavender_100} w="521px" h="85px" borderRadius={'0px 8px 8px 0px'}>
                <Text variant={VARIANTS.text.title1} fontWeight="bold" ml="70px" mt="29px" height={'32px'}>
                  {order?.name}
                </Text>
              </Box>
            </Flex>
            <Flex>
              <Box textAlign={'center'} w="225px" h="77px">
                <Text>Powered By:</Text>
                <Flex direction="row">
                  <Image src={SALONSCALE_LOGO_URL} h="40px" />
                  <Image
                    src="https://storage.googleapis.com/salonscale-prod-webapp-assets/salonscale-text-logo-navy.png"
                    h="50px"
                  />
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Flex align="center" marginTop="30px">
            <Flex
              w="210px"
              h="85px"
              bg={COLORS.lavender_100}
              borderRadius={'0px 8px 8px 0px'}
              pl="12px"
              align="center"
              justify="center"
            >
              <Text variant={VARIANTS.text.title2}>{salon?.name}</Text>
            </Flex>
            <Flex>
              <Box textAlign={'center'} w="100px" h="50px">
                <Text>Powered By:</Text>
                <Flex direction="row">
                  <Image src={SALONSCALE_LOGO_URL} h="40px" />
                  <Image
                    src="https://storage.googleapis.com/salonscale-prod-webapp-assets/salonscale-text-logo-navy.png"
                    h="50px"
                  />
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </>
      )}
    </>
  )
}

export const DetailMiddleRow = (props: { order: OrderLara, shippingAddress: OrderShippingAddress }) => {
  const { order, shippingAddress } = props
  const {
    address_line_1,
    address_line_2 = '',
    city,
    state,
    zip
  } = shippingAddress

  const address = address_line_1 + (address_line_2 ?? '')

  const salon: Salon | null = useAppSelector(selectActiveSalonContext)
  const { isMobile } = UseViewSize()
  const orderDate = order.orderDate ? buildDateYYYYMMDD(order.orderDate) : null
  const orderId = order.id;
  return (
    <>
      {!isMobile ? (
        <>
          <Flex align="center" justify="space-between" marginTop="50px">
            <Flex ml="70px" flexDirection="column">
              <Text fontFamily={'Oswald'} fontSize={'18px'}>
                SHIPPING INFORMATION
              </Text>
              <Box h="20px" />
              <Flex>
                <Box fontSize={'14px'}>
                  Name <Box h="1px" /> Address <Box h="1px" /> City/State <Box h="1px" /> ZIP/Postal
                </Box>
                <Box fontSize={'14px'} ml="30px">
                  {salon?.name} <Box h="1px" /> {address} <Box h="1px" />{' '}
                  {city} {state}
                  <Box h="1px" /> {zip}
                </Box>
              </Flex>
            </Flex>
            <Flex flexDirection="column" mr="90px">
              <Flex flexDirection="column">
                <Text fontFamily={'Oswald'} fontSize={'18px'}>
                  Order Date
                </Text>
                <Box h="8px" />
                <Text fontSize={'14px'}>{orderDate}</Text>
              </Flex>
              <Box h="15px" />
              <Flex flexDirection="column">
                <Text fontFamily={'Oswald'} fontSize={'18px'}>
                  Order ID
                </Text>
                <Box h="8px" />
                <Text fontSize={'14px'}>{orderId}</Text>
              </Flex>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Flex align="center" justify="space-between" marginTop="50px">
            <Flex ml="20px" flexDirection="column">
              <Text fontFamily={'Oswald'} fontSize={'18px'}>
                SHIPPING INFORMATION
              </Text>
              <Box h="20px" />
              <Flex>
                <Text fontSize={'14px'}>
                  Name <Box h="1px" /> Address <Box h="1px" /> City/State <Box h="1px" /> ZIP/Postal
                </Text>
                <Text fontSize={'14px'} ml="30px">
                  {salon?.name} <Box h="1px" /> {address} <Box h="1px" />{' '}
                  {city} {state}
                  <Box h="1px" /> {zip}
                </Text>
              </Flex>
            </Flex>
            <Flex flexDirection="column" mr="20px">
              <Flex flexDirection="column">
                <Text fontFamily={'Oswald'} fontSize={'18px'}>
                  Order Date
                </Text>
                <Box h="8px" />
                <Text fontSize={'14px'}>{orderDate}</Text>
              </Flex>
              <Box h="15px" />
              <Flex flexDirection="column">
                <Text fontFamily={'Oswald'} fontSize={'18px'}>
                  Order ID
                </Text>
                <Box h="8px" />
                <Text fontSize={'14px'}>{orderId}</Text>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </>
  )
}

export const DetailBottomRow = (props: { order: OrderLara }) => {
  const productsById = useAppSelector(selectProductsById)
  const { order } = props
  const { isMobile } = UseViewSize()

  const orderItems = productsById && order.orderItems.map(orderItem => {
    const product = productsById[orderItem.productId]
    return {...orderItem, product};
  })

  const sortedOrderItems = orderBy(
    orderItems,
    ['product.category', 'product.line.name', 'product.type'],
    ['asc', 'asc', 'asc'
  ])

  return (
    <>
      <TableContainer ml={isMobile ? '45px' : '0'} overflowY="scroll">
        <Table variant="styled" textAlign={'center'} w={'1060px'}>
          <Thead borderBottom={'2px solid black'}>
            <Tr>
              <Th>Brand</Th>
              <Th>Category</Th>
              <Th>Type</Th>
              <Th>Unit Price</Th>
              <Th>Quantity</Th>
              <Th>Total</Th>
            </Tr>
          </Thead>

          <Tbody borderBottom={'2px solid black'}>
            {productsById && sortedOrderItems.map((orderItem, key) => {
                const product = productsById[orderItem.productId]
                return <DetailTableRowLara orderItem={orderItem} product={product} key={key} />
              }
            )}

          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}

export const DetailTotalRow = (props: { order: OrderLara }) => {
  const { order } = props
  const salon = useAppSelector(selectActiveSalonContext)
  const sumTotal = order ? calculateExistingOrderTotal(order) : null
  return (
    <Flex justifyContent="flex-end">
      <Flex
        bg={COLORS.lavender_100}
        w="575px"
        h="55px"
        borderRadius={'8px 0px 0px 8px'}
        justifyContent="space-between"
        padding="10px"
        overflow="scroll"
      >
        <Text fontFamily={'Oswald'} fontSize={'20px'} fontWeight={'700'} height={'32px'}>
          {salon?.name}
        </Text>
        <Text fontFamily={'Oswald'} fontSize={'20px'} mr="40px">
          Total: ${sumTotal?.toFixed(2)}
        </Text>
      </Flex>
    </Flex>
  )
}

export const DetailTableRowLara = (props: { orderItem: OrderItemLara, product: Product | null }) => {
  const { orderItem, product } = props
  const total = orderItem.quantity * orderItem.unitCostDollars
  return (
    <Tr>
      <Td>{product?.line?.name || 'deleted product'}</Td>
      <Td>{product?.category}</Td>
      <Td>{product?.type}</Td>
      <Td>${formatDollars(orderItem.unitCostDollars)}</Td>
      <Td>{orderItem.quantity}</Td>
      <Td>${total.toFixed(2)}</Td>
    </Tr>
  )
}
