export interface APIPagedResponse {
  data: any
  page: number
  has_more: boolean
  total: number
  last_page: number
}

export interface APIModelPagination {
  page: number
  has_more: boolean
  total: number
  last_page: number
}

export interface ModelPagination {
  page: number
  hasMore: boolean
  total: number
  lastPage: number
  type: 'list' | 'search'
}

export const mapAPIModelPaginationToModelPagination = (
  api: APIModelPagination,
  type: 'list' | 'search',
): ModelPagination => {
  return {
    page: api.page,
    hasMore: api.has_more,
    total: api.total,
    lastPage: api.last_page,
    type: type,
  }
}
