import { Box, Button, Flex, Img, Text } from "@chakra-ui/react";
import { SignupFormLayout } from "./SignupFormLayout";
import { Gap } from "../../../mini-lib/gap/Gap";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { useDispatch } from "react-redux";
import { dispatchListVendors } from "../../../data/products/api";
import { SearchFilter } from "../../../mini-lib/filters/SearchFilter";
import { COLORS } from "../../../mini-lib/theme/colors";
import { FONTS } from "../../../mini-lib/theme/fonts";
import { BlurBottom, BlurScrollContainer } from "../../../mini-lib/blur/Blur";
import { dispatchAddMasterColorsLara } from "../../../data/master-products/api";
import { LOADING_MASTER_PRODUCTS } from "../../../data/master-products/constants";
import { selectVendors } from "../../../data/master-products/slice";
import { MaterialIcon } from "../../../mini-lib/icons/MaterialIcon";
import { generatePath, Redirect } from "react-router-dom";
import { ROUTES } from "../../../appRoutes";
import { without } from 'lodash'
import { maybePluralize } from "../../../core/text";

export const SignupAddColorsPage = () => {
  return (
    <SignupFormLayout stepNumber={4} totalSteps={4}>
      <Flex justify='center' align='center' direction='column'>
        <Gap/>
        <Text p='0 24px' textAlign='center' variant='title2-desktop-bold'>What is the primary color brand you
          use?</Text>
        <Gap s='6px'/>
        <Text variant='callout-desktop-regular'>Choose up to four brands.</Text>
        <Gap s='32px'/>
        <Flex justify='center'>
          <Box w='350px'>
            <AddColorsByVendor/>
          </Box>
        </Flex>

      </Flex>
    </SignupFormLayout>
  )
}


export const AddColorsByVendor = () => {
  const dispatch = useDispatch()
  const vendors = useAppSelector(selectVendors)
  const { user } = UseBaseApiParams()
  const salonId = user?.currentSalonContext?.salonId
  const vendorsLoaded = !!vendors
  const [searchText, setSearchText] = useState('')
  const [selectedVendorIds, setSelectedVendorIds] = useState<number[]>([])
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!vendorsLoaded && user.token) {
      dispatch(dispatchListVendors({token: user.token}))
    }
  }, [dispatch, user.token, vendorsLoaded])
  const loadingName = LOADING_MASTER_PRODUCTS
  const onContinue = () => {
    if (selectedVendorIds.length > 0 && salonId) {

      dispatch(
        dispatchAddMasterColorsLara({
          token: user.token,
          salonId,
          vendorIds: selectedVendorIds,
          loadingName,
        }),
      )
      setSubmitted(true)
    }
  }
  const filteredVendors = vendors?.filter(vendor => vendor.name.toLowerCase().includes(searchText.toLowerCase()))
  if (submitted) {
    return <Redirect to={generatePath(ROUTES.signupV2SuccessTrial)}/>
  }

  const onVendorSelect = (vendorId: number, isSelected: boolean) => {
    if (selectedVendorIds.length >= 4 && !isSelected) {
      return
    }
    if (isSelected) {
      setSelectedVendorIds(without(selectedVendorIds, vendorId))
    } else {
      setSelectedVendorIds([...selectedVendorIds, vendorId])
    }
  }
  return (
    <Box pb='24px' w='100%'>
      <SearchFilter
        width='100%'
        placeholder="Search"
        value={searchText}
        onChange={( searchText ) => setSearchText(searchText)}
        onClear={() => {
          setSearchText('')
        }}
      />
      <Gap/>
      <BlurScrollContainer>
        <Flex w='100%' gridGap='12px' direction='column' h='350px' overflow='scroll'>
          {filteredVendors && filteredVendors.map(vendor => {
            const isSelected = selectedVendorIds.includes(vendor.id)
            return (
              <Box
                key={vendor.id}
                onClick={() => onVendorSelect(vendor.id, isSelected)}
                border={isSelected ? `1.5px solid ${COLORS.lavender_500}` : '1.5px solid white'} borderRadius='12px' p='4px'
                cursor='pointer'
              >
                <Flex w='100%' justify='space-between' align='center'>
                  <Flex align='center' gridGap='12px'>
                    <VendorLogo vendor={vendor}/>
                    <Text fontSize='16px' fontFamily={FONTS.openSans}>{vendor.name}</Text>
                  </Flex>
                  {isSelected && <MaterialIcon name='close' colorhex={COLORS.lavender_500}/>}
                </Flex>
              </Box>
            )
          })}
        </Flex>
        <BlurBottom/>
      </BlurScrollContainer>
      <Box p='0 12px'>
        <Button
          disabled={selectedVendorIds.length === 0}
          variant='round-large'
          colorScheme='brand.midnight'
          w='100%'
          onClick={() => onContinue()}
        >
          Add {selectedVendorIds.length > 0 && selectedVendorIds.length} {maybePluralize('Brand', selectedVendorIds.length)}
        </Button>
      </Box>
    </Box>

  )
}

const VendorLogo = ( props: {
  vendor
} ) => {
  const { vendor } = props
  return (
    <>
      {vendor.logoUrl ? (
        <Flex
          maxW='50px'
          maxH='50px'
          w='50px'
          h='50px'
          justify='center'
          align='center'
          borderRadius='50%'
          border={`1px solid ${COLORS.midnight_900}`} p='4px'
        >
          <Img

            // w='auto'
            // h='100%'
            m='4px'
            objectFit='cover'
            alt="vendor"
            key={vendor.id}
            src={vendor.logoUrl ? vendor.logoUrl : ''}
          />
        </Flex>
      ) : (
        <Flex maxW='50px' maxH='50px' w='50px' h='50px' justify='center' align='center' borderRadius='50%'
              border={`1px solid ${COLORS.midnight_900}`} p='4px' bg={COLORS.lavender_50}>
          {vendor.name[0]}
        </Flex>
      )}
    </>
  )
}
