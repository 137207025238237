import { SetLocalStorageFromUrl } from '../hooks/SetLocalStorageFromUrl'
import { getLocalStorageItem } from '../localStorage'
import { UseCurrentUser } from '../hooks/UseCurrentUser'
import { Flex, Spinner } from '@chakra-ui/react'
import React from 'react'
import { UseCurrentSalonId } from '../hooks/UseCurrentSalonId'
import { Page402, Page403, Page404 } from './NoAccessPages'
import { isSuperUser, redirectToAngularAdmin } from '../../data/user/utils'
import { UseSignOut } from '../hooks/UseSignOut'

export const RoutingGuard = (props: {
  children?: any
  requireAuth?: boolean
  requireActiveSubscription?: boolean
  requireAdmin?: boolean
  requiredRole?: boolean
  requiredFlag?: boolean
  authFailRedirect?: string
  requireSalon?: boolean
  salonFailRedirect?: string
  roleFailRedirect?: string
}) => {
  // grab any info you might need off the url
  SetLocalStorageFromUrl()

  // get things from local storage if needed
  const localStorageToken = getLocalStorageItem('auth-token')
  const localStorageUserId = getLocalStorageItem('user-id')

  const { user, loadingUser } = UseCurrentUser()
  const { currentSalonId } = UseCurrentSalonId()

  const {
    children,
    requireAuth = false,
    requireActiveSubscription = false,
    requireSalon = false,
    requiredRole = true,
    requireAdmin = false,
    requiredFlag = true,
    // salonFailRedirect = ROUTES.signupCheckout, // todo: in the future send them to the user dashboard
    // authFailRedirect = ROUTES.login, // todo: use this when we move login to the react app
  } = props
  const userToLoad = localStorageToken && localStorageUserId
  const subscriptionActive = !!user?.currentSalonContext?.subscription?.isActive

  // if admin and is admin route send them through
  if (user && isSuperUser(user) && requireAdmin) {
    return <>{children}</>
  }
  // if admin and no salon context send them to the angular admin page
  if (user && isSuperUser(user) && !user?.currentSalonContext) {
    redirectToAngularAdmin(user.token, user.userId)
  }
  if (user && isSuperUser(user)) {
    return <>{children}</>
  }

  if (userToLoad && loadingUser) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner />
      </Flex>
    )
  }

  // check if feature flag is enabled
  if(!requiredFlag) {
    return <Page403 />
  }

  // check required role
  if (!requiredRole) {
    return <Page403 />
  }

  if (requireActiveSubscription && !subscriptionActive) {
    return <Page402 />
  }
  // check auth
  if (requireAuth && !userToLoad && !loadingUser && !user) {
    // const ngBase = process.env.REACT_APP_ANGULAR_HOST
    // window.location.href = `${ngBase}/login`
    UseSignOut()
    // return <Redirect to={authFailRedirect} />
  }

  // check the current salon context is the right context for the url
  if (requireSalon && !(user?.currentSalonContext?.salonId === currentSalonId)) {
    return <Page404 />
  }
  return <>{children}</>
}
