// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, filter, keyBy, omit, orderBy, values } from 'lodash'

import { RootState } from '../../store'
import {
  BowlType,
  Session,
  SessionColorLara,
  SessionExtensionLara,
  SessionMetaLara,
  SessionPhotoLara,
  SessionSupplyLara
} from './interfaces'
import { ModelPagination } from '../../core/pagination'
import { LaraPagination } from "../../mini-lib/lara/lara-utils";
import {
  MOCKED_DISPLAY_UNIT_WE_SHOULD_SET_SOMEWHERE_MAYBE_USER_SETTINGS_OR_SOMETHING,
  SESSION_STATUS_OPTIONS
} from "./constants";
import {
  getSessionExtensionsLaraTotalCost,
  getSessionSuppliesLaraTotalCost,
  getTotalSessionColorCostLara
} from "./utils";
import { DATE_RANGE_OPTION_MAP, DateRangeType, DateSelection } from "../../mini-lib/dates-and-times/constants";

// state
//
interface SessionState {
  recentSessionsById: { [key: string]: Session } | null
  sessionsById: { [key: string]: Session } | null
  selectedSessionId: number | null
  selectedBowlId: number | null
  sessionDetails: Session | null
  pagination: ModelPagination | null

  searchPagination: ModelPagination | null
  isExtensionSheetVisible: boolean
  isSupplySheetVisible: boolean
  bowlTypes: BowlType[] | null
  redirectToSessionId: number | null

  // lara sessions
  //
  sessionsLaraById: { [key: string]: SessionMetaLara } | null
  paginationLara: LaraPagination | null

  // session details state
  //
  activeSessionMeta: SessionMetaLara | null
  activeSessionSupplies: SessionSupplyLara[] | null
  activeSessionExtensions: SessionExtensionLara[] | null
  activeSessionColors: SessionColorLara[] | null
  activeSessionPhotos: SessionPhotoLara[] | null
  activeSessionDisplayUnit: 'g' | 'oz'
  // our server sometimes sends deleted sessions if we navigate from session details to session list too fast after a deletion
  // this ensures that they are never displayed even if the client beats the server
  recentlyDeletedSessionIds: number[]
  dateSelection: DateSelection
  memberViewDateSelected: Date,

}
const initialState: SessionState = {
  sessionsById: null,
  recentSessionsById: null,
  selectedSessionId: null,
  selectedBowlId: null,
  sessionDetails: null,
  pagination: null,
  searchPagination: null,
  isExtensionSheetVisible: false,
  isSupplySheetVisible: false,
  redirectToSessionId: null,
  bowlTypes: null,

  // lara sessions
  //
  sessionsLaraById: null,
  paginationLara: null,

  // session details state
  //
  activeSessionMeta: null,
  activeSessionSupplies: null,
  activeSessionExtensions: null,
  activeSessionColors: null,
  activeSessionPhotos: null,
  activeSessionDisplayUnit: MOCKED_DISPLAY_UNIT_WE_SHOULD_SET_SOMEWHERE_MAYBE_USER_SETTINGS_OR_SOMETHING,
  recentlyDeletedSessionIds: [],
  dateSelection: {
    type: DATE_RANGE_OPTION_MAP.day.value as DateRangeType,
    startDate: null,
    endDate: null
  },
  memberViewDateSelected: new Date()
}

// reducer
//
export const SessionSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    reduceSetSelectedSessionId: (state, action: PayloadAction<number | null>) => {
      state.selectedSessionId = action.payload
    },
    reduceSetSelectedBowlId: (state, action: PayloadAction<number | null>) => {
      state.selectedBowlId = action.payload
    },
    reduceListSessions: (state, action: PayloadAction<{ pagination: ModelPagination; sessions: Session[] }>) => {
      state.sessionsById = assign({}, state.sessionsById, keyBy(action.payload.sessions, 'id'))

      if (action.payload.pagination.type === 'list') {
        state.pagination = action.payload.pagination
      } else {
        state.searchPagination = action.payload.pagination
      }
    },
    reduceListRecentSessions: (state, action: PayloadAction<{ pagination: ModelPagination; sessions: Session[] }>) => {
      state.recentSessionsById = assign({}, state.sessionsById, keyBy(action.payload.sessions, 'id'))
    },
    reduceCreateSession: (state, action: PayloadAction<Session>) => {
      if (state.sessionsById) {
        state.sessionsById[action.payload.id] = action.payload
      }
    },
    reduceCreateSessionLara: (state, action: PayloadAction<SessionMetaLara>) => {
      if (state.sessionsLaraById) {
        state.sessionsLaraById[action.payload.id] = action.payload
      }
    },
    reduceGetSession: (state, action: PayloadAction<Session>) => {
      state.sessionDetails = action.payload
    },
    reduceClearSessionDetails: (state, action: PayloadAction<null>) => {
      state.sessionDetails = null
    },
    reduceUpdateSession: (state, action: PayloadAction<Session>) => {
      // if sessions have been loaded update the one being edited otherwise don't update that state
      if (state.sessionsById) {
        state.sessionsById[action.payload.id] = action.payload
      } else if (action.payload?.id) {
        state.sessionsById = { [action.payload.id]: action.payload }
      }
      state.sessionDetails = action.payload
    },
    reduceDeleteSession: (state, action: PayloadAction<Session>) => {
      state.sessionsById = omit(state.sessionsById, action.payload.id)
    },
    reduceIsExtensionSheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isExtensionSheetVisible = action.payload
    },
    reduceIsSupplySheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isSupplySheetVisible = action.payload
    },
    reduceListBowlTypes: (state, action: PayloadAction<BowlType[]>) => {
      state.bowlTypes = action.payload
    },

    reduceRedirectToSessionId: (state, action: PayloadAction<number | null>) => {
      state.redirectToSessionId = action.payload
    },


    // lara sessions
    //
    //
    reduceListSessionsLara: (state, action: PayloadAction<SessionMetaLara[]>) => {
      const sessions = assign({}, state.sessionsLaraById, keyBy(action.payload, 'id'))
      state.sessionsLaraById = omit(sessions, state.recentlyDeletedSessionIds)
    },
    reduceResetSessionsLara: (state, action: PayloadAction<void>) => {
      state.sessionsLaraById = {}
    },
    reduceSessionsLaraPagination: (state, action: PayloadAction<LaraPagination>) => {
      state.paginationLara = action.payload
    },

    // session details
    //
    //
    reduceActiveSessionMeta: (state, action: PayloadAction<SessionMetaLara>) => {
      state.activeSessionMeta = action.payload
    },
    reduceActiveSessionSupplies: (state, action: PayloadAction<SessionSupplyLara[]>) => {
      state.activeSessionSupplies = action.payload
    },
    reduceDeleteActiveSessionSupply: (state, action: PayloadAction<number>) => {
      state.activeSessionSupplies = filter(state.activeSessionSupplies, item => item.id !== action.payload)
    },
    reduceActiveSessionExtensions: (state, action: PayloadAction<SessionExtensionLara[]>) => {
      state.activeSessionExtensions = action.payload
    },
    reduceDeleteActiveSessionExtension: (state, action: PayloadAction<number[]>) => {
      state.activeSessionExtensions = filter(state.activeSessionExtensions, item => !action.payload.includes(item.id))
    },
    reduceActiveSessionColors: (state, action: PayloadAction<SessionColorLara[]>) => {
      state.activeSessionColors = action.payload
    },
    reduceDeleteActiveSessionColor: (state, action: PayloadAction<number[]>) => {
      state.activeSessionColors = filter(state.activeSessionColors, item => !action.payload.includes(item.id))
    },
    reduceSetActiveSessionDisplayUnit: (state, action: PayloadAction<'g' | 'oz'>) => {
      state.activeSessionDisplayUnit = action.payload
    },
    reduceActiveSessionPhotos: ( state, action: PayloadAction<SessionPhotoLara[]> ) => {
      state.activeSessionPhotos = action.payload
    },
    reduceDeleteActiveSessionPhoto: ( state, action: PayloadAction<number> ) => {
      state.activeSessionPhotos = filter(state.activeSessionPhotos, item => action.payload !== item.id)
    },
    reduceCreateActiveSessionPhoto: ( state, action: PayloadAction<SessionPhotoLara> ) => {
      state.activeSessionPhotos = state.activeSessionPhotos ? [...state.activeSessionPhotos, action.payload] : [action.payload]
    },
    reduceCompleteSessionLara: ( state, action: PayloadAction<number> ) => {
      if (state.sessionsLaraById) {
        const updatedSession = assign({}, state.sessionsLaraById[action.payload], {status: SESSION_STATUS_OPTIONS.complete})
        state.sessionsLaraById = assign({}, state.sessionsLaraById, {[action.payload]: updatedSession})
      }
    },
    reduceDeleteActiveSession: (state, action: PayloadAction<number>) => {
      state.activeSessionMeta = null
      state.activeSessionSupplies = null
      state.activeSessionExtensions = null
      state.activeSessionColors = null
      state.recentlyDeletedSessionIds = [...state.recentlyDeletedSessionIds, action.payload]
      state.sessionsById = omit(state.sessionsById, action.payload)
    },
    reduceSessionsDateSelection: (state, action: PayloadAction<DateSelection>) => {
      state.dateSelection = action.payload
    },
    reduceMemberViewDateSelected: (state, action: PayloadAction<Date>) => {
      state.memberViewDateSelected = action.payload
    }
  },
})

// actions
//
export const {
  reduceSetSelectedSessionId,
  reduceSetSelectedBowlId,
  reduceMemberViewDateSelected,
  reduceGetSession,
  reduceListSessions,
  reduceListRecentSessions,
  reduceCreateSession,
  reduceUpdateSession,
  reduceDeleteSession,
  reduceIsExtensionSheetVisible,
  reduceIsSupplySheetVisible,
  reduceRedirectToSessionId,
  reduceListBowlTypes,

  // lara sessions
  reduceListSessionsLara,
  reduceSessionsLaraPagination,
  reduceCreateSessionLara,
  reduceActiveSessionMeta,
  reduceActiveSessionSupplies,
  reduceDeleteActiveSessionSupply,
  reduceDeleteActiveSessionExtension,
  reduceActiveSessionExtensions,
  reduceDeleteActiveSessionColor,
  reduceActiveSessionColors,
  reduceActiveSessionPhotos,
  reduceDeleteActiveSessionPhoto,
  reduceCreateActiveSessionPhoto,
  reduceSetActiveSessionDisplayUnit,
  reduceDeleteActiveSession,
  reduceCompleteSessionLara,
  reduceSessionsDateSelection,
  reduceResetSessionsLara
} = SessionSlice.actions

// selectors
//
export const selectSelectedSession = (state: RootState): Session | null => {
  return state.sessions.sessionsById && state.sessions.selectedSessionId
    ? state.sessions.sessionsById[state.sessions.selectedSessionId]
    : null
}

export const selectSelectedBowlId = (state: RootState): number | null => {
  return state.sessions.selectedBowlId
}

export const selectSessionDetails = (state: RootState): Session | null => {
  return state.sessions.sessionDetails
}
export const selectSessionsById = (state: RootState) => state.sessions.sessionsById
export const selectIsExtensionSheetVisible = (state: RootState) => state.sessions.isExtensionSheetVisible
export const selectIsSupplySheetVisible = (state: RootState) => state.sessions.isSupplySheetVisible
export const selectSessionPagination = (state: RootState) => state.sessions.pagination
export const selectSessionSearchPagination = (state: RootState) => state.sessions.searchPagination
export const selectRecentSessions = (state: RootState): Session[] | null => {
  const dateSorter = (session) => session.date
  return state.sessions.recentSessionsById
    ? orderBy(values(state.sessions.recentSessionsById), [dateSorter], ['desc'])
    : null
}
export const selectSessionList = (state: RootState): Session[] | null => {
  const dateSorter = (session) => session.date
  return state.sessions.sessionsById ? orderBy(values(state.sessions.sessionsById), [dateSorter], ['desc']) : null
}
export const selectSessionsForClient = (state: RootState, clientId: number): Session[] | null => {
  // if there are no sessions return null, the state is loading
  if (!state.sessions.sessionsById) {
    return null
  }

  const dateSorter = (session) => session.date
  const sessionList = values(state.sessions.sessionsById)
  const filteredSessionList = filter(sessionList, (session) => session.client.id === clientId)
  const sortedFilteredSessionList = orderBy(filteredSessionList, [dateSorter], ['desc'])
  return sortedFilteredSessionList
}

export const selectRedirectToSessionId = (state: RootState) => state.sessions.redirectToSessionId
export const selectBowlTypes = (state: RootState): BowlType[] | null => state.sessions.bowlTypes


// lara sessions
//
//
export const selectSessionsLara = (state: RootState): SessionMetaLara[] | null => {
  const dateSorter = (session) => session.date
  // the server returns dates without times which means that we have no way to compare two sessions on the same date
  // we want newer sessions at the top, this will make higher ids go to the top allowing us to 'sort' within dates
  const idSorter = (session) => session.id
  return state.sessions.sessionsLaraById ? orderBy(values(state.sessions.sessionsLaraById), [dateSorter, idSorter], ['desc', 'desc']) : null
}

export const selectSessionsPaginationLara = (state: RootState): LaraPagination | null => {
  return state.sessions.paginationLara
}


// active session
//
//
//
export const selectActiveSessionMeta = (state: RootState): SessionMetaLara | null => state.sessions.activeSessionMeta
export const selectActiveSessionSupplies = (state: RootState): SessionSupplyLara[] | null => state.sessions.activeSessionSupplies
export const selectActiveSessionExtensions = (state: RootState): SessionExtensionLara[] | null => state.sessions.activeSessionExtensions
export const selectActiveSessionColors = (state: RootState): SessionColorLara[] | null => state.sessions.activeSessionColors
export const selectActiveSessionPhotos = (state: RootState): SessionPhotoLara[] | null => state.sessions.activeSessionPhotos
export const selectActiveSessionDisplayUnit = (state: RootState): 'g' | 'oz' => state.sessions.activeSessionDisplayUnit
export const selectActiveSessionTotalCents = (state: RootState): number => {
  const totals: number[] = []
  if (state.sessions.activeSessionColors) {
    const colorTotal = getTotalSessionColorCostLara(state.sessions.activeSessionColors, 'amount')
    totals.push(colorTotal)
  }

  if (state.sessions.activeSessionSupplies) {
    const supplyTotal = getSessionSuppliesLaraTotalCost(state.sessions.activeSessionSupplies)
    totals.push(supplyTotal)
  }
  if (state.sessions.activeSessionExtensions) {
    const extensionTotal = getSessionExtensionsLaraTotalCost(state.sessions.activeSessionExtensions)
    totals.push(extensionTotal)
  }
  return totals.reduce((acc, total) => acc + total , 0)
}

export const selectSessionsDateSelection = (state: RootState): DateSelection => state.sessions.dateSelection
export const selectMemberViewDateSelected = (state: RootState): Date => state.sessions.memberViewDateSelected


// export
//
export default SessionSlice.reducer
