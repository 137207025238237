import { Box, Button, Divider, Flex, Input, NumberInput, NumberInputField, Select, Text } from '@chakra-ui/react'
import { reduceSetSelectedBowlId, selectBowlTypes } from '../../../data/sessions/slice'
import { ConfirmPopover } from '../../../mini-lib/confirm-popover/ConfirmPopover'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import { BowlType, SessionItem } from '../../../data/sessions/interfaces'
import { getBowlType } from '../../../data/sessions/utils'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { find, values } from 'lodash'
import { BOWL_SHEET_VIEW_TYPES, BowlSheetViewType, DISPLAY_UNIT_OPTIONS } from '../../../data/sessions/constants'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useAppSelector } from '../../../hooks'
import { dispatchGetBowlTypes } from '../../../data/sessions/api'
import { COLORS } from '../../../mini-lib/theme/colors'
import { DropdownFilter } from '../../../mini-lib/filters/DropdownFilter'

export const SessionBowlColorsViewLegacy = ( props: {
  setBowlView: (view: BowlSheetViewType) => void
  setShowProductSheet: (showSheet: boolean) => void
  updatedBowl: SessionItem[]
  onBowlTypeSelect: (type: BowlType | null) => void
  onBowlTagChange: (bowlTag: string) => void
  onDeleteBowlWaste: (sessionItems: SessionItem[]) => void
  onDeleteSessionItem: (item: Partial<SessionItem>) => void
  updateSessionItem: (sessionItem: SessionItem, paramsToUpdate: Partial<SessionItem>) => void
  onDeleteBowl: () => void
  onSave: () => void
  isDisabled: boolean
  bowlTag: string | null
}) => {
  const {
    setBowlView,
    setShowProductSheet,
    updatedBowl,
    onBowlTypeSelect,
    onBowlTagChange,
    onDeleteSessionItem,
    updateSessionItem,
    onDeleteBowl,
    onSave,
    isDisabled,
    bowlTag
  } = props
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const sessionItemsWithWaste = updatedBowl.filter((item) => item.waste && item.waste > 0)
  return (
    <>
      <Flex align="center" justify="space-between" gridGap="12px">
        <MaterialIcon
          cursor="pointer"
          colorhex={COLORS.lavender_500}
          size="36px"
          name="chevron_left"
          onClick={() => dispatch(reduceSetSelectedBowlId(null))}
        />
        <Flex align="center" gridGap="12px">
          <ConfirmPopover
            title={`This Action is Permanent`}
            subtitle={`This will delete the bowl from your session`}
            onConfirm={() => {
              onDeleteBowl()
            }}
          >
            <Button variant="round-ghost-upper" color="danger" minW="100px">
              {isMobile ? 'Delete' : 'Delete Bowl'}
            </Button>
          </ConfirmPopover>

          <Button colorScheme="brand.midnight" variant="round" onClick={onSave} minW="100px">
            Save
          </Button>
        </Flex>
      </Flex>
      <Box h="12px" />
      <Text variant="largetitle" textTransform="uppercase">
        Edit Bowl
      </Text>
      <Box h="12px" />

      {updatedBowl.length > 0 && (
        <Flex gridGap="12px" align="center">
          <BowlTypeSelect bowlType={getBowlType(updatedBowl[0])} onBowlTypeSelect={onBowlTypeSelect} />
          <Input placeholder='Bowl Tag' w='160px' fontSize={13} borderRadius="100px" onChange={(evt) => onBowlTagChange(evt.currentTarget.value)} value={bowlTag ?? ''}
    />
        </Flex>
      )}
      <ConfirmPopover
        title={`You can't edit this bowl`}
        subtitle={`A session with waste cannot be modified`}
        onConfirm={() => { }}
        showCancel={false}
        confirmText="Ok"
        showPopover={isDisabled}
      >
        <Box>
          {sessionItemsWithWaste.length > 0 && (
            <>
              <Box h="24px" />
              <Text variant="title1">Color Mixed</Text>
              <Box h="12px" />
            </>
          )}

          {updatedBowl &&
            updatedBowl.length > 0 &&
            updatedBowl.map((sessionItem, index) => {
              return (
                <SessionItemRow
                  bowlView={'amount'} // todo: delete bowlView prop
                  key={index}
                  isDisabled={isDisabled}
                  sessionItem={sessionItem}
                  updateBowl={updateSessionItem}
                  deleteItem={onDeleteSessionItem}
                />
              )
            })}
        </Box>
      </ConfirmPopover>

      {updatedBowl.length === 0 && (
        <>
          {' '}
          <EmptyBox h="200px" />
          <Box h="12px" />
        </>
      )}

      {isMobile && <Box h="12px" />}

      <Flex justify="flex-end" gridGap="12px">
        {updatedBowl.length > 0 && (
          <Button
            variant="round-outline"
            colorScheme="brand.lavender"
            onClick={() => setBowlView(BOWL_SHEET_VIEW_TYPES.waste)}
          >
            {isDisabled ? 'View Waste' : 'Record Waste'}
          </Button>
        )}

        <Button
          disabled={isDisabled}
          variant="round-outline"
          colorScheme="brand.lavender"
          onClick={() => setShowProductSheet(true)}
        >
          <MaterialIcon name="add" style={{ marginRight: '4px' }} /> Products
        </Button>
      </Flex>

      <Box h="36px" />
      {sessionItemsWithWaste.length > 0 && (
        <>
          <Text variant="title1">Color Wasted</Text>
          <Box h="12px" />
          {sessionItemsWithWaste &&
            sessionItemsWithWaste.length > 0 &&
            sessionItemsWithWaste.map((sessionItem, index) => {
              return (
                <SessionItemRow
                  isDisabled={isDisabled}
                  bowlView={'waste-disabled'}
                  key={index}
                  sessionItem={sessionItem}
                  updateBowl={updateSessionItem}
                  deleteItem={onDeleteSessionItem}
                />
              )
            })}
          {/*<Flex justify="flex-end" gridGap="12px">*/}
          {/*  <Button variant="round-ghost-upper" color="danger" onClick={() => onDeleteBowlWaste(sessionItemsWithWaste)}>*/}
          {/*    Delete Waste*/}
          {/*  </Button>*/}
          {/*</Flex>*/}
        </>
      )}
    </>
  )
}

export const SessionItemRow = (props: {
  sessionItem: Partial<SessionItem>
  updateBowl:
    | any
    | ((sessionItem: Partial<SessionItem>, paramsToUpdate: Partial<SessionItem>) => void)
    | ((sessionItem: SessionItem, paramsToUpdate: Partial<SessionItem>) => void)
  deleteItem: (item: Partial<SessionItem>) => void
  bowlView: 'waste' | 'amount' | 'waste-disabled'
  isDisabled: boolean
}) => {
  const { isMobile } = UseViewSize()
  const { isDisabled, bowlView, sessionItem, updateBowl, deleteItem } = props
  const initialValue = getSessionItemRowInitialValue(bowlView, sessionItem)
  const [numInputValue, setNumInputValue] = useState<string | number>(initialValue)

  const amount = sessionItem.amount || 0
  const waste = sessionItem.waste || 0

  useEffect(() => {
    if (bowlView === 'amount') {
      setNumInputValue(amount)
    }
    if (bowlView === 'waste') {
      setNumInputValue(waste)
    }
  }, [amount, waste, bowlView])
  return (
    <>
      <Box h="12px" />
      <Flex
        key={sessionItem.id}
        align={isMobile ? 'start' : 'center'}
        justify="space-between"
        p="0 0 12px 0"
        cursor="pointer"
        gridGap="12px"
        direction={isMobile ? 'column' : 'row'}
      >
        <Box maxW="60%" w="200px">
          <Text variant={isMobile ? 'title' : 'title3opensans'}>{sessionItem?.product?.type}</Text>
          <Text color="text.secondary">{sessionItem?.product?.line.name}</Text>
        </Box>
        <Flex align="center" justify="end" gridGap="12px">
          <Box>
            <Text color="text.secondary">Amount</Text>
            <NumberInput
              isDisabled={bowlView === 'waste-disabled' || isDisabled}
              w="120px"
              value={numInputValue}
              inputMode="decimal"
              precision={2}
              min={0}
              onChange={(updatedAmount) => {
                setNumInputValue(updatedAmount)
                if (bowlView === 'waste') {
                  updateBowl(sessionItem, { waste: parseFloat(updatedAmount) })
                }
                if (bowlView === 'amount') {
                  updateBowl(sessionItem, { amount: parseFloat(updatedAmount) })
                }
              }}
              borderRadius="100px"
            >
              <NumberInputField borderRadius="100px" />
            </NumberInput>
          </Box>
          {(bowlView === 'waste' || bowlView === 'amount') && (
            <Box>
              <Text color="text.secondary">Units</Text>
              <Select
                disabled={bowlView === 'waste' || isDisabled}
                defaultValue={sessionItem.displayUnit}
                borderRadius="100px"
                w="120px"
                onChange={(evt: any) => {
                  const updatedUnit = evt.target.value
                  if (updatedUnit) {
                    updateBowl(sessionItem, { displayUnit: updatedUnit })
                  }
                }}
              >
                {values(DISPLAY_UNIT_OPTIONS).map((option: any, index) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  )
                })}
              </Select>
            </Box>
          )}
          {bowlView === 'amount' && !isDisabled && (
            <MaterialIcon mt="16px" name="delete" onClick={() => deleteItem(sessionItem)} />
          )}
        </Flex>
      </Flex>
      <Box h="12px" />
      {isMobile && <Divider />}
    </>
  )
}

const getSessionItemRowInitialValue = (bowlView: string, sessionItem: any): number | string => {
  if (bowlView === 'amount') {
    return sessionItem.amount || 0
  }
  if (bowlView === 'waste') {
    return sessionItem.waste || 0
  }
  return `${sessionItem.waste}${sessionItem?.displayUnit}`
}

const BowlTypeSelect = (props: {
  bowlType: BowlType | null
  onBowlTypeSelect: (bowlType: BowlType | null) => void
}) => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()
  const bowlTypes = useAppSelector(selectBowlTypes)
  const { bowlType, onBowlTypeSelect } = props
  const bowlTypesLoaded = !!bowlTypes
  useEffect(() => {
    if (!bowlTypesLoaded) {
      dispatch(dispatchGetBowlTypes({ token: user.token }))
    }
  }, [dispatch, user.token, bowlTypesLoaded])

  const bowlTypesOptions = bowlTypes?.map((e) => ({ label: e.name, value: e.id }))
  // Adding none option
  bowlTypesOptions?.splice(0, 0, { label: 'none', value: '' })
  const selectedOption = bowlType?.id ?? ''
  return (
    <>
      <DropdownFilter
        w="160px"
        placeholder="Bowl Type"
        value={selectedOption}
        options={bowlTypesOptions ?? []}
        variant='round'
        theme=''
        onChange={(evt) => {
          const typeId = evt.value
          if (typeId === '') {
            onBowlTypeSelect(null)
          } else {
            const selectedType: BowlType | any = find(bowlTypes, (type) => type.id === typeId)
            onBowlTypeSelect(selectedType ?? null)
          }

        }}
        isClearable={false}
      />
    </>
  )
}

