/////////////// api.ts
//
//

import axios, { AxiosResponse } from 'axios'
import { Dispatch } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
  reduceActiveSessionColors,
  reduceActiveSessionExtensions,
  reduceActiveSessionMeta,
  reduceActiveSessionPhotos,
  reduceActiveSessionSupplies,
  reduceCompleteSessionLara,
  reduceCreateActiveSessionPhoto,
  reduceCreateSession,
  reduceCreateSessionLara,
  reduceDeleteActiveSession,
  reduceDeleteActiveSessionColor,
  reduceDeleteActiveSessionExtension,
  reduceDeleteActiveSessionPhoto,
  reduceDeleteActiveSessionSupply,
  reduceDeleteSession,
  reduceGetSession,
  reduceListBowlTypes,
  reduceListRecentSessions,
  reduceListSessions,
  reduceListSessionsLara,
  reduceRedirectToSessionId,
  reduceSessionsLaraPagination,
  reduceUpdateSession,
} from './slice'
import {
  APIBowlType,
  APICreateOrUpdateSessionColorLara,
  APICreateOrUpdateSessionExtensionLara,
  APICreateOrUpdateSessionSupplyLara,
  APICreateSession,
  APICreateSessionPhotoLara,
  APISession,
  APISessionColorLara,
  APISessionExtensionLara,
  APISessionMetaCreateLara,
  APISessionMetaLara,
  APISessionMetaPatchLara,
  APISessionPhotoLara,
  APISessionSupplyLara,
  APIUpdateSession,
  APIUpdateSessionItem,
  BowlType,
  Session,
  SessionColorLara,
  SessionExtensionLara,
  SessionItem,
  SessionMetaLara,
  SessionPhotoLara,
  SessionSupplyLara,
} from './interfaces'
import {
  mapAPIBowlTypesToBowlTypes,
  mapAPISessionColorsLaraToSessionColorsLara,
  mapAPISessionExtensionsLaraToSessionExtensionsLara,
  mapAPISessionItemToSessionItem,
  mapAPISessionLaraToSessionLara,
  mapAPISessionPhotoLaraToSessionPhotoLara,
  mapAPISessionPhotosLaraToSessionPhotosLara,
  mapAPISessionsLaraToSessionsLara,
  mapAPISessionSuppliesLaraToSessionSuppliesLara,
  mapAPISessionToSession,
  mapAPISessionToSessions,
} from './mappers'
import { APIPagedResponse, mapAPIModelPaginationToModelPagination, ModelPagination } from '../../core/pagination'
import { GetServerBaseUrl } from '../../env'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { assign } from 'lodash'
import { Client, ClientPhoto } from '../clients/interfaces'
import { mapAPIClientPhotoToClientPhoto } from '../clients/mappers'
import { reduceCreateClient } from '../clients/slice'
import { apiCreateClient } from '../clients/api'
import { apiWrapper } from '../../mini-lib/api-utils/apiWrapper'
import {
  APIPagedLaraResponse,
  buildLaraConfig,
  buildLaraPageParams,
  buildLaraStringFilter,
  LaraPagination,
  mapAPILaraPaginationToLaraPagination
} from "../../mini-lib/lara/lara-utils";
import { buildDateYYYYMMDD } from "../../core/dates";
import { LOADING_SESSIONS } from "./constants";

// session apis
//
//
//
export const apiGetBowlTypes = (params: { token: string }): Promise<BowlType[]> => {
  const {token} = params
  const url = `bowl-types/`
  return apiWrapper({
    version: 'v3',
    server: 'lara',
    method: 'get',
    token,
    url,
  }).then((response: AxiosResponse<{ data: APIBowlType[] }>) => {
    return mapAPIBowlTypesToBowlTypes(response.data.data)
  })
}

export const apiGetSession = (params: { token: string; salonId: number; modelId: number }): Promise<Session> => {
  const {token, modelId, salonId} = params
  const url = `sessions/${modelId}/?salon_id=${salonId}`
  return apiWrapper({version: 'v3', server: 'django', method: 'get', token, url}).then(
    (response: AxiosResponse<{ data: APISession }>) => {
      return mapAPISessionToSession(response.data.data)
    },
  )
}

export const apiCompleteSession = (params: {
  token: string
  salonId: number
  modelId: number | string
}): Promise<void> => {
  const {token, salonId, modelId} = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/sessions/${modelId}/complete/?token=${token}`
  return axios
    .post(url, {})
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiListSession = (params: {
  token: string
  salonId: number
  pageNumber?: number
  pageSize?: number
  stylistId?: number | null
  clientId?: number
  startDateTime?: Date
  endDateTime?: Date
}): Promise<{ pagination: ModelPagination; sessions: Session[] }> => {
  const {token, salonId, pageNumber = 1, pageSize, stylistId, clientId, startDateTime, endDateTime} = params
  const pageSizeParam = pageSize ? `&page_size=${pageSize}` : ''
  const clientIdParam = clientId ? `&client_id=${clientId}` : ''
  const stylistIdParam = stylistId ? `&stylist_id=${stylistId}` : ''
  const startDateTimeParam = startDateTime ? `&start_date=${startDateTime.toISOString()}` : ''
  const endDateTimeParam = endDateTime ? `&end_date=${endDateTime.toISOString()}` : ''
  const url = `${GetServerBaseUrl()}/sessions/?token=${token}&salon_id=${salonId}&page=${pageNumber}${clientIdParam}${stylistIdParam}${pageSizeParam}${startDateTimeParam}${endDateTimeParam}`
  return axios
    .get(url)
    .then((response: { data: APIPagedResponse }) => {
      return {
        pagination: mapAPIModelPaginationToModelPagination(response.data, 'list'),
        sessions: mapAPISessionToSessions(response.data.data),
      }
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateSession = (params: {
  token: string
  user_id: number
  salon_id: number
  model: APIUpdateSession
}): Promise<Session> => {
  const {token, user_id, salon_id, model} = params
  const url = `${GetServerBaseUrl()}/sessions/${model.session_id}/?salon_id=${salon_id}`
  // hopefully this is temporary, in the future we should split up sessions and extensions, they are amalgamated for now
  // todo: when Justice separates the apis update this endpoint
  const modelWithMergedSessionsAndExtensions = assign({}, model, {
    session_items: [...model.session_items, ...model.extensions],
  })
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    ...modelWithMergedSessionsAndExtensions,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPISessionToSession(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateSession = (params: {
  token: string
  userId: number
  salonId: number
  model: APICreateSession
}): Promise<Session> => {
  const {token, userId, salonId, model} = params
  const url = `${GetServerBaseUrl()}/sessions/`
  const body = {
    token,
    owner_id: userId,
    salon_id: salonId,
    ...model,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPISessionToSession(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteSession = (params: { token: string; salonId: number; model: Session }): Promise<any> => {
  const {token, model, salonId} = params
  const url = `${GetServerBaseUrl()}/sessions/${model.id}/?token=${token}&salon_id=${salonId}`
  return axios
    .delete(url)
    .then((response: any) => {
      return {...response.data, id: model.id}
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateSessionPhoto = (params: {
  token: string
  user_id: number
  salon_id: number
  session_id: number
  photo_tag: string
  client_photo: File
  session: Session
}): Promise<ClientPhoto> => {
  const {token, salon_id, session_id, client_photo, photo_tag} = params
  const url = `${GetServerBaseUrl()}/sessions/${session_id}/client_photo/?token=${token}&salon_id=${salon_id}`
  const body = new FormData()
  body.append('client_photo', client_photo)
  body.append('photo_tag', photo_tag)

  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPIClientPhotoToClientPhoto(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionPhoto = (params: {
  token: string
  user_id: number
  salon_id: number
  photo: ClientPhoto
  session: Session
}): Promise<ClientPhoto> => {
  const {token, salon_id, photo, session} = params
  const url = `${GetServerBaseUrl()}/sessions/${session.id}/client_photo/?token=${token}&salon_id=${salon_id}`
  const body = {
    token: token,
    session_photo_id: photo.id,
  }

  return axios
    .delete(url, {data: body})
    .then((response: any) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

// session item apis
//
//
//
export const apiUpdateSessionItem = (params: {
  token: string
  user_id: number
  salon_id: number
  session_id: number
  model: APIUpdateSessionItem
}): Promise<SessionItem> => {
  const {token, user_id, session_id, salon_id, model} = params
  const url = `${GetServerBaseUrl()}/sessions/${session_id}/session-items/${model.id}/?token=${token}`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    ...model,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPISessionItemToSessionItem(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateSessionItem = (params: {
  token: string
  user_id: number
  salon_id: number
  session_id: number
  model: SessionItem
}): Promise<SessionItem> => {
  const {token, user_id, salon_id, session_id, model} = params
  const url = `${GetServerBaseUrl()}/sessions/${session_id}/session-items/`
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    session: model,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPISessionItemToSessionItem(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteSessionItem = (params: {
  token: string
  salon_id: number
  session_id: number
  model: SessionItem
}): Promise<any> => {
  const {token, salon_id, session_id, model} = params
  const url = `${GetServerBaseUrl()}/sessions/${session_id}/session-items/${
    model.id
  }/?token=${token}&salon_id=${salon_id}`
  return axios
    .delete(url)
    .then((response: any) => {
      return {...response.data, id: model.id}
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchGetSession = (params: { token: string; salonId: number; modelId: number }) => {
  return (dispatch: Dispatch) => {
    return apiGetSession(params)
      .then((resp) => {
        dispatch(reduceGetSession(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchCompleteSession = (params: { token: string; salonId: number; modelId: number | string }) => {
  return (dispatch: Dispatch) => {
    return apiCompleteSession(params)
      .then(() => {
        // this api has no response, in the future we may manually update the session complete to done here in state
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchListSessions = (params: {
  token: string
  salonId: number
  pageNumber?: number
  pageSize?: number
  stylistId?: number | null
  clientId?: number
  startDateTime?: Date
  endDateTime?: Date
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: 'sessions', state: true}))
    if (params.clientId) {
      dispatch(reduceSetLoadingState({name: `sessions-${params.clientId}`, state: true}))
    }
    return apiListSession(params).then((resp) => {
      dispatch(reduceListSessions(resp))
      dispatch(reduceSetLoadingState({name: 'sessions', state: false}))
      if (params.clientId) {
        dispatch(reduceSetLoadingState({name: `sessions-${params.clientId}`, state: false}))
      }
    })
  }
}

export const dispatchListRecentSessions = (params: {
  token: string
  salonId: number
  pageNumber?: number
  pageSize?: number
  stylistId?: number | null
  clientId?: number
  startDateTime?: Date
  endDateTime?: Date
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: 'recent-sessions', state: true}))
    return apiListSession(params).then((resp) => {
      dispatch(reduceListRecentSessions(resp))
      dispatch(reduceSetLoadingState({name: 'recent-sessions', state: false}))
    })
  }
}

export const dispatchUpdateSession = (params: {
  token: string
  user_id: number
  salon_id: number
  model: APIUpdateSession
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateSession(params)
      .then((resp) => {
        dispatch(reduceUpdateSession(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchCreateSession = (params: {
  token: string
  userId: number
  salonId: number
  model: APICreateSession
}) => {
  return (dispatch: Dispatch) => {
    return apiCreateSession(params)
      .then((resp) => {
        dispatch(reduceCreateSession(resp))
        dispatch(reduceRedirectToSessionId(resp.id))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchDeleteSession = (params: { token: string; salonId: number; model: Session }) => {
  return (dispatch: Dispatch) => {
    return apiDeleteSession(params)
      .then((resp) => {
        dispatch(reduceDeleteSession(resp))
        toast.success('Deleted Session')
      })
      .catch((error) => {
        throw error
      })
  }
}

// session item actions
//
//
//

export const dispatchUpdateSessionPhoto = (params: {
  token: string
  user_id: number
  salon_id: number
  session_id: number
  photo_tag: string
  client_photo: File
  session: Session
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateSessionPhoto(params)
      .then((resp) => {
        const updatedSession = assign({}, params.session, {clientPhotos: [...params.session.clientPhotos, resp]})
        dispatch(reduceUpdateSession(updatedSession))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchDeleteSessionPhoto = (params: {
  token: string
  user_id: number
  salon_id: number
  photo: ClientPhoto
  session: Session
}) => {
  return (dispatch: Dispatch) => {
    return apiDeleteSessionPhoto(params)
      .then(() => {
        const updatedPhotos = params.session.clientPhotos.filter((p) => p.id !== params.photo.id)
        const updatedSession = assign({}, params.session, {clientPhotos: updatedPhotos})
        dispatch(reduceUpdateSession(updatedSession))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchGetBowlTypes = (params: { token: string }) => {
  return (dispatch: Dispatch) => {
    return apiGetBowlTypes(params)
      .then((resp) => {
        dispatch(reduceListBowlTypes(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchCreateClientAndSession = (params: {
  token: string
  userId: number
  salonId: number
  client: Client
  session: APICreateSession
}) => {
  const {token, userId, salonId, client, session} = params
  return (dispatch: Dispatch) => {
    return apiCreateClient({token, salonId, model: client})
      .then((resp) => {
        dispatch(reduceCreateClient(resp))
        const sessionWithClient = assign({}, session, {client_id: resp.id})
        return apiCreateSession({token, userId, salonId, model: sessionWithClient})
          .then((resp) => {
            dispatch(reduceCreateSession(resp))
            dispatch(reduceRedirectToSessionId(resp.id))
          })
          .catch((error) => {
            throw error
          })
      })
      .catch((error) => {
        throw error
      })
  }
}


// lara sessions
//
//
//
//


export const apiListSessionsLara = (params: {
  token: string;
  salonId: number,
  clientId?: number,
  userId?: number,
  startDateTime?: Date,
  endDateTime?: Date,
  pageSize?: number,
  pageNumber?: number,
}): Promise<{ models: SessionMetaLara[]; pagination: LaraPagination }> => {
  const {token, salonId, pageNumber = 1, clientId, userId, startDateTime, endDateTime, pageSize = 30} = params
  const startsBetween = startDateTime && endDateTime ? `${buildDateYYYYMMDD(startDateTime)},${buildDateYYYYMMDD(endDateTime)}` : undefined

  const startsBetweenFilter = buildLaraStringFilter({key: 'starts_between', value: startsBetween, encode: false})
  const clientIdFilter = buildLaraStringFilter({key: 'client_id', value: clientId, encode: false})
  const userIdFilter = buildLaraStringFilter({key: 'user_id', value: userId, encode: false})

  const config = buildLaraConfig({token})
  const {pageSizeParam, pageNumberParam} = buildLaraPageParams({pageSize: pageSize, pageNumber})

  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/?${pageSizeParam}&${pageNumberParam}${clientIdFilter}${userIdFilter}${startsBetweenFilter}`

  return axios
    .get(url, config)
    .then((response: { data: APIPagedLaraResponse }) => {
      return {
        pagination: mapAPILaraPaginationToLaraPagination(response.data.meta),
        models: mapAPISessionsLaraToSessionsLara(response.data.data),
      }
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateSessionLara = (params: {
  token: string
  salonId: number
  model: APISessionMetaCreateLara
}): Promise<SessionMetaLara> => {
  const {token, salonId, model} = params

  const config = buildLaraConfig({token})
  const body = model
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionMetaLara} }) => {
      return mapAPISessionLaraToSessionLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiBulkCreateSessionLara = (params: {
  token: string
  salonId: number
  models: APISessionMetaCreateLara[]
}): Promise<SessionMetaLara[]> => {
  const {token, salonId, models} = params

  const config = buildLaraConfig({token})
  const body = {
    sessions: models
  }
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/bulk-sessions/`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionMetaLara[]} }) => {
      return mapAPISessionsLaraToSessionsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateOrUpdateSessionSuppliesLara = (params: {
  token: string
  salonId: number
  sessionId: number
  models: APICreateOrUpdateSessionSupplyLara[]
}): Promise<SessionSupplyLara[]> => {
  const {token, salonId, sessionId, models} = params

  const config = buildLaraConfig({token})
  const body = models
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-supplies`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionSupplyLara[]} }) => {
      return mapAPISessionSuppliesLaraToSessionSuppliesLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionSupplyLara = (params: {
  token: string
  salonId: number
  sessionId: number
  sessionSupplyId: number
}): Promise<void> => {
  const {token, salonId, sessionId, sessionSupplyId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-supplies/${sessionSupplyId}`

  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateOrUpdateSessionExtensionsLara = (params: {
  token: string
  salonId: number
  sessionId: number
  models: APICreateOrUpdateSessionExtensionLara[]
}): Promise<SessionExtensionLara[]> => {
  const {token, salonId, sessionId, models} = params

  const config = buildLaraConfig({token})
  const body = models
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/extension-items`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionExtensionLara[]} }) => {
      return mapAPISessionExtensionsLaraToSessionExtensionsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionExtensionLara = (params: {
  token: string
  salonId: number
  sessionId: number
  sessionExtensionIds: number[]
}): Promise<void> => {
  const {token, salonId, sessionId, sessionExtensionIds} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/extension-items/`
  // note: axios is weird about adding a body
  // ref: https://stackoverflow.com/a/53263784
  config['data'] = {
    sessionitem_id: sessionExtensionIds
  }
  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiListSessionExtensionsLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionExtensionLara[]> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/extension-items`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionExtensionLara[]} }) => {
      return mapAPISessionExtensionsLaraToSessionExtensionsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}


export const apiSessionMetaPatchLara = ( params: {
  token: string
  salonId: number
  sessionId: number
  model: APISessionMetaPatchLara
}): Promise<SessionMetaLara> => {
  const {token, salonId, sessionId, model} = params

  const config = buildLaraConfig({token})
  const body = model
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}`

  return axios
    .patch(url, body, config)
    .then((response: { data: {data: APISessionMetaLara} }) => {
      return mapAPISessionLaraToSessionLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiListSessionSuppliesLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionSupplyLara[]> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-supplies`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionSupplyLara[]} }) => {
      return mapAPISessionSuppliesLaraToSessionSuppliesLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetSessionMetaLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionMetaLara> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionMetaLara} }) => {
      return mapAPISessionLaraToSessionLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionMetaLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<void> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}`

  return axios
    .delete(url, config)
    .then(() => {return} )
    .catch((error) => {
      throw error
    })
}

export const apiCreateOrUpdateSessionColorsLara = (params: {
  token: string
  salonId: number
  sessionId: number
  models: APICreateOrUpdateSessionColorLara[]
}): Promise<SessionColorLara[]> => {
  const {token, salonId, sessionId, models} = params

  const config = buildLaraConfig({token})
  const body = models
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/color-items`

  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionColorLara[]} }) => {
      return mapAPISessionColorsLaraToSessionColorsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionColorLara = (params: {
  token: string
  salonId: number
  sessionId: number
  sessionColorIds: number[]
}): Promise<void> => {
  const {token, salonId, sessionId, sessionColorIds} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/color-items/`
  // note: axios is weird about adding a body
  // ref: https://stackoverflow.com/a/53263784
  config['data'] = {
    sessionitem_id: sessionColorIds
  }
  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiListSessionColorsLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionColorLara[]> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/color-items`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionColorLara[]} }) => {
      return mapAPISessionColorsLaraToSessionColorsLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}


export const apiListSessionPhotosLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionPhotoLara[]> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-photo`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionPhotoLara[]} }) => {
      return mapAPISessionPhotosLaraToSessionPhotosLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateSessionPhotoLara = (params: {
  token: string
  salonId: number
  sessionId: number
  model: APICreateSessionPhotoLara
}): Promise<SessionPhotoLara> => {
  const {token, salonId, sessionId, model} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-photo/`
  const body = model
  return axios
    .post(url, body, config)
    .then((response: { data: {data: APISessionPhotoLara} }) => {
      return mapAPISessionPhotoLaraToSessionPhotoLara(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiDeleteSessionPhotoLara = (params: {
  token: string
  salonId: number
  sessionId: number
  photoId: number
}): Promise<void> => {
  const {token, salonId, sessionId, photoId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/session-photo/${photoId}`

  return axios
    .delete(url, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiCompleteSessionLara = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<any> => {
  const {token, salonId, sessionId} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/sessions/${sessionId}/complete`

  return axios
    .post(url, null, config)
    .then((resp) => {
      return resp
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchListSessionsLara = (params: {
  token: string;
  salonId: number,
  clientId?: number,
  userId?: number,
  startDateTime?: Date,
  endDateTime?: Date,
  pageSize?: number,
  pageNumber?: number
  loadingName?: string
}) => {
  const {loadingName = LOADING_SESSIONS} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionsLara(params).then((resp) => {
      dispatch(reduceListSessionsLara(resp.models))
      dispatch(reduceSessionsLaraPagination(resp.pagination))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchCreateSessionLara = (params: {
  token: string
  salonId: number
  model: APISessionMetaCreateLara
  loadingName?: string
}) => {
  const {loadingName = 'creating-session'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateSessionLara(params).then((resp) => {
      dispatch(reduceCreateSessionLara(resp))
      dispatch(reduceRedirectToSessionId(resp.id))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchBulkCreateSessionsLara = (params: {
  token: string
  salonId: number
  models: APISessionMetaCreateLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-session'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiBulkCreateSessionLara(params).then((resp) => {
      dispatch(reduceListSessionsLara(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchPatchSessionMetaLara = (params: {
  token: string
  salonId: number
  sessionId: number
  model: APISessionMetaPatchLara
  loadingName?: string
}) => {
  const {loadingName = 'updating-session'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiSessionMetaPatchLara(params).then(( resp) => {
      dispatch(reduceActiveSessionMeta(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchGetSessionMetaLara = (params: {
  token: string
  salonId: number
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'getting-session'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiGetSessionMetaLara(params).then((resp) => {
      dispatch(reduceActiveSessionMeta(resp))
      dispatch(reduceRedirectToSessionId(resp.id))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}
export const dispatchDeleteSessionMetaLara = ( params: {
  token: string
  salonId: number
  sessionId: number
  loadingName?: string
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionMetaLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSession(params.sessionId))
      })
      .catch(( error ) => {
        throw error
      })
  }
}


export const dispatchCreateClientAndSessionLara = (params: {
  token: string
  salonId: number
  session: APISessionMetaCreateLara
  client: Client
  loadingName?: string
}) => {
  const {token, salonId, client, session} = params
  return (dispatch: Dispatch) => {
    return apiCreateClient({token, salonId, model: client})
      .then((resp) => {
        dispatch(reduceCreateClient(resp))
        const sessionWithClient = assign({}, session, {client_id: resp.id})
        return apiCreateSessionLara({token, salonId, model: sessionWithClient})
          .then((resp) => {
             dispatch(reduceCreateSessionLara(resp))
            dispatch(reduceRedirectToSessionId(resp.id))
          })
          .catch((error) => {
            throw error
          })
      })
      .catch((error) => {
        throw error
      })
  }
}


// session supplies
//
//
export const dispatchListSessionSuppliesLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'loading-session-supplies'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionSuppliesLara(params).then((resp) => {
      dispatch(reduceActiveSessionSupplies(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}


export const dispatchCreateOrUpdateSessionSuppliesLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  models: APICreateOrUpdateSessionSupplyLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-session-supplies'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateOrUpdateSessionSuppliesLara(params).then((resp) => {
      dispatch(reduceActiveSessionSupplies(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionSupplyLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number,
  sessionSupplyId: number
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionSupplyLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSessionSupply(params.sessionSupplyId))
      })
      .catch(( error ) => {
        throw error
      })
  }
}

// session extensions
//
//
export const dispatchListSessionExtensionsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'loading-extension-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionExtensionsLara(params).then((resp) => {
      dispatch(reduceActiveSessionExtensions(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}



export const dispatchCreateOrUpdateSessionExtensionsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  models: APICreateOrUpdateSessionExtensionLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-extension-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateOrUpdateSessionExtensionsLara(params).then((resp) => {
      dispatch(reduceActiveSessionExtensions(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionExtensionLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number,
  sessionExtensionIds: number[]
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionExtensionLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSessionExtension(params.sessionExtensionIds))
      })
      .catch(( error ) => {
        throw error
      })
  }
}


// session colors
//
//
export const dispatchListSessionColorsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'listing-color-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionColorsLara(params).then((resp) => {
      dispatch(reduceActiveSessionColors(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}


export const dispatchCreateOrUpdateSessionColorsLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  models: APICreateOrUpdateSessionColorLara[]
  loadingName?: string
}) => {
  const {loadingName = 'creating-color-items'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateOrUpdateSessionColorsLara(params).then((resp) => {
      dispatch(reduceActiveSessionColors(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionColorLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number,
  sessionColorIds: number[]
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiDeleteSessionColorLara(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSessionColor(params.sessionColorIds))
      })
      .catch(( error ) => {
        throw error
      })
  }
}


// session photos lara
export const dispatchListSessionPhotosLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  loadingName?: string
}) => {
  const {loadingName = 'loading-photos'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionPhotosLara(params).then((resp) => {
      dispatch(reduceActiveSessionPhotos(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionPhotoLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  photoId: number
  loadingName?: string
}) => {
  const {loadingName = 'deleting-photo'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiDeleteSessionPhotoLara(params).then((resp) => {
      dispatch(reduceDeleteActiveSessionPhoto(params.photoId))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchCreateSessionPhotoLara = (params: {
  token: string;
  salonId: number,
  sessionId: number
  model: APICreateSessionPhotoLara
  loadingName?: string
}) => {
  const {loadingName = 'creating-photo'} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiCreateSessionPhotoLara(params).then((resp) => {
      dispatch(reduceCreateActiveSessionPhoto(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchCompleteSessionLara = ( params: {
  token: string;
  salonId: number;
  sessionId: number,
} ) => {
  return ( dispatch: Dispatch ) => {
    return apiCompleteSessionLara(params)
      .then(( resp ) => {
        dispatch(reduceCompleteSessionLara(params.sessionId))
      })
      .catch(( error ) => {
        throw error
      })
  }
}

