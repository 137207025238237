import { APIUpdateSession, Session } from '../../../data/sessions/interfaces'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { reduceIsSupplySheetVisible, selectIsSupplySheetVisible } from '../../../data/sessions/slice'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { SuppliesSheetLegacy } from './SuppliesSheetLegacy'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'

import { formatDollars } from "../../../mini-lib/units/money";

export const SessionSuppliesLegacy = ( props: { session: Session; updateSession: ( u: Partial<APIUpdateSession>) => void }) => {
  const dispatch = useDispatch()
  const { session, updateSession } = props
  const [show, setShow] = useState(true)
  const isSupplySheetVisible = useAppSelector(selectIsSupplySheetVisible)
  return (
    <>
      {isSupplySheetVisible && <SuppliesSheetLegacy session={session} updateSession={updateSession} />}
      <Flex
        minH="60px"
        borderRadius="8px"
        bg="brand.linen.50"
        align="center"
        justify="space-between"
        p="24px"
        onClick={() => setShow(!show)}
        cursor="pointer"
      >
        <Text variant="title3">Add-Ons</Text>
        <Flex align="center" gridGap="24px">
          <Text>${formatDollars(session.totalSuppliesCost)}</Text>
          <MaterialIcon pr="4px" name={show ? 'arrow_drop_down' : 'arrow_drop_up'} />
        </Flex>
      </Flex>
      {show && (
        <>
          {session.sessionSupplies.map((supply) => {
            return (
              <Box
                key={supply.id}
                onClick={() => {
                  if (session.status === SESSION_STATUS_OPTIONS.open) {
                    dispatch(reduceIsSupplySheetVisible(true))
                  }
                }}
                cursor={session.status === SESSION_STATUS_OPTIONS.open ? 'pointer' : ''}
                className="cy-session-supply"
              >
                <Flex p="24px 12px" justify="space-between" pr="24px">
                  <Text>{supply.name}</Text>
                  <Text>${supply.pricePerUnit * supply.quantity}</Text>
                </Flex>
                <Divider />
              </Box>
            )
          })}
          {session.sessionSupplies.length === 0 && <EmptyBox h="200px" />}
          {session.status === SESSION_STATUS_OPTIONS.open && (
            <Flex
              align="center"
              p="12px"
              onClick={() => {
                dispatch(reduceIsSupplySheetVisible(true))
              }}
              cursor="pointer"
            >
              <MaterialIcon colorhex={COLORS.linen_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
              <Text color="brand.linen.500">add add-ons</Text>
            </Flex>
          )}
        </>
      )}
    </>
  )
}
