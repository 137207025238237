export const LOADING_SESSIONS = 'loading-sessions'
export const LOADING_SESSIONS_PAGE = 'loading-sessions-page'

export const SESSION_VIEW_TYPES = {
  salonMember: 'member',
  list: 'list',
}


export const DISPLAY_UNIT_OPTIONS = {
  g: { value: 'g', label: 'g' },
  oz: { value: 'oz', label: 'oz' },
}

export const SESSION_STATUS_OPTIONS = {
  open: 'open',
  complete: 'complete',
}

export const SESSION_SORT_OPTIONS = {
  dateAsc: 'dateAsc',
  dateDesc: 'dateDesc',
}

export const SESSION_SORT_LABEL_MAP = {
  dateAsc: 'Date Asc',
  dateDesc: 'Date Desc',
}
export const SESSION_STYLIST_DATA_OPTIONS = {
  all: 'all',
  withSessions: 'withSessions',
}
export const SESSION_STYLIST_DATA_LABEL_MAP = {
  all: 'All Stylists',
  withSessions: 'With Sessions',
}

export const SESSION_STATUS_LABEL_MAP = {
  open: 'Open',
  complete: 'Complete',
}

export type BowlSheetViewTypeLara = 'amountGrams' | 'wasteGrams' | 'empty-bowl' | 'empty-bowl-details'

export const BOWL_SHEET_VIEW_TYPES_LARA: { [key: string]: BowlSheetViewTypeLara } = {
  amount: 'amountGrams',
  waste: 'wasteGrams',
  emptyBowls: 'empty-bowl',
  emptyBowlDetails: 'empty-bowl-details',
}

export type BowlSheetViewType = 'amount' | 'waste' | 'empty-bowl' | 'empty-bowl-details'

export const BOWL_SHEET_VIEW_TYPES: { [key: string]: BowlSheetViewType } = {
  amount: 'amount',
  waste: 'waste',
  emptyBowls: 'empty-bowl',
  emptyBowlDetails: 'empty-bowl-details',
}

// todo: ensure we set this where Ryan and Kayla want it
export const MOCKED_DISPLAY_UNIT_WE_SHOULD_SET_SOMEWHERE_MAYBE_USER_SETTINGS_OR_SOMETHING = 'g'
