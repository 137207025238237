// @flow
import axios, { AxiosResponse } from 'axios'
import {
  APIBulkCreateUserLara,
  APICreateUserLara,
  APILoggedInUserSalonPermissions,
  APIPatchUserLara,
  APIStylist,
  APIUser,
  APIUserDeviceInfo,
  APIUserLara,
  LoggedInUser,
  LoggedInUserSalonPermissions,
  SalonMember,
  SalonMemberLara,
  SalonRole,
  UserDeviceInfo,
  UserMeta,
} from './interfaces'
import { Dispatch } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
  mapAPILoggedInUserSalonPermissionsToLoggedInUserSalonPermissions,
  mapAPISalonContextRoleToLoggedInUserSalonPermissions,
  mapAPISalonRolesToSalonRoles,
  mapAPISalonRoleToSalonRole,
  mapAPIStylistToSalonMember, mapAPIUserDeviceInfoToUserDeviceInfo,
  mapAPIUserLaraToLoggedInUser, mapApiUsersMetaToUsersMeta,
  mapAPIUsersToSalonMembers,
  mapAPIUsersToSalonMembersLara,
  mapAPIUserToLoggedInUser,
} from './mappers'
import {
  reduceCreateStylist,
  reduceDeleteLaborTierUsers,
  reduceDeleteStylist,
  reduceListMembers,
  reduceListMembersLara,
  reduceListSalonMemberRoles,
  reduceLoggedInUser,
  reduceLoggedInUserSalonPermissions, reduceSetPasswordSuccessfully,
  reduceUpdateLaborTierUsers,
  reduceUpdateLoggedInUser,
  reduceUpdateSalonMemberRole,
  reduceUpdateStylist, reduceUserDeviceInfo, reduceUsersMeta,
} from './slice'
import { extractErrorMessage, extractErrorStatusCode } from '../../mini-lib/utils/errors'
import { extractErrorFromResponse } from '../../core/errors/utils'
import { APIUserLaborTier, UserLaborTier } from '../labor/interfaces'
import { mapAPIUserLaborTierToUserLaborTiers } from '../labor/mappers'
import { GetServerBaseUrl } from '../../env'
import { setLocalStorageItem } from '../../core/localStorage'
import { stripNone } from '../../core/collections/collections'
import { generatePath } from 'react-router-dom'
import { UseSignOut } from '../../core/hooks/UseSignOut'
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
import { ROUTES } from '../../appRoutes'
import { reduceSetLoadingState } from '../../core/loading/slice'
import { LOADING_USER } from './constants'

// apis
//
//
export const apiGetLoggedInUserSalonPermissions = (
  token: string,
  userId: number,
  salonId: number,
): Promise<LoggedInUserSalonPermissions> => {
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/users/salons/${salonId}/roles?token=${token}&impersonate_user_id=${userId}`

  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: APILoggedInUserSalonPermissions }>) => {
      return mapAPILoggedInUserSalonPermissionsToLoggedInUserSalonPermissions(salonId, response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiListSalonMemberRoles = (params: { token: string; salonId: number }): Promise<SalonRole[]> => {
  const { token, salonId } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/roles?token=${token}`
  return axios
    .get(url)
    .then((response: any) => {
      return mapAPISalonRolesToSalonRoles(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateSalonMemberRole = (params: {
  token: string
  salonId: number
  userId: number
  roleName: string
  roleAccess?: boolean
}): Promise<SalonRole> => {
  const { token, userId, salonId, roleName, roleAccess = true } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/${userId}/roles?token=${token}`
  const body = {
    role: roleName,
    access: roleAccess,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPISalonRoleToSalonRole(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiListSalonMembers = (token: string, salon_id: number): Promise<SalonMember[]> => {
  const url = `${GetServerBaseUrl()}/users/?token=${token}&salon_id=${salon_id}`
  return axios
    .get(url)
    .then((response: any) => {
      return mapAPIUsersToSalonMembers(response.data.data, salon_id)
    })
    .catch((error) => {
      throw error
    })
}
// TODO - change the name to apiListSalonMembers once the users api completely replaced
export const apiListSalonMembersLara = (
  token: string,
  salonId: number,
  permissions?: string,
): Promise<SalonMemberLara[]> => {
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/users/?token=${token}&filter[permissions]=${permissions}`
  return axios
    .get(url)
    .then((response: any) => {
      // TODO - change the name to mapAPIUsersToSalonMembers once the users api completely replaced
      return mapAPIUsersToSalonMembersLara(response.data.data, salonId)
    })
    .catch((error) => {
      throw error
    })
}

export const apiListUsersMetaLara = (params: {
  token: string,
  salonId: number,
}): Promise<UserMeta[]> => {
  const {token, salonId} = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl(
    'v3',
    'lara',
  )}/salons/${salonId}/users-meta`
  return axios
    .get(url, config)
    .then((response: any) => {
      return mapApiUsersMetaToUsersMeta(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetLoggedInUser = (params: {
  token: string;
  userId?: number;
  salonId?: number
}): Promise<LoggedInUser> => {
  const { token, userId, salonId } = params
  const user_id_param = userId ? `&user_id=${userId}` : ''
  const url = `${GetServerBaseUrl()}/users/account/?token=${token}${user_id_param}`
  return axios
    .get(url)
    .then((response: any) => {
      return mapAPIUserToLoggedInUser(token, response.data.data, salonId)
    })
    .catch((error) => {
      throw error
    })
}

export const apiSendPasswordResetEmail = (params: { email: string }): Promise<boolean> => {
  const { email } = params
  const url = `${GetServerBaseUrl()}/reset-pw/`
  const body = { email: email.trim() }
  return axios
    .post(url, body)
    .then((response: AxiosResponse<{ data: { success: boolean } }>) => {
      return response.data.data.success
    })
    .catch((error) => {
      throw error
    })
}

export const apiResetPassword = (params: {
  userId: number
  resetToken: string
  password: string
}): Promise<boolean> => {
  const { userId, resetToken, password } = params
  const url = `${GetServerBaseUrl()}/set-pw/`
  const body = { password, reset_token: resetToken, user_id: userId }
  return axios
    .post(url, body)
    .then((response: AxiosResponse<{ data: { success: boolean } }>) => {
      return response.data.data.success
    })
    .catch((error) => {
      throw error
    })
}

export const apiLogIn = (params: { email: string; password: string; salonId?: number }): Promise<LoggedInUser> => {
  const { email, password, salonId } = params
  const url = `${GetServerBaseUrl()}/login/`
  const body = { email: email.trim(), password: password.trim() }
  return axios
    .post(url, body)
    .then((response: AxiosResponse<{ data: { token: string; user: APIUser } }>) => {
      return mapAPIUserToLoggedInUser(response.data.data.token, response.data.data.user, salonId)
    })
    .catch((error) => {
      throw error
    })
}
export const apiUpdateLoggedInUser = (params: {
  token: string
  salon_id: number
  user_id: number
  first_name?: string
  last_name?: string
  email?: string
}): Promise<LoggedInUser> => {
  const { token, salon_id, user_id, first_name, last_name, email } = params
  const url = `${GetServerBaseUrl()}/users/account/?token=${token}&user_id=${user_id}`
  const body = stripNone({ first_name, last_name, email })
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPIUserToLoggedInUser(token, response.data.data, salon_id)
    })
    .catch((error) => {
      throw error
    })
}
export const apiUpdateLoggedInUserPhoto = (params: {
  token: string
  user_id: number
  salon_id: number
  photo: File
}): Promise<LoggedInUser> => {
  const { token, user_id, salon_id, photo } = params
  const url = `${GetServerBaseUrl()}/users/${user_id}/photos/?token=${token}&user_id=${user_id}`
  const body = new FormData()
  body.append('user_photo', photo)

  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPIUserToLoggedInUser(token, response.data.data, salon_id)
    })
    .catch((error) => {
      throw error
    })
}
export const apiUpdateStylist = (params: {
  token: string
  user_id: number
  salon_id: number
  stylist_id: number
  first_name: string
  last_name: string
  email: string
}): Promise<SalonMember> => {
  const {
    token,
    user_id,
    salon_id,
    stylist_id,
    first_name,
    last_name,
    // , email
  } = params
  const url = `${GetServerBaseUrl()}/stylists/${stylist_id}/`
  const stylist = {
    first_name,
    last_name,
    // email
  }
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    stylist,
  }
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPIStylistToSalonMember(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiCreateStylist = (params: {
  token: string
  user_id: number
  salon_id: number
  first_name: string
  last_name: string
  email: string
}): Promise<SalonMember> => {
  const { token, user_id, salon_id, first_name, last_name, email } = params
  const url = `${GetServerBaseUrl()}/stylists/`
  const stylist = {
    first_name,
    last_name,
    email,
  }
  const body = {
    token,
    owner_id: user_id,
    salon_id: salon_id,
    stylist,
  }
  return axios
    .post(url, body)
    .then((response: { data: { data: APIStylist } }) => {
      return mapAPIStylistToSalonMember(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiSendSalonInviteToken = (params: {
  token: string
  salonId: number
  type: 'email' | 'phone' | string
  email?
  countryCode?: string
  phoneNumber?: string
}): Promise<void> => {
  const { token, salonId, type, email, countryCode, phoneNumber } = params
  const url = `${GetServerBaseUrl()}/salons/${salonId}/invite_stylist/`
  const body = stripNone({
    token: token,
    email,
    invite_type: type,
    phone_number: phoneNumber,
    country_code: countryCode,
  })
  return axios
    .post(url, body)
    .then((response: any) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteStylist = (params: {
  token: string
  salonId: number
  model: SalonMember
}): Promise<{ deleted: boolean; id: number; userId: number }> => {
  const { token, salonId, model } = params
  const url = `${GetServerBaseUrl()}/stylists/${model.stylistId}/?token=${token}&salon_id=${salonId}`
  return axios
    .delete(url)
    .then((response: any) => {
      return { ...response.data, userId: model.userId }
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateLaborTierUsers = (params: {
  token: string
  salon_id: number
  user_id: number
  models: APIUserLaborTier[]
}): Promise<UserLaborTier[]> => {
  const { token, salon_id, user_id, models } = params
  const url = `${GetServerBaseUrl()}/labor-tiers/users/?token=${token}&salon_id=${salon_id}&user_id=${user_id}`
  const body = {
    users_and_tiers: models,
  }
  return axios
    .put(url, body)
    .then((response: AxiosResponse<{ data: APIUserLaborTier[] }>) => {
      return mapAPIUserLaborTierToUserLaborTiers(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteLaborTierUsers = (params: {
  token: string
  salon_id: number
  user_id: number
  models: SalonMember[]
}): Promise<{ deleted: boolean }> => {
  const { token, salon_id, user_id, models } = params
  const modelIds = models.map((model) => model.userId).join(',')
  const url = `${GetServerBaseUrl()}/labor-tiers/users/?token=${token}&salon_id=${salon_id}&user_id=${user_id}&ids=${modelIds}`
  return axios
    .delete(url)
    .then((response: any) => {
      return { deleted: true }
    })
    .catch((error) => {
      throw error
    })
}

export const apiSetLaraPassword = (params: {
  token: string
  userId: number
  password: string
}): Promise<void> => {
  const { token, userId, password } = params
  const body = { lara_password: password }
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/${userId}/set-password`
  return axios
    .post(url, body, config)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiResetLaraPassword = (params: {
  email: string
  password: string
  resetToken: string
}): Promise<void> => {
  const {  email, password } = params
  const body = { email, lara_password: password }
  const url = `${GetServerBaseUrl('v3', 'lara')}/reset-password?expires=1712867059&signature=eded0eb19ab2eb49997ee4ca55853515fb189b1d00c944c3066a7c8bef86ac2a`
  return axios
    .post(url, body)
    .then(() => {
      return
    })
    .catch((error) => {
      throw error
    })
}

export const apiPatchUserLara = (params: {
  token: string
  userId: number
  body: APIPatchUserLara
}): Promise<Partial<LoggedInUser>> => {

  const { token, userId, body } = params
  const config = buildLaraConfig({ token })
  // have to add impersonate user id or this will break anytime admin token is added to this api
  // apparently it takes the user from the token not from the USER ID in the request..a
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/${userId}?impersonate_user_id=${userId}`
  return axios
    .patch(url, body, config)
    .then((resp: { data: { data: APIUserLara } }) => {
      const updatedUser: Partial<LoggedInUser> = {
        email: resp.data.data.email,
        firstName: resp.data.data.first_name,
        lastName: resp.data.data.last_name,
        photoUrl: resp?.data?.data?.user_photo_url ? resp.data.data.user_photo_url : '',
      }
      return updatedUser
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateUserLara = (params: {
  body: APICreateUserLara
}): Promise<LoggedInUser> => {
  const { body } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/`
  return axios
    .post(url, body)
    .then((resp: { data: { data: { login_token: string, user: APIUserLara } } }) => {
      return mapAPIUserLaraToLoggedInUser(resp.data.data.login_token, resp.data.data.user)
    })
    .catch((error) => {
      throw error
    })
}

export const apiBulkCreateUserLara = (params: {
  token: string,
  salonId: number
  users: APIBulkCreateUserLara[]
}): Promise<any> => {
  const { users, salonId, token } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/`
  const config = buildLaraConfig({ token })
  const body = {users}
  return axios
    .post(url, body, config)
    .then(resp => {
      toast.success('Users Created')
      return resp
    })
    .catch((error) => {
      toast.error('We are unable to create users')
      throw error
    })
}

export const apiLogInLara = (params: {
  email: string;
  password: string;
  salonId?: number
}): Promise<LoggedInUser> => {
  const { email, password, salonId } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/login/`
  const body = { email_address: email.trim(), lara_password: password.trim() }
  return axios
    .post(url, body)
    .then((response: AxiosResponse<{ data: { login_token: string; user: APIUserLara } }>) => {
      return mapAPIUserLaraToLoggedInUser(response.data.data.login_token, response.data.data.user, salonId)
    })
    .catch((error) => {
      throw error
    })
}

export const apiGetUserLara = (params: {
  token: string
  userId: number
  salonId?: number
}): Promise<LoggedInUser> => {

  const { token, userId, salonId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/${userId}`
  return axios
    .get(url, config)
    .then((resp: { data: { data: APIUserLara } }) => {
      return mapAPIUserLaraToLoggedInUser(token, resp.data.data, salonId)
    })
    .catch((error) => {
      throw error
    })
}

// actions
//
//
export const dispatchListSalonMemberRoles = (params: { token: string; salonId: number }) => {
  return (dispatch: Dispatch) => {
    return apiListSalonMemberRoles(params).then((resp) => {
      dispatch(reduceListSalonMemberRoles(resp))
    })
  }
}
export const dispatchListSalonMembers = (token: string, salon_id: number) => {
  return (dispatch: Dispatch) => {
    return apiListSalonMembers(token, salon_id).then((resp) => {
      dispatch(reduceListMembers(resp))
    })
  }
}

// TODO - change the name to dispatchListSalonMembers once the users api completely replaced
export const dispatchListSalonMembersLara = (token: string, salonId: number, permisison: string) => {
  return (dispatch: Dispatch) => {
    return apiListSalonMembersLara(token, salonId, permisison).then((resp) => {
      dispatch(reduceListMembersLara(resp))
    })
  }
}

export const dispatchListUsersMetaLara = (params: {token: string, salonId: number}) => {
  return (dispatch: Dispatch) => {
    return apiListUsersMetaLara(params).then((resp) => {
      dispatch(reduceUsersMeta(resp))
    })
  }
}


export const dispatchSendPasswordResetEmail = (params: { email: string }) => {
  return (dispatch: Dispatch | any) => {
    return apiSendPasswordResetEmail(params)
      .then((resp) => {
        toast.success('Password reset email sent, check your email for next steps')
      })
      .catch((error) => {
        const errorMessage = extractErrorMessage(
          error,
          'We were unable to send a password reset email, please contact customer support',
        )
        toast.error(errorMessage)
        throw error
      })
  }
}
export const dispatchResetPassword = (params: { userId: number; resetToken: string; password: string }) => {
  return (dispatch: Dispatch | any) => {
    return apiResetPassword(params)
      .then((resp) => {
        toast.success('Password reset')
        const url = generatePath(ROUTES.login)
        window.location.href = url
      })
      .catch((error) => {
        const errorMessage = extractErrorMessage(error, 'We were unable to reset your password, please contact support')
        toast.error(errorMessage)
        throw error
      })
  }
}
export const dispatchLogIn = (params: { email: string; password: string; salonId?: number }) => {
  return (dispatch: Dispatch | any) => {
    return apiLogIn(params)
      .then((resp) => {
        dispatch(reduceLoggedInUser(resp))
        dispatch(dispatchSetLaraPassword({ userId: resp.userId, password: params.password, token: resp.token }))
        setLocalStorageItem('auth-token', resp.token)
        setLocalStorageItem('user-id', resp.userId)
        // todo: when we add this to the user response in the future get this from that response instead of
        // chaining these api calls
        if (resp?.currentSalonContext?.salonId) {
          apiGetLoggedInUserSalonPermissions(resp.token, resp.userId, resp.currentSalonContext?.salonId).then(
            (resp2) => {
              dispatch(reduceLoggedInUserSalonPermissions(resp2))
            },
          )
        }
      })
      .catch((error) => {
        const errorMessage = extractErrorMessage(error, 'Incorrect username or password')
        toast.error(errorMessage)
        throw error
      })
  }
}

export const dispatchLogInWithLaraAndDjango = (params: { email: string; password: string; salonId?: number }) => {
  return (dispatch: Dispatch | any) => {
    dispatch(reduceSetLoadingState({ name: LOADING_USER, state: true }))
    // if the lara login fails attempt to call django in case they have old login info
    return apiLogInLara(params)
      .then((laraResp) => {
        dispatch(reduceLoggedInUser(laraResp))
        setLocalStorageItem('auth-token', laraResp.token)
        setLocalStorageItem('user-id', laraResp.userId)
        if (laraResp?.currentSalonContext?.salonId) {
          apiGetLoggedInUserSalonPermissions(
            laraResp.token,
            laraResp.userId,
            laraResp.currentSalonContext?.salonId,
          ).then((laraResp2) => {
            dispatch(reduceLoggedInUserSalonPermissions(laraResp2))
          })
        }
        dispatch(reduceSetLoadingState({ name: LOADING_USER, state: false }))
      })
      .catch(() => {
        return apiLogIn(params).then((resp) => {
          dispatch(reduceLoggedInUser(resp))
          dispatch(dispatchSetLaraPassword({ userId: resp.userId, password: params.password, token: resp.token }))
          setLocalStorageItem('auth-token', resp.token)
          setLocalStorageItem('user-id', resp.userId)
          if (resp?.currentSalonContext?.salonId) {
            apiGetLoggedInUserSalonPermissions(resp.token, resp.userId, resp.currentSalonContext?.salonId).then(
              (resp2) => {
                dispatch(reduceLoggedInUserSalonPermissions(resp2))
              },
            )
          }
          dispatch(reduceSetLoadingState({ name: LOADING_USER, state: false }))
        })
      })
      .catch((error) => {
        const errorMessage = extractErrorMessage(error, 'Incorrect username or password')
        dispatch(reduceSetLoadingState({ name: LOADING_USER, state: false }))
        toast.error(errorMessage)
        throw error
      })
  }
}

export const dispatchGetLoggedInUser = (params: { token: string; userId: number; salonId?: number }) => {
  return (dispatch: Dispatch | any) => {
    return apiGetLoggedInUser(params)
      .then((resp) => {
        dispatch(reduceLoggedInUser(resp))
        setLocalStorageItem('auth-token', resp.token)
        setLocalStorageItem('user-id', resp.userId)
        // todo: when we add this to the user response in the future get this from that response instead of
        // chaining these api calls
        if (resp?.currentSalonContext?.salonId) {
          apiGetLoggedInUserSalonPermissions(resp.token, resp.userId, resp.currentSalonContext?.salonId).then(
            (resp2) => {
              dispatch(reduceLoggedInUserSalonPermissions(resp2))
            },
          )
        }
      })
      .catch((error) => {
        const responseStatusCode: number | null = extractErrorStatusCode(error)
        if (responseStatusCode && responseStatusCode === 401) {
          toast.error('Your token has expired, please sign in again.')
          UseSignOut()
        }
        throw error
      })
  }
}
export const dispatchUpdateLoggedInUser = (params: {
  token: string
  user_id: number
  salon_id: number
  first_name?: string
  last_name?: string
  email?: string
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateLoggedInUser(params)
      .then((resp) => {
        dispatch(reduceLoggedInUser(resp))
        toast.success('Updated User')
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchUpdateLoggedInUserPhoto = (params: {
  token: string
  salon_id: number
  user_id: number
  photo: File
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateLoggedInUserPhoto(params)
      .then((resp) => {
        dispatch(reduceLoggedInUser(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchUpdateStylist = (params: {
  token: string
  user_id: number
  existing_user_id: number
  salon_id: number
  role_name: string
  stylist_id: number
  first_name: string
  last_name: string
  email: string
}) => {
  return (dispatch: Dispatch) => {
    const { token, salon_id, existing_user_id, role_name } = params
    return apiUpdateStylist(params)
      .then((resp) => {
        dispatch(reduceUpdateStylist(resp))
        toast.success('Updated Stylist')
        return apiUpdateSalonMemberRole({ token, salonId: salon_id, userId: existing_user_id, roleName: role_name })
          .then((resp) => {
            dispatch(reduceUpdateSalonMemberRole(resp))
          })
          .catch((error) => {
            throw error
          })
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchCreateStylist = (params: {
  token: string
  user_id: number
  salon_id: number
  role_name: string
  first_name: string
  last_name: string
  email: string
}) => {
  return (dispatch: Dispatch) => {
    const { token, salon_id, role_name } = params
    return apiCreateStylist(params)
      .then((resp) => {
        dispatch(reduceCreateStylist(resp))
        toast.success('Created Stylist')
        return apiUpdateSalonMemberRole({ token, salonId: salon_id, userId: resp.userId, roleName: role_name }).then(
          (resp) => {
            dispatch(reduceUpdateSalonMemberRole(resp))
          },
        )
      })
      .catch((error: { response: any }) => {
        toast.error(extractErrorFromResponse(error.response, 'Unable to create stylist'))
        throw error
      })
  }
}

export const dispatchUpdateSalonMemberRole = (params: {
  token: string
  salonId: number
  userId: number
  roleName: string
  roleAccess?: boolean
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateSalonMemberRole(params)
      .then((resp) => {
        dispatch(reduceUpdateSalonMemberRole(resp))
        const permissions: LoggedInUserSalonPermissions = {
          id: resp.role.roleId,
          salonId: params.salonId,
          role: resp.role.roleName,
          permissions: resp.role.permissions,
        }
        dispatch(reduceLoggedInUserSalonPermissions(permissions))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchSendSalonInviteToken = (params: {
  token: string
  salonId: number
  type: 'email' | 'phone' | string
  email?: string
  phoneNumber?: string
  countryCode?: string
}) => {
  return (dispatch: Dispatch) => {
    const { token, salonId, type, email, countryCode, phoneNumber } = params
    return apiSendSalonInviteToken({ token, salonId, type, email, countryCode, phoneNumber })
      .then((resp) => {
        phoneNumber && toast.success(`Invite sent to ${phoneNumber}`)
        email && toast.success(`Invite sent to ${email}`)
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchDeleteStylist = (params: {
  token: string
  salonId: number
  roleName: string
  model: SalonMember
}) => {
  const { token, salonId, model, roleName } = params
  return (dispatch: Dispatch) => {
    return apiDeleteStylist(params)
      .then((resp) => {
        dispatch(reduceDeleteStylist(resp))
        return apiUpdateSalonMemberRole({ token, salonId, userId: model.userId, roleName, roleAccess: false })
          .then((resp) => {
            toast.success('Deleted Stylist')
          })
          .catch((error) => {
            throw error
          })
      })
      .catch((error) => {
        toast.error(extractErrorMessage(error, 'Unable to delete Stylist'))
        throw error
      })
  }
}

export const dispatchDeleteUserTiers = (params: {
  token: string
  salon_id: number
  user_id: number
  models: SalonMember[]
}) => {
  return (dispatch: Dispatch) => {
    return apiDeleteLaborTierUsers(params)
      .then((resp) => {
        dispatch(reduceDeleteLaborTierUsers(params.models))
        toast.success('Removed tier from user')
      })
      .catch((error) => {
        toast.error(extractErrorMessage(error, 'Unable to delete User Tier'))
        throw error
      })
  }
}

export const dispatchUpdateUserTiers = (params: {
  token: string
  salon_id: number
  user_id: number
  models: APIUserLaborTier[]
}) => {
  return (dispatch: Dispatch) => {
    return apiUpdateLaborTierUsers(params)
      .then((resp: UserLaborTier[]) => {
        dispatch(reduceUpdateLaborTierUsers(resp))
        toast.success('Added user to tier')
      })
      .catch((error) => {
        toast.error(extractErrorMessage(error, 'Unable to add user to tier'))
        throw error
      })
  }
}

export const dispatchSetLaraPassword = (params: {
  token: string
  userId: number
  password: string
}) => {
  return (dispatch: Dispatch) => {
    return apiSetLaraPassword(params)
      .then(() => {
        dispatch(reduceSetPasswordSuccessfully(true))
      })
      .catch(() => {
      })
  }
}
export const dispatchResetPasswordLara = (params: {
  email: string
  password: string
  resetToken: string
}) => {
  return (dispatch: Dispatch) => {
    return apiResetLaraPassword(params)
      .then(() => {
        dispatch(reduceSetPasswordSuccessfully(true))
      })
      .catch(() => {
      })
  }
}

export const dispatchPatchUserLara = (params: {
  token: string
  userId: number
  salonId: number
  body: APIPatchUserLara
}) => {
  return (dispatch: Dispatch) => {
    return apiPatchUserLara(params)
      .then((resp: Partial<LoggedInUser>) => {
        dispatch(reduceUpdateLoggedInUser(resp))
        toast.success('Updated User')
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchGetUserLara = (params: {
  token: string
  userId: number
  salonId?: number
}) => {
  return (dispatch: Dispatch) => {
    return apiGetUserLara(params)
      .then((resp) => {
        dispatch(reduceLoggedInUser(resp))
        setLocalStorageItem('auth-token', resp.token)
        setLocalStorageItem('user-id', resp.userId)
        if (resp?.currentSalonContext?.salonId && resp?.currentSalonContext?.role) {
          const loggedInUserSalonPermissions = mapAPISalonContextRoleToLoggedInUserSalonPermissions(resp?.currentSalonContext?.salonId, resp?.currentSalonContext?.role)
          dispatch(reduceLoggedInUserSalonPermissions(loggedInUserSalonPermissions))
        } else if (resp.currentSalonContext?.salonId) {
          apiGetLoggedInUserSalonPermissions(resp.token, resp.userId, resp.currentSalonContext?.salonId).then(
            (resp2) => {
              dispatch(reduceLoggedInUserSalonPermissions(resp2))
            },
          )
        }
      })
      .catch((error) => {
        const responseStatusCode: number | null = extractErrorStatusCode(error)
        if (responseStatusCode && responseStatusCode === 401) {
          toast.error('Your token has expired, please sign in again.')
          UseSignOut()
        }
        throw error
      })
  }
}
export const dispatchCreateUserLara = (params: {
  body: APICreateUserLara
}) => {
  return (dispatch: Dispatch) => {
    return apiCreateUserLara(params)
      .then((resp) => {
        dispatch(reduceLoggedInUser(resp))
        setLocalStorageItem('auth-token', resp.token)
        setLocalStorageItem('user-id', resp.userId)
      })
      .catch((error) => {
        const responseStatusCode: number | null = extractErrorStatusCode(error)
        if (responseStatusCode && responseStatusCode === 422) {
          toast.error('That email is already in use - please log in instead')
        }
        throw error
      })
  }
}

export const dispatchBulkCreateUserLara = (params: {
  token: string
  salonId: number,
  users: APIBulkCreateUserLara[]
}) => {
  return (dispatch: Dispatch) => {
    return apiBulkCreateUserLara(params)
      .then((resp) => {
        console.info({resp})
      })
      .catch((error) => {
        throw error
      })
  }
}

export const apiGetDeviceInfo = (params: {
  token: string
  userId: number
}): Promise<UserDeviceInfo> => {

  const { token, userId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/${userId}/device-info?impersonate_user_id=${userId}`
  return axios
    .get(url, config)
    .then((resp: {data: {data: APIUserDeviceInfo}}) => {
      return mapAPIUserDeviceInfoToUserDeviceInfo(resp.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const dispatchGetUserDeviceInfo = (params: {
  token: string
  userId: number
}) => {
  return (dispatch: Dispatch) => {
    return apiGetDeviceInfo(params)
      .then((resp) => {
        dispatch(reduceUserDeviceInfo(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}

