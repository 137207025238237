import { filter, map, sortBy, sumBy } from 'lodash'
import {
  AddonReportAddon,
  AddonReportCSV,
  APIAddonReportAddon,
  APIClientDetailReportAddon,
  APIClientDetailReportLara,
  APIClientReportAddon,
  APIClientReportLara,
  APIExtensionOrProductReportDetailsLara,
  APIProductReport,
  APIStylistDetailReportAddon,
  APIStylistDetailReportLara,
  APIStylistReport,
  APIStylistReportAddon,
  ClientDetailReportAddon,
  ClientDetailReportCSV,
  ClientDetailReportLara,
  ClientDetailReportLaraCSVRow,
  ClientReport,
  ClientReportAddon,
  ClientReportCSV,
  ClientReportLara,
  ClientReportLaraCSVRow,
  ExtensionDetailUnusedProductCSVRow,
  ExtensionDetailUsedProductCSVRow,
  ExtensionOrProductReportDetailsLara,
  ExtensionReportDetailsLaraCSVRow,
  ExtensionReportLaraCSVRow,
  ProductReport,
  StylistDetailReportAddon,
  StylistDetailReportCSV,
  StylistDetailReportLara,
  StylistDetailReportLaraCSVRow,
  StylistReport,
  StylistReportAddon,
  StylistReportCSV,
  StylistReportLara,
  StylistReportLaraCSVRow,
  StylistDetailReportForExtensionsLaraCSVRow
} from './interfaces'
import { buildDateYYYYMMDD } from '../../core/dates'
import { getQuantityOnHand } from '../inventory/utils'

export const mapAPIReportToReport = (api: APIProductReport): ProductReport => {
  return {
    brand: api.brand.trim(),
    retail: api.retail,
    profit: api.profit,
    wholesale: api.wholesale,
  }
}

export const mapAPIReportsToReports = (apiModels: APIProductReport[]): ProductReport[] => {
  return map(apiModels, mapAPIReportToReport)
}

export const mapAPIProductReportDetailToProductReportDetail = (
  api: APIExtensionOrProductReportDetailsLara,
): ExtensionOrProductReportDetailsLara => {
  return {
    line: api.line,
    length: api.size,
    type: api.type,
    color: api.color,
    category: api.category,
    quantity: Number(getQuantityOnHand(parseFloat(Number(api.total_grams).toFixed(2)), api.size ?? 0).toFixed(2)),
    sessionId: api.session_id,
    wholesale: api.wholesale,
    retail: api.retail,
    profit: api.profit,
  }
}

export const mapAPIProductReportsDetailToUsedProductReportsDetail = (
  apiModels: APIExtensionOrProductReportDetailsLara[],
): ExtensionOrProductReportDetailsLara[] => {
  const data = map(apiModels, mapAPIProductReportDetailToProductReportDetail)
  return filter(data, (obj: ExtensionOrProductReportDetailsLara) => {
    return obj.sessionId != null
  })
}

export const mapAPIProductReportsDetailToUnusedProductReportsDetail = (
  apiModels: APIExtensionOrProductReportDetailsLara[],
): ExtensionOrProductReportDetailsLara[] => {
  const data = map(apiModels, mapAPIProductReportDetailToProductReportDetail)
  return filter(data, (obj: ExtensionOrProductReportDetailsLara) => {
    return obj.sessionId === null
  })
}

export const mapAPIStylistToStylist = (api: APIStylistReport): StylistReport => {
  return {
    stylistId: api.stylist_id,
    stylistName: api.stylist_name,
    retail: api.retail,
    profit: api.profit,
    wholesale: api.wholesale,
  }
}

export const mapAPIStylistsToStylists = (apiModels: APIStylistReport[]): StylistReport[] => {
  return map(apiModels, mapAPIStylistToStylist)
}

export const mapAPIStylistDetailExtensionToStylistDetailExtension = (
  api: APIStylistDetailReportLara,
): StylistDetailReportLara => {
  return {
    line: api.line,
    category: api.category,
    length: api.size,
    color: api.color,
    quantity: Number(getQuantityOnHand(parseFloat(Number(api.total_grams).toFixed(2)), api.size ?? 0).toFixed(2)),
    type: api.type,
    retail: api.retail,
    profit: api.profit,
    wholesale: api.wholesale,
  }
}

export const mapAPIStylistsDetailExtensionToStylistsDetailExtension = (
  apiModels: APIStylistDetailReportLara[],
): StylistDetailReportLara[] => {
  return map(apiModels, mapAPIStylistDetailExtensionToStylistDetailExtension)
}

export const mapStylistsToCSV = (stylist: StylistReport): StylistReportLaraCSVRow => {
  return {
    stylist: stylist.stylistName.trim(),
    cost: parseFloat(stylist.wholesale.toFixed(2)),
    price: parseFloat(stylist.retail.toFixed(2)),
    'mark up': parseFloat(stylist.profit.toFixed(2)),
  }
}

export const mapStylistToCSV = (exports: StylistReport[]): StylistReportLaraCSVRow[] => {
  const csv = map(exports, mapStylistsToCSV)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    stylist: 'Total',
    cost: parseFloat(sumWholesale.toFixed(2)),
    price: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapStylistsLaraToCSV = (stylist: StylistReportLara): StylistReportLaraCSVRow => {
  return {
    stylist: stylist.stylistName.trim(),
    cost: parseFloat(stylist.wholesale.toFixed(2)),
    price: parseFloat(stylist.retail.toFixed(2)),
    'mark up': parseFloat(stylist.profit.toFixed(2)),
  }
}

export const mapStylistLaraToCSV = (exports: StylistReportLara[]): StylistReportLaraCSVRow[] => {
  const csv = map(exports, mapStylistsLaraToCSV)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    stylist: 'Total',
    cost: parseFloat(sumWholesale.toFixed(2)),
    price: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapStylistsDetailsToCSV = (stylist: StylistDetailReportLara): StylistDetailReportLaraCSVRow => {
  return {
    stylist: stylist.stylistName,
    line: stylist.line,
    color: stylist.color,
    type: excelCompatibleStringConvertor(stylist.type),
    quantity: stylist.quantity,
    cost: parseFloat(stylist.wholesale.toFixed(2)),
    price: parseFloat(stylist.retail.toFixed(2)),
    'mark up': parseFloat(stylist.profit.toFixed(2)),
  }
}

export const mapStylistsDetailsToCSVForExtensions = (stylist: StylistDetailReportLara): StylistDetailReportForExtensionsLaraCSVRow => {
  return {
    stylist: stylist.stylistName,
    line: stylist.line,
    shades: stylist.color,
    length: 1,
    quantity: stylist.quantity,
    cost: parseFloat(stylist.wholesale.toFixed(2)),
    price: parseFloat(stylist.retail.toFixed(2)),
    'mark up': parseFloat(stylist.profit.toFixed(2)),
  }
}

export const mapStylistDetailToCSV = (exports: StylistDetailReportLara[], stylistName: string): StylistDetailReportLaraCSVRow[] => {
  const listWithStylistName = exports.map((e) => ({ ...e, stylistName }))
  const csv = map(listWithStylistName, mapStylistsDetailsToCSV)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    stylist: 'Total',
    line: '',
    color: '',
    type: '',
    quantity: null,
    cost: parseFloat(sumWholesale.toFixed(2)),
    price: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapStylistDetailToCSVForExtensions = (exports: StylistDetailReportLara[], stylistName: string): StylistDetailReportForExtensionsLaraCSVRow[] => {
  const listWithStylistName = exports.map((e) => ({ ...e, stylistName }))
  const csv = map(listWithStylistName, mapStylistsDetailsToCSVForExtensions)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    stylist: 'Total',
    line: '',
    shades: '',
    length: null,
    quantity: null,
    cost: parseFloat(sumWholesale.toFixed(2)),
    price: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}


export const mapStylistsDetailsLaraToCSV = (stylist: StylistDetailReportLara): StylistDetailReportLaraCSVRow => {
  return {
    line: stylist.line,
    type: stylist.type,
    color: stylist.color,
    quantity: stylist.quantity,
    cost: parseFloat((stylist.retail - stylist.profit).toFixed(2)),
    price: parseFloat(stylist.retail.toFixed(2)),
    'mark up': parseFloat(stylist.profit.toFixed(2)),
  }
}

export const mapStylistDetailLaraToCSV = (exports: StylistDetailReportLara[]): StylistDetailReportLaraCSVRow[] => {
  const csv = map(exports, mapStylistsDetailsLaraToCSV)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    line: 'Total',
    color: '',
    type: '',
    quantity: null,
    cost: parseFloat(sumWholesale.toFixed(2)),
    price: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapAPIClientToClient = (api: APIClientReportLara): ClientReportLara => {
  return {
    clientName: api.client_name.trim(),
    stylistName: api.stylist_name.trim(),
    stylistId: api.stylist_id,
    sessionId: api.session_id,
    color: api.color,
    clientId: api.client_id,
    profit: api.profit,
    retail: api.retail,
    wholesale: api.wholesale,
    createdAt: api.created_date,
    line: api.line,
    quantity: api.quantity,
    length: api.length,
    type: api.type,
  }
}



export const mapAPIClientsToClients = (apiModels: APIClientReportLara[]): ClientReportLara[] => {
  return map(apiModels, mapAPIClientToClient)
}

export const mapAPIClientDetailToClientDetail = (api: APIClientDetailReportLara): ClientDetailReportLara => {
  return {
    clientName: api.client_name.trim(),
    stylistName: api.stylist_name.trim(),
    sessionId: api.session_id,
    color: api.color,
    quantity: api.quantity,
    type: api.type,
    line: api.line,
    profit: api.profit,
    retail: api.retail,
    wholesale: api.wholesale,
  }
}

export const mapAPIClientsDetailToClientsDetail = (
  apiModels: APIClientDetailReportLara[],
): ClientDetailReportLara[] => {
  return map(apiModels, mapAPIClientDetailToClientDetail)
}

export const mapAPIAddonStylistToAddonStylist = (api: APIStylistReportAddon): StylistReportAddon => {
  return {
    stylist: api.stylist,
    stylistId: api.stylist_id,
    retail: parseFloat(api.retail),
  }
}

export const mapAPIAddonToAddon = (api: APIAddonReportAddon): AddonReportAddon => {
  return {
    title: api.title,
    retail: parseFloat(api.retail),
  }
}

export const mapAPIAddonStylistsToAddonStylists = (apiModels: APIStylistReportAddon[]): StylistReportAddon[] => {
  return map(apiModels, mapAPIAddonStylistToAddonStylist)
}

export const mapAPIAddonStylistDetailToAddonStylistDetail = (
  api: APIStylistDetailReportAddon,
): StylistDetailReportAddon => {
  return {
    title: api.title,
    retail: parseFloat(api.retail),
  }
}

export const mapAPIAddonStylistsDetailToAddonStylistsDetail = (
  apiModels: APIStylistDetailReportAddon[],
): StylistDetailReportAddon[] => {
  return map(apiModels, mapAPIAddonStylistDetailToAddonStylistDetail)
}

export const mapAPIAddonClientToAddonClient = (api: APIClientReportAddon): ClientReportAddon => {
  return {
    clientName: api.client_name.trim(),
    stylistName: api.stylist_name.trim(),
    stylistId: api.stylist_id,
    clientId: api.client_id,
    sessionId: api.session_id,
    quantity: api.quantity,
    title: api.title,
    createdAt: api.created_date,
    retail: parseFloat(api.retail),
  }
}

export const mapAPIAddonClientsToAddonClients = (apiModels: APIClientReportAddon[]): ClientReportAddon[] => {
  return map(apiModels, mapAPIAddonClientToAddonClient)
}

export const mapAPIAddonClientDetailToAddonClientDetail = (
  api: APIClientDetailReportAddon,
): ClientDetailReportAddon => {
  return {
    clientName: api.client_name.trim(),
    stylistName: api.stylist_name.trim(),
    title: api.title,
    quantity: api.quantity,
    retail: api.retail,
  }
}

export const mapAPIAddonClientsDetailToAddonClientsDetail = (
  apiModels: APIClientDetailReportAddon[],
): ClientDetailReportAddon[] => {
  return map(apiModels, mapAPIAddonClientDetailToAddonClientDetail)
}
export const mapClientsToCSV = (reports: ClientReport): ClientReportLaraCSVRow => {
  return {
    stylistName: reports.stylistName,
    clientName: reports.clientName,
    cost: parseFloat(reports.wholesale.toFixed(2)),
    price: parseFloat(reports.retail.toFixed(2)),
    'mark up': parseFloat(reports.profit.toFixed(2)),
  }
}

export const mapStylistClientToCSV = (exports: ClientReport[]): any => {
  const listByStylistId = exports
  const list: any = []
  Object.keys(listByStylistId).forEach((stylistId) => {
    const listOfClientsUnderStylist = listByStylistId[stylistId]
    Object.keys(listOfClientsUnderStylist).forEach((clientId) => {
      const listBySessionId = listOfClientsUnderStylist[clientId]
      Object.keys(listBySessionId).forEach((sessionId) => {
        const sessionItems = listBySessionId[sessionId]
        const sessionDetails = sessionItems?.[0] ?? null
        const total = listBySessionId[sessionId].reduce((a, b) => ({
          profit: a.profit + b.profit,
          wholesale: a.wholesale + b.wholesale,
          retail: a.retail + b.retail
        }), {
          profit: 0, wholesale: 0, retail: 0
        })
        list.push({
          stylistName: sessionDetails.stylistName,
          clientName: sessionDetails.clientName,
          date: buildDateYYYYMMDD(sessionDetails.createdAt),
          cost: parseFloat(total.wholesale.toFixed(2)),
          price: parseFloat(total.retail.toFixed(2)),
          'mark up': parseFloat(total.profit.toFixed(2)),
        })
      })
    })
  })

  const sortedList = sortBy(list, ["stylistName", "date"])

  sortedList.push({
    stylistName: 'Total',
    clientName: null,
    cost: parseFloat(sumBy(list, 'cost').toFixed(2)),
    price: parseFloat(sumBy(list, 'price').toFixed(2)),
    'mark up': parseFloat(sumBy(list, 'mark up').toFixed(2)),
  })
  
  return sortedList
}

export const mapStylistClientToCSVForAddon = (exports: ClientReport[]): any => {
  const listByStylistId = exports
  const list: any = []
  Object.keys(listByStylistId).forEach((stylistId) => {
    const listOfClientsUnderStylist = listByStylistId[stylistId]
    Object.keys(listOfClientsUnderStylist).forEach((clientId) => {
      const listBySessionId = listOfClientsUnderStylist[clientId]
      Object.keys(listBySessionId).forEach((sessionId) => {
        const sessionItems = listBySessionId[sessionId]
        const sessionDetails = sessionItems?.[0] ?? null
        const total = listBySessionId[sessionId].reduce((a, b) => ({ retail: a.retail + b.retail }), { retail: 0 })
        list.push({
          stylistName: sessionDetails.stylistName,
          clientName: sessionDetails.clientName,
          date: buildDateYYYYMMDD(sessionDetails.createdAt),
          price: parseFloat(total.retail.toFixed(2)),
        })
      })
    })
  })

  const sortedList = sortBy(list, ["stylistName", "date"])

  sortedList.push({
    stylistName: 'Total',
    clientName: '',
    date: '',
    price: parseFloat(sumBy(list, 'price').toFixed(2)),
  })
  return sortedList

}

export const mapClientSessionToCSV = (exports: any[]): ClientReportLaraCSVRow[] => {
  const csvData = exports.map((sessionDetails) => {
    return ({
      stylistName: sessionDetails.stylistName,
      clientName: sessionDetails.clientName,
      date: buildDateYYYYMMDD(sessionDetails.createdAt),
      cost: parseFloat(sessionDetails.wholesale.toFixed(2)),
      price: parseFloat(sessionDetails.retail.toFixed(2)),
      'mark up': parseFloat(sessionDetails.profit.toFixed(2)),
    })
  });

  csvData.push({
    stylistName: 'Total',
    clientName: null,
    date: '',
    cost: parseFloat(sumBy(csvData, 'cost').toFixed(2)),
    price: parseFloat(sumBy(csvData, 'price').toFixed(2)),
    'mark up': parseFloat(sumBy(csvData, 'mark up').toFixed(2)),
  })

  return csvData
}

export const mapClientSessionToCSVForAddon = (exports: any[]): any[] => {
  const csvData = exports.map((sessionDetails) => {
    return ({
      stylistName: sessionDetails.stylistName,
      clientName: sessionDetails.clientName,
      date: buildDateYYYYMMDD(sessionDetails.createdAt),
      price: parseFloat(sessionDetails.retail.toFixed(2)),
    })
  });
  csvData.push({
    stylistName: 'Total',
    clientName: '',
    date: '',
    price: parseFloat(sumBy(csvData, 'price').toFixed(2)),
  })
  return csvData
}

export const mapClientSessionDetailToCSV = (exports: ClientReport[]): ClientReportLaraCSVRow[] => {
  const list = exports.map((e) => {
    const { profit, retail, stylistName, clientName, line, quantity, type, color } = e
    const wholesale = (retail - profit) ?? 0
    return ({
      stylistName: stylistName,
      clientName: clientName,
      line: line,
      color: color,
      type: type,
      quantity: quantity,
      cost: parseFloat(wholesale.toFixed(2)),
      price: parseFloat(retail.toFixed(2)),
      'mark up': parseFloat(profit.toFixed(2)),
    })
  })

  list.push({
    stylistName: 'Total',
    clientName: null,
    line: null,
    color: null,
    type: null,
    quantity: null,
    cost: parseFloat(sumBy(list, 'cost').toFixed(2)),
    price: parseFloat(sumBy(list, 'price').toFixed(2)),
    'mark up': parseFloat(sumBy(list, 'mark up').toFixed(2)),
  })
  return list
}

export const mapClientSessionDetailToCSVForExtension = (exports: ClientReport[]): ClientReportLaraCSVRow[] => {
  return exports.map((e) => {
    const { profit, retail, stylistName, clientName, line, quantity, color, length, wholesale } = e
    return ({
      stylistName: stylistName,
      clientName: clientName,
      line: line,
      shades: color,
      length: length,
      quantity: quantity,
      cost: parseFloat(wholesale.toFixed(2)),
      price: parseFloat(retail.toFixed(2)),
      'mark up': parseFloat(profit.toFixed(2)),
    })
  })
}

export const mapClientSessionDetailToCSVForAddon = (exports: any[]): any[] => {
  const list = exports.map((e) => {
    const { retail, stylistName, clientName, title, quantity } = e
    return ({
      stylistName,
      clientName,
      title,
      quantity,
      price: parseFloat(retail.toFixed(2)),
    })
  })
  list.push({
    stylistName: 'Total',
    clientName: '',
    title: '',
    quantity: '',
    price: parseFloat(sumBy(list, 'price').toFixed(2)),
  })
  return list
}

export const mapClientsDetailLaraToCSV = (reports: ClientDetailReportLara): ClientDetailReportLaraCSVRow => {
  return {
    stylistName: reports.stylistName,
    clientName: reports.clientName,
    type: reports.type,
    color: reports.color,
    quantity: reports.quantity,
    line: reports.line,
    cost: parseFloat(reports.wholesale.toFixed(2)),
    price: parseFloat(reports.retail.toFixed(2)),
    'mark up': parseFloat(reports.profit.toFixed(2)),
  }
}

export const mapClientDetailLaraToCSV = (exports: ClientDetailReportLara[]): ClientDetailReportLaraCSVRow[] => {
  const csv = map(exports, mapClientsDetailLaraToCSV)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    stylistName: 'Total',
    clientName: 'Total',
    price: parseFloat(sumWholesale.toFixed(2)),
    cost: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapExtensionsToCSV = (extensions: ProductReport): ExtensionReportLaraCSVRow => {
  return {
    title: extensions.brand.trim(),
    cost: parseFloat(extensions.wholesale.toFixed(2)),
    price: parseFloat(extensions.retail.toFixed(2)),
    'mark up': parseFloat(extensions.profit.toFixed(2)),
  }
}

export const mapExtensionToCSV = (exports: ProductReport[]): ExtensionReportLaraCSVRow[] => {
  const csv = map(exports, mapExtensionsToCSV)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    title: 'Total',
    cost: parseFloat(sumWholesale.toFixed(2)),
    price: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapUsedExtensionsDetailsToCSV = (
  extensions: ExtensionOrProductReportDetailsLara,
): ExtensionDetailUsedProductCSVRow => {
  return {
    line: excelCompatibleStringConvertor(extensions.line),
    color: excelCompatibleStringConvertor(extensions.color),
    type: excelCompatibleStringConvertor(extensions.category),
    quantity: extensions.quantity,
    cost: parseFloat(extensions.wholesale.toFixed(2)),
    price: parseFloat(extensions.retail.toFixed(2)),
    'mark up': parseFloat(extensions.profit.toFixed(2)),
  }
}

export const mapUsedExtensionDetailToCSV = (
  exports: ExtensionOrProductReportDetailsLara[],
): ExtensionDetailUsedProductCSVRow[] => {
  const csv = map(exports, mapUsedExtensionsDetailsToCSV)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    line: 'Total',
    cost: parseFloat(sumWholesale.toFixed(2)),
    price: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapExtensionsDetailsLaraToCSV = (
  extensions: ExtensionOrProductReportDetailsLara,
): ExtensionReportDetailsLaraCSVRow => {
  return {
    line: extensions.line,
    type: extensions.type,
    shades: extensions.color,
    length: extensions.length,
    quantity: extensions.quantity,
    cost: parseFloat(extensions.wholesale.toFixed(2)),
    price: parseFloat(extensions.retail.toFixed(2)),
    'mark up': parseFloat(extensions.profit.toFixed(2)),
  }
}

export const mapExtensionDetailLaraToCSV = (
  exports: ExtensionOrProductReportDetailsLara[],
): ExtensionReportDetailsLaraCSVRow[] => {
  const csv = map(exports, mapExtensionsDetailsLaraToCSV)
  const sumProfit = exports ? exports.map((model) => model.profit).reduce((a, b) => a + b, 0) : 0
  const sumRevenue = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const sumWholesale = sumProfit && sumRevenue ? sumRevenue - sumProfit : 0
  const obj = {
    line: 'Total',
    type: null,
    shades: null,
    length: null,
    quantity: null,
    cost: parseFloat(sumWholesale.toFixed(2)),
    price: parseFloat(sumRevenue.toFixed(2)),
    'mark up': parseFloat(sumProfit.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapUnusedExtensionsDetailsToCSV = (
  extensions: ExtensionOrProductReportDetailsLara,
): ExtensionDetailUnusedProductCSVRow => {
  return {
    line: extensions.line,
    color: extensions.color,
    type: extensions.category,
  }
}

export const mapUnusedExtensionDetailToCSV = (
  exports: ExtensionOrProductReportDetailsLara[],
): ExtensionDetailUnusedProductCSVRow[] => {
  return map(exports, mapUnusedExtensionsDetailsToCSV)
}

export const mapAPIAddonsToAddon = (exports: APIAddonReportAddon[]): AddonReportAddon[] => {
  return map(exports, mapAPIAddonToAddon)
}

// --------ADDONCSVMAPPERS------------
export const mapAddonsToAddonCSV = (addon: AddonReportAddon): AddonReportCSV => {
  return {
    title: addon.title,
    price: parseFloat(addon.retail.toFixed(2)),
  }
}

export const mapAddonsToCSV = (exports: AddonReportAddon[]): AddonReportCSV[] => {
  const csv = map(exports, mapAddonsToAddonCSV)
  const sumRetail = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const obj = {
    title: 'Total',
    price: parseFloat(sumRetail.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapAddonStylistToAddonStylistCSV = (addon: StylistReportAddon): StylistReportCSV => {
  return {
    stylist: addon.stylist,
    price: parseFloat(addon.retail.toFixed(2)),
  }
}

export const mapAddonsStylistToCSV = (exports: StylistReportAddon[]): StylistReportCSV[] => {
  const csv = map(exports, mapAddonStylistToAddonStylistCSV)
  const sumRetail = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const obj = {
    stylist: 'Total',
    price: parseFloat(sumRetail.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapAddonStylistDetailToAddonStylistDetailCSV = (
  addon: StylistDetailReportAddon,
): StylistDetailReportCSV => {
  return {
    stylist: addon.stylistName,
    title: addon.title,
    price: parseFloat(addon.retail.toFixed(2)),
  }
}

export const mapAddonsStylistDetailToCSV = (exports: StylistDetailReportAddon[], stylistName: string): StylistDetailReportCSV[] => {
  const csv = map(exports.map((e) => ({ ...e, stylistName })), mapAddonStylistDetailToAddonStylistDetailCSV)
  const sumRetail = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const obj = {
    stylist: 'Total',
    title: '',
    price: parseFloat(sumRetail.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapAddonClientToAddonClientCSV = (addon: ClientReportAddon): ClientReportCSV => {
  return {
    stylistName: addon.stylistName,
    clientName: addon.clientName,
    price: parseFloat(addon.retail.toFixed(2)),
  }
}

export const mapAddonsClientToCSV = (exports: ClientReportAddon[]): ClientReportCSV[] => {
  const csv = map(exports, mapAddonClientToAddonClientCSV)
  const sumRetail = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const obj = {
    stylistName: 'Total',
    price: parseFloat(sumRetail.toFixed(2)),
  }
  csv.push(obj)
  return csv
}

export const mapAddonClientDetailToAddonClientDetailCSV = (addon: ClientDetailReportAddon): ClientDetailReportCSV => {
  return {
    title: addon.title,
    stylistName: addon.stylistName,
    clientName: addon.clientName,
    price: parseFloat(addon.retail.toFixed(2)),
  }
}

export const mapAddonsClientDetailToCSV = (exports: ClientDetailReportAddon[]): ClientDetailReportCSV[] => {
  const csv = map(exports, mapAddonClientDetailToAddonClientDetailCSV)
  const sumRetail = exports ? exports.map((model) => model.retail).reduce((a, b) => a + b, 0) : 0
  const obj = {
    title: 'Total',
    price: parseFloat(sumRetail.toFixed(2)),
  }
  csv.push(obj)
  return csv
}
export const excelCompatibleStringConvertor = (string: String) => `=""${string}""`
