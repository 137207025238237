import { useEffect } from 'react'
import { selectCurrentSalonContext, selectLoggedInUser, selectUserDeviceInfo } from '../data/user/slice'
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import { useAppSelector } from '../hooks'
import { dispatchGetUserDeviceInfo } from "../data/user/api";
import { useDispatch } from "react-redux";

// ref - docs - https://docs.launchdarkly.com/sdk/client-side/react/react-web
// ref - where flags are set https://app.launchdarkly.com/default/production/features

export const InitializeLDSalon = () => {
  const dispatch = useDispatch()
  const salonContext = useAppSelector(selectCurrentSalonContext)
  const user = useAppSelector(selectLoggedInUser)
  const userDeviceInfo = useAppSelector(selectUserDeviceInfo)
  const salonId = salonContext?.salonId
  const salonName = salonContext?.salonName
  const ldClient = useLDClient()

  useEffect(() => {
    if (user?.token && user?.userId) {
      dispatch(dispatchGetUserDeviceInfo({token: user.token, userId: user.userId}))
    }
  }, [dispatch, user?.token, user?.userId])

  useEffect(() => {
    // there might not be userDeviceInfo but make sure it's returned something other than null so we know we completed the api call
    if (salonId && salonName && userDeviceInfo?.salonscaleVersion !== null) {
      const context = { key: salonId.toString(), name: salonName, custom: {salonId: salonId, 'app version': userDeviceInfo?.salonscaleVersion || ''}}
      ldClient?.identify(context, undefined, () => {})
    }
  }, [dispatch, ldClient, salonName, salonId, userDeviceInfo?.salonscaleVersion])
}

export const InitializeLDProvider = async () => {
  return await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY || '',
  })
}
