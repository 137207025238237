import { keys } from 'lodash'
import { CBSalonType, CBSubscriptionType } from '../../integrations/chargebee/constants'

export const CostMap = (isSpecialPricing: boolean) => {
  return {
    // basic
    'team-monthly': 149,
    'team-yearly': isSpecialPricing ? 1499 : 1499,
    'solo-monthly': 55,
    'solo-yearly': isSpecialPricing ? 555 : 555,
    'setup-addon': 149,

    // special
    'team-perfectpairing': 219,
    'solo-perfectpairing': 219,

    'team-blackfriday': 599,
    'solo-blackfriday': 275,

    // misc
    scale: 60,
  }
}

export const PlanNameMap = (isSpecialPricing: boolean) => ({
  // basic
  'team-monthly': {name: 'team monthly plan', description: ''},
  'team-yearly': {
    name: isSpecialPricing ? 'Team Annual BOGO' : 'team annual bundle',
    description: isSpecialPricing ? 'Renews at $1499 USD in 2025' : 'Per Year, Billed Annually in USD',
  },
  'solo-monthly': {name: 'solo monthly plan', description: ''},
  'solo-yearly': {
    name: isSpecialPricing ? 'Solo Annual Bogo' : 'solo annual bundle',
    description: isSpecialPricing ? 'Renews at $555 USD in 2025' : 'Per Year, Billed Annually in USD',
  },

  // special
  'team-perfectpairing': {name: 'team perfect pairing', description: 'One time payment, Billed in USD'},
  'solo-perfectpairing': {name: 'solo perfect pairing', description: 'One time payment, Billed in USD'},
})

export const getTotalPrice = (
  salonType: CBSalonType,
  subscriptionType: CBSubscriptionType,
  scaleQuantities: { [key: string]: number },
  isSpecialPricing: boolean
): number => {
  // first month is free
  const planPrice = getPlanPrice(salonType, subscriptionType, isSpecialPricing)
  // scale costs
  const scalesPrice = getScalesPrice(scaleQuantities, isSpecialPricing)

  return planPrice + scalesPrice
}

export const getPlanPrice = (salonType: CBSalonType, subscriptionType: CBSubscriptionType, isSpecialPricing: boolean): number => {
  const costMap = CostMap(isSpecialPricing)
  return costMap[`${salonType}-${subscriptionType}`]
}

export const getPlanNameAndDescription = (
  salonType: CBSalonType,
  subscriptionType: CBSubscriptionType,
  isSpecialPricing: boolean
): { name: string; description: string } => {
  const planMap = PlanNameMap(isSpecialPricing)
  return planMap[`${salonType}-${subscriptionType}`]
}

export const getScalePrice = (isSpecialPricing: boolean): number => {
  const costMap = CostMap(isSpecialPricing)
  return costMap['scale']
}

export const getScalesPrice = (scaleMap: { [key: string]: number }, isSpecialPricing: boolean): number => {
  const totalQuantity = keys(scaleMap).reduce((total, key) => {
    return total + scaleMap[key]
  }, 0)
  const costMap = CostMap(isSpecialPricing)
  return totalQuantity * costMap['scale']
}
