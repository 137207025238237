import { Flex, Tab, TabList, Tabs } from '@chakra-ui/react'
import { UseViewSize } from '../../../core/UseViewSize'
import { generatePath, useHistory } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { COLORS } from '../../../mini-lib/theme/colors'
import { ReportActions } from '../ReportActions'
import { ROUTES } from "../../../appRoutes";

export const COLOR_REPORTS_TABS = {
  colorBrand: 0,
  colorStylist: 1,
  colorClient: 2,
}
export const ColorReportsTabs = (props: { selectedTab: number; onCsvExport: () => void }) => {
  const { selectedTab, onCsvExport } = props
  const { isMobile, isTablet } = UseViewSize()
  const history = useHistory()
  const { salonId } = UseBaseApiParams()

  const navigateToTab = (newIndex: number) => {
    if (newIndex === 0) {
      history.push(generatePath(ROUTES.colorBrandReports, { salonId }))
    } else if (newIndex === 1) {
      history.push(generatePath(ROUTES.colorStylistReports, { salonId }))
    } else if (newIndex === 2) {
      history.push(generatePath(ROUTES.colorReportsClient, { salonId }))
    }
  }
  return (
    <Flex  w="100%" direction={(isMobile || isTablet)  ? 'column-reverse' : 'row'}  justify="space-between" borderBottom={`1px solid ${COLORS.shades_neutral_300}`} pb="4px">
      <Tabs index={selectedTab} onChange={(index) => navigateToTab(index)} colorScheme="brand.lavender" border="none">
        <TabList overflowX="auto" padding="4px" border="none">
          <Tab minW={isMobile ? '60px' : '200px'}>{isMobile ? 'Lines' : 'Colors Line Reports'}</Tab>
          <Tab minW={isMobile ? '60px' : '200px'}>{isMobile ? 'Stylists' : 'Stylist Reports'}</Tab>
          <Tab minW={isMobile ? '80px' : '200px'}>{isMobile ? 'Clients' : 'Client Reports'}</Tab>
        </TabList>
      </Tabs>
      <ReportActions onCsvExport={onCsvExport} />
    </Flex>
  )
}
