import { APIUpdateSession, Session } from '../../data/sessions/interfaces'
import { UseViewSize } from '../../core/UseViewSize'
import { Box, Flex, Text } from '@chakra-ui/react'
import { DateInput } from '../../mini-lib/dates-and-times/DateInput'
import { StylistAutocompleteDeprecated } from '../stylists/StylistAutocompleteDeprecated'
import { ClientAutocomplete } from '../clients/ClientAutocomplete'
import React from 'react'
import { SESSION_STATUS_OPTIONS } from '../../data/sessions/constants'

export const SessionMetaDetailsLegacy = ( props: { session: Session; updateSession: ( u: Partial<APIUpdateSession>) => void }) => {
  const { isMobile } = UseViewSize()
  const { session, updateSession } = props
  const date = new Date(session.date)
  return (
    <>
      <Text variant="title3">Session Details</Text>
      <Box h="24px" />
      <Flex align="center" gridGap="12px" flexWrap="wrap">
        <Box w={isMobile ? '45%' : '165px'}>
          <Text>Date</Text>
          <DateInput
            placeholder='Date'
            disabled={session.status === SESSION_STATUS_OPTIONS.complete}
            value={date}
            onChange={(date) => {
              updateSession({ date: date.toISOString(), completed_date: date.toISOString() })
            }}
            isClearable={false}
            onKeyDown={(e) => {
              e.preventDefault();
           }}
          />
        </Box>
        <Box w={isMobile ? '45%' : '165px'}>
          <Text>Stylist</Text>
          {session.stylist && (
            <StylistAutocompleteDeprecated
              disabled={session.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialStylist={session.stylist}
              onSelect={(stylist) => updateSession({ stylist_id: stylist.stylistId, stylist_user_id: stylist.userId })}
            />
          )}
        </Box>
        <Box w={isMobile ? '45%' : '165px'}>
          <Text>Client</Text>
          {session.client && (
            <ClientAutocomplete
              disabled={session.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialClient={session.client}
              onSelect={(client) => updateSession({ client_id: client.id })}
            />
          )}
        </Box>
        {(session.assistant || session.status === SESSION_STATUS_OPTIONS.open) && (
          <Box w={isMobile ? '45%' : '165px'}>
            <Text>Assistant</Text>
            <StylistAutocompleteDeprecated
              disabled={session.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialStylist={session.assistant}
              onSelect={(stylist) => updateSession({ assistant_user_id: stylist.userId })}
            />
          </Box>
        )}
      </Flex>
    </>
  )
}
