import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Center, Flex, Text } from '@chakra-ui/react'
import { useAppSelector } from '../../../hooks'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import {
  reduceRedirectInventoryCountId,
  selectAllItemCategories,
  selectAllItemLines,
  selectAllItemVendors,
  selectFilteredInventoryCountItemsByCategory,
  selectInventoryCountItemsLength,
  selectSelectedInventoryCount,
  selectSelectedInventoryCountItems,
} from '../../../data/inventory/slice'
import {
  dispatchCompleteInventoryCount,
  dispatchDeleteInventoryCount,
  dispatchGetInventoryCount,
  dispatchGetInventoryCountItems,
} from '../../../data/inventory/api'
import { InventorySelectProductsButton } from './InventorySelectProducts'
import { InventoryCountAccordion } from './InventoryCountAccordion'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { SalonScaleProductIcon } from '../../../mini-lib/icons/SalonScaleProductIcon'
import { Loading } from '../../../mini-lib/loading/Loading'
import { selectLoadingState } from '../../../core/loading/slice'
import { INVENTORY_COUNT_TYPES, INVENTORY_LOADING_CONSTANT } from '../../../data/inventory/constants'
import { keys } from 'lodash'
import { InventoryItemFilters } from '../filters/InventoryItemFilters'
import { EmptySearch } from '../../../mini-lib/empty/EmptySearch'
import { buildInventoryAuditCsvRows } from '../../../data/inventory/utils'
import { InventoryCountType, InventoryCsvTitleRow } from '../../../data/inventory/interfaces'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { ConfirmPopover } from '../../../mini-lib/confirm-popover/ConfirmPopover'
import { COLORS } from '../../../mini-lib/theme/colors'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
} from '../../../data/start-guide/slice'
import { HelpStepCompleteDrawer } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { ReleaseLaraColors } from "../../../mini-lib/flags/Release";
import { ROUTES } from "../../../appRoutes";

export const InventoryCountDetailsPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()

  // todo: fix up the params typing on this
  const params: any = useParams()
  const { countId } = params

  const count = useAppSelector(selectSelectedInventoryCount)
  const loading = useAppSelector((state) => selectLoadingState(state, INVENTORY_LOADING_CONSTANT))
  const numItems = useAppSelector(selectInventoryCountItemsLength)
  const countItemsByCategory = useAppSelector(selectFilteredInventoryCountItemsByCategory)
  const countItems = useAppSelector(selectSelectedInventoryCountItems)
  const countItemsByCategoryLoading = !countItemsByCategory
  const allItemCategories = useAppSelector(selectAllItemCategories)
  const allItemLines = useAppSelector(selectAllItemLines)
  const allItemVendors = useAppSelector(selectAllItemVendors)
  const releaseLaraColors = ReleaseLaraColors()

  // initialize substep index to 0
  useEffect(() => {
    dispatch(reduceSetCurrentSubStepIndex(0))
  }, [dispatch])

  const [sideSheetIsOpen, setSideSheetIsOpen] = useState(false)

  useEffect(() => {
    if (countId) {
      dispatch(dispatchGetInventoryCount({ token, salonId, countId }))
      dispatch(reduceRedirectInventoryCountId(null))
    }
  }, [dispatch, token, salonId, countId])

  useEffect(() => {
    if (countId) {
      dispatch(dispatchGetInventoryCountItems({ token, salonId, countId }))
    }
  }, [dispatch, token, salonId, countId])

  const breadcrumbs =
    count && count.countType === 'inventory'
      ? [
          { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
          { label: 'Inventory', url: generatePath(ROUTES.inventoryAudits, { salonId }) },
          { label: 'Inventory Count' },
        ]
      : [
          { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
          { label: 'Add Stock', url: generatePath(ROUTES.inventoryAddStock, { salonId }) },
          { label: count?.countType === 'manual' ? 'Manual' : 'Order' },
        ]

  const exportCsv = () => {
    if (countItems && count) {
      const rows = buildInventoryAuditCsvRows(countItems, count.assignedUser.fullName)
      buildCsv(count.title + '_inventory_count', InventoryCsvTitleRow, rows)
    }
  }
  const queryParams: any = UseQueryParams()
  const guideType = queryParams.get('guide')
  return (
    <>
      <HelpStepCompleteDrawer
        guideType={CHECKLIST_CODES.inventoryCompleteCount}
        title="Way to go you've started your first count!"
        subtitle="Feel free to count more products while we are here. Click complete to add the products you counted to your inventory or click below to go to the next step!"
        actions={
          <Link to={generatePath(ROUTES.startGuide, { salonId })}>
            <Button
              variant="round"
              colorScheme="brand.midnight"
              onClick={() => {
                dispatch(
                  dispatchUpdateUserChecklistItem({
                    token: token,
                    checklistItemCode: CHECKLIST_CODES.inventoryCompleteCount,
                  }),
                )
                dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.inventoryStockAlerts))
                dispatch(reduceSetStepCompleteDrawerState('hidden'))
                dispatch(reduceSetCurrentSubStepIndex(0))
              }}
            >
              Go to Next Step
            </Button>
          </Link>
        }
      />
      <PageLayout
        variant="full"
        header={
          <>
            {count && (
              <PageHeader
                title={loading ? '' : count.title}
                breadcrumbs={breadcrumbs}
                actions={
                  <>
                    <Flex gridGap="12px">
                      {!count.completedAt && (
                        <ConfirmPopover
                          title={`This Action is Permanent`}
                          subtitle={`This count will be deleted and the quantities will not be subtracted from your stock!`}
                          onConfirm={() => {
                            dispatch(dispatchDeleteInventoryCount({ token, salonId, model: count }))
                            const afterDelete =
                              count.countType === 'inventory' ? ROUTES.inventoryAudits : ROUTES.inventoryAddStock
                            history.push(generatePath(afterDelete, { salonId }))
                          }}
                        >
                          <Button variant="round-ghost-upper" color="danger" cursor="pointer">
                            Delete
                          </Button>
                        </ConfirmPopover>
                      )}
                      {!loading && !count.completedAt && (count.countType === 'manual' || count.countType === 'order') && (
                        <ConfirmPopover
                          title="Finish Adding to Inventory"
                          subtitle="Once you click this you won't be able to change the quantities in this count!"
                          confirmText="Add To Inventory"
                          onConfirm={() => {
                            dispatch(dispatchCompleteInventoryCount({ token, salonId, model: count, releaseLaraColors }))
                            history.push(generatePath(ROUTES.inventoryAddStock, { salonId }))
                          }}
                        >
                          <Button variant="round" colorScheme="brand.midnight">
                            Add To Inventory
                          </Button>
                        </ConfirmPopover>
                      )}
                      {!!count.completedAt && (
                        <>
                          <Center h="40px">
                            <Text variant="button" color={COLORS.shades_neutral_400}>
                              Delete
                            </Text>
                          </Center>

                          <Center h="40px">
                            <Text variant="button" color={COLORS.shades_neutral_400}>
                              Count Completed
                            </Text>
                          </Center>
                        </>
                      )}
                      {!!count.completedAt && count.countType === 'manual' && (
                        <Center h="40px">
                          <Text variant="button" color={COLORS.shades_neutral_400}>
                            Add Products
                          </Text>
                        </Center>
                      )}
                      {!loading && count.countType === 'manual' && !count.completedAt && (
                        <InventorySelectProductsButton
                          setSideSheetIsOpen={setSideSheetIsOpen}
                          sideSheetIsOpen={sideSheetIsOpen}
                          countId={countId}
                        />
                      )}
                      {!loading && (
                        <>
                          {!count.completedAt && (
                            <HelpPopover
                              guideTypes={[CHECKLIST_CODES.inventoryCompleteCount]}
                              children={
                                <Box>
                                  <ConfirmPopover
                                    title="Finish Inventory Count?"
                                    subtitle="Once you click this you won't be able to change the quantities in this count!"
                                    confirmText="Add To Inventory"
                                    onConfirm={() => {
                                      if (guideType && guideType === CHECKLIST_CODES.inventoryCompleteCount) {
                                        dispatch(
                                          dispatchUpdateUserChecklistItem({
                                            token: token,
                                            checklistItemCode: CHECKLIST_CODES.inventoryCompleteCount,
                                          }),
                                        )
                                        dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.inventoryStockAlerts))
                                        dispatch(reduceSetStepCompleteDrawerState('hidden'))
                                        dispatch(reduceSetCurrentSubStepIndex(0))
                                        history.push(generatePath(ROUTES.startGuide, { salonId }))
                                      } else {
                                        dispatch(dispatchCompleteInventoryCount({ token, salonId, model: count, releaseLaraColors }))
                                        history.push(generatePath(ROUTES.inventoryAudits, { salonId }))
                                      }
                                    }}
                                  >
                                    {/* note: this box needs to be here or this will fail, the confirm popover needs an anchor */}
                                    <Box>
                                      <Button variant="round" colorScheme="brand.midnight">
                                        Complete
                                      </Button>
                                    </Box>
                                  </ConfirmPopover>
                                </Box>
                              }
                              description="Click the complete button to finish your count and add your product quantities to your inventory!"
                              stepIndex={2}
                              placement="left"
                            />
                          )}
                          {countItems && countItems.length > 0 && (
                            <Button variant="round" colorScheme="brand.midnight" onClick={exportCsv}>
                              Export csv
                            </Button>
                          )}
                        </>
                      )}
                    </Flex>
                  </>
                }
              />
            )}
          </>
        }
      >
        {!loading && count && (
          <>
            <Box h="24px" />
            <InventoryItemFilters
              showVendorFilter={true}
              showLineFilter={true}
              showCategoryFilter={true}
              showCountedTypeFilter={count.countType === 'inventory'}
              showAuditReasonTypeFilter={count.countType === 'inventory'}
              showAddOrOrderReasonTypeFilter={count.countType === 'order'}
              allCategories={allItemCategories}
              allLines={allItemLines}
              allVendors={allItemVendors}
            />
          </>
        )}

        <Box h="24px" />
        {(loading || countItemsByCategoryLoading) && <Loading />}
        {!loading && count && countItemsByCategory && keys(countItemsByCategory).length > 0 && (
          <>
            <InventoryCountAccordion count={count} itemsByCategory={countItemsByCategory} />
          </>
        )}
        <Box h="24px" />
        {!loading && !!numItems && numItems > 0 && keys(countItemsByCategory).length === 0 && (
          <EmptySearch variant="clear">Nothing matched your filters</EmptySearch>
        )}
        {!loading && numItems === 0 && (
          <EmptyBox
            h="320px"
            icon={<SalonScaleProductIcon name="demi" size="64px" />}
            title={getEmptyTitle(count?.countType || null)}
            content={getEmptyContent(count?.countType || null)}
            children={
              <>
                {count?.countType === INVENTORY_COUNT_TYPES.manual && (
                  <Button
                    mt="24px"
                    variant="round"
                    colorScheme="brand.midnight"
                    onClick={() => setSideSheetIsOpen(true)}
                  >
                    Add Products
                  </Button>
                )}
              </>
            }
          />
        )}
      </PageLayout>
    </>
  )
}

const getEmptyTitle = (countType: InventoryCountType | null): string => {
  if (countType && countType === INVENTORY_COUNT_TYPES.order) {
    return 'No Products in Order'
  }
  if (countType && countType === INVENTORY_COUNT_TYPES.inventory) {
    return 'No Products in Inventory'
  }
  if (countType && countType === INVENTORY_COUNT_TYPES.manual) {
    return 'No Products Added Yet'
  }
  return 'No Products Found'
}

const getEmptyContent = (countType: InventoryCountType | null): string => {
  if (countType && countType === INVENTORY_COUNT_TYPES.order) {
    return 'Please add products to an order and create another order count'
  }
  if (countType && countType === INVENTORY_COUNT_TYPES.inventory) {
    return 'Please add some products and create another inventory count'
  }
  if (countType && countType === INVENTORY_COUNT_TYPES.manual) {
    return 'Click “Add Stock” button to get started.'
  }
  return ''
}
