import { ROUNDED_ALL_STYLES } from '../../styles'
import { ColorForMode } from '../../theme'
import { Flex, Spinner } from '@chakra-ui/react'
import React from 'react'

export const Loading = (props: { variant?: 'card' | 'clear'; size?: string; colorhex?: string } | any) => {
  const { variant = 'card', size = '24px', colorhex } = props
  return (
    <Flex
      {...props}
      style={ROUNDED_ALL_STYLES}
      bg={variant === 'card' ? ColorForMode('card-bg') : ''}
      minH={size}
      align="center"
      justify="center"
    >
      <Spinner h={size} w={size} color={colorhex} />
    </Flex>
  )
}
