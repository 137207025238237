import { Box, Button, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import React from 'react'
import { SalonMember } from '../../data/user/interfaces'
import { Icon } from '@chakra-ui/icons'
import { MOBILE_IDENTIFIER_COLUMN_MAX_WIDTH } from '../../data/constants'
import { UseViewSize } from '../../core/UseViewSize'
import { reduceSetIsSalonMemberSheetOpen, reduceSetSelectedStylist } from '../../data/user/slice'
import { useDispatch } from 'react-redux'
import { ColorForMode } from '../../theme'
import { generatePath, Link } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { ROUTES } from "../../appRoutes";
import { PhotoCircle } from "../../mini-lib";

interface Props {
  stylists: SalonMember[]
}

export const StylistList = (props: Props) => {
  const { stylists } = props
  return (
    <>
      {stylists.map((stylist, index) => {
        return (
          <Box key={stylist.userId}>
            <StylistCard index={index} stylist={stylist} key={stylist.userId} />
            <Divider />
          </Box>
        )
      })}
    </>
  )
}

export const StylistCard = ({ index, stylist }: { index: number; stylist: SalonMember }) => {
  const { isMobile, isTablet } = UseViewSize()
  const secondaryBg = ColorForMode('secondary-bg')
  return (
    <Box className="cy-stylist-row" p="12px" maxW="100%" bg={(index + 1) % 2 === 0 ? secondaryBg : ''}>
      <Flex align="center" justify="space-between">
        <Flex align="center" gridGap="24px">
          <PhotoCircle photoUrl={stylist.photoUrl} backupText={`${stylist.firstName[0]}${stylist.lastName[0]}`} />
          {isMobile || isTablet ? (
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: MOBILE_IDENTIFIER_COLUMN_MAX_WIDTH,
                width: '100%',
                whiteSpace: 'nowrap',
              }}
            >
              <Text fontWeight="bold">
                {stylist.firstName} {stylist.lastName}
              </Text>
              <div>{stylist.email}</div>
            </div>
          ) : (
            <>
              <Box w="220px" isTruncated={true}>
                <Text fontWeight="bold">
                  {stylist.firstName} {stylist.lastName}
                </Text>
              </Box>
              <Box w="220px" isTruncated={true}>
                {stylist.email}
              </Box>
            </>
          )}
        </Flex>
        <StylistActions stylist={stylist} />
      </Flex>
    </Box>
  )
}

const StylistActions = (props: { stylist: SalonMember }) => {
  const dispatch = useDispatch()
  const { stylist } = props
  const { salonId } = UseBaseApiParams()
  const { isMobile, isTablet } = UseViewSize()
  return (
    <>
      {isMobile || isTablet ? (
        <Menu>
          <MenuButton>
            <Icon as={() => <i className="material-icons-round">more_vert</i>} />
          </MenuButton>
          <MenuList>
            <MenuItem>
              <Link to={generatePath(ROUTES.sessions, { salonId })}>View Sessions</Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(reduceSetIsSalonMemberSheetOpen(true))
                dispatch(reduceSetSelectedStylist(stylist))
              }}
            >
              Edit
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Flex align="center" gridGap="12px">
          <Link to={generatePath(ROUTES.sessions, { salonId }) + `?userId=${stylist.userId}`}>
            <Button colorScheme="brand.lavender" variant="round-ghost-lower">
              View Sessions
            </Button>
          </Link>
          <Button
            colorScheme="brand.lavender"
            variant="round-ghost-lower"
            onClick={() => {
              dispatch(reduceSetSelectedStylist(stylist))
              dispatch(reduceSetIsSalonMemberSheetOpen(true))
            }}
          >
            Edit
          </Button>
        </Flex>
      )}
    </>
  )
}
