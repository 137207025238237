import React from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, useLocation } from 'react-router-dom'
import { setNavVisible } from './slice'
import { Box, Circle, Flex, Link } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { ReleaseMultiLocation } from '../flags/Release'
import { COLORS } from '../theme/colors'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { DisableAutofocus } from '../disable-autofocus/DisableAutofocus'
import { Sidenav, SidenavTile } from './Sidenav'
import { SALONSCALE_LOGO_URL } from '../../data/constants'
import { ngBaseUrl } from '../../core/dealingWithAngular'

import { ROUTES } from "../../appRoutes";

export const AdminSidenav = (props: { children: any }) => {
  const { children } = props
  const releaseMultiLocation = ReleaseMultiLocation()
  return (
    <Sidenav
      header={<SidenavHeader />}
      showLogout={!releaseMultiLocation}
      showStopImpersonating={false}
      content={<AdminSidenavLinks />}
    >
      {children}
    </Sidenav>
  )
}

export const AdminSidenavLinks = () => {
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const location = useLocation()
  const isSelected = (route: string): boolean => location.pathname.includes(route)
  const mobileDismissNav = () => {
    if (isMobile || isTablet) {
      dispatch(setNavVisible(false))
    }
  }
  return (
    <Box overflowX="scroll">
        <>
          <Link onClick={mobileDismissNav}  href={`${ngBaseUrl()}/admin`}>
            <SidenavTile iconName="home" label="Home" isSelected={isSelected('home')} />
          </Link>
          <Link onClick={mobileDismissNav} href={generatePath(ROUTES.adminNotification)}>
            <SidenavTile iconName="create" label="Create" isSelected={isSelected('create')} />
          </Link>
          <Link onClick={mobileDismissNav} href={generatePath(ROUTES.adminValidateUpc)}>
            <SidenavTile iconName="create" label="validate UPC" isSelected={isSelected('validate-upc')} />
          </Link>

        </>
      <DisableAutofocus />
    </Box>
  )
}

export const SidenavHeader = () => {
  const { user } = UseBaseApiParams()
  return (
    <>
        <>
          <Flex justify="center" align="center" m="24px 0">
            <Flex justify="center" align="center" w="48px" h="48px" p="4px" borderRadius="12px" bg="brand.lavender.500">
              <img alt="logo" style={{ height: '100%', width: '100%', padding: '4px' }} src={SALONSCALE_LOGO_URL} />
            </Flex>
          </Flex>

          <Flex justify="center" align="center" m="24px 0 12px 0">
            <Circle size="89px" bg={COLORS.lavender_100} border="2px solid" borderColor="brand.lavender.500">
              {user && user.photoUrl ? (
                <img
                  alt="stylist logo"
                  src={user.photoUrl}
                  style={{ height: '100%', width: '100%', borderRadius: '50%', margin: '2px' }}
                />
              ) : (
                <i style={{ height: '100%', width: '100%', fontSize: '85px' }} className="material-icons-round">
                  account_circle
                </i>
              )}
            </Circle>
          </Flex>
          <Flex m="0 0 24px 0" direction="column" align="center" justify="center">
            <div style={{ fontSize: '20px' }}>
              {user?.firstName} {user?.lastName}
            </div>
            <Box style={{ fontSize: '16px' }} color={COLORS.text_secondary}>
              Super Admin
            </Box>
          </Flex>
        </>
    </>
  )
}
