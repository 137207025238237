import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from "react";
import { getLocalStorageItem } from "../../core/localStorage";

export const InitializeSentry = () => {
  const localStorageEnv = getLocalStorageItem('env')
  const isProdEnv = !localStorageEnv || localStorageEnv === 'prod'
  if (process.env.REACT_APP_ENVIRONMENT !== 'local' && isProdEnv) {
    // ref: https://docs.sentry.io/platforms/javascript/guides/react/configuration/
    Sentry.init({
      dsn: 'https://fd329b56fc8a4a248940de696b5cf81f@o476029.ingest.sentry.io/5514859',
      integrations: [new BrowserTracing()],
      environment: process.env.REACT_APP_ENVIRONMENT,
      autoSessionTracking: true,
      normalizeDepth: 10,
      tracesSampleRate: 1.0, // 0.1 = send 10% of sentry transactions, 1 = send 100% of sentry transactions
      sampleRate: 1.0, // 0.1 = send 10% of errors, 1 = send 100% of errors
      beforeSend( event ) {
        return removeErrorsForHttpStatuses(event, [402, 403])
      },
    })
  }
}

// filter out errors for expected 4xx level statuses
export const removeErrorsForHttpStatuses = ( event: Sentry.Event, statuses: number[] ): Sentry.Event | null => {
  if (event.breadcrumbs) {
    for (const crumb of event.breadcrumbs) {
      if (
        crumb.type === 'http' &&
        crumb.data &&
        statuses.includes(Number(crumb.data.status_code))
      ) {
        // returning null prevents the event from being sent to sentry
        return null
      }
    }
  }
  return event
}

// hook to throw sentry exceptions so we can develop filters for expected exceptions
export const UseThrowSentryException = () => {

  useEffect(() => {
    fetch("https://httpstat.us/402")
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();  // if you expect a JSON response
      })
      .catch(( error ) => {
        Sentry.captureException(error);
      });
  }, [])
}
