import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Divider, Flex, Spinner, Text, Button, Image, Link as ChakraLink } from '@chakra-ui/react'
import { useAppSelector } from '../../../hooks'

import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath, Link } from 'react-router-dom'
import { selectChecklistItemsByCode } from '../../../data/start-guide/slice'
import { dispatchListUserChecklistItems, dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import { COLORS } from '../../../mini-lib/theme/colors'
import { SalonScaleIcon } from '../../../mini-lib/icons/SalonScaleIcon'
import { TaskBar } from '../HelpStepperV2'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { PricingSteps } from './SGPricingIndex'
import { TeamAndClientsSteps } from './SGTeamAndClientsIndex'
import { Gap } from '../../../mini-lib/gap/Gap'
import { UseQueryParams } from '../../../mini-lib/utils/basic'
import { UseViewSize } from '../../../core/UseViewSize'
import { InventorySteps } from './SGInventoryIndex'
import { ExtrasSteps } from './SGExtrasIndex'
import { ImplementationSteps } from "./SGImplementationIndex";
import implementationGuideCover from '../../../assets/start-guide/implementation-guide-cover.png'
import { SalonTypes } from '../../../data/salon/interfaces'
import { ROUTES } from "../../../appRoutes";
import {
  SALON_IMPLEMENTATION_GUIDE_URL,
  SOLO_IMPLEMENTATION_GUIDE_URL
} from '../../../data/start-guide/constants'

export const SGSteps = {
  pricing: 'pricing',
  team: 'team',
  implementation: 'implementation',
  extras: 'extras',
  inventory: 'inventory',
}
export const SGIndexPage = () => {
  const { user, salonId } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Start Guide' }]
  const dispatch = useDispatch()
  const checklistMap = useAppSelector(selectChecklistItemsByCode)
  const modelsLoaded = !!checklistMap

  useEffect(() => {
    dispatch(dispatchListUserChecklistItems(user.token, user.userId))
  }, [dispatch, user.token, user.userId])

  const query = UseQueryParams()
  const currentStep: any = query.get('step')

  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader title="" breadcrumbs={breadcrumbs}/>}
        content={
          <>
            {modelsLoaded && (
              <>
                <SGCongratulationsBanner/>
                <PricingSteps isDefaultOpen={currentStep === SGSteps.pricing}/>
                <Gap size="24px"/>
                <TeamAndClientsSteps isDefaultOpen={currentStep === SGSteps.team}/>
                <Gap size="24px"/>
                <ImplementationSteps isDefaultOpen={currentStep === SGSteps.implementation}/>
                <Gap size="24px"/>
                <Text color="text.secondary" variant="footnote">When you're ready:</Text>
                <Gap size="24px"/>
                <ExtrasSteps isDefaultOpen={currentStep === SGSteps.extras}/>
                <Gap size="24px"/>
                <InventorySteps isDefaultOpen={currentStep === SGSteps.inventory}/>
              </>
            )}
            {!modelsLoaded && (
              <Flex h="500px" align="center" justify="center">
                <Spinner/>
              </Flex>
            )}
          </>
        }
      />
    </>
  )
}

export const StepStatus = ( props: { isDisabled?: boolean; complete: boolean } ) => {
  const { complete, isDisabled } = props
  const { isMobile } = UseViewSize()
  if (isDisabled) {
    return (
      <Flex align="center" gridGap={isMobile ? '4px' : '12px'} color={COLORS.text_secondary}>
        <MaterialIcon name="lock"/>
        <Text>Requires Upgrade</Text>
      </Flex>
    )
  }
  if (complete) {
    return (
      <Flex align="center" gridGap={isMobile ? '4px' : '12px'} color={COLORS.lavender_500}>
        <MaterialIcon name="check_circle"/>
        <Text>Complete</Text>
      </Flex>
    )
  }
  return <Text color={COLORS.text_secondary}>Incomplete</Text>
}

export const StepHeader = ( props: {
  iconName: string
  label: string
  setExpanded: any
  expanded: boolean
  stepsComplete: number
  totalSteps: number
} ) => {
  const { iconName, label, setExpanded, expanded, stepsComplete, totalSteps } = props
  const { isMobile } = UseViewSize()
  return (
    <Flex
      justify="space-between"
      align="center"
      onClick={() => setExpanded(!expanded)}
      cursor="pointer"
      p={isMobile ? '12px' : '24px'}
      wrap="wrap"
    >
      <Flex align="center" gridGap="12px" justify="space-between" w={isMobile ? '100%' : ''}>
        <Flex align="center" gridGap="8px">
          <SalonScaleIcon name={iconName}/> <Text>{label}</Text>
        </Flex>
        {isMobile && <StepDropdown expanded={expanded}/>}
      </Flex>
      <Flex align="center" gridGap="12px">
        <TaskBar showLabel={false} stepsComplete={stepsComplete} totalSteps={totalSteps}/>
        {!isMobile && <StepDropdown expanded={expanded}/>}
      </Flex>
    </Flex>
  )
}
export const StepDropdown = ( props: { expanded: boolean } ) => {
  const { expanded } = props
  if (expanded) {
    return <MaterialIcon cursor="pointer" name="expand_less" size="24px"/>
  }
  return <MaterialIcon cursor="pointer" name="expand_more" size="24px"/>
}


export const StepContent = (props: {
  steps: { code?: string; label: string; complete: boolean; link: string; isDisabled?: boolean; allowSkip?: boolean }[]
}) => {
  const { steps } = props
  const { isMobile } = UseViewSize()
  return (
    <Box p={isMobile ? '0 12px 12px 12px' : '0 24px 24px 24px'}>
      <Box border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius="15px">
        {steps.map((step, index) => {
          return <Box  key={index}>
            <Step step={step} />
            {index < steps.length - 1 && <Divider />}
          </Box>
        })}
      </Box>
    </Box>
  )
}

export const Step = (props: { step: any }) => {
  const { step } = props
  const { user } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()

  // note: the step.code is only required for a very specific case so i'm just handling undefined
  // instead of forcing it to be added to all of the step arrays
  const completeSkippedStep = (evt: any) => {
    evt.preventDefault()
    const stepCode = step.code || null
    if (stepCode) {
      dispatch(dispatchUpdateUserChecklistItem({ token: user.token, checklistItemCode: stepCode }))
    }
  }
  const variant = step.variant || 'default'
  return (
    <>
      {variant === 'default' && (
        <Box color={step.isDisabled ? COLORS.text_secondary : ''}>
          <Link to={step.isDisabled ? '#' : step.link}>
            <Flex align="center" justify="space-between" p={isMobile ? '12px' : '24px'}>
              <Text>{step.label}</Text>
              <StepStatus
                isDisabled={step.isDisabled || false}
                complete={step.complete}
              />
            </Flex>
          </Link>
        </Box>
      )}

      {variant === 'skippable' && (
        <Box color={step.isDisabled ? COLORS.text_secondary : ''}>
          <Link to={step.isDisabled ? '#' : step.link}>
            <Flex align="center" justify="space-between" p={isMobile ? '12px' : '24px'}>
              <Text>{step.label}</Text>
              <Flex align="center" justify="flex-end" gridGap='8px'>
                {!step.complete && (
                  <Button h='24px' variant='round-ghost' onClick={completeSkippedStep}>
                    {isMobile ? 'skip' : 'skip step'}
                  </Button>
                )}
                <Text color={step.complete ? COLORS.lavender_500 : COLORS.text_secondary}>{step.complete ? 'Complete' : 'Incomplete'}</Text>
              </Flex>
            </Flex>
          </Link>
        </Box>
      )}
    </>
  )

}

export const SGCongratulationsBanner = () => {
  const { user } = UseBaseApiParams()
  const query = UseQueryParams()
  const completedStep: any = query.get('completed')
  if (completedStep === SGSteps.pricing) {
    return (
      <Flex direction='column' align='center' justify='center'>
        <Text fontSize='48px'>🎉</Text>
        <Gap/>
        <Text variant='title1' fontWeight='bold'>Your Essential tasks are completed!</Text>
        <Gap/>
        <Text>Welcome to SalonScale! We’re excited to help you get set-up and ensure every service you do is a
          profitable one!</Text>
        <Gap/>
      </Flex>
    )
  }
  return (
    <Flex direction='column' align='center' justify='center'>
      <Text fontSize='48px'>👋</Text>
      <Gap/>
      <Text variant='title1' fontWeight='bold'>WELCOME TO SALONSCALE!</Text>
      <Gap/>
      <Text>We’re excited to help you get set-up and ensure every service you do is a profitable one!</Text>
      <Gap/>
      <LearnMoreLinks />
      <Gap/>
      {
        user?.currentSalonContext?.salonType === SalonTypes.salon && <>
          <ImplementationGuideCard />
          <Gap />
        </>
      }
    </Flex> )
}

export const LearnMoreLinks = () => {
  return (
    <>
      <Flex wrap="wrap" gridGap="12px" justify="center">
        <a
          href="https://salonscale.com/resources/"
          target="_blank"
          rel="noreferrer"
        >
          <Flex cursor="pointer" color="brand.lavender.500" gridGap="6px">
            <MaterialIcon name="library_books" colorhex={COLORS.peach_200}/>
            Resources
          </Flex>
        </a>
        <a href="https://helpcenter.salonscale.com/" target="_blank" rel="noreferrer">
          <Flex cursor="pointer" color="brand.lavender.500" gridGap="6px">
            <MaterialIcon name="help" colorhex={COLORS.peach_200}/>
            Help Center
          </Flex>
        </a>
      </Flex>
    </>
  )
}

export const ImplementationGuideCard = () => {
  const { isMobile } = UseViewSize()
  const {user} = UseBaseApiParams()
  const salonType = user?.currentSalonContext?.salonType || 'salon'
  const profitableGuideUrl = salonType === 'salon'
    ? SALON_IMPLEMENTATION_GUIDE_URL
    : SOLO_IMPLEMENTATION_GUIDE_URL
  return (
    <Flex borderRadius='16px' border={`1px solid ${COLORS.shades_neutral_300}`} p='24px' wrap={isMobile ? 'wrap' : 'nowrap'} justify='center'>
      <Image
        src={implementationGuideCover}
        objectFit='cover'
        height={isMobile ? '200px' : '170px'}
        width={isMobile ? '300px' : '300px'}
      />
      <Gap s={isMobile ? '24px' : '72px'}/>
      <Box>
        <Text variant='title3'>WATCH OUR IMPLEMENTATION GUIDE</Text>
        <Gap/>
        <Text
          color={COLORS.text_secondary}
        >We teamed up with Salon Growth Strategist, Caylee Auge to help you navigate pricing with SalonScale, talking to your team, and talking to your clients.</Text>
        <Gap/>
        <ChakraLink href='https://helpcenter.salonscale.com/en/articles/8959658-caylee-auge-s-implementation-guide' target='_blank' rel='noreferrer'>
          <Button variant='round' colorScheme='brand.lavender' w={isMobile ? '100%' : '200px'}  margin='4px'>
            Watch Now
          </Button>
        </ChakraLink>
        <ChakraLink href={profitableGuideUrl} target='_blank' rel='noreferrer'>
          <Button variant='round-outline' colorScheme='brand.lavender' w={isMobile ? '100%' : '200px'}   margin='4px'>
            Read Now
          </Button>
        </ChakraLink>
      </Box>
    </Flex>
  )
}
