import { APILaraSalon, APISalon, Salon } from './interfaces'
import { buildDateFromServer } from "../../core/dates";

// todo: deprecated soon
export const mapAPISalonToSalon = ( api: APISalon ): Salon => {
  return {
    id: api.id,
    name: api.name,
    phone: api.phone,
    city: api.city,
    country: api.country,
    province: api.province,
    postalCode: api.postal_code,
    addressLine1: api.address_line_1,
    notes: api.notes,
    sessionCount: api.session_count,
    customerId: api.customer_id,
    defaultMarkupPercentage: api.default_markup_percentage,
    defaultExtensionMarkupPercentage: api.default_extension_markup_percentage,
    instagramHandle: api.instagram_handle,
    bookingSoftware: api.booking_software,
    timezone: api.timezone,
    photoUrl: '',
    // stub this out, this will be deprecated soon
    role: {
      id: -1,
      role: '',
      permissions: [],
    },
    clientsCount: 0,
    productsCount: 0,
    inventoryCompletedCount: 0,
    targetSetCount: 0,
    onHandSetCount: 0,
    extensionsCount: 0,
    colorsCount: 0,
    colorsPricedCount: 0,
    extensionsPricedCount: 0,
    addonsCount: 0,
    teamCount: 0,
    trialStartDate: null,
    trialExpiryDate: null,
    trialPlanId: null,
    trialSalonDescription: null,
  }
}

export const mapAPILaraSalonToSalon = ( api: APILaraSalon ): Salon => {
  return {
    id: api.id,
    name: api.name,
    phone: api.phone,
    city: api.city,
    country: api.country,
    province: api.province,
    postalCode: api.postal_code,
    addressLine1: api.address,
    notes: api.notes,
    defaultMarkupPercentage: api.default_markup_percentage,
    defaultExtensionMarkupPercentage: api.default_extension_markup_percentage,
    timezone: api.timezone,
    photoUrl: api.photo_url,
    role: api.role && {
      id: api.role.id,
      role: api.role.role,
      permissions: api.role.permissions,
    },
    clientsCount: api.clients_count || 0,
    productsCount: api.products_count || 0,
    inventoryCompletedCount: api.inventory_completed_count || 0,
    targetSetCount: api.target_set_count || 0,
    onHandSetCount: api.on_hand_set_count || 0,
    extensionsCount: api.extension_count || 0,
    colorsCount: api.color_count || 0,
    colorsPricedCount: api.color_priced_count || 0,
    extensionsPricedCount: api.extension_priced_count || 0,
    addonsCount: api.supplies_count || 0,
    teamCount: api.stylists_count || 0,
    trialStartDate: api.trial_start_date ? buildDateFromServer(api.trial_start_date): null,
    trialExpiryDate: api.trial_expiry_date ? buildDateFromServer(api.trial_expiry_date): null,
    trialPlanId: api.trial_plan_id || null,
    trialSalonDescription: api.trial_salon_description || null,
  }
}

export const mapAPILaraSalonsToSalons = ( apis: APILaraSalon[] ): Salon[] => {
  return apis.map(( api ) => mapAPILaraSalonToSalon(api))
}
