import React, { ReactNode } from 'react'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Text, Tooltip } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { setNavVisible } from '../sidenav/slice'
import { MaterialIcon } from '../icons/MaterialIcon'
import { ReleaseMultiLocation, ReleasePageHelp } from '../flags/Release'
import { reduceHelpDrawerState, selectHelpDrawerState } from '../../data/start-guide/slice'
import { COLORS } from '../theme/colors'
import { useAppSelector } from '../../hooks'
import { UseIsInTrial } from "../../pages/trial-v1/utils";

interface HeaderProps {
  variant?: 'clear'
  title: string | ReactNode
  breadcrumbs: { label: string; url?: string }[]
  showHelp?: boolean
  actions?: ReactNode
}

/**
 * Primary UI component for user interaction
 */
export const PageHeader = (p: HeaderProps) => {
  const { title, breadcrumbs, actions , showHelp = false} = p
  const { showSideNavAsSheet } = UseViewSize()
  const dispatch = useDispatch()
  const releaseMultiSalon = ReleaseMultiLocation()
  const releasePageHelp = ReleasePageHelp()
  const helpDrawerState = useAppSelector(selectHelpDrawerState)
  const isTrial = UseIsInTrial()

  return (
    <Box m="12px 0">
      {/* hide breadcrumbs from trials, they link to owner pages not trial pages */}
      {!isTrial && (
        <Breadcrumb>
          {!releaseMultiSalon && showSideNavAsSheet && (
            <BreadcrumbItem>
              <MaterialIcon size="14px" name="menu" onClick={() => dispatch(setNavVisible(true))} />
            </BreadcrumbItem>
          )}
          {breadcrumbs.map((crumb, index) => {
            return (
              <BreadcrumbItem key={index} isCurrentPage={breadcrumbs.length === index + 1}>
                <BreadcrumbLink href={crumb.url || ''} fontSize="18px">
                  {crumb.label}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )
          })}
        </Breadcrumb>
      )}

      <Flex w="100%" justify="space-between" align="center" wrap="wrap" gridRowGap="12px" pt="12px">
        <Flex align="center" gridGap="4px">
          <Text variant="largetitle" textTransform="uppercase">
            {title}
          </Text>
          {releasePageHelp && showHelp && (
            <Tooltip label="toggle help mode">
              <Flex
                cursor="pointer"
                onClick={() => dispatch(reduceHelpDrawerState(helpDrawerState === 'drawer' ? 'hidden' : 'drawer'))}
                h="40px"
                w="40px"
                justify="center"
                align="center"
                borderRadius="50%"
                _hover={{ border: `3px solid ${COLORS.lavender_200}` }}
              >
                <MaterialIcon name="help_outline" colorhex={COLORS.text_secondary} />
              </Flex>
            </Tooltip>
          )}
        </Flex>
        {actions}
      </Flex>
    </Box>
  )
}
