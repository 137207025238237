declare var Grin: any
declare var window: any

// ref - grin integration code and token https://app.grin.co/settings/account/marketplace/integrations/20284
//     - grid docs https://help.grin.co/docs/en/setting-up-affiliate-tracking-for-any-website
//     - for login credentials contact Sarah Orr or John Mason (last updated Mar 7 2023)
export const InitializeGrin = () => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
    Grin = window.Grin || (window.Grin = [])
    let s: any = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = 'https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js'
    let x: any = document.getElementsByTagName('script')[0]
    x.parentNode.insertBefore(s, x)
  }
}

// add a conversion event to the grin sdk
//
// note: all GrinParams must be present for this to work
//
// to get a grin link to go
//   grin -> contacts -> my creators -> creator name -> affiliate links (on the left)
//
// these conversions are recorded in two known places
//   grin -> contacts -> my creators -> creator name -> recent activity
//   grin -> program -> activations -> click an activation name -> conversions tab
export const grinConversion = (params: {
  amount: number
  userId: number
  planId: string
}): void => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
    const { amount, userId, planId } = params
    const orderName = `userId: ${userId}, planId: ${planId}`
    Grin = window.Grin || (window.Grin = [])
    if (planId.includes('trial') || planId.includes('monthly')) {
      Grin.push(['conversion', 0, { order_number: orderName }])
    } else {
      Grin.push(['conversion', amount, { order_number: orderName }])
    }
  }
}
