import { getLocalStorageItem } from './localStorage'

export const ngBaseUrl = () => {
  const localStorageEnv = getLocalStorageItem('env')
  if (localStorageEnv === 'staging') {
    return 'https://staging.salonscale.com'
  }
  if (localStorageEnv === 'prod') {
    return 'https://app.salonscale.com'
  }
  if (localStorageEnv === 'local') {
    return 'http://localhost:4200'
  }
  return process.env.REACT_APP_ANGULAR_HOST
}
