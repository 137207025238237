import { APIClient, APIClientPhoto, Client, ClientPhoto } from '../clients/interfaces'
import { APIAssistant, APIStylist, SalonMember } from '../user/interfaces'
import { APIColorLara, APIProduct, ColorLara, Product } from '../products/interfaces'
import { DropdownOption } from "../../mini-lib/filters/DropdownFilter";

// external interfaces
//
//

export type APIBowlTypes =
  | string
  | 'base' // name: 'Base'
  | 'highlight' // name: 'Highlight'
  | 'toner' // name: 'Toner'
  | 'root' // name: 'Root'
  | 'shadow-root' // name: 'Shadow Root'
  | 'high-lift' // name: 'High Lift'
  | 'treatment' // name: 'Treatment'
  | 'end' // name: 'End'
  | 'lowlight' // name: 'Lowlight'
  | 'base-break' // name: 'Base Break'
  | 'base-bump' // name: 'Base Bump'
  | 'foil' // name: 'Foil'
  | 'mid' // name: 'Mid'
  | 'extensions' // name: 'Extensions'

export type APIDisplayUnit = 'g' | 'oz' | string
export type APIMethodOfEntry = 'manual' | 'scale' | 'voice' | string
export type APISessionStatus = 'open' | 'complete' | string

export interface APIUpdateSessionItem {
  id?: number // if no id it will add item, if it will update it
  session_id?: number
  product_id: number
  amount: number
  waste: number | null
  target_amount?: number
  bowl: number
  bowl_type?: APIBowlTypes | null
  user_defined_bowl_name: string | null
  display_unit?: APIDisplayUnit
  method_of_entry: APIMethodOfEntry
}

export interface APIUpdateLaborItem {
  id?: number
  labor_tier_service_id: number
  duration_seconds: number
}

export interface APIUpdateSupplies {
  session_supply_id?: number
  supply_id: number
  quantity: number
}

export interface APIUpdateSession {
  session_id: number
  client_id: number
  stylist_id?: number
  stylist_user_id?: number
  assistant_id?: number
  assistant_user_id?: number
  date?: string // 2020-07-03T17:25:17.145067Z use date.toISOString()
  completed_date?: string // 2020-07-03T17:25:17.145067Z use date.toISOString()
  notes?: string
  is_favourite?: boolean
  session_items: APIUpdateSessionItem[]
  extensions: APIUpdateSessionItem[]
  labor_items?: APIUpdateLaborItem[]
  supplies?: APIUpdateSupplies[]
  status: SessionStatus
  replace_session_items: boolean // we will always have this be true
}

export interface APICreateSession {
  client_id: number
  stylist_id: number
  date: string // 2020-07-03T17:25:17.145067Z use date.toISOString()
  session_items: APISessionItem[]
  status: SessionStatus
}

export interface APISessionLaborItem {
  id: number
  session_id: number
  labor_tier_service_id: number
  type: string
  name: string
  price_per_hour: number
  duration_seconds: number
}

export interface APISessionSupply {
  id?: number
  session_id: number
  supply_id: number
  name: string
  price_per_unit: number
  quantity: number
}


export interface APISession {
  id: number
  client: APIClient
  date: string
  completed_date: string
  is_deleted: boolean
  notes?: string
  session_items: APISessionItem[]
  extensions: APISessionItem[]
  labor_items: APISessionLaborItem[]
  supplies: APISessionSupply[]
  stylist: APIStylist
  assistant: APIAssistant
  client_photos: APIClientPhoto[]
  status: string
}

export interface APISessionItem {
  id: number
  amount: number
  waste: number
  bowl: number
  user_defined_bowl_name: string | null
  bowl_type?: APIBowlType
  product: APIProduct
  display_unit: 'g' | 'oz'
}

export interface APIBowlType {
  bowltype_id?: number // this will come from lara but not from django
  id: string
  name: string
}

// internal interfaces
//
//
export type SessionStatus = 'open' | 'complete' | string
export type DisplayUnit = 'g' | 'oz'

export interface Session {
  id: number
  client: Client
  date: string
  completedDate: string
  isDeleted: boolean
  notes?: string
  sessionItems: SessionItem[]
  sessionExtensions: SessionItem[]
  sessionLaborItems: SessionLaborItem[]
  sessionSupplies: SessionSupply[]
  stylist: SalonMember
  assistant?: SalonMember
  clientPhotos: ClientPhoto[]
  status: SessionStatus
  totalColorCost: number
  totalExtensionsCost: number
  totalSuppliesCost: number
  totalLaborItemsCost: number
  totalSessionCost: number
}

export interface SessionItem {
  id?: number
  amount: number
  waste: number | null
  bowl: number
  bowlType?: BowlType
  userDefinedBowlName?: string | null
  product: Product
  displayUnit: DisplayUnit
}

export interface SessionSupply {
  id?: number
  sessionId: number
  supplyId: number
  name: string
  pricePerUnit: number
  quantity: number
}

export interface SessionLaborItem {
  id: number
  sessionId: number
  laborTierServiceId: number
  type: string
  name: string
  pricePerHour: number
  durationSeconds: number
}

export interface BowlType {
  bowltypeId?: number // this will come from lara but not from django
  id: string
  name: string
}

export type ProductTargetFilter = 'hasTarget' | 'noTarget' | null | string
export const ProductTargetFilterOptions: DropdownOption[] = [
  {label: 'All Targets', value: ''},
  {label: 'Has target', value: 'hasTarget'},
  {label: 'No target', value: 'noTarget'},
]

export type ProductOnHandFilter = 'hasOnHand' | 'noOnHand' | null | string
export const ProductOnHandFilterOptions: DropdownOption[] = [
  {label: 'All On-Hand', value: ''},
  {label: 'Counted', value: 'hasOnHand'},
  {label: 'Not Counted', value: 'noOnHand'},
]

export type ProductPricingFilter = 'hasPricing' | 'noPricing' | null | string
export const ProductPricingFilterOptions: DropdownOption[] = [
  {label: 'All Pricing', value: ''},
  {label: 'With Pricing', value: 'hasPricing'},
  {label: 'No Pricing', value: 'noPricing'},
]


// lara session interfaces
//
//
//
//
//
export interface APISessionMetaCreateLara {
  user_id: number,
  assistant_id?: number,
  client_id: number
  notes?: string,
  date: string // 2023-06-14T06:00:00+00:00
}

export interface APISessionMetaPatchLara {
  user_id?: number,
  user_assistant_id?: number,
  client_id?: number
  notes?: string,
  date?: string // 2023-06-14T06:00:00+00:00
}

export interface APISessionSupplyLara {
  id: number,
  name: string,
  price: number,
  quantity: number,
  supply: {
    id: number,
    name: string,
    price: number
  }
}

export interface APICreateOrUpdateSessionSupplyLara {
  id?: number
  supply_id: number
  quantity: number
}

export interface APISessionExtensionLara {
  id: number;
  session_id: number;
  product: APIColorLara;
  salon_purchase_price_per_unit: number;
  client_purchase_price_per_unit: number;
  quantity: string;
}

export interface APIBowlTypeLara {
  bowltype_id: number,
  id: string,
  name: string
}

export interface APISessionColorLara {
  id: number;
  session_id: number;
  product: APIColorLara;
  salon_purchase_price_per_gram: number;
  client_purchase_price_per_gram: number;
  bowl_type: APIBowlTypeLara | null
  bowl_nickname: string | null
  amount_grams: string;
  target_amount_grams: null | string;
  waste_grams: string | null;
  bowl: number;
  method_of_entry: string;
  display_unit: APIDisplayUnit
}

export interface APISessionPhotoLara {
  id: number
  photo_url: string
  photo_tag: string // 'before' | 'after'
  storage_object: string
}
export interface APICreateSessionPhotoLara {
  photo_url: string
  photo_tag: string // 'before' | 'after'
  storage_object: string
}

export interface APICreateOrUpdateSessionExtensionLara {
  id?: number
  product_id: number
  quantity: number
}

export interface APICreateOrUpdateSessionColorLara {
  id?: number
  product_id: number
  amount_grams: number
  display_unit?: APIDisplayUnit
  waste_grams?: number
  bowl?: number
  bowl_nickname: string | null

  // possibly unneeded
  amount?: number
  waste?: number
  method_of_entry?: string
  bowl_type_id?: number | null
}

export interface APISessionMetaLara {
  id: number
  client: {
    id: number
    first_name: string
    last_name: string
    middle_name: string
    phone: string
    session_count: null | number
    note: null | string
  }
  date: string
  date_in_tz: string
  notes: string
  user: {
    id: number
    user_id: number
    full_name: string
    first_name: string
    last_name: string
    user_photo_url?: string
  }
  assistant: {
    id: number
    user_id: number
    full_name: string
    first_name: string
    last_name: string
    user_photo_url?: string
  } | null
  status: null | string
  completed_date: string
  completed_date_in_tz: null | string
  total_client_purchase_price_session_color_items: number
  total_client_purchase_price_session_extension_items: number
  total_client_purchase_price_session_labor_items: number
  total_client_purchase_price_session_supply: number
  total_client_purchase_price: number
}

export interface SessionMetaLara {
  id: number
  client: {
    id: number
    firstName: string
    lastName: string
    middleName: string
    phone: string
    sessionCount: null | number
    note: null | string
  }
  date: Date
  dateInTz: string
  notes: string
  user: {
    id: number
    userId: number
    fullName: string
    firstName: string
    lastName: string
    photoUrl: string
    // todo: add photo url to this
  }
  assistant: {
    id: number
    userId: number
    fullName: string
    firstName: string
    lastName: string
    photoUrl: string
    // todo: add photo url to this
  } | null
  status: null | string
  completedDate: string
  completedDateInTz: null | string
  totalClientPurchasePriceSessionColorItemsCents: number
  totalClientPurchasePriceSessionExtensionItemsCents: number
  totalClientPurchasePriceSessionLaborItemsCents: number
  totalClientPurchasePriceSessionSupplyCents: number
  totalClientPurchasePriceCents: number
}

export interface SessionSupplyLara {
  id: number,
  name: string,
  priceCents: number,
  quantity: number,
  supply: {
    id: number,
    name: string,
    priceCents: number
  }
}

export interface SessionExtensionLara {
  id: number
  sessionId: number
  product: ColorLara
  salonPurchasePrice: number
  clientPurchasePrice: number
  quantity: number
}

export interface BowlTypeLara {
  bowltypeId: number,
  id: string,
  name: string
}

export interface SessionColorLara {
  id: number
  sessionId: number
  product: ColorLara
  salonPurchasePrice: number
  clientPurchasePrice: number
  bowlType: BowlTypeLara | null
  bowlNickname?: string | null
  amountGrams: number
  targetAmountGrams: null | number
  wasteGrams: number | null
  clientTotalPriceCents: number
  wasteTotalPriceCents: number
  bowl: number
  methodOfEntry: string
  displayUnit: DisplayUnit | string
}

export interface SessionPhotoLara {
  id: number
  photoUrl: string
  photoTag: string // 'before' | 'after'
  storageObject: string
}
