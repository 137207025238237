/////////////// utils.ts
//
//

import {LABOR_SERVICE_TYPES, LaborItem, LaborService, LaborTier} from './interfaces'
import {SalonMember} from '../user/interfaces'

export const EMPTY_TIER = (): LaborTier => {
  return {
    id: -1,
    name: '',
  }
}
export const EMPTY_SERVICE = (): LaborService => {
  return {
    id: -1,
    name: '',
    type: '',
  }
}

export const EMPTY_LABOR = (): LaborItem => {
  return {
    id: -1,
    priceDollars: 0,
    tier: EMPTY_TIER(),
    service: EMPTY_SERVICE(),
  }
}

export const splitLaborServicesByType = (
  items: LaborService[] | null,
): { hourlyServices: LaborService[]; flatServices: LaborService[] } => {
  if (!items) {
    return { hourlyServices: [], flatServices: [] }
  }
  const flat: LaborService[] = []
  const hourly: LaborService[] = []
  items.forEach((item) => {
    if (item.type === LABOR_SERVICE_TYPES.service) {
      flat.push(item)
    } else {
      hourly.push(item)
    }
  })

  return { hourlyServices: hourly, flatServices: flat }
}

export const buildTierServiceKey = (tierId: number, serviceId: number): string => `${tierId}-${serviceId}`

export const buildTierServiceMap = (items: LaborItem[]): { [key: string]: LaborItem } => {
  const itemsByTierService: { [key: string]: LaborItem } = {}
  items.forEach((item) => {
    itemsByTierService[buildTierServiceKey(item?.tier?.id, item?.service?.id)] = item
  })
  return itemsByTierService
}

export const getMembersForTier = (tierId: number, members: SalonMember[]): SalonMember[] => {
  return members.filter((member) => tierId === member.laborTier.id)
}

export const buildLoadingIdentifier = (
  type: 'item' | 'service' | 'tier',
  tierId?: number,
  serviceId?: number,
): string => {
  if (type === 'item') {
    return `labor-item-${tierId}-${serviceId}`
  }
  if (type === 'service') {
    return `labor-service-${serviceId}`
  }
  if (type === 'tier') {
    return `labor-tier-${tierId}`
  }
  return ''
}
