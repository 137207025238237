export const LOADING_MASTER_PRODUCTS = 'master-products'
export const ADDING_MASTER_PRODUCTS = 'adding-master-products'
export const LOADING_TRIAL_PRICING = 'loading-trial-pricing'
export const ADDING_TRIAL_PRICING = 'adding-trial-pricing'

export const INTERNAL_VENDOR_IDS = [
  268, // candy
  199, // taco
  161, // demo
]

// note: these are currently unique, but in the future if someone adds a real line called Candy it will not show up
export const INTERNAL_VENDOR_NAMES = [
  'Candy', // 268
  'Taco Testing', // 199
  'Demo', // 161
]
