export const CHARGEBEE_PLAN_IDS = {
  // team plans
  teamTrialUSD: 'team-trial-usd',
  teamAddonsMonthlyUSD: 'team-addons-monthly-usd',
  teamSetupMonthlyUSD: 'team-setup-monthly-usd',
  teamPremiumsetupMonthlyUSD: 'team-premiumsetup-monthly-usd',
  teamBundleUSD: 'team-bundle-usd',

  // solo plans
  soloTrialUSD: 'solo-trial-usd',
  soloAddonsMonthlyUSD: 'solo-addons-monthly-usd',
  soloSetupMonthlyUSD: 'solo-setup-monthly-usd',
  soloPremiumsetupMonthlyUSD: 'solo-premiumsetup-monthly-usd',
  soloBundleUSD: 'solo-bundle-usd',

  // special plans
  temporaryAccess: 'temporary-access',
  teamKaoYearlyUSD: 'team-kao-yearly-usd',
  teamAffiliateYearlyUSD: 'team-affiliate-yearly-usd',
  teamAffiliateQuarterlyUSD: 'team-affiliate-quarterly-usd',
  teamMeetYourStylistUSD: 'team-meet-your-stylist-usd',

  // todo: update these when we get the chargebee ids
  // perfect pairing
  soloPerfectPairing: 'solo-perfectpairing-usd',
  teamPerfectPairing: 'team-perfectpairing-usd',
}

export const CHARGEBEE_ADDON_IDS = {
  // free black scale
  freeBluetoothScaleUSD: 'salonscale-bluetooth-scale-free-usd',

  // free colored scales
  salonscaleBluetoothScaleFreeLavenderUSD: 'salonscale-bluetooth-scale-free-lavender-usd',
  salonscaleBluetoothScaleFreePeachUSD: 'salonscale-bluetooth-scale-free-peach-usd',
  salonscaleBluetoothScaleFreeMidnightUSD: 'salonscale-bluetooth-scale-free-midnight-usd',
  salonscaleBluetoothScaleFreeLinenUSD: 'salonscale-bluetooth-scale-free-linen-usd',

  // basic black scale
  salonscaleBluetoothScaleUSD: 'salonscale-bluetooth-scale-usd',

  // colored scales
  salonscaleBluetoothScaleLavenderUSD: 'salonscale-bluetooth-scale-lavender-usd',
  salonscaleBluetoothScaleMidnightUSD: 'salonscale-bluetooth-scale-midnight-usd',
  salonscaleBluetoothScalePeachUSD: 'salonscale-bluetooth-scale-peach-usd',
  salonscaleBluetoothScaleLinenUSD: 'salonscale-bluetooth-scale-linen-usd',
}

export const CHARGEBEE_COUPON_IDS = {
  // todo: delete these coupons once pricing is released
  team30DayTrialUSD: 'team-30-day-trial-usd',
  solo30DayTrialUSD: 'solo-30-day-trial-usd',
  solo30DayTrialUSD2022: 'solo-30-day-trial-usd-2022',

  team30DayTrialUSD2023: 'team-30-day-trial-usd-2023',
  solo30DayTrialUSD2023: 'solo-30-day-trial-usd-2023',
  teamBundleCouponUSD: 'team-bundle-coupon-usd',

  soloBlackFriday2022: 'solo-blackfriday2022-usd',
  teamBlackFriday2022: 'team-blackfriday2022-usd',

  // todo: get actual coupon
  soloBlackFriday2023: 'solo-blackfriday2023-usd',
  teamBlackFriday2023: 'team-blackfriday2023-usd',

  grinAffiliateCoupon: 'AFFILIATE10',
  cakeDayCoupon: 'cake-day'
}

export const PLAN_ID_TO_COUPON_ID_MAP = (isSpecialPricing: boolean, hasGrin: boolean) => {
  const teamBundleCoupons: string[] = []
  const soloBundleCoupons: string[] = []
  if (isSpecialPricing) {
    // no coupons associated with the dec19 sale
    // teamBundleCoupons.push(CHARGEBEE_COUPON_IDS.teamBlackFriday2023)
    // soloBundleCoupons.push(CHARGEBEE_COUPON_IDS.soloBlackFriday2023)
  }
  if (!isSpecialPricing && hasGrin) {
    teamBundleCoupons.push(CHARGEBEE_COUPON_IDS.grinAffiliateCoupon)
    soloBundleCoupons.push(CHARGEBEE_COUPON_IDS.grinAffiliateCoupon)
  }
  return {
    [CHARGEBEE_PLAN_IDS.teamSetupMonthlyUSD]: [],
    [CHARGEBEE_PLAN_IDS.teamAddonsMonthlyUSD]: [],
    [CHARGEBEE_PLAN_IDS.teamBundleUSD]: teamBundleCoupons,

    [CHARGEBEE_PLAN_IDS.soloSetupMonthlyUSD]: [],
    [CHARGEBEE_PLAN_IDS.soloAddonsMonthlyUSD]: [],
    [CHARGEBEE_PLAN_IDS.soloBundleUSD]: soloBundleCoupons,

    [CHARGEBEE_PLAN_IDS.teamTrialUSD]: [],
    [CHARGEBEE_PLAN_IDS.soloTrialUSD]: [],

    [CHARGEBEE_PLAN_IDS.teamPremiumsetupMonthlyUSD]: [],
    [CHARGEBEE_PLAN_IDS.soloPremiumsetupMonthlyUSD]: [],
    [CHARGEBEE_PLAN_IDS.soloPerfectPairing]: [],
    [CHARGEBEE_PLAN_IDS.teamPerfectPairing]: [],
  }
}

export type CBSalonType = 'team' | 'solo'
export type CBSubscriptionType = 'monthly' | 'yearly' | 'perfectpairing'

export const QPS = {
  plan: 'plan',
  peach: 'peach',
  black: 'black',
  midnight: 'midnight',
  lavender: 'lavender',
  linen: 'linen',
  freescale: 'freescale',
}
