import { differenceInDays, differenceInMinutes } from 'date-fns'
import { maybePluralize } from "../../core/text";
import { hasActiveQuickTrialAndNoSubscription } from "../../data/salon/utils";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";

export const getTrialDaysLeft = (trialExpiryDate: Date): number => {
  const now = Date.now()
  const daysLeft = differenceInDays(trialExpiryDate, now)

  if (daysLeft < 1) {
    const minutesLeft = differenceInMinutes(trialExpiryDate, now)
    if (minutesLeft < 1) {
      return -1
    } else {
      return 0
    }
  }
  return daysLeft
}

export const getTrialDaysLeftText = (daysLeft: number | null): string => {
  if (daysLeft === null) {
    return ''
  }
  if (daysLeft < 0) {
    return 'Your trial has ended. Please upgrade to continue using SalonScale!'
  }
  if (daysLeft === 0) {
    return 'Your trial expires today'
  }
  // daysLeft > 0
  return `You have ${daysLeft + 1} ${maybePluralize('day', daysLeft + 1)} remaining in your trial`
}

export const UseIsInTrial = (): boolean => {
  // return true
  const {user} = UseBaseApiParams()
  return user && user.currentSalonContext ? hasActiveQuickTrialAndNoSubscription(user.currentSalonContext) : false
}