import { map } from 'lodash'
import {
  APIAssistant, APIIntegrationContextMap,
  APILoggedInUserSalonPermissions,
  APISalonContextLara,
  APISalonRole,
  APISalonRoleLara,
  APISalonSubscriptionLara,
  APIStylist,
  APIUser, APIUserDeviceInfo,
  APIUserLara, APIUserMeta,
  APIUserSalonContext,
  LoggedInUser,
  LoggedInUserSalonContext,
  LoggedInUserSalonContextSubscription,
  LoggedInUserSalonPermissions, LoggedInUserSquareContext,
  SalonContextRole,
  SalonMember,
  SalonMemberLara,
  SalonRole, UserDeviceInfo, UserMeta
} from './interfaces'
import {
  getActiveSalonContext,
  getHighestSalonStatusFromSubscriptionsLara,
  getLoggedInUserActiveSalonContext, getLoggedInUserActiveSquareContext,
  getSalonTypeFromSubscriptionLara
} from './utils'
import { mapAPILaborTierToLaborTier } from '../labor/mappers'
import { EMPTY_TIER } from '../labor/utils'

export const mapAPISalonContextToLoggedInUserSalonContext = (
  apiContext: APIUserSalonContext,
): LoggedInUserSalonContext => {
  // note: the next iteration has subscription plan id, currently monthly/bundle/yearly doesnt matter
  // this just adds in realistic data without having to update the django server
  const subscriptionPlanId = apiContext.customer.salon_type === 'salon'
    ? 'team-monthly-usd'
    : 'solo-monthly-usd'
  return {
    salonName: apiContext.salon_name,
    salonType: apiContext.salon_type,
    salonId: apiContext.salon_id,
    stylistId: apiContext.stylist_id,
    customerId: apiContext.customer.id,
    subscription: {
      id: apiContext.customer.id,
      isActive: apiContext.customer.stripe_active_account,
      subscriptionPlanId,
      subscriptionStatus: apiContext.customer.stripe_subscription_status,
      salonType: apiContext.customer.salon_type,
    },
    role: null,
    photoUrl: apiContext.photo_url,
    defaultMarkupPercentage: apiContext.salon_default_markup_percentage,
    defaultExtensionMarkupPercentage: apiContext.salon_default_extension_markup_percentage,
    timezone: apiContext.timezone,
  }
}

export const mapAPIUserToLoggedInUser = ( token: string, apiUser: APIUser, salonId?: number ): LoggedInUser => {
  let apiSalonContext: APIUserSalonContext | null = getActiveSalonContext(apiUser.salon_context, salonId)
  return {
    token: token,
    isSuperuser: apiUser.is_superuser,
    userId: apiUser.user_id,

    email: apiUser.email,
    phone: apiUser.phone,
    firstName: apiUser.first_name,
    lastName: apiUser.last_name,
    photoUrl: apiUser.user_photo,

    currentSalonContext: apiSalonContext ? mapAPISalonContextToLoggedInUserSalonContext(apiSalonContext) : null,
    salonContexts: apiUser.salon_context.map(( context ) => mapAPISalonContextToLoggedInUserSalonContext(context)),
    squareContexts: null,
    currentSquareContext: null,
  }
}

export const mapAPIStylistToUser = ( stylist: APIStylist ): APIUser => {
  return {
    token: '',
    user_id: stylist.user_id,
    email: stylist.email,
    phone: stylist?.phone ? stylist.phone : '',
    first_name: stylist.first_name,
    last_name: stylist.last_name,
    salon_context: [],
    is_superuser: false,
    customer: {},
    user_photo: '',
  }
}

export const mapAPIStylistToSalonMember = ( apiStylist: APIStylist ): SalonMember => {
  return {
    userId: apiStylist.user_id,
    stylistId: apiStylist.id,

    firstName: apiStylist.first_name,
    lastName: apiStylist.last_name,
    email: apiStylist.email,
    laborTier: EMPTY_TIER(),
    photoUrl: '', // todo: make api response return this, updating a stylist will cause them to lose their photo
    roles: apiStylist.roles,
  }
}
export const mapAPIStylistsToSalonMembers = ( apiStylists: APIStylist[] ): SalonMember[] => {
  return map(apiStylists, mapAPIStylistToSalonMember)
}

export const mapAPIUserToSalonMember = ( apiUser: APIUser, salonId: number ): SalonMember => {
  const salonContext = getActiveSalonContext(apiUser.salon_context, salonId)
  return {
    userId: apiUser.user_id,
    stylistId: salonContext ? salonContext.stylist_id : -1, // todo: find a better way to handle possible null values

    firstName: apiUser.first_name,
    lastName: apiUser.last_name,
    email: apiUser.email,

    photoUrl: apiUser.user_photo,
    laborTier:
      salonContext && salonContext.labor_tier ? mapAPILaborTierToLaborTier(salonContext.labor_tier) : EMPTY_TIER(),
    roles: salonContext ? salonContext.roles : [],
  }
}
// TODO - change the name to mapAPIUserToSalonMember once the users api completely replaced
export const mapAPIUserToSalonMemberLara = ( apiUser: APIUser, salonId: number ): SalonMemberLara => {
  return {
    userId: apiUser.user_id,
    fullName: apiUser.full_name ?? '',
    firstName: apiUser.first_name,
    lastName: apiUser.last_name,
  }
}

export const mapAPIUsersToSalonMembers = ( apiUsers: APIUser[], salonId: number ): SalonMember[] => {
  return apiUsers.map(( user ) => mapAPIUserToSalonMember(user, salonId))
}

// TODO - change the name to mapAPIUsersToSalonMembers once the users api completely replaced
export const mapAPIUsersToSalonMembersLara = ( apiUsers: APIUser[], salonId: number ): SalonMemberLara[] => {
  return apiUsers.map(( user ) => mapAPIUserToSalonMemberLara(user, salonId))
}

export const mapAPIAssistantToSalonMember = ( api: APIAssistant ): SalonMember => {
  return {
    userId: api.user_id,
    stylistId: api.id,

    firstName: api.first_name,
    lastName: api.last_name,
    email: '', // todo: make sure this doesnt break anything
    laborTier: EMPTY_TIER(),

    photoUrl: '',
    roles: [],
  }
}
export const mapAPISalonRoleToSalonRole = ( api: APISalonRole ): SalonRole => {
  return {
    userId: api?.id,
    role: {
      roleId: api?.role?.id,
      roleName: api?.role?.role,
      permissions: api?.role?.permissions,
    },
  }
}

export const mapAPISalonRoleToSalonContextRole = ( api: APISalonRoleLara ): SalonContextRole => {
  return {
    role: {
      roleId: api.id,
      roleName: api.role,
      permissions: api.permissions,
    }
  }
}

export const mapAPISalonRolesToSalonRoles = ( apiSalonRoles: APISalonRole[] ): SalonRole[] => {
  return apiSalonRoles.map(( salonRole ) => mapAPISalonRoleToSalonRole(salonRole))
}

export const mapAPILoggedInUserSalonPermissionsToLoggedInUserSalonPermissions = (
  salonId: number,
  api: APILoggedInUserSalonPermissions,
): LoggedInUserSalonPermissions => {
  return {
    id: api.id,
    salonId,
    role: api.role,
    permissions: api.permissions,
  }
}

export const mapAPISalonContextRoleToLoggedInUserSalonPermissions = (
  salonId: number,
  salonContextRole: SalonContextRole,
): LoggedInUserSalonPermissions => {
  return {
    id: salonContextRole.role.roleId,
    salonId,
    role: salonContextRole.role.roleName,
    permissions: salonContextRole.role.permissions,
  }
}

export const mapAPISalonSubscriptionLaraToSalonSubscriptionLara = ( api: APISalonSubscriptionLara | null ): LoggedInUserSalonContextSubscription | null => {
  if (!api) {return null}
  return {
    id: api.id,
    salonType: getSalonTypeFromSubscriptionLara(api),
    // salonId: api.salon_id,
    isActive: ['non_renewing', 'in_trial', 'active'].includes(api.status) || api.plan_id === 'trial-plan',
    subscriptionStatus: api.status,
    subscriptionPlanId: api.plan_id,
  }
}
export const mapAPISalonContextLaraToLoggedInUserSalonContext = (
  api: APISalonContextLara,
): LoggedInUserSalonContext => {
  const apiSubscriptionWithBestStatus = getHighestSalonStatusFromSubscriptionsLara(api.subscriptions)
  const salonType = getSalonTypeFromSubscriptionLara(apiSubscriptionWithBestStatus)
  const subscription = mapAPISalonSubscriptionLaraToSalonSubscriptionLara(apiSubscriptionWithBestStatus)
  const role = mapAPISalonRoleToSalonContextRole(api.role)
  return {
    salonId: api.id,
    stylistId: -1,
    customerId: api.customer_id,
    salonName: api.name,
    salonType,
    photoUrl: '', // todo: get photo url
    role,
    defaultMarkupPercentage: api.salon_default_markup_percentage || 0,
    defaultExtensionMarkupPercentage: api.salon_default_extension_markup_percentage || 0,
    subscription,
    timezone: api.timezone,
  }
}

export const mapAPIIntegrationContextMapToLoggedInUserSquareContexts = (
  apis: APIIntegrationContextMap,
): LoggedInUserSquareContext[] | null => {
  return apis?.square?.map(context => {
    return {
      merchantId: context.merchant_id,
      locationId: context.location_id,
      salonId: context.salon_id,
      userId: context.user_id,
    }
  })

}

export const mapAPIUserLaraToLoggedInUser = ( token: string, apiUser: APIUserLara, salonId?: number ): LoggedInUser => {
  // let apiSalonContext: APIUserSalonContext | null = getActiveSalonContextLara(apiUser.salon_contexts, salonId)

  const salonContexts = map(apiUser.salon_contexts, mapAPISalonContextLaraToLoggedInUserSalonContext)
  const currentSalonContext = getLoggedInUserActiveSalonContext(salonContexts, salonId)

  const squareContexts = apiUser.integration_contexts ? mapAPIIntegrationContextMapToLoggedInUserSquareContexts(apiUser.integration_contexts) : null
  const currentSquareContext = squareContexts ? getLoggedInUserActiveSquareContext(squareContexts, salonId) : null

  return {
    token: token,
    isSuperuser: apiUser.is_superuser,
    userId: apiUser.user_id,

    email: apiUser.email,
    phone: apiUser.phone,
    firstName: apiUser.first_name,
    lastName: apiUser.last_name,
    photoUrl: apiUser.user_photo_url || '',
    squareContexts,
    currentSquareContext,
    currentSalonContext,
    salonContexts,
  }
}

export const mapAPIUserMetaToUserMeta = (api: APIUserMeta): UserMeta => {
  return {
    id: api.id,
    fullName: api.full_name,
    firstName: api.first_name,
    lastName: api.last_name,
    email: api.email,
    phone: api.phone,
    userPhotoUrl: api.user_photo_url,
  }
}

export const mapApiUsersMetaToUsersMeta = ( api: APIUserMeta[] ): UserMeta[] => {
  return api.map(( user ) => mapAPIUserMetaToUserMeta(user))
}

export const mapAPIUserDeviceInfoToUserDeviceInfo = (api: APIUserDeviceInfo): UserDeviceInfo => {
  return {
    salonscaleVersion: api?.salonscale_version || '',
    deviceVersion: api?.device_version || '',
    deviceType: api?.device_type || ''
  }
}
