import { clearAllLocalStorage } from '../localStorage'
import { delay } from 'lodash'
import { generatePath } from 'react-router-dom'

import { ROUTES } from '../../appRoutes'

declare var window

// ref - intercom docs https://developers.intercom.com/installing-intercom/docs/intercom-javascript
export const UseSignOut = () => {
  clearAllLocalStorage()

  if (window && window.Intercom) {
    window.Intercom('shutdown');
  }
  delay(() => {
    // const ngBase = process.env.REACT_APP_ANGULAR_HOST
    // window.location.href = `${ngBase}/logout`
    const url = generatePath(generatePath(ROUTES.login))
    window.location.href = url
  }, 500)
}
