// gets base url for api calls based on local storage or the default env
import { getLocalStorageItem } from './core/localStorage'

export const GetServerBaseUrl = (version: 'v2' | 'v3' = 'v3', server: 'lara' | 'django' = 'django'): string => {
  const localStorageEnv = getLocalStorageItem('env')
  if (server === 'lara') {
    if (localStorageEnv === 'prod') {
      return `https://api.salonscale.com/lara/api/${version}`
    }
    if (localStorageEnv === 'staging') {
      return `https://stage.salonscale.com/lara/api/${version}`
    }
    if (localStorageEnv === 'lara-local') {
      return `http://0.0.0.0/lara/api/${version}`
    }
    return `${process.env.REACT_APP_SERVER_HOST}/lara/api/${version}`
  }
  if (localStorageEnv === 'prod') {
    return `https://api.salonscale.com/api/${version}`
  }
  if (localStorageEnv === 'staging') {
    return `https://stage.salonscale.com/api/${version}`
  }
  if (localStorageEnv === 'local') {
    return `http://localhost:8000/api/${version}`
  }

  return `${process.env.REACT_APP_SERVER_HOST}/api/${version}`
}
