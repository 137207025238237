import { LABOR_SERVICE_TYPES, LaborItem, LaborService, LaborTier } from '../../data/labor/interfaces'
import { Box, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { buildTierServiceKey, buildTierServiceMap, splitLaborServicesByType } from '../../data/labor/utils'
import { BRAND_THEMES } from '../../theme'
import {
  LaborServiceAddCell,
  LaborServiceCell,
  LaborServiceLabel,
  LaborTierAddCell,
  LaborTierCell,
  LaborTierServiceCell,
} from './LaborTableCells'
import { UseViewSize } from '../../core/UseViewSize'
import { stringNumberSort } from '../../mini-lib/utils/sorting'
import { DEFAULT_SERVICE_NAME, DEFAULT_TIER_NAME } from '../../data/labor/constants'
import { TableCell } from '../../mini-lib/table/Table'

export const LaborTable = (props: {
  laborItems: LaborItem[]
  laborServices: LaborService[]
  laborTiers: LaborTier[]
}) => {
  const { laborItems, laborServices, laborTiers } = props
  const { hourlyServices, flatServices } = splitLaborServicesByType(laborServices)

  const [showFlatServices, setShowFlatServices] = useState(true)
  const [showHourlyServices, setShowHourlyServices] = useState(true)

  // this builds a map by tier-service id (they should be unique)
  // this is much more performant than trying to find an item by tier.id and service.id later
  // todo: consider extracting this to a selector or memoize it
  const laborItemsByTierService: { [key: string]: LaborItem } = buildTierServiceMap(laborItems)
  const { isMobile } = UseViewSize()
  return (
    <Flex>
      {/* Labor Services Column */}
      <LaborServicesCol
        hourlyServices={hourlyServices}
        showHourlyServices={showHourlyServices}
        setShowHourlyServices={setShowHourlyServices}
        flatServices={flatServices}
        showFlatServices={showFlatServices}
        setShowFlatServices={setShowFlatServices}
      />

      {/* Tiers */}
      <Flex ml={isMobile ? '12px' : ''} isTruncated={true} overflow="scroll" width="2000px" maxWidth="100%">
        {stringNumberSort(laborTiers, 'name', DEFAULT_TIER_NAME).map((tier) => {
          // individual tier column component
          return (
            <LaborTierCol
              key={tier.id}
              tier={tier}
              showHourlyServices={showHourlyServices}
              hourlyServices={hourlyServices}
              showFlatServices={showFlatServices}
              flatServices={flatServices}
              laborItemsByTierService={laborItemsByTierService}
            />
          )
        })}
        <Box p={isMobile ? '' : '12px'}>
          <TableCell>
            <LaborTierAddCell />
          </TableCell>
        </Box>
      </Flex>
    </Flex>
  )
}

export const LaborServicesCol = (props: {
  hourlyServices: LaborService[]
  showHourlyServices: boolean
  setShowHourlyServices: (boolean) => void
  showFlatServices: boolean
  setShowFlatServices: (boolean) => void
  flatServices: LaborService[]
}) => {
  const {
    hourlyServices,
    showHourlyServices,
    setShowHourlyServices,
    flatServices,
    showFlatServices,
    setShowFlatServices,
  } = props
  const { isMobile } = UseViewSize()
  return (
    <Box m={isMobile ? '' : '12px'}>
      <TableCell />
      <TableCell>
        <LaborServiceLabel
          label="Hourly Pricing"
          theme={BRAND_THEMES.skylight}
          setShowServices={setShowHourlyServices}
          showServices={showHourlyServices}
        />
      </TableCell>
      {showHourlyServices && (
        <>
          {stringNumberSort(hourlyServices, 'name', DEFAULT_SERVICE_NAME).map((service) => {
            return (
              <TableCell key={service.id}>
                <LaborServiceCell service={service} theme={BRAND_THEMES.skylight} />
              </TableCell>
            )
          })}
          <TableCell>
            <LaborServiceAddCell serviceType={LABOR_SERVICE_TYPES.hourly} theme={BRAND_THEMES.skylight} />
          </TableCell>
        </>
      )}
      {!showHourlyServices && <TableCell />}

      <TableCell />

      <TableCell>
        <LaborServiceLabel
          label="Flat Fee Pricing"
          theme={BRAND_THEMES.midnight}
          setShowServices={setShowFlatServices}
          showServices={showFlatServices}
        />
      </TableCell>

      {showFlatServices && (
        <>
          {stringNumberSort(flatServices, 'name', DEFAULT_SERVICE_NAME).map((service) => {
            return (
              <TableCell key={service.id}>
                <LaborServiceCell service={service} theme={BRAND_THEMES.midnight} />
              </TableCell>
            )
          })}

          <TableCell>
            <LaborServiceAddCell serviceType={LABOR_SERVICE_TYPES.service} theme={BRAND_THEMES.midnight} />
          </TableCell>
        </>
      )}
    </Box>
  )
}

export const LaborTierCol = (props: {
  tier: LaborTier
  hourlyServices: LaborService[]
  showHourlyServices: boolean
  flatServices: LaborService[]
  showFlatServices: boolean
  laborItemsByTierService: { [key: string]: LaborItem }
}) => {
  const { tier, hourlyServices, showHourlyServices, flatServices, showFlatServices, laborItemsByTierService } = props
  const { isMobile } = UseViewSize()
  return (
    <Box key={tier.id} m={isMobile ? '0 12px 0 0' : '12px'}>
      <TableCell>
        <LaborTierCell tier={tier} />
      </TableCell>
      <TableCell />

      {/*pricing for hourly items by tier*/}
      {showHourlyServices &&
        hourlyServices.map((service, index) => {
          return (
            <TableCell key={buildTierServiceKey(tier.id, service.id)}>
              <LaborTierServiceCell
                service={service}
                tier={tier}
                laborItemsByTierService={laborItemsByTierService}
                type={LABOR_SERVICE_TYPES.hourly}
              />
            </TableCell>
          )
        })}

      <TableCell />
      <TableCell />
      <TableCell />

      {/*pricing for service items by tier*/}
      {showFlatServices &&
        flatServices.map((service, index) => {
          return (
            <TableCell key={buildTierServiceKey(tier.id, service.id)}>
              <LaborTierServiceCell
                service={service}
                tier={tier}
                laborItemsByTierService={laborItemsByTierService}
                type={LABOR_SERVICE_TYPES.service}
              />
            </TableCell>
          )
        })}
    </Box>
  )
}

