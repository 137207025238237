export interface VideoData {
  id: string
  title: string
  length: string
  startTime?: string
}

export const VIDEO_MAP = {
  // web dashboard videos
  addingClients: { title: 'Adding Clients', id: 'l9g92-KNTLU', length: '(30 sec)' }, // last updated mar 2023
  addingStylists: { title: 'Adding Stylists (Team Accounts)', id: 'y4UO45UZuOk', length: '(1 min)' }, // last updated mar 2023
  createOrder: { title: 'Creating and Download an Order', id: 'VSx7zY3XATc', length: '(2.5 min)' }, // last updated mar 2023
  runningReports: { title: 'Running Reports', id: 'uloGVgDx2bk', length: '(1.5 min)' }, // last updated mar 2023
  connectToScale: { title: 'Setting up your Bluetooth Scale', id: 'IUWsGw7efQ0', length: '(3 min)' }, // last updated mar 2023
  addingColorLinesPricingAndMarkup: {
    title: 'Adding Products, Pricing and Markup',
    id: 'MiZqhCtDJOQ',
    length: '(6.5 min)',
  }, // last updated mar 2023
  addPricing: { title: 'Adding Pricing', id: 'b7DxG7c0Np0', length: '(2.5min)'},
  // welcome videos
  soloWelcomeVideo: { title: 'Welcome to SalonScale', id: 'kMH3NrfMEIk', length: '(52 sec)' },
  teamWelcomeVideo: { title: 'Welcome to SalonScale', id: '0L6z3YTgTjI', length: '(1:08 min)' },

  // start guide videos
  getToKnowSalonScale: { title: 'Welcome to SalonScale', id: 'A4LO2GEdv1I', length: '(1 min)' },
  designedForYou: { title: 'Welcome to SalonScale', id: 't_CEiAR1dZQ', length: '(1 min)' },
  addLines: { title: 'Adding Color Lines', id: 'zyMwJ4-2ZUY', length: '(49 sec)' },
  addAddons: { title: 'Adding Addons', id: 'cDoay8XveTQ', length: '(1 min)' },
  addExtensions: { title: 'Adding Extensions', id: 'tiAVSt-hgsM', length: '(1.5 min)' },
  addStylist: { title: 'Adding Team Members', id: 'y4UO45UZuOk', length: '(1 min)' },
  addSession: { title: 'Add a Session', id: 'jl6diYqfIoA', length: '(2 min)' },
  learnReports: { title: 'Reports', id: 'uloGVgDx2bk', length: '(2 min)' },
  squareGuideVideo: { title: 'Connecting to Square', id: 'RzjQ0K1tZNk', length: '(3.5 min)' },

  inventoryIntroduction: { title: 'What is SalonScale Inventory Management', id: 'dSaxewR-enQ', length: '(2 min)' },
  inventoryBackBarTargets: {
    title: 'How to Set Backbar Targets in SalonScale',
    id: 'Lr9y0AFO0LU',
    length: '(1.5 min)',
  },
  inventoryCount: { title: 'Complete Your First Count', id: 'PspllQXVh8A', length: '(4 min)' },
  inventoryStockAlerts: { title: 'All About Stock Alerts', id: '_vwpPjB912s', length: '(3 min)' },
  inventoryOrders: { title: 'Create an Order', id: 'YXraJ46Nvx4', length: '(4 min)' },
  inventoryAddingStock: { title: 'How to Add Stock in SalonScale', id: 'c8zdKFYchFc', length: '(5.5 min)' },

  // mobile app videos
  remixAndDuplicateFormulas: {
    title: 'How to Remix and Duplicate Formulas',
    id: 'WzZ0UVDs61w',
    startTime: '194',
    length: '(2 min)',
  },
  creatingSessions: { title: 'Creating Sessions', id: 'WzZ0UVDs61w', length: '(5 min)' },
  customerProfileAndDuplicateSession: {
    title: 'Customer profiles and Duplicating Sessions In App',
    id: 'GYwEVWhT41k',
    length: '(1.5 min)',
  },
  manageSettingsInApp: { title: 'Manage Settings in App', id: 'Xv3pbaCtJQ0', length: '(1 min)' },
  updateProductsMobile: { title: 'How to Update Products in your Mobile App', id: 'usq1PNP20VQ', length: '(2 min)' },

  // transparent backbar
  module1WhatAreCostsOfGoodsSold: {
    title: 'Module 1: What are Cost of Goods Sold? (Team)',
    id: 'zn_bEAgcsdk',
    length: '(5 min)',
  },
  module2HowCostOfGoodsBenefitsYourSalon: {
    title: 'Module 2: How Cost of Goods Sold Benefit your Salon',
    id: '2mDLH11RlwA',
    length: '(6 min)',
  },
  module3HowCostOfGoodsBenefitsStylists: {
    title: 'Module 3: How Cost of Goods Sold Benefit the Stylist',
    id: 'YWBCS_dbBnU',
    length: '(18 min)',
  },
  module4HowCostOfGoodsBenefitsClients: {
    title: 'Module 4: How Cost of Goods Sold Benefit Your Client',
    id: '_fwVEKptYTI',
    length: '(6 min)',
  },
  bonusCashFlowBooster: { title: 'Bonus: How to Make a Cash Flow Booster', id: 'PqlQfYgdriU', length: '(9.5 min)' },
  bonusHistoryOfServicePricing: {
    title: 'Bonus: History Lesson on Service Pricing',
    id: 'n6IjQMg2eYk',
    length: '(5 min)',
  },
  // trial videos
  trialWhyColor: { title: 'How is Color Paid for in Your Salon', id: 'UfgutbgY0YY', length: '(3.5 min)' },
  trialDemoWithAliciaAndWhitney: { title: 'Demo with Alicia & Whitney', id: 'wM3RMdgjk3E', length: '(65 min)' },
  trialCreateSession: { title: 'How to Make a Session', id: '-49J0q161nU', length: '(2 min)' },
  trialWelcome: { title: 'Welcome to Your SalonScale Trial', id: 'kr5Ic1DG6RQ', length: '(1 min)' },
  trial8MinuteDemo: { title: 'SalonScale 8 min Demo', id: '9FGBcdeZnFA', length: '(8.5 min)' },
  trialKnowYourColorCosts : { title: 'How is Color Paid for in Your Salon', id: 'pNrIzuCD6nw', length: '(2 min)' },

}

export const TRANSPARENT_BACKBAR: VideoData[] = [
  VIDEO_MAP.module1WhatAreCostsOfGoodsSold,
  VIDEO_MAP.module2HowCostOfGoodsBenefitsYourSalon,
  VIDEO_MAP.module3HowCostOfGoodsBenefitsStylists,
  VIDEO_MAP.module4HowCostOfGoodsBenefitsClients,
  VIDEO_MAP.bonusCashFlowBooster,
  VIDEO_MAP.bonusHistoryOfServicePricing,
]

export const INVENTORY_VIDEOS: VideoData[] = [
  VIDEO_MAP.inventoryCount,
  VIDEO_MAP.inventoryStockAlerts,
  VIDEO_MAP.inventoryBackBarTargets,
  VIDEO_MAP.inventoryIntroduction,
  VIDEO_MAP.inventoryAddingStock,
]

export const WEB_DASHBOARD_VIDEOS: VideoData[] = [
  VIDEO_MAP.addLines,
  VIDEO_MAP.addPricing,
  VIDEO_MAP.addingClients,
  VIDEO_MAP.inventoryOrders,
  VIDEO_MAP.runningReports,
]

export const MOBILE_APP_VIDEOS: VideoData[] = [
  VIDEO_MAP.remixAndDuplicateFormulas,
  VIDEO_MAP.creatingSessions,
  VIDEO_MAP.connectToScale,
  VIDEO_MAP.customerProfileAndDuplicateSession,
  VIDEO_MAP.manageSettingsInApp,
  VIDEO_MAP.updateProductsMobile,
]

export const SETUP_VIDEOS: VideoData[] = [
  VIDEO_MAP.addLines,
  VIDEO_MAP.addPricing,
  VIDEO_MAP.connectToScale,
  VIDEO_MAP.addingStylists,
]

export const TabTypes = {
  app_setup: 'app_setup',
  welcome_tour: 'welcome_tour',
  setup: 'setup',
  mobile_app: 'mobile_app',
  web_dashboard: 'web_dashboard',
}
