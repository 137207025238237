import { APIUpdateSession, Session, SessionItem, SessionStatus } from '../../../data/sessions/interfaces'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { getBowlTitle, getSessionItemsByBowl, getTotalSessionItemCost } from '../../../data/sessions/utils'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { reduceSetSelectedBowlId, selectSelectedBowlId } from '../../../data/sessions/slice'
import { COLORS } from '../../../mini-lib/theme/colors'
import { useAppSelector } from '../../../hooks'
import { SessionBowlSheetLegacy } from './SessionBowlSheetLegacy'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'

import { formatDollars } from "../../../mini-lib/units/money";

export const SessionBowlsLegacy = ( props: { session: Session; updateSession: ( u: Partial<APIUpdateSession>) => void }) => {
  const dispatch = useDispatch()
  const { session, updateSession } = props
  const [show, setShow] = useState(true)
  const { bowls, bowlIds } = getSessionItemsByBowl(session.sessionItems)
  const highestBowlId = bowlIds && bowlIds.length > 0 ? Math.max(...bowlIds) : 0
  const [newBowlId, setNewBowlId] = useState(highestBowlId + 1)
  const selectedBowlId = useAppSelector(selectSelectedBowlId)

  return (
    <>
      {!!selectedBowlId && <SessionBowlSheetLegacy session={session} updateSession={updateSession} />}
      <Flex
        minH="60px"
        borderRadius="8px"
        bg="brand.lavender.100"
        align="center"
        justify="space-between"
        p="24px"
        onClick={() => setShow(!show)}
        cursor="pointer"
      >
        <Text variant="title3">Color</Text>
        <Flex align="center" gridGap="24px">
          <Text>${formatDollars(session.totalColorCost)}</Text>
          <MaterialIcon pr="4px" name={show ? 'arrow_drop_down' : 'arrow_drop_up'} />
        </Flex>
      </Flex>
      {show && (
        <Box>
          {bowls.map((bowl, index) => {
            return <SessionBowl key={index} sessionStatus={session.status} bowl={bowl} />
          })}
          {bowls.length === 0 && <EmptyBox h="200px" />}
          {session.status === SESSION_STATUS_OPTIONS.open && (
            <Flex
              align="center"
              p="12px"
              onClick={() => {
                dispatch(reduceSetSelectedBowlId(newBowlId))
                setNewBowlId(newBowlId + 1)
              }}
              cursor="pointer"
            >
              <MaterialIcon colorhex={COLORS.lavender_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
              <Text color="brand.lavender.500">add bowl</Text>
            </Flex>
          )}
        </Box>
      )}
    </>
  )
}

export const SessionBowl = (props: { sessionStatus: SessionStatus; bowl: SessionItem[] }) => {
  const dispatch = useDispatch()
  const { sessionStatus, bowl } = props
  const { bowlType, bowlTag } = getBowlTitle(bowl[0])
  const bowlAmountTotal = getTotalSessionItemCost(bowl, 'amount')
  return (
    <Box
      onClick={() => {
        if (sessionStatus === SESSION_STATUS_OPTIONS.open) {
          dispatch(reduceSetSelectedBowlId(bowl[0].bowl))
        }
      }}
      cursor={sessionStatus === SESSION_STATUS_OPTIONS.open ? 'pointer' : ''}
      className="cy-session-color"
    >
      <Box p="24px 12px">
        <Box>{bowlType} {bowlType && bowlTag && '•'} {bowlTag}</Box>
        <Flex align="center" justify="space-between" gridGap="12px" pr="24px">
          <Flex align="center">
            <BowlFormula bowl={bowl} bowlView="amount" />
          </Flex>
          <Text>${formatDollars(bowlAmountTotal)}</Text>
        </Flex>
      </Box>
      <Divider />
    </Box>
  )
}

export const BowlFormula = (props: { bowlView: 'amount' | 'waste'; bowl: SessionItem[] }) => {
  const { bowl, bowlView } = props
  // many of our types have a + on the end of them, that is confusing here
  const cleanType = (type: string): string => (type.trim().slice(-1) === '+' ? type.slice(0, type.length - 1) : type)

  const text = bowl.reduce((text, item, index) => {
    const maybePlusSign = index + 1 !== bowl.length ? ' + ' : ''
    if (bowlView === 'waste') {
      return `${text} ${item.waste} ${item.displayUnit} ${cleanType(item.product.type)}${maybePlusSign}`
    }
    return `${text} ${item.amount} ${item.displayUnit} ${cleanType(item.product.type)}${maybePlusSign}`
  }, '')
  return (
    <Text variant={bowlView === 'waste' ? 'footnote' : ''} color="text.secondary">
      {text}
    </Text>
  )
}
