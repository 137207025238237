export const SALONSCALE_LOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/salonscale.appspot.com/o/images%2Fsalonscale-icon.png?alt=media&token=8c53eae3-72ed-4df7-88e6-6d8e863bbf85'
export const PAGE_CONTENT_NARROW_MAX_WIDTH = '720px'
export const PAGE_CONTENT_WIDE_MAX_WIDTH = '1200px'
export const MOBILE_IDENTIFIER_COLUMN_MAX_WIDTH = '245px'
export const SIDENAV_WIDTH = '256px'
export const INFONAV_WIDTH = '256px'
export const SHOW_INFONAV_AS_SHEET = 1250
export const DESKTOP_BREAKPOINT_PIXELS = 951
export const TABLET_BREAKPOINT_PIXELS = 721
export const DEFAULT_MARKUP = 0

export const INTERNAL_SALONS = {
  demo: 5, // demo@salonscale.com
  tech: 9945, // tech@salonscale.com
  willow: 5500, // demo+1@salonscale.com
  lindsey: 5501, // demo+2@salonscale.com
  trent: 5502, // demo+3@salonscale.com
  allInternal: [5, 5500, 5501, 5502],
  smallSalon: 4607,
}

export const INVENTORY_BETA_SALONS = [6906, 2343]
// [36, 6906, 4, 1475, 7, 3373, 8012, 13, 2343, 3688]
