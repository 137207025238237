import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import { APIUpdateSession, Session, SessionSupply } from '../../../data/sessions/interfaces'
import { reduceIsSupplySheetVisible, selectIsSupplySheetVisible } from '../../../data/sessions/slice'
import { assign, filter, find, findIndex } from 'lodash'
import { mapSessionSuppliesToAPISessionSupplies } from '../../../data/sessions/mappers'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { Supply } from '../../../data/supplies/interfaces'
import { SupplySelectSheetLegacy } from './SupplySelectSheetLegacy'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'

export const SuppliesSheetLegacy = ( props: { session: Session; updateSession: ( u: Partial<APIUpdateSession>) => void }) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const isSupplySheetVisible = useAppSelector(selectIsSupplySheetVisible)
  const { session, updateSession } = props
  const [updatedSupplies, setUpdatedSupplies] = useState(session.sessionSupplies)
  const [showSupplySheet, setShowSupplySheet] = useState(false)

  const updateSupplies = (supply: SessionSupply, paramsToUpdate: Partial<SessionSupply>) => {
    const updatedSessionSupply: SessionSupply = assign({}, supply, paramsToUpdate)
    const supplyIndex = findIndex(updatedSupplies, (item) => item.id === supply.id)
    const suppliesToUpdate: SessionSupply[] = [...updatedSupplies]
    if (supplyIndex >= 0) {
      suppliesToUpdate[supplyIndex] = updatedSessionSupply
    }
    setUpdatedSupplies(suppliesToUpdate)
  }

  const deleteSupply = (sessionSupply: Partial<SessionSupply>) => {
    if (sessionSupply && sessionSupply.supplyId) {
      const toUpdate = filter([...updatedSupplies], (ext) => ext.supplyId !== sessionSupply.supplyId)
      setUpdatedSupplies(toUpdate)
    }
  }
  const updateSelectedSupplies = (supplies: Supply[]) => {
    // note: session items that no longer have products associated with them will implicitly be deleted
    // because we use replace_session_items=true by default
    const updated: SessionSupply[] = []
    supplies.forEach((supply) => {
      const existingSessionSupplyForSupply: SessionSupply | undefined = find(
        updatedSupplies,
        (item) => item?.id === supply.id,
      )
      if (existingSessionSupplyForSupply) {
        updated.push(existingSessionSupplyForSupply)
      } else {
        const newSessionSupply: SessionSupply = {
          sessionId: session.id,
          supplyId: supply.id,
          name: supply.name,
          pricePerUnit: supply.priceDollars,
          quantity: 1,
        }
        updated.push(newSessionSupply)
      }
      setUpdatedSupplies(updated)
    })
  }

  const onSave = () => {
    const apiUpdatedSupplies = mapSessionSuppliesToAPISessionSupplies(updatedSupplies)
    updateSession({ supplies: apiUpdatedSupplies })
    dispatch(reduceIsSupplySheetVisible(false))
  }
  const selectedSupplyIds: number[] = updatedSupplies.map((item) => item.supplyId)
  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={isSupplySheetVisible}
      placement="right"
      onClose={() => dispatch(reduceIsSupplySheetVisible(false))}
    >
      {showSupplySheet && (
        <SupplySelectSheetLegacy
          selectedSupplyIds={selectedSupplyIds}
          show={showSupplySheet}
          onHide={() => setShowSupplySheet(false)}
          onSuppliesSelected={(supplies) => {
            updateSelectedSupplies(supplies)
            setShowSupplySheet(false)
          }}
        />
      )}
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <Text variant="title3">Edit Add-Ons</Text>
            <Flex align="center" gridGap="12px">
              <Button
                variant="round-ghost-upper"
                colorScheme="brand.linen"
                minW="100px"
                onClick={() => dispatch(reduceIsSupplySheetVisible(false))}
              >
                Cancel
              </Button>
              <Button colorScheme="brand.linen" variant="round" onClick={onSave} minW="100px">
                Save
              </Button>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <>
            <DisableAutofocus />
            <Box h="24px" />
            {updatedSupplies &&
              updatedSupplies.length > 0 &&
              updatedSupplies.map((supply, index) => {
                return (
                  <SupplyRow key={index} supply={supply} updateSupplies={updateSupplies} deleteSupply={deleteSupply} />
                )
              })}
            {updatedSupplies.length === 0 && <EmptyBox h="200px" />}
            <Box h="12px" />
            <Flex justify="flex-end">
              <Button variant="round-outline" colorScheme="brand.linen" onClick={() => setShowSupplySheet(true)}>
                <MaterialIcon name="add" style={{ marginRight: '4px' }} /> Supplies
              </Button>
            </Flex>
          </>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const SupplyRow = (props: {
  supply: Partial<SessionSupply>
  updateSupplies:
    | any
    | ((supply: Partial<SessionSupply>, paramsToUpdate: Partial<SessionSupply>) => void)
    | ((supply: SessionSupply, paramsToUpdate: Partial<SessionSupply>) => void)
  deleteSupply: (supply: Partial<SessionSupply>) => void
}) => {
  const { supply, updateSupplies, deleteSupply } = props
  return (
    <Flex key={supply.id} align="center" justify="space-between" p="0 0 24px 0" cursor="pointer">
      <Box>
        <Text variant="title3">{supply?.name}</Text>
        <Text color="text.secondary">{supply?.name}</Text>
      </Box>
      <Flex align="center" gridGap="12px">
        <Box>
          <Text color="text.secondary">Amount</Text>
          <NumberInput
            maxW={32}
            defaultValue={supply.quantity || 0}
            min={0}
            onChange={(updatedAmount) => updateSupplies(supply, { quantity: parseInt(updatedAmount) })}
            borderRadius="100px"
          >
            <NumberInputField borderRadius="100px" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <MaterialIcon mt="16px" name="delete" onClick={() => deleteSupply(supply)} />
      </Flex>
    </Flex>
  )
}
