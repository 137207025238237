import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import React, { useState } from 'react'
import { SalonMember } from '../../data/user/interfaces'
import { getMembersForTier } from '../../data/labor/utils'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUser } from '../../data/user/slice'
import { APIUserLaborTier, LaborTier } from '../../data/labor/interfaces'
import { dispatchDeleteUserTiers, dispatchUpdateUserTiers } from '../../data/user/api'
import { filterSalonMembers } from '../../data/user/utils'
import { UseViewSize } from '../../core/UseViewSize'
import { ColorForMode } from '../../theme'

export const LaborTierUsers = (props: { tier: LaborTier; salonMembers: SalonMember[] }) => {
  const { tier, salonMembers } = props
  const salonMembersInTier: SalonMember[] = salonMembers ? getMembersForTier(tier.id, salonMembers) : []
  const dispatch = useDispatch()
  const params: any = useParams()
  const { salonId } = params
  const user = useAppSelector(selectLoggedInUser)
  const [searchText, setSearchText] = useState('')
  const { isMobile } = UseViewSize()

  const removeFromTier = (member: SalonMember) => {
    if (user) {
      dispatch(
        dispatchDeleteUserTiers({ token: user.token, user_id: user.userId, salon_id: salonId, models: [member] }),
      )
    }
  }
  const addToTier = (member: SalonMember) => {
    const models: APIUserLaborTier[] = [{ user_id: member.userId, tier_id: tier.id }]
    if (user) {
      dispatch(dispatchUpdateUserTiers({ token: user.token, user_id: user.userId, salon_id: salonId, models: models }))
    }
  }
  const salonMembersInTierIds = salonMembersInTier.map((member) => member.userId)
  const membersNotInTier = salonMembers.filter((member) => !salonMembersInTierIds.includes(member.userId))
  const filteredMembers = filterSalonMembers(membersNotInTier, searchText)
  const baseText = ColorForMode('base-text')
  return (
    <Popover>
      <PopoverTrigger>
        <AvatarGroup size="xs" max={2} cursor="pointer" spacing="-6px">
          {!isMobile &&
            salonMembersInTier.map((member) => {
              return (
                <Avatar
                  key={member.userId}
                  size="sm"
                  mr="8px"
                  bg="brand.lavender.400"
                  color="white"
                  name={`${member.firstName} ${member.lastName}`}
                  src={member.photoUrl}
                />
              )
            })}
          {(isMobile || salonMembersInTier.length === 0) && <Avatar />}
        </AvatarGroup>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Box h="24px" />
          {salonMembersInTier.map((member) => {
            return (
              <Box
                cursor="pointer"
                key={member.userId}
                bg="brand.lavender.100"
                w="200px"
                mb="8px"
                borderRadius="50px"
                onClick={() => removeFromTier(member)}
              >
                <Flex align="center" justify="space-between" pr="4px">
                  <Flex align="center" isTruncated={true} maxW="185px">
                    <Avatar
                      size="sm"
                      mr="8px"
                      bg="brand.lavender.400"
                      color="white"
                      name={`${member.firstName} ${member.lastName}`}
                      src={member.photoUrl}
                    />
                    {member.firstName} {member.lastName}
                  </Flex>
                  <MaterialIcon name="close" color="brand.lavender.500" />
                </Flex>
              </Box>
            )
          })}
          <Box mt="24px">
            <Search searchText={searchText} setSearchText={setSearchText} />
            <Box mt="24px" p="0 12px" h="200px" overflowX="scroll">
              {filteredMembers.map((member) => {
                return (
                  <Flex justify="space-between" align="center" key={member.userId}>
                    <Box isTruncated={true} maxW="145px" mb="8px">
                      <Box>
                        <Text color={baseText}>
                          {member.firstName} {member.lastName}
                        </Text>
                      </Box>
                      {member?.laborTier?.name && (
                        <Box fontSize="sm" color="shades.neutral.500">
                          {member.laborTier.name}
                        </Box>
                      )}
                      {!member?.laborTier?.name && (
                        <Box fontSize="sm" color="shades.neutral.500">
                          no tier
                        </Box>
                      )}
                    </Box>
                    <Flex align="center">
                      <Button variant="ghost" colorScheme="brand.lavender" onClick={() => addToTier(member)}>
                        <MaterialIcon name="add_circle" style={{ marginRight: '4px' }} />
                        add
                      </Button>
                    </Flex>
                  </Flex>
                )
              })}
            </Box>
            {(!filteredMembers || filteredMembers.length === 0) && (
              <Flex m="12px 0" justify="center" color="brand.lavender.400">
                No Stylists found
              </Flex>
            )}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const Search = (props: { searchText: string; setSearchText: (string) => void }) => {
  const { searchText, setSearchText } = props
  return (
    <InputGroup>
      <Input
        onChange={(evt) => {
          setSearchText(evt.target.value)
        }}
        placeholder="Enter a name"
        value={searchText}
      />
    </InputGroup>
  )
}
