import { filter } from "lodash";
import { SalonMember } from "../../data/user/interfaces";

export const filterSalonMembers = (members: SalonMember[], searchText: string): SalonMember[] => {
  const normalizedText = searchText.toLowerCase()
  return filter(members, member => {
    if (member.firstName.toLowerCase().includes(normalizedText)) {return true}
    if (member.lastName.toLowerCase().includes(normalizedText)) {return true}
    if (member.email.toLowerCase().includes(normalizedText)) {return true}
    return false
  })
}
