/////////////// mappers.ts
//
//

// todos: copy paste Session and session

import { map, round } from 'lodash'
import {
  APIBowlType,
  APIBowlTypeLara,
  APISession,
  APISessionColorLara,
  APISessionExtensionLara,
  APISessionItem,
  APISessionLaborItem,
  APISessionMetaLara,
  APISessionPhotoLara,
  APISessionSupply,
  APISessionSupplyLara,
  APIUpdateSessionItem,
  BowlType,
  BowlTypeLara,
  Session,
  SessionColorLara,
  SessionExtensionLara,
  SessionItem,
  SessionLaborItem,
  SessionMetaLara,
  SessionPhotoLara,
  SessionSupply,
  SessionSupplyLara,
} from './interfaces'
import { mapAPIColorLaraToColorLara, mapAPIProductToProduct } from '../products/mappers'
import { mapAPIClientPhotosToClientPhotos, mapAPIClientToClient } from '../clients/mappers'
import { mapAPIAssistantToSalonMember, mapAPIStylistToSalonMember } from '../user/mappers'
import {
  getTotalSessionExtensionCost,
  getTotalSessionItemCost,
  getTotalSessionLaborItemsCost,
  getTotalSessionSuppliesCost,
} from './utils'
import { centsToDollars, dollarsToCents } from '../../core/money/utils'
import { buildDateFromServer } from "../../core/dates";

export const mapAPISessionToSession = ( api: APISession ): Session => {
  const mappedSession: any = {
    id: api.id,
    client: mapAPIClientToClient(api.client),
    date: api.date,
    completedDate: api.completed_date,
    isDeleted: api.is_deleted,
    notes: api.notes,
    sessionItems: mapAPISessionItemsToSessionItems(api.session_items),
    sessionExtensions: mapAPISessionItemsToSessionItems(api.extensions),
    sessionSupplies: mapAPISessionSuppliesToSessionSupplies(api.supplies),
    sessionLaborItems: mapAPISessionLaborItemsToSessionLaborItems(api.labor_items),
    stylist: mapAPIStylistToSalonMember(api.stylist),
    assistant: api.assistant ? mapAPIAssistantToSalonMember(api.assistant) : undefined,
    clientPhotos: mapAPIClientPhotosToClientPhotos(api.client_photos),
    status: api.status,
  }
  const mappedSessionWithTotals: Session = {
    ...mappedSession,
    totalColorCost: getTotalSessionItemCost(mappedSession.sessionItems),
    totalExtensionsCost: getTotalSessionExtensionCost(mappedSession),
    totalSuppliesCost: getTotalSessionSuppliesCost(mappedSession),
    totalLaborItemsCost: getTotalSessionLaborItemsCost(mappedSession),
  }
  const totalSessionCost =
    mappedSessionWithTotals.totalColorCost +
    mappedSessionWithTotals.totalExtensionsCost +
    mappedSessionWithTotals.totalSuppliesCost +
    mappedSessionWithTotals.totalLaborItemsCost
  return { ...mappedSessionWithTotals, totalSessionCost: Math.round(totalSessionCost * 100) / 100 }
}
export const mapAPISessionToSessions = ( apiModels: APISession[] ): Session[] => {
  return map(apiModels, mapAPISessionToSession)
}

export const mapAPISessionItemToSessionItem = ( api: APISessionItem ): SessionItem => {
  return {
    id: api.id,
    amount: api.amount,
    waste: api.waste,
    bowl: api.bowl,
    userDefinedBowlName: api.user_defined_bowl_name,
    bowlType: api.bowl_type ? mapAPIBowlTypeToBowlType(api.bowl_type) : undefined,
    product: mapAPIProductToProduct(api.product),
    displayUnit: api.display_unit,
  }
}
export const mapAPISessionItemsToSessionItems = ( api: APISessionItem[] ): SessionItem[] => {
  return map(api, mapAPISessionItemToSessionItem)
}

export const mapSessionItemToAPISessionItem = ( item: SessionItem ): APIUpdateSessionItem => {
  return {
    id: item.id,
    product_id: item.product.id,
    amount: item.amount,
    waste: item.waste,
    bowl: item.bowl,
    bowl_type: item.bowlType?.id ?? null,
    user_defined_bowl_name: item.userDefinedBowlName ?? null,
    display_unit: item.displayUnit,
    method_of_entry: 'manual',
  }
}
export const mapSessionItemsToAPISessionItems = ( api: SessionItem[] ): APIUpdateSessionItem[] => {
  return map(api, mapSessionItemToAPISessionItem)
}

export const mapAPISessionSupplyToSessionSupply = ( api: APISessionSupply ): SessionSupply => {
  return {
    id: api.id,
    sessionId: api.session_id,
    supplyId: api.supply_id,
    name: api.name,
    pricePerUnit: centsToDollars(api.price_per_unit),
    quantity: api.quantity,
  }
}
export const mapAPISessionSuppliesToSessionSupplies = ( api: APISessionSupply[] ): SessionSupply[] => {
  return map(api, mapAPISessionSupplyToSessionSupply)
}

export const mapSessionSupplyToAPISessionSupply = ( api: SessionSupply ): APISessionSupply => {
  return {
    id: api.id,
    session_id: api.sessionId,
    supply_id: api.supplyId,
    name: api.name,
    price_per_unit: dollarsToCents(api.pricePerUnit),
    quantity: api.quantity,
  }
}
export const mapSessionSuppliesToAPISessionSupplies = ( api: SessionSupply[] ): APISessionSupply[] => {
  return map(api, mapSessionSupplyToAPISessionSupply)
}

export const mapAPISessionLaborItemToSessionLaborItem = ( api: APISessionLaborItem ): SessionLaborItem => {
  return {
    id: api.id,
    sessionId: api.session_id,
    laborTierServiceId: api.labor_tier_service_id,
    type: api.type,
    name: api.name,
    pricePerHour: api.price_per_hour,
    durationSeconds: api.duration_seconds,
  }
}
export const mapAPISessionLaborItemsToSessionLaborItems = ( api: APISessionLaborItem[] ): SessionLaborItem[] => {
  return map(api, mapAPISessionLaborItemToSessionLaborItem)
}

export const mapSessionLaborItemToAPISessionLaborItem = ( api: SessionLaborItem ): APISessionLaborItem => {
  return {
    id: api.id,
    session_id: api.sessionId,
    labor_tier_service_id: api.laborTierServiceId,
    type: api.type,
    name: api.name,
    price_per_hour: api.pricePerHour,
    duration_seconds: api.durationSeconds,
  }
}
export const mapSessionLaborItemsToAPISessionLaborItems = ( api: SessionLaborItem[] ): APISessionLaborItem[] => {
  return map(api, mapSessionLaborItemToAPISessionLaborItem)
}

export const mapAPIBowlTypeToBowlType = ( api: APIBowlType ): BowlType => {
  return {
    bowltypeId: api.bowltype_id,
    id: api.id,
    name: api.name,
  }
}
export const mapAPIBowlTypesToBowlTypes = ( api: APIBowlType[] ): BowlType[] => {
  return map(api, mapAPIBowlTypeToBowlType)
}


// lara sessions
//
//
//
//
export const mapAPISessionLaraToSessionLara = ( api: APISessionMetaLara ): SessionMetaLara => {
  return {
    id: api.id,
    client: {
      id: api.client.id,
      firstName: api.client.first_name,
      lastName: api.client.last_name,
      middleName: api.client.middle_name,
      phone: api.client.phone,
      sessionCount: api.client.session_count,
      note: api.client.note,
    },

    date: buildDateFromServer(api.date),
    dateInTz: api.date_in_tz,
    notes: api.notes,
    user: {
      id: api.user.id,
      userId: api.user.user_id,
      fullName: api.user.full_name,
      firstName: api.user.first_name,
      lastName: api.user.last_name,
      photoUrl: api.user.user_photo_url || '',
    },
    assistant: api.assistant ? {
      id: api.assistant.id,
      userId: api.assistant.user_id,
      fullName: api.assistant.full_name,
      firstName: api.assistant.first_name,
      lastName: api.assistant.last_name,
      photoUrl: api.user.user_photo_url || '',
    } : null,
    status: api.status,
    completedDate: api.completed_date,
    completedDateInTz: api.completed_date_in_tz,
    totalClientPurchasePriceSessionColorItemsCents: api.total_client_purchase_price_session_color_items,
    totalClientPurchasePriceSessionExtensionItemsCents: api.total_client_purchase_price_session_extension_items,
    totalClientPurchasePriceSessionLaborItemsCents: api.total_client_purchase_price_session_labor_items,
    totalClientPurchasePriceSessionSupplyCents: api.total_client_purchase_price_session_supply,
    totalClientPurchasePriceCents: api.total_client_purchase_price,
  };
};
export const mapAPISessionsLaraToSessionsLara = ( api: APISessionMetaLara[] ): SessionMetaLara[] => {
  return map(api, mapAPISessionLaraToSessionLara)
}
export const mapAPISessionSupplyLaraToSessionSupplyLara = ( api: APISessionSupplyLara ): SessionSupplyLara => {
  return {
    id: api.id,
    name: api.name,
    priceCents: api.price,
    quantity: api.quantity,
    supply: {
      id: api.supply.id,
      name: api.supply.name,
      priceCents: api.supply.price
    }
  }
}
export const mapAPISessionSuppliesLaraToSessionSuppliesLara = ( api: APISessionSupplyLara[] ): SessionSupplyLara[] => {
  return map(api, mapAPISessionSupplyLaraToSessionSupplyLara)
}

export const mapAPISessionExtensionLaraToSessionExtensionLara = ( api: APISessionExtensionLara ): SessionExtensionLara => {
  return {
    id: api.id,
    sessionId: api.session_id,
    product: mapAPIColorLaraToColorLara(api.product),
    salonPurchasePrice: api.salon_purchase_price_per_unit,
    clientPurchasePrice: api.client_purchase_price_per_unit,
    quantity: api.quantity ? parseFloat(api.quantity) : 0,
  }
}
export const mapAPISessionExtensionsLaraToSessionExtensionsLara = ( api: APISessionExtensionLara[] ): SessionExtensionLara[] => {
  return map(api, mapAPISessionExtensionLaraToSessionExtensionLara)
}

export const mapAPIBowlTypeLaraToBowlTypeLara = ( api: APIBowlTypeLara ): BowlTypeLara => {
  return {
    bowltypeId: api.bowltype_id,
    id: api.id,
    name: api.name,
  }
}
export const mapAPISessionColorLaraToSessionColorLara = ( api: APISessionColorLara ): SessionColorLara => {
  return {
    id: api.id,
    sessionId: api.session_id,
    product: mapAPIColorLaraToColorLara(api.product),
    clientTotalPriceCents: api.amount_grams ? round(api.client_purchase_price_per_gram * parseFloat(api.amount_grams),2) : 0,
    wasteTotalPriceCents: api.waste_grams ? round(api.client_purchase_price_per_gram * parseFloat(api.waste_grams), 2) : 0,
    salonPurchasePrice: api.salon_purchase_price_per_gram,
    clientPurchasePrice: api.client_purchase_price_per_gram,
    bowlType: api.bowl_type ? mapAPIBowlTypeLaraToBowlTypeLara(api.bowl_type) : null,
    bowlNickname: api.bowl_nickname,
    amountGrams: api.amount_grams ? parseFloat(api.amount_grams) : 0,
    targetAmountGrams: api.target_amount_grams ? parseFloat(api.target_amount_grams) : null,
    wasteGrams: api.waste_grams ? parseFloat(api.waste_grams) : null,
    bowl: api.bowl,
    methodOfEntry: api.method_of_entry,
    displayUnit: api.display_unit,
  }
}
export const mapAPISessionColorsLaraToSessionColorsLara = ( api: APISessionColorLara[] ): SessionColorLara[] => {
  return map(api, mapAPISessionColorLaraToSessionColorLara)
}

export const mapAPISessionPhotoLaraToSessionPhotoLara = ( api: APISessionPhotoLara ): SessionPhotoLara => {
  return {
    id: api.id,
    photoUrl: api.photo_url,
    photoTag: api.photo_tag,
    storageObject: api.storage_object,
  }
}
export const mapAPISessionPhotosLaraToSessionPhotosLara = ( api: APISessionPhotoLara[] ): SessionPhotoLara[] => {
  return map(api, mapAPISessionPhotoLaraToSessionPhotoLara)
}
