import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { buttonStyles } from '../../theme'
import { COLORS } from '../theme/colors'

export const ConfirmPopover = (props: {
  children: any
  title: string
  subtitle: string
  onConfirm: () => void
  confirmText?: string
  showCancel?: boolean
  showPopover?: boolean
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const {
    showPopover = true,
    onConfirm,
    children,
    title,
    subtitle,
    confirmText = 'Yes, Remove',
    showCancel = true,
  } = props
  return (
    <>
      {showPopover ? (
        <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Flex p="36px 0 24px" direction="column">
                <Text align="center" p="0 0 24px 0" fontSize="xl">
                  {title}
                </Text>
                <Text align="center" p="0 0 24px 0" fontSize="sm">
                  {subtitle}
                </Text>
                {/* note: these 'buttons' are done using flexboxes to prevent dom issues involving button in a button errors */}
                {/* eg: if you have a button on an accordion and you've wrapped it with this popover
                        you are clicking a button on a button on a button and the dom does not know which events to fire
                        here we say 'fire the onClose event and then stop propagating that something was clicked
                        that way the accordion doesnt open when all we wanted to do was click a button on top of it
                */}
                <Flex justify="center" gridGap="12px">
                  {showCancel && (
                    <Flex
                      style={buttonStyles({})}
                      background={'white !important'}
                      color={COLORS.lavender_500 + '!important'}
                      border={`1px solid ${COLORS.shades_neutral_300}`}
                      onClick={(evt) => {
                        onClose()
                        evt.stopPropagation()
                      }}
                    >
                      No, Cancel
                    </Flex>
                  )}
                  <Flex
                    style={buttonStyles({})}
                    background={COLORS.lavender_500 + '!important'}
                    color={'white !important'}
                    onClick={(evt) => {
                      evt.stopPropagation()
                      onConfirm()
                      onClose()
                    }}
                  >
                    {confirmText}
                  </Flex>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : (
        children
      )}
    </>
  )
}
