import { Flex, Tab, TabList, Tabs } from '@chakra-ui/react'
import { UseViewSize } from '../../../core/UseViewSize'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { COLORS } from '../../../mini-lib/theme/colors'
import { ReportActions } from '../ReportActions'
import { ROUTES } from "../../../appRoutes";

export const COLOR_BRAND_DETAILS_REPORTS_TABS = {
  usedColors: 0,
  unusedColors: 1,
  wasteColors: 2,
}
export const ColorLineDetailsReportsTabs = (props: { selectedTab: number; onCsvExport: () => void }) => {
  const { selectedTab, onCsvExport } = props
  const { isMobile, isTablet } = UseViewSize()
  const history = useHistory()
  const { salonId } = UseBaseApiParams()
  const { brand }: any = useParams()
  const navigateToTab = (newIndex: number) => {
    if (newIndex === 0) {
      history.push(generatePath(ROUTES.colorBrandUsedProductReports, { salonId, brand }))
    } else if (newIndex === 1) {
      history.push(generatePath(ROUTES.colorBrandUnusedProductReports, { salonId, brand }))
    } else if (newIndex === 2) {
      history.push(generatePath(ROUTES.colorLineWasteProductsReports, { salonId, brand }))
    }
  }
  return (
    <Flex
      w="100%"
      direction={isMobile || isTablet ? 'column-reverse' : 'row'}
      justify="space-between"
      borderBottom={`1px solid ${COLORS.shades_neutral_300}`}
      pb="4px"
    >
      <Tabs index={selectedTab} onChange={(index) => navigateToTab(index)} colorScheme="brand.lavender" border="none">
        <TabList overflowX="auto" padding="4px" border="none">
          <Tab minW={isMobile ? '60px' : '200px'}>{isMobile ? 'Used' : 'Used Products'}</Tab>
          <Tab minW={isMobile ? '60px' : '200px'}>{isMobile ? 'Unused' : 'Unused Products'}</Tab>
          <Tab minW={isMobile ? '60px' : '200px'}>{isMobile ? 'Waste' : 'Waste Products'}</Tab>
        </TabList>
      </Tabs>
      <ReportActions onCsvExport={onCsvExport} />
    </Flex>
  )
}
