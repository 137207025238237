import { Session } from '../../data/sessions/interfaces'
import { ClientPhoto } from '../../data/clients/interfaces'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { isImageType } from '../../core/validators'
import { dispatchDeleteSessionPhoto, dispatchUpdateSessionPhoto } from '../../data/sessions/api'
import { toast } from 'react-toastify'
import { FileUpload } from '../../mini-lib/file-upload/FileUpload'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import React, { useState } from 'react'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import { SESSION_STATUS_OPTIONS } from '../../data/sessions/constants'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'

export const SessionPhotosLegacy = ( props: { session: Session }) => {
  const { session } = props
  const beforePhotos: ClientPhoto[] = []
  const afterPhotos: ClientPhoto[] = []
  session.clientPhotos.forEach((photo) => {
    if (photo.photoTag === 'before') {
      beforePhotos.push(photo)
    } else {
      afterPhotos.push(photo)
    }
  })
  return (
    <Box>
      <Text variant="title3">Photos</Text>
      <Box h="24px" />
      <PhotoDisplay title="before" photos={beforePhotos} session={session} />
      <Box h="24px" />
      <PhotoDisplay title="after" photos={afterPhotos} session={session} />
    </Box>
  )
}

const photoSize = { height: '150px', width: '100px' }

export const PhotoDisplay = (props: { title: string; photos: ClientPhoto[]; session: Session }) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const { title, photos, session } = props
  const onFileUpload = (file) => {
    if (file && isImageType(file.type)) {
      dispatch(
        dispatchUpdateSessionPhoto({
          token: user.token,
          salon_id: salonId,
          user_id: user.userId,
          session_id: session.id,
          client_photo: file,
          photo_tag: title,
          session,
        }),
      )
    } else {
      toast.error('Please enter a valid image')
    }
  }

  return (
    <Box>
      <Text variant="caption2">{title}</Text>
      <Box h="12px" />
      {session.status === SESSION_STATUS_OPTIONS.open ? (
        <Flex gridGap="12px" align="center" flexWrap="wrap">
          <FileUpload
            onFileUpload={onFileUpload}
            override={
              <Flex
                borderRadius="15px"
                bg="shades.neutral.200"
                h={photoSize.height}
                w={photoSize.width}
                justify="center"
                align="center"
              >
                <MaterialIcon name="add_a_photo" />
              </Flex>
            }
          />
          {photos.map((photo) => (
            <SessionPhoto key={photo.id} photo={photo} session={session} />
          ))}
        </Flex>
      ) : (
        <EmptyBox title="No Photos" content="nothing to display" h={photoSize.height} />
      )}
    </Box>
  )
}

export const SessionPhoto = (props: { photo: ClientPhoto; session: Session }) => {
  const dispatch = useDispatch()
  const [hover, setHover] = useState(false)
  const { user, salonId } = UseBaseApiParams()
  const { photo, session } = props

  return (
    <Box
      width={photoSize.width}
      height={photoSize.height}
      position="relative"
      borderRadius="15px"
      bg="shades.neutral.200"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        alt="client"
        style={{
          width: photoSize.width,
          height: photoSize.height,
          objectFit: 'contain',
          borderRadius: '15px',
        }}
        src={photo.photoUrl}
      />
      {hover && (
        <ConfirmPopover
          title={`This Action is Permanent`}
          subtitle={`This photo will be removed from this session`}
          onConfirm={() => {
            dispatch(
              dispatchDeleteSessionPhoto({
                token: user.token,
                user_id: user.userId,
                salon_id: salonId,
                photo,
                session,
              }),
            )
          }}
        >
          <Button position="absolute" bottom="0" borderBottomRightRadius="15px" borderBottomLeftRadius="15px" w="100%">
            Delete
          </Button>
        </ConfirmPopover>
      )}
    </Box>
  )
}
