import { Box, Button, Divider, Flex, NumberInput, NumberInputField, Select, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { DisplayUnit, SessionItem } from '../../../data/sessions/interfaces'
import { UseViewSize } from '../../../core/UseViewSize'
import { values } from 'lodash'
import { BOWL_SHEET_VIEW_TYPES, BowlSheetViewType, DISPLAY_UNIT_OPTIONS } from '../../../data/sessions/constants'
import { EmptyBowl } from '../../../data/empty-bowls/interfaces'
import { COLORS } from '../../../mini-lib/theme/colors'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { convertDisplayUnits, normalizeToGrams } from '../../../data/sessions/utils'
import { FormikError } from "../../../mini-lib/formik-utils/FormikError";

export const SessionBowlWasteViewLegacy = ( props: {
  onSaveWaste: () => void
  emptyBowl: EmptyBowl | null
  setBowlView: (view: BowlSheetViewType) => void
  updatedBowl: SessionItem[]
  totalBowlAmount: number
  wasteTotal: string
  setWasteTotal: (total: string) => void
  emptyBowlWeight: string
  setEmptyBowlWeight: (newWeight: string) => void
  isDisabled: boolean
}) => {
  const {
    onSaveWaste,
    setBowlView,
    emptyBowl,
    updatedBowl,
    totalBowlAmount,
    wasteTotal,
    setWasteTotal,
    emptyBowlWeight,
    setEmptyBowlWeight,
    isDisabled,
  } = props

  useEffect(() => {
    return () => {
      if (emptyBowl?.id) {
        setSelectEmptyBowlErrorText('')
      }
    }
  }, [emptyBowl?.id])
  const units = updatedBowl[0].displayUnit
  const [selectEmptyBowlErrorText, setSelectEmptyBowlErrorText] = useState('')
  const normalizedWasteTotal = normalizeToGrams(parseFloat(wasteTotal), units)
  const normalizedTotalBowlAmount = normalizeToGrams(totalBowlAmount, units)
  const wasteWeightErrorText =
    normalizedWasteTotal - parseFloat(emptyBowlWeight) > normalizedTotalBowlAmount
      ? `Waste weight (${
          normalizedWasteTotal - parseFloat(emptyBowlWeight)
        }${units}) cannot exceed original color weight (${normalizedTotalBowlAmount}${units})`
      : ''
  const emptyBowlWeightErrorText =
    !!emptyBowl && emptyBowl.amountInGrams > normalizedWasteTotal
      ? `Your waste weight (${normalizedWasteTotal}${units}) is less than your bowl weight (${emptyBowlWeight}${units})`
      : ''
  return (
    <>
      <Flex justify="space-between" gridGap="12px">
        <MaterialIcon
          cursor="pointer"
          colorhex={COLORS.lavender_500}
          size="36px"
          name="chevron_left"
          onClick={() => setBowlView(BOWL_SHEET_VIEW_TYPES.amount)}
        />
        <Button
          colorScheme="brand.midnight"
          variant="round"
          onClick={() => {
            if (!!emptyBowl) {
              onSaveWaste()
            } else {
              setSelectEmptyBowlErrorText('Please select or create an empty bowl to continue')
            }
          }}
          minW="100px"
          disabled={!!wasteWeightErrorText || !!emptyBowlWeightErrorText || isDisabled}
        >
          Calculate Waste
        </Button>
      </Flex>
      <Box h="12px" />
      <Text variant="largetitle" textTransform="uppercase">
        Record Waste
      </Text>
      <Box h="12px" />
      <WasteRow
        units={units}
        bowlItems={updatedBowl}
        wasteTotal={wasteTotal}
        setWasteTotal={setWasteTotal}
        isDisabled={isDisabled}
      />
      {!isDisabled && (
        <>
          <EmptyBowlRow
            units={units}
            emptyBowlWeight={emptyBowlWeight}
            setEmptyBowlWeight={setEmptyBowlWeight}
            emptyBowl={emptyBowl}
            setBowlView={setBowlView}
            isDisabled={isDisabled}
          />
          <FormikError showError={!!emptyBowlWeightErrorText} errorText={emptyBowlWeightErrorText} />
          <FormikError showError={!!wasteWeightErrorText} errorText={wasteWeightErrorText} />
          <FormikError showError={!!selectEmptyBowlErrorText} errorText={selectEmptyBowlErrorText} />
        </>
      )}
    </>
  )
}

export const WasteRow = (props: {
  bowlItems: SessionItem[]
  units: DisplayUnit
  wasteTotal: string
  setWasteTotal: (updated: string) => void
  isDisabled: boolean
}) => {
  const { isMobile } = UseViewSize()
  const { units, wasteTotal, setWasteTotal, bowlItems, isDisabled } = props
  const bowlName = bowlItems[0].bowlType?.name || `Bowl ${bowlItems[0].bowl}`
  return (
    <>
      <Box h="12px" />
      <Flex
        align={isMobile ? 'start' : 'center'}
        justify="space-between"
        p="0 0 12px 0"
        cursor="pointer"
        gridGap="12px"
        direction={isMobile ? 'column' : 'row'}
      >
        <Box maxW="60%" w="200px">
          <Text variant={isMobile ? 'title' : 'title3opensans'}>{bowlName}</Text>
          <Text color="text.secondary">
            {/* if they save and come look at this page we are just showing the waste weight not the bowl + waste */}
            {/* bowl weight is not saved and is only available at the time of waste calculation not later */}
            {isDisabled ? 'weight of waste' : 'weight of bowl with waste'}
          </Text>
        </Box>
        <Flex align="center" justify="end" gridGap="12px">
          <Box>
            <Text color="text.secondary">Amount</Text>
            <NumberInput
              isDisabled={isDisabled}
              w="120px"
              value={wasteTotal}
              inputMode="decimal"
              precision={2}
              min={0}
              onChange={(updatedAmount) => {
                setWasteTotal(updatedAmount)
              }}
              borderRadius="100px"
            >
              <NumberInputField borderRadius="100px" />
            </NumberInput>
          </Box>
          <Box>
            <Text color="text.secondary">Units</Text>
            <Select disabled={true} defaultValue={units} borderRadius="100px" w="120px">
              {values(DISPLAY_UNIT_OPTIONS).map((option: any, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
            </Select>
          </Box>
        </Flex>
      </Flex>
      <Box h="12px" />
    </>
  )
}

export const EmptyBowlRow = (props: {
  emptyBowl: EmptyBowl | null
  emptyBowlWeight: string
  setEmptyBowlWeight: (updated: string) => void
  units: DisplayUnit
  setBowlView: (view: BowlSheetViewType) => void
  isDisabled: boolean
}) => {
  const { isMobile } = UseViewSize()
  const { setBowlView, emptyBowl, isDisabled, units } = props

  // todo: possibly use display unit instead of defaulting to grams here
  return (
    <>
      <Box h="12px" />

      <Box maxW="60%" w="200px">
        <Text variant={isMobile ? 'title' : 'title3opensans'}>Empty Bowl</Text>
      </Box>
      <Box h="12px" />

      <>
        <Flex
          justify="space-between"
          cursor="pointer"
          align="center"
          onClick={() => !isDisabled && setBowlView(BOWL_SHEET_VIEW_TYPES.emptyBowls)}
        >
          <Text>{emptyBowl ? emptyBowl.name : 'Select Empty Bowl'}</Text>
          <Flex align="center" gridGap="12px">
            {emptyBowl && (
              <Text>
                {convertDisplayUnits(emptyBowl.amountInGrams, 'g', units)}
                {units}
              </Text>
            )}
            <MaterialIcon
              colorhex={isDisabled ? COLORS.shades_neutral_400 : COLORS.lavender_500}
              size="36px"
              name="chevron_right"
            />
          </Flex>
        </Flex>
        <Box h="12px" />
        <Divider />
      </>
      <Box h="12px" />
    </>
  )
}
