import { APIOrderCreateItem, OrderLara, OrderProduct } from './interfaces'
import { values } from 'lodash'
import { Product } from '../products/interfaces'
import { endOfDay, startOfDay } from "date-fns";

export const ORDER_PDF = 'creating-order-pdf'


export const getOrderStatusColor = (status: string): string => {
  // todo, flags the colors at a future date
  return '#9c9c9c'
  // if (status === ORDER_STATUS.draft) {
  //   return '#8885E7'
  // }
  // if (status === ORDER_STATUS.pending) {
  //   return '#8885E7'
  // }
  // if (status === ORDER_STATUS.complete) {
  //   return '#9c9c9c'
  // }
  // return '#9c9c9c'
}

export const calculateOrderTotal = (orderQuantitiesByProductId: { [key: string]: APIOrderCreateItem }): number => {
  let total = 0
  values(orderQuantitiesByProductId).forEach((item) => {
    total += item.order_item_quantity * item.estimated_unit_cost
  })
  return Math.round(total * 100) / 100
}

// amount needed to get the product’s quantity on hand to the target
export const getProductQuantityNeededToReachProductTarget = (product: Product): number => {
  const quantityNeeded = product.inventory.maxStockLevel
    ? Math.ceil(product.inventory.maxStockLevel - product.inventory.quantityOnHand)
    : 0
  return quantityNeeded
}

export const getProductsForSelectedLinesforOrderProducts = (
  // lines coulid be name or id
  lines: string[] | number[] | null,
  productsByLineName: { [key: string]: OrderProduct[] } | null,
): OrderProduct[] => {
  const allProducts: OrderProduct[] | null = []
  lines?.forEach((name) => {
    const products = productsByLineName ? productsByLineName[name] : []
    allProducts.push(...products)
  })
  return allProducts
}

// line ids are strings because it's iterating over a map and the map coerces all indexes into strings
export const getProductsForSelectedLinesIds = (
  lineIds: string[],
  productsByLineName: { [key: string]: Product[] } | null,
): Product[] => {
  const allProducts: Product[] | null = []
  lineIds?.forEach((name) => {
    const products = productsByLineName ? productsByLineName[name] : []
    allProducts.push(...products)
  })
  return allProducts
}

export const getProductsWithMaxStockLevel = (productsList: Product[]) => {
  return productsList?.filter((e) => e.inventory.maxStockLevel && e.inventory.maxStockLevel > 0);
}

export const filterOrdersLara = (
  orders: OrderLara[],
  productsById: {[key: string]: Product},
  searchText: string,
  startDate: Date | null,
  endDate: Date | null,
): OrderLara[] => {
  const normalizedSearchText = searchText.toLowerCase()
  const filteredOrders: OrderLara[] = []
  orders.forEach((order) => {
    let matchesSearchText = searchText === '' // default to true if no searchtext
    let matchesDateRange = false // default to true if no start date or no end date
    order.orderItems.forEach((orderItem) => {
      const product = productsById[orderItem.productId]
      // search text checks
      if (product?.line.name.toLowerCase().includes(normalizedSearchText)) {
        matchesSearchText = true
      } else if (product?.vendor.name.toLowerCase().includes(normalizedSearchText)) {
        matchesSearchText = true
      } else if (product?.type.toLowerCase().includes(normalizedSearchText)) {
        matchesSearchText = true
      }

      // date range checks
      const orderDate = startOfDay(order.orderDate)
      const start = startDate ? startOfDay(startDate) : null
      const end = endDate ? endOfDay(endDate) : null
      if (
        (!start || orderDate >= start) &&
        (!end || orderDate <= end)
      ){
        matchesDateRange = true
      }
    })
    if (matchesSearchText && matchesDateRange) {
      filteredOrders.push(order)
    }
  })
  return filteredOrders
}

export const calculateExistingOrderTotal = (order: OrderLara): number => {
  return order.orderItems.map(( model ) => model.quantity * model.unitCostDollars).reduce(( a, b ) => a + b, 0)
} 
