import React from 'react'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { LaborOnboard } from './LaborOnboard'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import { ROUTES } from "../../appRoutes";

export const LaborOnboardingPage = () => {
  const { salonId } = UseBaseApiParams()
  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Labor', url: generatePath(ROUTES.labor, { salonId }) },
    { label: 'Onboarding' },
  ]

  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Labor Setup" breadcrumbs={breadcrumbs} showHelp={false} />}
      content={<LaborOnboard />}
    />
  )
}
