import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUserSalonPermissions } from '../../data/user/slice'
import { LoggedInUser } from '../../data/user/interfaces'
import { redirectToAngularAdmin } from '../../data/user/utils'
import { Redirect } from 'react-router-dom'
import { selectChecklistItemsByCode } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { Loading } from '../../mini-lib/loading/Loading'
import { selectActiveSalonContext } from '../../data/salon/slice'
import { selectLoadingState } from '../../core/loading/slice'
import { LOADING_SALON } from '../../data/salon/constants'
import { dispatchLaraGetSalon } from '../../data/salon/api'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import { getRedirectUrl } from './utils'
import { ReleaseQuickTrial } from "../../mini-lib/flags/Release";

export const AuthRedirect = (props: { user: LoggedInUser; redirectUrl?: string | null }) => {
  const dispatch = useDispatch()
  const { user, redirectUrl } = props

  const activeSalon = useAppSelector(selectActiveSalonContext)
  const userPermissions = useAppSelector(selectLoggedInUserSalonPermissions)
  const loadingSalon = useAppSelector((state) => selectLoadingState(state, LOADING_SALON))
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistLoaded = !!checklistItems
  const salonId = user?.currentSalonContext?.salonId || null
  const salonLoaded = !!activeSalon
  const userLoaded = !!user
  const releaseQuickTrial = ReleaseQuickTrial()

  const isChecklistCompleted = checklistLoaded
    ? checklistItems[CHECKLIST_CODES.addPricing]?.completed || checklistItems[CHECKLIST_CODES.autoPricing]?.completed
    : null
  const hasPricingData =
    salonLoaded &&
    !!activeSalon.colorsCount &&
    activeSalon.colorsCount > 0 &&
    !!activeSalon.colorsPricedCount &&
    activeSalon.colorsPricedCount > 0
  const finishedGuide = isChecklistCompleted || hasPricingData

  useEffect(() => {
    if (user?.userId && user?.token && !salonLoaded && !loadingSalon && salonId) {
      dispatch(dispatchLaraGetSalon({ token: user.token, userId: user.userId, salonId }))
    }
  }, [dispatch, user?.token, user?.userId, loadingSalon, salonLoaded, salonId])

  useEffect(() => {
    if (!checklistLoaded) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, checklistLoaded, user.token, user.userId])

  const url = checklistLoaded && salonLoaded && userLoaded
      ? getRedirectUrl({
          user,
          userPermissions,
          finishedGuide,
          redirectUrl,
          releaseQuickTrial,
        })
      : null

  if (url === 'admin') {
    redirectToAngularAdmin(user.token, user.userId)
  } else if (url) {
    return <Redirect to={url} />
  }
  return <Loading h="400px" />

  // todo: keeping this around for a bit in case anything goes awry with the new redirects
  // delete any time after april 15th 2024
  //
  //
  // if (salon && user && checklistLoaded) {
  //   if (redirectUrl) {
  //     return <Redirect to={redirectUrl} />
  //   }
  //   // send superusers to the admin dashboard
  //   if (user.isSuperuser) {
  //     redirectToAngularAdmin(user.token, user.userId)
  //   }
  //   // if they have a user with an active trial and no active subscription
  //   //
  //   if (user.currentSalonContext && hasActiveQuickTrialAndNoSubscription(user.currentSalonContext)) {
  //     const url = generatePath(ROUTES.trialIndex, { salonId: salon.id })
  //     return <Redirect to={url} />
  //   }
  //   // if they have a user with an expired trial and no active subscription
  //   // note: this won't look the same as an inactive subscription which comes from chargebee
  //   // note: the saloncontext just returns null for this right now so - we probably want a way to differentiate between inactive subs and expired trials
  //   //
  //   if (user.currentSalonContext && hasExpiredQuickTrialAndNoSubscription(user.currentSalonContext)) {
  //     const url = generatePath(ROUTES.checkoutSubscription, { salonId: user.currentSalonContext.salonId })
  //     return <Redirect to={url} />
  //   }
  //   // if they already have a user but just have an inactive salon context send them to settings
  //   //
  //   if (user.currentSalonContext && hasInactiveSubscription(user.currentSalonContext)) {
  //     const url = generatePath(ROUTES.settings, { salonId: user.currentSalonContext.salonId }) + '?tab=subscription'
  //     return <Redirect to={url} />
  //   }
  //
  //   // todo: this should take them to ROUTES.signupV2Salon OR a salon dashboard of some type after quick trial is released
  //   // if they already have a user but no salons, send them to signup
  //   //
  //   if (!user.salonContexts || user.salonContexts.length === 0) {
  //     const url = generatePath(ROUTES.signupPlans)
  //     return <Redirect to={url} />
  //   }
  //
  //   // todo: this should take them to ROUTES.checkoutSubscription after quick trial is released
  //   // if they already have a user but no active account associated with the salon they have created send them to complete their signup
  //   //
  //   if (user.currentSalonContext && hasNoSubscription(user.currentSalonContext)) {
  //     const url = generatePath(ROUTES.signupPlans, { salonId: user.currentSalonContext.salonId })
  //     return <Redirect to={url} />
  //   }
  //
  //   // redirect stylists directly to sessions
  //   //
  //   if (user.currentSalonContext && userPermissions && isStylistUser(userPermissions)) {
  //     const url = generatePath(ROUTES.sessions, { salonId: user.currentSalonContext.salonId })
  //     return <Redirect to={url} />
  //     // if they have completed start guide
  //     //
  //   }
  //   if (user.currentSalonContext && finishedGuide) {
  //     const url = generatePath(ROUTES.home, { salonId: user.currentSalonContext.salonId })
  //     return <Redirect to={url} />
  //     // if they have not completed start guide
  //     //
  //   }
  //   if (user.currentSalonContext) {
  //     const url = releaseGuideV2
  //       ? generatePath(ROUTES.sgIndex, { salonId: user.currentSalonContext.salonId })
  //       : generatePath(ROUTES.startGuide, { salonId: user.currentSalonContext.salonId })
  //     return <Redirect to={url} />
  //   }
  // }
  // return <Loading h="400px" />
}