import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { APIUpdateSession, BowlType, Session, SessionItem } from '../../../data/sessions/interfaces'
import { reduceSetSelectedBowlId, selectSelectedBowlId } from '../../../data/sessions/slice'
import { convertDisplayUnits, getBowlMap, normalizeToGrams } from '../../../data/sessions/utils'
import { assign, filter, find, isEqual, sumBy, values } from 'lodash'
import { mapSessionItemsToAPISessionItems } from '../../../data/sessions/mappers'
import { Product } from '../../../data/products/interfaces'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { ProductSelectSheet } from '../../products/products/ProductSelectSheet'
import { SessionBowlColorsViewLegacy } from './SessionBowlColorsViewLegacy'
import { SessionBowlWasteViewLegacy } from './SessionBowlWasteViewLegacy'
import { SessionBowlEmptyBowlsViewLegacy } from './SessionBowlEmptyBowlsViewLegacy'
import { EmptyBowl } from '../../../data/empty-bowls/interfaces'
import { SessionBowlEmptyBowlDetailsViewLegacy } from './SessionBowlEmptyBowlDetailsViewLegacy'
import { BOWL_SHEET_VIEW_TYPES, BowlSheetViewType } from '../../../data/sessions/constants'

export const SessionBowlSheetLegacy = ( props: {
  session: Session
  updateSession: (u: Partial<APIUpdateSession>) => void
}) => {
  const dispatch = useDispatch()
  const selectedBowlId = useAppSelector(selectSelectedBowlId)
  const { session, updateSession } = props
  const bowlMap = getBowlMap(session.sessionItems)
  const bowl = selectedBowlId && selectedBowlId in bowlMap ? bowlMap[selectedBowlId] : []
  const initialBowlType = bowl.length > 0 ? bowl[0].bowlType : null
  const initialBowlTag: string | null | undefined = bowl.length > 0 ? bowl[0].userDefinedBowlName : null
  const [updatedBowl, setUpdatedBowl] = useState<SessionItem[]>(bowl)

  const initialWasteTotal = sumBy(updatedBowl, 'waste')
  const [wasteTotal, setWasteTotal] = useState<string>(initialWasteTotal ? initialWasteTotal.toString() : '')

  const [selectedEmptyBowl, setSelectedEmptyBowl] = useState<Partial<EmptyBowl> | null>(null)
  const [emptyBowlWeight, setEmptyBowlWeight] = useState<string>('0')
  const [emptyBowl, setEmptyBowl] = useState<EmptyBowl | null>(null)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedBowlType, setSelectedBowlType] = useState(initialBowlType)
  const [bowlTag, setBowlTag] = useState<string | null>(initialBowlTag ?? '')
  const [showProductSheet, setShowProductSheet] = useState(false)
  const [wasteRecentlyAdded, setWasteRecentlyAdded] = useState(false)
  const [bowlView, setBowlView] = useState<BowlSheetViewType>(BOWL_SHEET_VIEW_TYPES.amount)

  const savedBowlWaste = sumBy(bowl, 'waste')
  const isBowlDisabled = savedBowlWaste > 0 || wasteRecentlyAdded

  const totalBowlAmount = sumBy(updatedBowl, BOWL_SHEET_VIEW_TYPES.amount)

  const updateSessionItem = (sessionItem: SessionItem, paramsToUpdate: Partial<SessionItem>) => {
    const updatedSessionItem: SessionItem = assign({}, sessionItem, paramsToUpdate)
    const updatedUpdatedBowl = updatedBowl.map((bowlItem) => {
      if (bowlItem.product.id === sessionItem.product.id) {
        return updatedSessionItem
      }
      return bowlItem
    })
    setUpdatedBowl(updatedUpdatedBowl)
  }

  const onDeleteBowlWaste = (sessionItems: SessionItem[]) => {
    const productIds = sessionItems.map((item) => item.product.id)
    const updatedItems = sessionItems.map((sessionItem) => {
      return assign({}, sessionItem, { waste: 0 })
    })
    const updatedUpdatedBowl = updatedBowl.map((bowlItem) => {
      if (productIds.includes(bowlItem.product.id)) {
        return updatedItems.filter((item) => item.product.id === bowlItem.product.id)[0]
      }
      return bowlItem
    })
    setUpdatedBowl(updatedUpdatedBowl)
  }

  const updateBowlProducts = (products: Product[]) => {
    // note: session items that no longer have products associated with them will implicitly be deleted
    // because we use replace_session_items=true by default
    const updated: SessionItem[] = []
    products.forEach((product) => {
      const sessionItemForProduct: SessionItem | undefined = find(updatedBowl, (item) => item.product.id === product.id)
      if (sessionItemForProduct) {
        updated.push(sessionItemForProduct)
      } else {
        const newSessionItem: SessionItem = {
          amount: 0,
          waste: null,
          bowl: selectedBowlId || -1,
          product: product,
          displayUnit: 'g',
          userDefinedBowlName: bowlTag
        }
        if (selectedBowlType) {
          newSessionItem.bowlType = selectedBowlType
        }
        updated.push(newSessionItem)
      }
      setUpdatedBowl(updated)
    })
  }

  const onSave = () => {
    const sessionItemsNotInBowl: SessionItem[] = []
    values(bowlMap).forEach((sessionItems) => {
      if (sessionItems && sessionItems.length > 0 && sessionItems[0].bowl !== selectedBowlId) {
        sessionItemsNotInBowl.push(...sessionItems)
      }
    })
    const apiUpdatedSessionItems = mapSessionItemsToAPISessionItems([...sessionItemsNotInBowl, ...updatedBowl])
    updateSession({ session_items: apiUpdatedSessionItems })
    dispatch(reduceSetSelectedBowlId(null))
  }

  const onSaveWaste = () => {
    const updatedUpdatedBowl = updatedBowl.map((item) => {
      const itemPercentage = item.amount / totalBowlAmount
      const normalizedWaste = normalizeToGrams(parseFloat(wasteTotal), item.displayUnit)
      const totalColorWaste = normalizedWaste - parseFloat(emptyBowlWeight)
      const calculatedWaste = Math.round(itemPercentage * totalColorWaste * 100) / 100
      return assign({}, item, { waste: convertDisplayUnits(calculatedWaste, 'g', item.displayUnit) })
    })
    setUpdatedBowl(updatedUpdatedBowl)
    setWasteRecentlyAdded(true)
    setBowlView(BOWL_SHEET_VIEW_TYPES.amount)
  }

  const onDeleteSessionItem = (sessionItem: Partial<SessionItem> | any) => {
    if (sessionItem && sessionItem.product) {
      const toUpdate = filter([...updatedBowl], (ext) => ext.product.id !== sessionItem.product.id)
      setUpdatedBowl(toUpdate)
    }
  }
  const onDeleteBowl = () => {
    const sessionItemsNotInBowl: SessionItem[] = []
    values(bowlMap).forEach((sessionItems) => {
      if (sessionItems && sessionItems.length > 0 && sessionItems[0].bowl !== selectedBowlId) {
        sessionItemsNotInBowl.push(...sessionItems)
      }
    })
    const apiUpdatedSessionItems = mapSessionItemsToAPISessionItems([...sessionItemsNotInBowl])
    updateSession({ session_items: apiUpdatedSessionItems })
    dispatch(reduceSetSelectedBowlId(null))
  }

  const onBowlTypeSelect = (type: BowlType | null) => {
    const updatedUpdatedBowl = updatedBowl.map((bowlItem) => {
      return assign({}, bowlItem, { bowlType: type })
    })
    setSelectedBowlType(type)
    setUpdatedBowl(updatedUpdatedBowl)
  }

  const onBowlTagChange = (bowlTagValue) => {
    const updatedUpdatedBowl = updatedBowl.map((bowlItem) => {
      return assign({}, bowlItem, { userDefinedBowlName: bowlTagValue })
    })
    setBowlTag(bowlTagValue)
    setUpdatedBowl(updatedUpdatedBowl)
  }

  const selectedProductIds = updatedBowl.map((item) => item.product.id)

  return (
    <>
      <SaveSessionModal
        isOpen={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false)
          dispatch(reduceSetSelectedBowlId(null))
        }}
        onCancel={() => {
          setShowConfirmModal(false)
        }}
        onSave={onSave}
      />
      <Drawer
        onOverlayClick={() => {
          // if they have made changes make them confirm they want to leave
          if (!isEqual(bowl, updatedBowl)) {
            setShowConfirmModal(true)
          } else {
            dispatch(reduceSetSelectedBowlId(null))
          }
        }}
        closeOnOverlayClick={false}
        size="md"
        isOpen={!!selectedBowlId}
        placement="right"
        onClose={() => dispatch(reduceSetSelectedBowlId(null))}
      >
        {showProductSheet && (
          <ProductSelectSheet
            selectedProductIds={selectedProductIds}
            show={showProductSheet}
            onHide={() => setShowProductSheet(false)}
            onProductsSelected={(products) => {
              updateBowlProducts(products)
              setShowProductSheet(false)
            }}
          />
        )}
        <DrawerOverlay />
        <DrawerCloseButton />
        <DrawerContent>
          <DrawerBody>
            <>
              <DisableAutofocus />
              {bowlView === BOWL_SHEET_VIEW_TYPES.amount && (
                <SessionBowlColorsViewLegacy
                  onDeleteBowl={onDeleteBowl}
                  onSave={onSave}
                  setBowlView={setBowlView}
                  setShowProductSheet={setShowProductSheet}
                  updatedBowl={updatedBowl}
                  onBowlTypeSelect={onBowlTypeSelect}
                  onBowlTagChange={onBowlTagChange}
                  bowlTag={bowlTag}
                  onDeleteBowlWaste={onDeleteBowlWaste}
                  onDeleteSessionItem={onDeleteSessionItem}
                  updateSessionItem={updateSessionItem}
                  isDisabled={isBowlDisabled}
                />
              )}
              {bowlView === BOWL_SHEET_VIEW_TYPES.waste && (
                <SessionBowlWasteViewLegacy
                  totalBowlAmount={totalBowlAmount}
                  onSaveWaste={onSaveWaste}
                  emptyBowl={emptyBowl}
                  setBowlView={setBowlView}
                  updatedBowl={updatedBowl}
                  wasteTotal={wasteTotal}
                  setWasteTotal={setWasteTotal}
                  emptyBowlWeight={emptyBowlWeight}
                  setEmptyBowlWeight={setEmptyBowlWeight}
                  isDisabled={isBowlDisabled}
                />
              )}
              {bowlView === BOWL_SHEET_VIEW_TYPES.emptyBowls && (
                <SessionBowlEmptyBowlsViewLegacy
                  setSelectedEmptyBowl={setSelectedEmptyBowl}
                  setBowlView={setBowlView}
                  onEmptyBowlSelect={(bowl) => {
                    setEmptyBowl(bowl)
                    setEmptyBowlWeight(bowl.amountInGrams.toString())
                    setBowlView(BOWL_SHEET_VIEW_TYPES.waste)
                  }}
                />
              )}
              {bowlView === BOWL_SHEET_VIEW_TYPES.emptyBowlDetails && (
                <SessionBowlEmptyBowlDetailsViewLegacy
                  selectedEmptyBowl={selectedEmptyBowl}
                  setSelectedEmptyBowl={setSelectedEmptyBowl}
                  setBowlView={setBowlView}
                  onEmptyBowlSelect={(bowl) => {
                    setEmptyBowl(bowl)
                    setEmptyBowlWeight(bowl.amountInGrams.toString())
                    setBowlView(BOWL_SHEET_VIEW_TYPES.waste)
                  }}
                />
              )}
            </>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}



export const SaveSessionModal = (props: {
  isOpen: boolean
  onClose: () => void
  onCancel: () => void
  onSave: () => void
}) => {
  const { isOpen, onClose, onCancel, onSave } = props
  return (
    <>
      <Modal isOpen={isOpen} onClose={onCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box h="36px" />
            Are you sure you want to leave, any changes you made will be lost!
          </ModalBody>
          <ModalFooter>
            <Flex gridGap="12px">
              <Button variant="round-outline" onClick={onClose}>
                Close without saving
              </Button>
              <Button variant="round" onClick={onSave}>
                Save and Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
