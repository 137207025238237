import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react'
import { size, sortBy } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { buildDateYYYYMMDD } from '../../core/dates'
import { selectLoadingState } from '../../core/loading/slice'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { dispatchGetAllClientReport } from '../../data/report/api'
import { REPORTS_LOADING_CONSTANT } from '../../data/report/constants'
import { ClientReportLara, SessionDetails } from '../../data/report/interfaces'
import {
  reduceListClientSessionDetailReport,
  reduceListClientSessionReport,
  reduceSetCSVData,
  selectClientReportByStylist,
  selectClientSessionDetailReport,
  selectClientSessionReport,
  selectCustomEndDate,
  selectCustomStartDate,
  selectReportsDateRangeType,
} from '../../data/report/slice'
import { mapClientSessionDetailToCSV, mapStylistClientToCSV } from '../../data/report/mappers'
import { useAppSelector } from '../../hooks'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Loading } from '../../mini-lib/loading/Loading'
import { VARIANTS } from '../../mini-lib/theme/variants'
import { pages } from './ReportsPage'
import { TableContent, TableHead, TableTotal } from './Table'

export const ClientReportPage = (props: { page: string; setPage: (page: string) => void }) => {
  const [clientName, setClientName] = useState('')
  const [stylistName, setStylistName] = useState('')
  const [clientId, setClientId] = useState(0)
  const [stylistId, setStylistId] = useState(0)
  const [sessionId, setSessionId] = useState(0)

  const { page, setPage } = props
  useEffect(() => () => setPage(pages.clientList), [setPage])

  if (page === pages.clientList) {
    return (
      <ReportStylistClientTable
        setPage={setPage}
        setClientName={setClientName}
        setStylistName={setStylistName}
        setClientId={setClientId}
        setStylistId={setStylistId}
      />
    )
  }
  if (page === pages.clientSessionList) {
    return (
      <ReportStylistClientSessionListTable
        setPage={setPage}
        setSessionId={setSessionId}
        clientId={clientId}
        clientName={clientName}
        stylistName={stylistName}
        stylistId={stylistId}
      />
    )
  }

  if (page === pages.clientSessionDetail) {
    return (
      <ReportClientSessionDetail
        stylistId={stylistId}
        sessionId={sessionId}
        clientId={clientId}
        clientName={clientName}
        stylistName={stylistName}
        setPage={setPage}
      />
    )
  }
  return <></>
}

const ReportStylistClientTable = (props: {
  setPage: (string) => void
  setClientName: (string) => void
  setStylistName: (string) => void
  setClientId: (string) => void
  setStylistId: (string) => void
}) => {
  const { setPage, setClientName, setStylistName, setClientId, setStylistId } = props
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const listByStylist = useAppSelector(selectClientReportByStylist)
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const loadingReport = loadingReports ? loadingReports : null

  useEffect(() => {
    dispatch(dispatchGetAllClientReport({ token, salonId, dateStart: sDate, dateEnd: eDate, kind: 'color' }))
  }, [dispatch, token, salonId, sDate, eDate])

  return (
    <>
      {!loadingReport &&
        listByStylist &&
        Object.keys(listByStylist).map((stylistId, index) => {
          const clientRow = listByStylist[stylistId][Object.keys(listByStylist[stylistId])[0]]
          const clientid = Object.keys(clientRow)[0]
          const stylistName = clientRow[clientid][0].stylistName
          return (
            <Box key={index}>
              <Flex width="100%" align="center" justify="space-between" p="20px" borderRadius="15px" bg="brand.lavender.100">
                <Text variant={VARIANTS.text.caption1}>{stylistName}</Text>
              </Flex>
              <Box h={'25px'} />
              <ReportStylistClientTableContainer
                clientRows={listByStylist[stylistId]}
                setPage={setPage}
                setClientName={setClientName}
                setStylistName={setStylistName}
                setClientId={setClientId}
                setStylistId={setStylistId}
              />
            </Box>
          )
        })}
      {!loadingReport && listByStylist && Object.keys(listByStylist).length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </>
  )
}

const ReportStylistClientTableContainer = (props: {
  clientRows: { [key: number]: { [key: number]: ClientReportLara[] } }
  setPage: (string) => void
  setClientName: (string) => void
  setStylistName: (string) => void
  setClientId: (string) => void
  setStylistId: (string) => void
}) => {
  const { clientRows, setPage, setClientName, setStylistName, setClientId, setStylistId } = props
  const [numRowsToShow, setNumRowsToShow] = useState(10)
  const { isMobile, isTablet } = UseViewSize()
  const sortedClientList = sortBy(clientRows, () => {
    const clientDetails = clientRows[Object.keys(clientRows)[0]]
    const clientName = clientDetails[Object.keys(clientDetails)[0]][0].clientName
    return clientName
  })
  const pagedModels = sortedClientList.slice(0, numRowsToShow)
  const hasMore = numRowsToShow < Object.keys(clientRows).length
  const sumProfit = sortedClientList ? sortedClientList.map((model) => Object.keys(model).map((e) => model[e].map((a) => a.profit).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0) : null;
  const sumRevenue = sortedClientList ? sortedClientList.map((model) => Object.keys(model).map((e) => model[e].map((a) => a.retail).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0) : null;
  const sumWholesale = sortedClientList ? sortedClientList.map((model) => Object.keys(model).map((e) => model[e].map((a) => a.wholesale).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0) : null;
  const sumSessionCount = sortedClientList ? sortedClientList.map((e) => Object.keys(e).length).reduce((a, b) => a + b, 0) : null;
  const list = [
    { label: 'Customer' },
    { label: 'Sessions', value: sumSessionCount, additionalProps: { isNumeric: false, width: (isMobile || isTablet) ? 105 : 120 } },
    { label: 'Cost', value: sumWholesale, additionalProps: { isNumeric: true } },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: sumProfit, additionalProps: { isNumeric: true } },
  ]
  const minWidth = '110px'
  return (
    <>
      <TableHead list={list} minWidth={minWidth} />
      {pagedModels.map((clients, index) => (
        <Fragment key={index}>
          <ReportStylistClientTableRow
            clients={clients}
            setPage={setPage}
            setStylistName={setStylistName}
            setClientName={setClientName}
            setStylistId={setStylistId}
            setClientId={setClientId}
          />
        </Fragment>
      ))}
      {hasMore && (
        <Flex justify="center">
          <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(pagedModels.length + 15)}>
            View More
          </Button>
        </Flex>
      )}
      <TableTotal list={list} minWidth={minWidth} />
    </>
  )
}

const ReportStylistClientTableRow = (props: {
  clients: { [key: number]: ClientReportLara[] }
  setPage: (string) => void
  setClientName: (string) => void
  setStylistName: (string) => void
  setClientId: (string) => void
  setStylistId: (string) => void
}) => {
  const { clients, setPage, setClientName, setStylistName, setStylistId, setClientId } = props
  const dispatch = useDispatch()
  const { isMobile, isTablet } = UseViewSize()
  const listByStylist = useAppSelector(selectClientReportByStylist)
  const clientTotal = {
    profit: Object.keys(clients)
      ?.map((p) => clients[p].map((x) => x.profit).reduce((a, b) => a + b, 0))
      .reduce((a, b) => a + b, 0),
    retail: Object.keys(clients)
      ?.map((p) => clients[p].map((x) => x.retail).reduce((a, b) => a + b, 0))
      .reduce((a, b) => a + b, 0),
  }
  const sessionItems = clients[Object.keys(clients)[0]]
  const sessionItem = sessionItems[0]
  const wholesale = clientTotal.retail - clientTotal.profit
  const sessionCount = size(listByStylist[sessionItem.stylistId][sessionItem.clientId])
  const list = [
    { label: 'Customer', value: sessionItem.clientName },
    { label: 'sessions', value: sessionCount, additionalProps: { noTotal: true, width: (isMobile || isTablet) ? 105 : 120 } },
    { label: 'Cost', value: wholesale, additionalProps: { isNumeric: true } },
    { label: 'Price', value: clientTotal.retail, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: clientTotal.profit, additionalProps: { isNumeric: true } },
  ]
  const minWidth = '110px'
  return (
    <TableContent
      list={list}
      minWidth={minWidth}
      action={
        <Button
          minWidth={'70px'}
          height={'20px'}
          variant="round-ghost-lower"
          onClick={() => {
            setPage(pages.clientSessionList)
            setClientName(sessionItem.clientName)
            setStylistName(sessionItem.stylistName)
            setClientId(sessionItem.clientId)
            setStylistId(sessionItem.stylistId)
            dispatch(
              reduceListClientSessionReport({ list: listByStylist?.[sessionItem.stylistId][sessionItem.clientId] }),
            )
          }}
        >
          view all
        </Button>
      }
    />
  )
}

const ReportStylistClientSessionListTable = (props: {
  setPage: (string) => void
  setSessionId: (string) => void
  clientId: number
  clientName: string
  stylistName: string
  stylistId: number
}) => {
  const { setPage, setSessionId, clientId, stylistName, stylistId } = props
  const dispatch = useDispatch()
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const listBySession = useAppSelector(selectClientSessionReport)
  const listByStylist = useAppSelector(selectClientReportByStylist)
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const loadingReport = loadingReports ? loadingReports : null

  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null
  const { isMobile } = UseViewSize()

  useEffect(() => {
    dispatch(dispatchGetAllClientReport({ token, salonId, dateStart: sDate, dateEnd: eDate, kind: 'color' }))
  }, [dispatch, token, salonId, sDate, eDate])

  useEffect(() => {
    if (listByStylist && listByStylist[stylistId] && listByStylist[stylistId][clientId]) {
      dispatch(reduceListClientSessionReport({ list: listByStylist?.[stylistId][clientId] }))
    } else {
      setPage(pages.clientList)
    }
  }, [setPage, listByStylist, clientId, dispatch, stylistId])

  const onBackClick = () => {
    setPage(pages.clientList);
    dispatch(reduceSetCSVData(mapStylistClientToCSV(listByStylist)))
  }

  return (
    <>
      {isMobile ? (
        <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100">
          <Flex direction="column" width="80%">
            <Tooltip label={stylistName}>
              <Text variant={VARIANTS.text.caption1}>{stylistName}</Text>
            </Tooltip>
          </Flex>

          <Box cursor="pointer" onClick={onBackClick}>
            <Text variant={VARIANTS.text.caption1}>Back</Text>
          </Box>
        </Flex>
      ) : (
        <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100">
          <Text variant={VARIANTS.text.caption1}>{stylistName}</Text>
          <Box cursor="pointer" onClick={onBackClick}>
            <Text variant={VARIANTS.text.caption1}>Back</Text>
          </Box>
        </Flex>
      )}

      <Box h={'25px'} />

      {!loadingReport && <ReportStylistClientSessionListTableContainer
        clientRows={listBySession}
        setPage={setPage}
        clientId={clientId}
        stylistId={stylistId}
        setSessionId={setSessionId}
      />}
      {!loadingReport && listBySession && Object.keys(listBySession).length === 0 && <EmptyBox />}
      {loadingReport && <Loading />}
    </>
  )
}

const ReportStylistClientSessionListTableContainer = (props: {
  clientRows: SessionDetails[]
  setPage: (string) => void
  setSessionId: (number) => void
  clientId: number
  stylistId: number
}) => {
  const { clientRows, setPage, clientId, stylistId, setSessionId } = props
  const totalMarkup = clientRows?.reduce((a, b) => a + b.profit, 0)
  const totalPrice = clientRows?.reduce((a, b) => a + b.retail, 0)
  const sumCost = clientRows?.reduce((a, b) => a + b.wholesale, 0)
  const list = [
    { label: 'Customer' },
    { label: 'Date', additionalProps: { noTotal: true } },
    { label: 'Cost', value: sumCost, additionalProps: { isNumeric: true } },
    { label: 'Price', value: totalPrice, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: totalMarkup, additionalProps: { isNumeric: true } },
  ]
  const minWidth = '110px'
  return (
    <>
      <TableHead list={list} minWidth={minWidth} />
      {clientRows.map((row, index) => (
        <Fragment key={index}>
          <ReportStylistClientSessionListTableRow
            sessionDetails={row}
            setPage={setPage}
            clientId={clientId}
            stylistId={stylistId}
            setSessionId={setSessionId}
          />
        </Fragment>
      ))}
      <TableTotal list={list} minWidth={minWidth} />
    </>
  )
}

const ReportStylistClientSessionListTableRow = (props: {
  sessionDetails: SessionDetails
  setPage: (string) => void
  setSessionId: (number) => void
  clientId: number
  stylistId: number
}) => {
  const dispatch = useDispatch()
  const {
    sessionDetails: { clientName, createdAt, profit, retail, wholesale, sessionId },
    setPage,
    clientId,
    stylistId,
    setSessionId,
  } = props
  const list = [
    { label: 'Customer', value: clientName },
    { label: 'Date', value: buildDateYYYYMMDD(createdAt) },
    { label: 'Cost', value: wholesale, additionalProps: { isNumeric: true } },
    { label: 'Price', value: retail, additionalProps: { isNumeric: true } },
    { label: 'Mark up', value: profit, additionalProps: { isNumeric: true } },
  ]
  const listByStylist = useAppSelector(selectClientReportByStylist)

  return (
    <TableContent
      list={list}
      action={
        <Button
          minWidth={'70px'}
          height={'20px'}
          variant="round-ghost-lower"
          onClick={() => {
            setPage(pages.clientSessionDetail)
            setSessionId(sessionId)
            dispatch(reduceListClientSessionDetailReport({ list: listByStylist[stylistId][clientId][sessionId] }))
          }}
        >
          view all
        </Button>
      }
      minWidth={'110px'}
    />
  )
}

//=-----------=

const ReportClientSessionDetail = (props: {
  stylistId: number
  sessionId: number
  clientId: number
  clientName: string
  stylistName: string
  setPage: (string) => void
}) => {
  const dispatch = useDispatch()
  const { setPage, clientName, stylistName, stylistId, clientId, sessionId } = props //using this stylid and client id we will make a request to Client Detail Report
  const data = useAppSelector(selectClientSessionDetailReport) ?? []
  const listByStylist = useAppSelector(selectClientReportByStylist)
  const loadingReports = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))
  const { isMobile, isTablet } = UseViewSize()
  const sumProfit = data.map((model) => model.profit).reduce((a, b) => a + b, 0)
  const sumRevenue = data.map((model) => model.retail).reduce((a, b) => a + b, 0)
  const sumWholesale = data.map((model) => model.wholesale).reduce((a, b) => a + b, 0)
  const minWidth = '110px'
  const list = [
    { label: 'Line' },
    { label: 'Color', additionalProps: { noTotal: true, width: (isMobile || isTablet) ? 105 : 180 } },
    { label: 'Type', additionalProps: { noTotal: true, width: (isMobile || isTablet) ? 105 : 180 } },
    { label: 'Qty', additionalProps: { tooltip: 'number of containers', noTotal: true, width: (isMobile || isTablet) ? 105 : 120 } },
    { label: 'Cost', value: sumWholesale, additionalProps: { isNumeric: true, width: (isMobile || isTablet) ? 105 : 120 } },
    { label: 'Price', value: sumRevenue, additionalProps: { isNumeric: true, width: (isMobile || isTablet) ? 105 : 120 } },
    { label: 'Mark up', value: sumProfit, additionalProps: { isNumeric: true, width: (isMobile || isTablet) ? 105 : 120 } },
  ]
  const listBySession = useAppSelector(selectClientSessionReport)
  const onBackClick = () => {
    setPage(pages.clientSessionList);
    const list = listBySession
    dispatch(reduceSetCSVData(mapClientSessionDetailToCSV(list)))
  }

  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const dateFilter = useAppSelector(selectReportsDateRangeType)
  const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
  const customStartDate = useAppSelector(selectCustomStartDate)
  const customEndDate = useAppSelector(selectCustomEndDate)
  const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
  const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

  useEffect(() => {
    dispatch(dispatchGetAllClientReport({ token, salonId, dateStart: sDate, dateEnd: eDate, kind: 'color' }))
  }, [dispatch, token, salonId, sDate, eDate])


  useEffect(() => {
    if (listByStylist && listByStylist[stylistId] && listByStylist[stylistId][clientId]) {
      dispatch(reduceListClientSessionDetailReport({ list: listByStylist?.[stylistId][clientId][sessionId] }))
    }
    else {
      setPage(pages.clientList)
    }
  }, [setPage, listByStylist, stylistId, clientId, sessionId, dispatch])

  return (
    <div>
      {isMobile ? (
        <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100">
          <Flex direction="column" width="80%">
            <Text variant={VARIANTS.text.caption1}> {clientName}</Text>
            <Tooltip label={stylistName}>
              <Text variant={VARIANTS.text.caption1}>Stylist: {stylistName}</Text>
            </Tooltip>
          </Flex>

          <Box cursor="pointer" onClick={onBackClick}>
            <Text variant={VARIANTS.text.caption1}>Back</Text>
          </Box>
        </Flex>
      ) : (
        <Flex width="100%" justify="space-between" align="center" p="20px" borderRadius="15px" bg="brand.lavender.100">
          <Text variant={VARIANTS.text.caption1}>
            {clientName} - Stylist: {stylistName} - {buildDateYYYYMMDD(data[0].createdAt)}
          </Text>
          <Box cursor="pointer" onClick={onBackClick}>
            <Text variant={VARIANTS.text.caption1}>Back</Text>
          </Box>
        </Flex>
      )}

      <Box h={'25px'} />
      {!loadingReports && data.length > 0 && (
        <>
          <TableHead list={list} minWidth={minWidth} noAction />
          {data.map((product, index) => {
            const list = [
              { label: 'Line', value: product.line },
              { label: 'Color', value: product.color, additionalProps: { width: isMobile || isTablet ? 105 : 180 } },
              { label: 'Type', value: product.type, additionalProps: { width: isMobile || isTablet ? 105 : 180 } },
              {
                label: 'Qty',
                value: product.quantity,
                additionalProps: { noTotal: true, width: isMobile || isTablet ? 105 : 120 },
              },
              {
                label: 'Cost',
                value: product.wholesale,
                additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
              },
              {
                label: 'Price',
                value: product.retail,
                additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
              },
              {
                label: 'Mark up',
                value: product.profit,
                additionalProps: { isNumeric: true, width: isMobile || isTablet ? 105 : 120 },
              },
            ]
            return (
              <Fragment key={index}>
                <TableContent list={list} minWidth={'110px'} />
              </Fragment>
            )
          })}
          <TableTotal list={list} minWidth={minWidth} noAction />
        </>
      )}
      {!loadingReports && data.length === 0 && <EmptyBox />}
      {loadingReports && <Loading />}
    </div>
  )
}
