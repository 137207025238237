/////////////// api.ts
//
//

// import axios from 'axios'
import { Dispatch } from '@reduxjs/toolkit'


// import { GetServerBaseUrl } from '../../env'
// import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";
import {
  APISquareMember,
  // APISquareMember,
  SquareMember
} from "./interfaces";
import { mapAPISquareMembersToSquareMembers } from "./mappers";
import { reduceListSquareMembers } from "./slice";
import { MOCK_SQUARE_MEMBERS } from "./constants";
import axios from "axios";
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
import { GetServerBaseUrl } from "../../env";

// apis
//
//
export const apiListSquareMembers = ( params: {token: string, salonId: number}): Promise<SquareMember[]> => {
  const { token, salonId} = params
  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/bookings/members`

  // just return empty list until the api is working
  // return mockedSquareMembers()
  return axios
    .get(url, config)
    .then(( response: {data: {data: APISquareMember[]}} ) => {
      return mapAPISquareMembersToSquareMembers(response.data.data)
    })
    .catch(( error ) => {
      throw error
    })
}

// actions
//
//
export const dispatchListSquareMembers = ( params: { token: string, salonId: number } ) => {
    return ( dispatch: Dispatch ) => {
      return apiListSquareMembers(params).then(( resp ) => {
        dispatch(reduceListSquareMembers(resp))
      })
    }
}

export const mockedSquareMembers = () => {
  return Promise.resolve(mapAPISquareMembersToSquareMembers(MOCK_SQUARE_MEMBERS))
  // return Promise.resolve([])
}