import { APICreateSessionPhotoLara, SessionMetaLara, SessionPhotoLara } from '../../data/sessions/interfaces'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { isImageType } from '../../core/validators'
import {
  dispatchCreateSessionPhotoLara,
  dispatchDeleteSessionPhotoLara,
  dispatchListSessionPhotosLara
} from '../../data/sessions/api'
import { toast } from 'react-toastify'
import { FileUpload } from '../../mini-lib/file-upload/FileUpload'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import React, { useEffect, useState } from 'react'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import { SESSION_STATUS_OPTIONS } from '../../data/sessions/constants'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { useAppSelector } from "../../hooks";
import { selectActiveSessionPhotos } from "../../data/sessions/slice";
import { useParams } from "react-router-dom";
import { dispatchUploadFile } from "../../core/file-upload/api";
import { reduceRemoveFileUpload, selectFileUrlUploadByName } from "../../core/file-upload/slice";
import { Loading } from '../../mini-lib/loading/Loading'
import { selectLoadingState } from "../../core/loading/slice";

export const SessionPhotos = (props: { sessionMeta: SessionMetaLara }) => {
  const { sessionMeta } = props
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const photos = useAppSelector(selectActiveSessionPhotos)
  const beforePhotos: SessionPhotoLara[] = []
  const afterPhotos: SessionPhotoLara[] = []
  const params: any = useParams()
  const { sessionId } = params
  photos?.forEach((photo) => {
    if (photo.photoTag === 'before') {
      beforePhotos.push(photo)
    } else {
      afterPhotos.push(photo)
    }
  })

  useEffect(() => {
      dispatch(dispatchListSessionPhotosLara({ token: user.token, salonId, sessionId }))
  }, [dispatch, user.token, salonId, sessionId])


  return (
    <Box>
      <Text variant="title3">Photos</Text>
      <Box h="24px" />
      <PhotoDisplay title="before" photos={beforePhotos} sessionMeta={sessionMeta} />
      <Box h="24px" />
      <PhotoDisplay title="after" photos={afterPhotos} sessionMeta={sessionMeta} />
    </Box>
  )
}

const photoSize = { height: '150px', width: '100px' }

export const PhotoDisplay = (props: { title: string; photos: SessionPhotoLara[]; sessionMeta: SessionMetaLara }) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const { title, photos, sessionMeta } = props
  const uploadName = `session-${sessionMeta.id}-${title}-photo-${photos.length}`
  const uploadedPhotoUrl = useAppSelector((state) => selectFileUrlUploadByName(state, uploadName))
  const isCreatingPhoto = useAppSelector((state) => selectLoadingState(state, uploadName))

  const onFileUpload = (file) => {
    if (file && isImageType(file.type)) {
      dispatch(dispatchUploadFile({ token: user.token, uploadName, file }))
    } else {
      toast.error('Please enter a valid image')
    }
  }
  useEffect(() => {
    if (uploadedPhotoUrl) {
      const model: APICreateSessionPhotoLara = {
        photo_tag: title,
        photo_url: uploadedPhotoUrl,
        storage_object: uploadName
      }
      dispatch(dispatchCreateSessionPhotoLara({
        token: user.token,
        salonId,
        sessionId: sessionMeta.id,
        model,
        loadingName: uploadName
      }))
      dispatch(reduceRemoveFileUpload(uploadName))
    }
  }, [dispatch, user.token, salonId, sessionMeta.id, title, uploadName, uploadedPhotoUrl])
  return (
    <Box>
      <Text variant="caption2">{title}</Text>
      <Box h="12px" />
      {sessionMeta && sessionMeta.status === SESSION_STATUS_OPTIONS.open ? (
        <Flex gridGap="12px" align="center" flexWrap="wrap">
          <FileUpload
            onFileUpload={onFileUpload}
            override={
              <Flex
                borderRadius="15px"
                bg="shades.neutral.200"
                h={photoSize.height}
                w={photoSize.width}
                justify="center"
                align="center"
              >
                <MaterialIcon name="add_a_photo" />
              </Flex>
            }
          />
          {photos.map((photo) => (
            <SessionPhoto key={photo.id} photo={photo} sessionMeta={sessionMeta}/>
          ))}
          {isCreatingPhoto && <Flex align='center' justify='center' minW='100px'>
              <Loading/>
          </Flex>}
        </Flex>
      ) : (
        <EmptyBox title="No Photos" content="nothing to display" h={photoSize.height} />
      )}
    </Box>
  )
}

export const SessionPhoto = (props: { photo: SessionPhotoLara; sessionMeta: SessionMetaLara }) => {
  const dispatch = useDispatch()
  const [hover, setHover] = useState(false)
  const { user, salonId } = UseBaseApiParams()
  const { photo, sessionMeta } = props

  return (
    <Box
      width={photoSize.width}
      height={photoSize.height}
      position="relative"
      borderRadius="15px"
      bg="shades.neutral.200"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        alt="client"
        style={{
          width: photoSize.width,
          height: photoSize.height,
          objectFit: 'contain',
          borderRadius: '15px',
        }}
        src={photo.photoUrl}
      />
      {hover && (
        <ConfirmPopover
          title={`This Action is Permanent`}
          subtitle={`This photo will be removed from this session`}
          onConfirm={() => {
            dispatch(
              dispatchDeleteSessionPhotoLara({
                token: user.token,
                salonId,
                sessionId: sessionMeta.id,
                photoId: photo.id,
              }),
            )
          }}
        >
          <Button position="absolute" bottom="0" borderBottomRightRadius="15px" borderBottomLeftRadius="15px" w="100%">
            Delete
          </Button>
        </ConfirmPopover>
      )}
    </Box>
  )
}
