import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Circle,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { generatePath, Link } from 'react-router-dom'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { ReactNode, useEffect, useState } from 'react'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { dispatchDeleteProducts, dispatchListAllLines, dispatchListProducts } from '../../../data/products/api'
import { useAppSelector } from '../../../hooks'
import {
  selectAllLines,
  selectAllLinesByVendor,
  selectLineNamesByVendor,
  selectProductListForLine
} from '../../../data/products/slice'
import { useDispatch } from 'react-redux'
import { LineNamesByVendor, LineOption, LinesByVendor, Product } from '../../../data/products/interfaces'
import { intersection, keys, orderBy } from 'lodash'
import { COLORS } from '../../../mini-lib/theme/colors'
import { MasterProduct } from '../../../data/master-products/interfaces'
import {
  dispatchAddMasterColorsLara,
  dispatchAddMasterProducts,
  dispatchListMasterProducts,
  dispatchListMasterProductsLara
} from '../../../data/master-products/api'
import {
  reduceSearchText,
  selectMasterProductsForLine,
  selectMasterProductsLineNamesAndVendorNamesFilteredBySearchText,
  selectSearchText,
} from '../../../data/master-products/slice'
import { Loading } from '../../../mini-lib/loading/Loading'
import { selectLoadingState } from '../../../core/loading/slice'
import { LOADING_MASTER_PRODUCTS } from '../../../data/master-products/constants'
import { LOADING_PRODUCTS } from '../../../data/products/constants'
import { buildMasterProductsLoadingName, getProductForMasterProduct } from '../../../data/master-products/utils'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { ConfirmPopover } from '../../../mini-lib/confirm-popover/ConfirmPopover'
import { buttonStyles } from '../../../theme'
import { UseViewSize } from '../../../core/UseViewSize'
import { SearchFilter } from '../../../mini-lib/filters/SearchFilter'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import { HelpStepperV2 } from '../../start-guide-v2/HelpStepperV2'
import { HelpModal } from '../../start-guide/common-components/HelpModal'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { HELP_DRAWER_STATES, HelpStepCompleteDrawer } from '../../start-guide/common-components/HelpStepCompleteDrawer'
import { dispatchUpdateUserChecklistItem } from '../../../data/start-guide/api'
import {
  reduceSetCurrentStepCode,
  reduceSetCurrentSubStepIndex,
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState,
  selectStepsCompleted,
} from '../../../data/start-guide/slice'
import { UseQueryParams } from "../../../mini-lib/utils/basic";
import { UseResetStartGuideState } from "../../start-guide/common-components/UseResetStartGuideState";
import { ReleaseLaraColors, ReleaseMasterProducts, ReleaseUnitsSupport } from "../../../mini-lib/flags/Release";
import { EmptyFilters } from "../../../mini-lib";
import { ROUTES } from "../../../appRoutes";

export const AddLinesV2Page = ( props: { showBreadCrumbs?: boolean } ) => {
  const { showBreadCrumbs = true } = props
  const dispatch = useDispatch()
  UseResetStartGuideState()
  const queryParams: any = UseQueryParams()
  const guideParam = queryParams.get('guide')

  const isGuide = !!guideParam
  const {
    salonId,
    user: { token },
  } = UseBaseApiParams()
  const breadcrumbs = showBreadCrumbs ? [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Brands', url: generatePath(ROUTES.vendors, { salonId }) },
    { label: 'Lines', url: generatePath(ROUTES.lines, { salonId }) },
    { label: 'Add Lines' },
  ] : []
  const allLines = useAppSelector(selectAllLines)
  const allLinesByVendor = useAppSelector(selectAllLinesByVendor)
  const addedLinesByVendors = useAppSelector(selectLineNamesByVendor)
  const searchText = useAppSelector(selectSearchText)
  const releaseMasterProducts = ReleaseMasterProducts()

  const allLinesLoaded = !!allLines
  const loadingSearchText = useAppSelector(( state ) => selectLoadingState(state, searchText))

  const drawerState = useAppSelector(selectStepCompleteDrawerState)
  const minSearchTextLength = 2

  const qpSearchText = queryParams.get('search') || ''
  useEffect(() => {
    dispatch(reduceSearchText(qpSearchText.toLowerCase().trim()))
  }, [dispatch, qpSearchText])

  useEffect(() => {
    if (!allLinesLoaded && token) {
      dispatch(dispatchListAllLines(token, salonId))
    }
  }, [dispatch, token, allLinesLoaded, salonId])

  // initial dispatch for first row of master products
  useEffect(() => {
    if (token) {
      if (releaseMasterProducts) {
        dispatch(
          dispatchListMasterProductsLara({
            token,
            search: '4Hair Cosmetics',
            loadingName: LOADING_MASTER_PRODUCTS,
          }),
        )
      } else {
        dispatch(
          dispatchListMasterProducts({
            token,
            search: '4Hair Cosmetics',
            loadingName: LOADING_MASTER_PRODUCTS,
          }),
        )
      }


    }
  }, [dispatch, token, releaseMasterProducts])

  const releaseLaraColors = ReleaseLaraColors()
  useEffect(() => {
    dispatch(dispatchListProducts({ token, salonId, releaseLaraColors }))
  }, [dispatch, token, salonId, releaseLaraColors])

  useEffect(() => {
    if (searchText.length >= minSearchTextLength) {
      if (releaseMasterProducts) {
        dispatch(dispatchListMasterProductsLara({ token, search: searchText, loadingName: searchText }))
      } else {
        dispatch(dispatchListMasterProducts({ token, search: searchText, loadingName: searchText }))
      }
    }
  }, [dispatch, token, searchText, releaseMasterProducts])

  const { filteredMasterProducts, filteredLineNames, filteredVendorNames } = useAppSelector(
    selectMasterProductsLineNamesAndVendorNamesFilteredBySearchText,
  )
  const previousStepLink = generatePath(ROUTES.sgAddLinesIntro, { salonId }) + `?guide=${CHECKLIST_CODES.addLines}`
  const nextStepLink = generatePath(ROUTES.sgAddPricingIntro, { salonId })
  const stepCodes = [CHECKLIST_CODES.addLines, CHECKLIST_CODES.addPricing]
  const stepsCompleted = useAppSelector(( state ) => selectStepsCompleted(state, stepCodes)) || 0
  const emptySearch = !loadingSearchText && searchText.length > 0 && (!filteredMasterProducts || filteredMasterProducts.length === 0)

  return (
    <>
      <HelpModal title="Let’s Set Up Your Color Lines!" guideType={CHECKLIST_CODES.addLines}/>
      <HelpStepCompleteDrawer
        title="You have added your first color line."
        subtitle="Make sure you add all of your salon’s color lines before you continue. After this, we will customize your color line’s pricing."
        actions={
          <Flex gridGap="12px" borderRadius="15px" bg="white" p="0 12px">
            {drawerState === HELP_DRAWER_STATES.drawer && (
              <Button
                colorScheme="brand.midnight"
                variant="round-ghost-upper"
                onClick={() => dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.button))}
              >
                Continue adding color lines
              </Button>
            )}
            <Link to={generatePath(ROUTES.sgAddPricingIntro, { salonId })}>
              <Button
                variant="round"
                colorScheme="brand.midnight"
                onClick={() => {
                  dispatch(reduceSetCurrentStepCode(CHECKLIST_CODES.addLines))
                  dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.hidden))
                  dispatch(reduceSetCurrentSubStepIndex(0))
                }}
              >
                Next Step
              </Button>
            </Link>
          </Flex>
        }
        guideType={CHECKLIST_CODES.addLines}
      />
      <PageLayout
        variant="full"
        top={
          <HelpStepperV2
            title="Add Your Color Lines"
            titleIconName="products"
            currentStepNumber={1}
            stepsComplete={stepsCompleted}
            totalSteps={stepCodes.length}
            previousStepLink={previousStepLink}
            nextStepLink={nextStepLink}
            guideType={CHECKLIST_CODES.addLines}
          />
        }
        header={
          <PageHeader
            title="Add Colors"
            breadcrumbs={breadcrumbs}
            actions={
              <>
                {!isGuide && (
                  <Button variant="round" colorScheme="brand.midnight">
                    <Link to={generatePath(ROUTES.lines, { salonId })}>View My Lines</Link>
                  </Button>
                )}
              </>
            }
          />
        }
      >
        <Box h="24px"/>
        <HelpPopover
          description="Use the search bar to search for a brand, line, or product."
          stepIndex={0}
          guideTypes={[CHECKLIST_CODES.addLines]}
        >
          <Box>
            <SearchFilter
              width="100%"
              theme="midnight"
              buttonClickToSearch={true}
              minSearchLength={minSearchTextLength}
              placeholder="Search"
              onChange={( v ) => {
                dispatch(reduceSearchText(v))
              }}
              value={searchText}
              onClear={() => dispatch(reduceSearchText(''))}
            />
          </Box>
        </HelpPopover>
        {!loadingSearchText && allLinesByVendor && addedLinesByVendors && (
          <>
            <Box h="24px"/>
            <VendorAccordion
              filteredVendorNames={filteredVendorNames}
              filteredLineNames={filteredLineNames}
              filteredMasterProducts={filteredMasterProducts}
              linesByVendor={allLinesByVendor}
              addedLinesByVendors={addedLinesByVendors}
            />
          </>
        )}
        {( !allLinesByVendor || !addedLinesByVendors || loadingSearchText ) && (
          <>
            <Box h="48px"/>
            <Loading/>
          </>
        )}
        {( emptySearch ) && (
          <>
            <EmptyFilters
              theme='midnight'
              title='search result can’t be found'
              subtitle="It looks like we don't have that product!"
            />
          </>
        )}
      </PageLayout>
    </>
  )
}

export const VendorAccordion = ( props: {
  filteredVendorNames: string[] | null
  filteredLineNames: string[] | null
  filteredMasterProducts: MasterProduct[] | null
  linesByVendor: LinesByVendor
  addedLinesByVendors: LineNamesByVendor
} ) => {
  const { filteredVendorNames, filteredLineNames, filteredMasterProducts, linesByVendor, addedLinesByVendors } = props
  const dispatch = useDispatch()
  const {
    user: { token },
  } = UseBaseApiParams()

  const searchText = useAppSelector(selectSearchText)
  const loadingMps = useAppSelector(( state ) => selectLoadingState(state, LOADING_MASTER_PRODUCTS))
  const loadingProducts = useAppSelector(( state ) => selectLoadingState(state, LOADING_PRODUCTS))
  const releaseMasterProducts = ReleaseMasterProducts()
  const sortedVendorNames = keys(linesByVendor).sort()
  const sortedFilteredVendorNames = searchText
    ? intersection(sortedVendorNames, filteredVendorNames)
    : sortedVendorNames
  const [numRowsToShow, setNumRowsToShow] = useState(10)
  const hasMoreRowsToShow = numRowsToShow < sortedFilteredVendorNames.length
  return (
    <>
      <Accordion allowToggle={true} defaultIndex={0}>
        {sortedFilteredVendorNames.slice(0, numRowsToShow).map(( vendorName, index ) => {
          const vendorAddedLines = addedLinesByVendors[vendorName]
          const vendorLines = linesByVendor[vendorName]
          return (
            <AccordionItem key={vendorName} borderTop={index === 0 ? '0px solid black' : ''}>
              <AccordionButton
                p={0}
                onClick={() => {
                  if (releaseMasterProducts) {
                    dispatch(
                      dispatchListMasterProductsLara({
                        token,
                        search: vendorName,
                        loadingName: LOADING_MASTER_PRODUCTS,
                      }))
                  } else {
                    dispatch(dispatchListMasterProducts({
                      token,
                      search: vendorName,
                      loadingName: LOADING_MASTER_PRODUCTS
                    }))
                  }
                }}
                _focus={{ boxShadow: 'none' }}
              >
                <Flex w="100%" align="center" justify="space-between">
                  <GenericTile
                    title={vendorName}
                    subtitle={
                      <VendorSubtitle
                        addedLinesLength={vendorAddedLines?.length || 0}
                        vendorLinesLength={vendorLines.length}
                        loading={!loadingProducts}
                      />
                    }
                  />
                  <AccordionIcon/>
                </Flex>
              </AccordionButton>
              <AccordionPanel p={0}>
                {loadingMps && (
                  <AccordionItem p={'24px'}>
                    <Loading/>
                  </AccordionItem>
                )}
                {!loadingMps && (
                  <LineAccordion
                    filteredLineNames={filteredLineNames}
                    filteredMasterProducts={filteredMasterProducts}
                    lines={vendorLines}
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
      <Box h="24px"/>
      {hasMoreRowsToShow && (
        <Flex justify="center">
          <Button variant="round-ghost-lower" onClick={() => setNumRowsToShow(numRowsToShow + 10)}>
            View More
          </Button>
        </Flex>
      )}
    </>
  )
}

const VendorSubtitle = ( props: { loading: boolean; vendorLinesLength: number; addedLinesLength: number } ) => {
  const { loading, vendorLinesLength, addedLinesLength } = props
  return (
    <>
      {vendorLinesLength} Lines | {loading ? addedLinesLength : <Spinner h="12px" w="12px"/>} Used
    </>
  )
}
export const LineAccordion = ( props: {
  filteredLineNames: string[] | null
  filteredMasterProducts: MasterProduct[] | null
  lines: LineOption[]
} ) => {
  const { lines, filteredLineNames, filteredMasterProducts } = props
  const sortedLines = orderBy(lines, ['lineName'], ['asc'])
  const filteredSortedLineNames = sortedLines.filter(( line ) => filteredLineNames?.includes(line.lineName))
  return (
    <Accordion allowToggle={true}>
      {filteredSortedLineNames.map(( line ) => {
        return <LineAccordionItem key={line.lineId} line={line} filteredMasterProducts={filteredMasterProducts}/>
      })}
    </Accordion>
  )
}

export const LineAccordionItem = ( props: { line: LineOption; filteredMasterProducts: MasterProduct[] | null } ) => {
  const { isMobile } = UseViewSize()
  const { line, filteredMasterProducts } = props

  const mpsInLine: MasterProduct[] | null = useAppSelector(( state ) => selectMasterProductsForLine(state, line.lineName, line.vendorName))
  const filteredMpsInLine =
    mpsInLine && filteredMasterProducts ? intersection(mpsInLine, filteredMasterProducts) : mpsInLine
  const mpsInLineLoaded = !!mpsInLine
  const productsInLine: Product[] | null = useAppSelector(( state ) => selectProductListForLine(state, line.lineName, line.vendorName))

  const subtitle = mpsInLine ? (
    <>
      {filteredMpsInLine?.length} Items |{' '}
      <span style={{ color: productsInLine && productsInLine.length > 0 ? COLORS.lavender_500 : '' }}>
        {productsInLine?.length || 0} Added
      </span>
    </>
  ) : (
    ''
  )

  const hasAllProducts = productsInLine && filteredMpsInLine && productsInLine.length === filteredMpsInLine?.length
  const hasSomeProducts = productsInLine && filteredMpsInLine && productsInLine.length > 0
  const releaseUnitsSupport = ReleaseUnitsSupport();

  return (
    <AccordionItem key={line.lineId}>
      <AccordionButton p={isMobile ? '0 0 0 12px' : '0 0 0 24px'} _focus={{ boxShadow: 'none' }}>
        <Flex w="100%" align="center" justify="space-between">
          <GenericTile
            title={line.lineName}
            subtitle={subtitle}
            actions={
              <HelpPopover
                description="Add an entire line or expand the line to add individual products from that line."
                stepIndex={1}
                guideTypes={[CHECKLIST_CODES.addLines]}
              >
                <Flex wrap="wrap" justify="end" maxW="50%" gridGap={isMobile ? '4px' : ''}>
                  {mpsInLineLoaded && !hasAllProducts && (
                    <MasterProductsButton
                      variant={hasSomeProducts ? 'update-line' : 'add-line'}
                      masterProducts={filteredMpsInLine || []}
                      products={productsInLine}
                    />
                  )}
                  {mpsInLineLoaded && ( hasSomeProducts || hasAllProducts ) && (
                    <MasterProductsButton
                      variant={'remove-line'}
                      masterProducts={filteredMpsInLine || []}
                      products={productsInLine}
                    />
                  )}
                </Flex>
              </HelpPopover>
            }
          />
          <AccordionIcon/>
        </Flex>
      </AccordionButton>
      <AccordionPanel p={0}>
        {mpsInLineLoaded &&
          filteredMpsInLine?.map(( mp ) => {
            const product: Product | null = productsInLine ? getProductForMasterProduct(mp, productsInLine) : null
            const staticUnit = 'g or ml'
            const units = releaseUnitsSupport ? mp?.units ?? staticUnit : staticUnit
            return (
              <Box key={mp.id} p={isMobile ? '0 0 0 24px' : '0 0 0 48px'} _hover={{ background: COLORS.hover }}>
                <GenericTile
                  title={mp.type}
                  subtitle={`${mp.size}${units}`}
                  actions={
                    <MasterProductsButton
                      variant={product ? 'remove-product' : 'add-product'}
                      masterProducts={[mp]}
                      products={product ? [product] : null}
                    />
                  }
                />
              </Box>
            )
          })}
        {!mpsInLineLoaded && <Loading/>}
      </AccordionPanel>
    </AccordionItem>
  )
}

export const GenericTile = ( props: { title: string; subtitle: string | ReactNode; actions?: ReactNode } ) => {
  const { title, subtitle, actions } = props

  return (
    <Flex align="center" justify="space-between" p="12px 0" w="100%">
      <Box>
        <Text textAlign="left" fontWeight="bold">
          {title}
        </Text>
        <Box textAlign="left" color={COLORS.text_secondary}>
          {subtitle}
        </Box>
      </Box>
      {actions}
    </Flex>
  )
}

// note: the compiler complains when a button is on a button so the add line button is a flex box with button styles
export const MasterProductsButton = ( props: {
  variant: 'add-product' | 'add-line' | 'remove-product' | 'remove-line' | 'update-line' | string
  masterProducts: MasterProduct[]
  products: Product[] | null
} ) => {
  const dispatch = useDispatch()
  const { masterProducts, products, variant } = props
  const { salonId, user } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  const loadingName = buildMasterProductsLoadingName(masterProducts)
  const isLoading = useAppSelector(( state ) => selectLoadingState(state, loadingName))
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')
  const addProductsStep = useAppSelector(( state ) => selectChecklistItem(state, CHECKLIST_CODES.addLines))
  const releaseLaraColors = ReleaseLaraColors();
  const createClicked = ( evt ) => {
    evt.stopPropagation()
    if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
      dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
    }
    if (!addProductsStep.completed && isGuide) {
      dispatch(dispatchUpdateUserChecklistItem({ token: user.token, checklistItemCode: CHECKLIST_CODES.addLines }))
    }
    if (releaseLaraColors) {
      dispatch(
        dispatchAddMasterColorsLara({
          token: user.token,
          salonId,
          masterProductIds: masterProducts.map(( mp ) => mp.id),
          autoPriceMarkup: null,
          loadingName,
        }),
      )
    } else {
      dispatch(
        dispatchAddMasterProducts({
          token: user.token,
          salonId,
          masterProductIds: masterProducts.map(( mp ) => mp.id),
          loadingName,
        }),
      )
    }
  }
  const onRemoveClicked = ( evt?: any ) => {
    evt?.stopPropagation()
    dispatch(dispatchDeleteProducts({ token: user.token, salonId, models: products ?? [], loadingName, releaseLaraColors }))
  }
  return (
    <>
      {isLoading && <Loading mr="12px" color={COLORS.lavender_500}/>}
      {!isLoading && variant === 'add-product' && (
        <Circle
          onClick={( evt ) => createClicked(evt)}
          size="32px"
          _hover={{ border: `1px solid ${COLORS.lavender_500}` }}
          bg={COLORS.shades_neutral_100}
          cursor="pointer"
        >
          <MaterialIcon name="add" colorhex={COLORS.lavender_500}/>
        </Circle>
      )}
      {!isLoading && variant === 'add-line' && (
        <>
          {isMobile ? (
            <Circle
              onClick={( evt ) => createClicked(evt)}
              color={COLORS.lavender_500}
              bg={COLORS.shades_neutral_100}
              p="4px"
            >
              <MaterialIcon name="add"/>
            </Circle>
          ) : (
            <Flex
              onClick={( evt ) => createClicked(evt)}
              mr="12px"
              gridGap="6px"
              style={buttonStyles({})}
              _hover={{ border: `1px solid ${COLORS.lavender_500}` }}
            >
              <MaterialIcon name="add"/> {!isMobile && 'Add Line'}
            </Flex>
          )}
        </>
      )}
      {!isLoading && variant === 'remove-line' && (
        <>
          {isMobile ? (
            <ConfirmPopover
              title="Remove Color Line?"
              subtitle="Removing this color line means it will no longer appear in your salon."
              onConfirm={() => onRemoveClicked()}
            >
              <Circle
                onClick={( evt ) => evt.stopPropagation()}
                bg={COLORS.shades_neutral_100}
                p="4px"
                color={COLORS.lavender_500}
              >
                <MaterialIcon name="delete_outline"/>
              </Circle>
            </ConfirmPopover>
          ) : (
            <ConfirmPopover
              title="Remove Color Line?"
              subtitle="Removing this color line means it will no longer appear in your salon."
              onConfirm={() => onRemoveClicked()}
            >
              <Flex
                onClick={( evt ) => evt.stopPropagation()}
                mr="12px"
                style={buttonStyles({})}
                _hover={{ border: `1px solid ${COLORS.lavender_500}` }}
              >
                <MaterialIcon name="delete_outline"/> Remove Line
              </Flex>
            </ConfirmPopover>
          )}
        </>
      )}
      {!isLoading && variant === 'update-line' && (
        <>
          {isMobile ? (
            <Circle
              onClick={( evt ) => createClicked(evt)}
              color={COLORS.lavender_500}
              bg={COLORS.shades_neutral_100}
              p="4px"
            >
              <MaterialIcon name="sync"/>
            </Circle>
          ) : (
            <Flex
              onClick={( evt ) => createClicked(evt)}
              mr="12px"
              style={buttonStyles({})}
              _hover={{ border: `1px solid ${COLORS.lavender_500}` }}
            >
              <MaterialIcon name="sync"/> Update Line
            </Flex>
          )}
        </>
      )}
      {!isLoading && variant === 'remove-product' && (
        <ConfirmPopover
          title="Remove Product?"
          subtitle="Removing this product means it will no longer appear in your salon."
          onConfirm={() => onRemoveClicked()}
        >
          <Flex align="center">
            <MaterialIcon name="check" size="16px" colorhex={COLORS.text_secondary} mr="6px"/>
            Added
            <Circle
              ml="12px"
              size="32px"
              cursor="pointer"
              _hover={{ border: `1px solid ${COLORS.lavender_500}` }}
              bg={COLORS.shades_neutral_100}
            >
              <MaterialIcon name="delete_outline" colorhex={COLORS.lavender_500}/>
            </Circle>
          </Flex>
        </ConfirmPopover>
      )}
    </>
  )
}

