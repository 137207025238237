import React, { useEffect } from 'react'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'
import { selectLaborItemList, selectLaborServiceList, selectLaborTierList } from '../../data/labor/slice'
import { dispatchListLaborItems, dispatchListLaborServices, dispatchListLaborTiers } from '../../data/labor/api'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { LaborTable } from './LaborTable'
import { dispatchListSalonMembers } from '../../data/user/api'
import { ColorForMode } from '../../theme'
import laborEmpty from '../../assets/labor-empty.svg'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { selectMemberList } from '../../data/user/slice'
import { ROUTES } from '../../appRoutes'
import { EmptyBox } from "../../mini-lib/empty/EmptyBox";
import { Gap } from "../../mini-lib/gap/Gap";

export const LaborPage = () => {
  const {
    user: { token },
    salonId,
  } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Labors' }]
  const dispatch = useDispatch()
  const history = useHistory()
  const queryParams: any = UseQueryParams()
  const empty = queryParams.get('empty')
  const showTable = queryParams.get('showTable')
  const items = useAppSelector(selectLaborItemList)
  const tiers = useAppSelector(selectLaborTierList)
  const services = useAppSelector(selectLaborServiceList)
  const members = useAppSelector(selectMemberList)

  const itemsLoaded = !!items
  const tiersLoaded = !!tiers
  const servicesLoaded = !!services
  const membersLoaded = !!members

  useEffect(() => {
    if (!itemsLoaded) {
      dispatch(dispatchListLaborItems(token, salonId))
    }
  }, [dispatch, itemsLoaded, token, salonId])

  useEffect(() => {
    if (!tiersLoaded) {
      dispatch(dispatchListLaborTiers(token, salonId))
    }
  }, [dispatch, tiersLoaded, token, salonId])

  useEffect(() => {
    if (!servicesLoaded) {
      dispatch(dispatchListLaborServices(token, salonId))
    }
  }, [dispatch, servicesLoaded, token, salonId])

  useEffect(() => {
    if (!membersLoaded) {
      dispatch(dispatchListSalonMembers(token, salonId))
    }
  }, [dispatch, membersLoaded, token, salonId])

  const goToOnboard = () => {
    history.push(generatePath(ROUTES.laborOnboard, { salonId }))
  }

  // 1. make sure all data is loaded
  // 2. make data at least one type of data exists
  // 3. if show empty exists always show empty
  // 4. if show table exists always show the table
  const show = !empty && tiers && services && items && (tiers.length > 0 || services.length > 0 || showTable)
  const showEmpty = empty || (tiers && services && tiers.length === 0 && services.length === 0)
  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          title="Labor Pricing"
          breadcrumbs={breadcrumbs}
          showHelp={false}
          actions={
            <Link to={generatePath(ROUTES.laborOnboard, { salonId })}>
              <Button variant="round" colorScheme="brand.midnight">
                Setup
              </Button>
            </Link>
          }
        />
      }
      content={
        <>
          {/* table */}
          {show && (
            <LaborTable laborItems={items ? items : []} laborTiers={tiers || []} laborServices={services || []} />
          )}

          {/* empty state */}
          {showEmpty && (
            <>

              <Gap s='48px'/>
              <EmptyBox
                title="NO LABOR PRICES SET"
                content="Now you can account for your time with SalonScale’s Labor Feature. No matter how you charge for your time, we
          will make sure you charge your worth. Click below to get started."
              >
                <Box h="24px" />
                <Button variant="round" colorScheme="brand.midnight" onClick={goToOnboard}>
                  LET'S GO!
                </Button>
              </EmptyBox>
            </>
          )}

          {/* loading state */}
          {(!tiers || !services) && (
            <Flex h="500px" align="center" justify="center">
              <Spinner />
            </Flex>
          )}
        </>
      }
    />
  )
}

export const Empty = (props: {}) => {
  const st = ColorForMode('secondary-text')
  const history = useHistory()
  const params: any = useParams()
  const { salonId } = params
  const goToOnboard = () => {
    history.push(generatePath(ROUTES.laborOnboard, { salonId }))
  }

  return (
    <Flex direction="column" justify="center" align="center">
      <img alt="logo" style={{ height: '380px', maxWidth: '100%', padding: '4px' }} src={laborEmpty} />
      <Text variant="title1" fontWeight="bold">
        NO LABOR PRICES SET
      </Text>
      <Box h="24px" />
      <Text color={st} maxW="100%" w="640px">
        Now you can account for your time with SalonScale’s Labor Feature. No matter how you charge for your time, we
        will make sure you charge your worth. Click below to get started.
      </Text>
      <Box h="24px" />
      <Button variant="round" bg="brand.midnight.500" onClick={goToOnboard}>
        LET'S GO!
      </Button>
    </Flex>
  )
}
