import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Center, Divider, Flex, Spinner, Text, Textarea } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'
import { reduceRedirectToSessionId, selectRedirectToSessionId, selectSessionDetails } from '../../data/sessions/slice'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import {
  dispatchCompleteSession,
  dispatchDeleteSession,
  dispatchGetSession,
  dispatchUpdateSession,
} from '../../data/sessions/api'
import { APIUpdateSession, Session, SessionItem } from '../../data/sessions/interfaces'
import { assign } from 'lodash'
import {
  mapSessionItemsToAPISessionItems,
  mapSessionLaborItemsToAPISessionLaborItems,
  mapSessionSuppliesToAPISessionSupplies,
} from '../../data/sessions/mappers'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { COLORS } from '../../mini-lib/theme/colors'
import { SessionSuppliesLegacy } from './session-supplies/SessionSuppliesLegacy'
import { BowlFormula, SessionBowlsLegacy } from './session-colors/SessionBowlsLegacy'
import { SessionMetaDetailsLegacy } from './SessionMetaDetailsLegacy'
import { SessionExtensionsLegacy } from './session-extensions/SessionExtensionsLegacy'
import { SessionPhotosLegacy } from './SessionPhotosLegacy'
import { SESSION_STATUS_OPTIONS } from '../../data/sessions/constants'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import {
  getBowlTitle,
  getSessionItemsByBowl,
  getSessionWasteTotal,
  getTotalSessionItemCost,
} from '../../data/sessions/utils'

import { formatDollars } from "../../mini-lib/units/money";
import { ROUTES } from "../../appRoutes";

export const SessionDetailsLegacyPage = () => {
  const { user, salonId } = UseBaseApiParams()
  const linkUrl = generatePath(ROUTES.sessions, { salonId })
  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Sessions', url: linkUrl },
    { label: 'Details' },
  ]
  const dispatch = useDispatch()
  const history = useHistory()
  const params: any = useParams()
  const { isMobile } = UseViewSize()
  const model = useAppSelector(selectSessionDetails)
  const redirectToSessionId = useAppSelector(selectRedirectToSessionId)
  const modelLoaded = !!model
  const { sessionId } = params

  useEffect(() => {
    if (!modelLoaded || (modelLoaded && sessionId !== model.id)) {
      dispatch(dispatchGetSession({ token: user.token, salonId, modelId: sessionId }))
    }
  }, [dispatch, modelLoaded, user.token, salonId, sessionId, model?.id])

  // reset the redirect id if the user came here from creating a session
  useEffect(() => {
    if (redirectToSessionId !== null) {
      dispatch(reduceRedirectToSessionId(null))
    }
  }, [dispatch, redirectToSessionId])

  const updateSession = (paramsToUpdate: Partial<Session>): void => {
    if (model) {
      const updatedSession: APIUpdateSession = assign(
        {},
        {
          session_id: model.id,
          client_id: model.client.id,
          stylist_user_id: model.stylist.userId,
          stylist_id: model.stylist.stylistId,
          date: model.date,
          completed_date: model.completedDate,
          notes: model.notes,
          // currently session items and extensions are updated together, that will change in the future
          session_items: mapSessionItemsToAPISessionItems([...model.sessionItems]),
          extensions: mapSessionItemsToAPISessionItems(model.sessionExtensions),
          labor_items: mapSessionLaborItemsToAPISessionLaborItems(model.sessionLaborItems),
          supplies: mapSessionSuppliesToAPISessionSupplies(model.sessionSupplies),
          status: model.status,
          replace_session_items: true,
        },
        paramsToUpdate,
      )
      dispatch(
        dispatchUpdateSession({ token: user.token, user_id: user.userId, salon_id: salonId, model: updatedSession }),
      )
    }
  }
  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          title={model && model.status === SESSION_STATUS_OPTIONS.complete ? 'View Session' : 'Edit Session'}
          breadcrumbs={breadcrumbs}
          actions={
            <Flex gridGap="12px">
              {/*<Link to={generatePath(ROUTES.sessions, { salonId })}>*/}
              {/*  <Button variant="round-ghost-upper">Back</Button>*/}
              {/*</Link>*/}
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`Removing this session means it will no longer appear in your salon.`}
                onConfirm={() => {
                  if (model) {
                    dispatch(dispatchDeleteSession({ token: user.token, salonId, model }))
                    const linkUrl = generatePath(ROUTES.sessions, { salonId })
                    history.push(linkUrl)
                  }
                }}
              >
                <Button w="100px" variant="round-ghost-upper" color="danger" cursor="pointer">
                  {isMobile ? 'Delete' : 'Delete Session'}
                </Button>
              </ConfirmPopover>
              {model && <SessionStatusButton session={model} updateSession={updateSession} />}
            </Flex>
          }
        />
      }
      content={
        <>
          {model && (
            <>
              <Box h={isMobile ? '' : '48px'} />
              <Flex justify="space-between" gridGap="24px" flexWrap="wrap">
                <Box w={isMobile ? '100%' : '60%'}>
                  <SessionMetaDetailsLegacy session={model} updateSession={updateSession} />
                  <Box h="48px" />
                  <SessionBowlsLegacy session={model} updateSession={updateSession} />
                  <Box h="48px" />
                  <SessionExtensionsLegacy session={model} updateSession={updateSession} />
                  <Box h="48px" />
                  <SessionSuppliesLegacy session={model} updateSession={updateSession} />
                </Box>
                <Box w={isMobile ? '100%' : '34%'}>
                  <SessionTotal session={model} />
                  <Box h="24px" />
                  <Divider />
                  <Box h="24px" />
                  <SessionWaste session={model} />

                  <SessionNotes session={model} updateSession={updateSession} />
                  <Box h="24px" />
                  <Divider />
                  <Box h="24px" />
                  <SessionPhotosLegacy session={model} />
                  <Box h="24px" />
                </Box>
              </Flex>
            </>
          )}
          {!model && (
            <Flex h="500px" align="center" justify="center">
              <Spinner />
            </Flex>
          )}
        </>
      }
    />
  )
}
export const SessionTotal = (props: { session: Session }) => {
  const { session } = props
  return (
    <Flex justify="space-between">
      <Text variant="title1" fontWeight="bold">
        Session total
      </Text>
      <Text variant="title1" fontWeight="bold">
        ${formatDollars(session.totalSessionCost)}
      </Text>
    </Flex>
  )
}

export const SessionWaste = (props: { session: Session }) => {
  const { session } = props
  const wasteTotal = getSessionWasteTotal(session)
  const { bowls } = getSessionItemsByBowl(session.sessionItems)
  return (
    <>
      {!!wasteTotal && (
        <>
          <Flex justify="space-between">
            <Text variant="title3" fontWeight="bold">
              Waste
            </Text>
            <Text variant="title3" fontWeight="bold">
              ${wasteTotal}
            </Text>
          </Flex>
          <Box h="12px" />
          {bowls.map((bowl, index) => {
            return <SessionBowlWaste key={index} bowl={bowl} />
          })}
          <Box h="12px" />
          <Divider />
          <Box h="24px" />
        </>
      )}
    </>
  )
}

export const SessionBowlWaste = (props: { bowl: SessionItem[] }) => {
  const { bowl } = props
  const bowlWasteTotal = getTotalSessionItemCost(bowl, 'waste')
  const sessionItemsWithWaste = bowl.filter((item) => item.waste && item.waste > 0)
  const { bowlType, bowlTag } = getBowlTitle(bowl[0])
  return (
    <>
      {sessionItemsWithWaste.length > 0 && (
        <>
          <Box h="8px" />
          <Text variant="footnote">{bowlType} {bowlType && bowlTag && '•'} {bowlTag} Waste</Text>
          <Flex align="center" justify="space-between" gridGap="12px">
            <Flex align="center">
              <BowlFormula bowl={bowl} bowlView="waste" />
            </Flex>
            <Text variant="footnote">${formatDollars(bowlWasteTotal)}</Text>
          </Flex>
          <Box h="12px" />
        </>
      )}
    </>
  )
}

export const SessionNotes = (props: { session: Session; updateSession: (u: Partial<APIUpdateSession>) => void }) => {
  const { session, updateSession } = props
  const [notes, setNotes] = useState(session.notes)
  const [isEditable, setIsEditable] = useState(false)
  return (
    <Box>
      <Text variant="title3">Notes</Text>
      <Box h="24px" />
      {session.notes !== '' && !isEditable && (
        <>
          <Text onClick={() => setIsEditable(true)}>{session.notes}</Text>
          <Flex align="center" p="12px 0" onClick={() => setIsEditable(true)} cursor="pointer">
            <MaterialIcon colorhex={COLORS.lavender_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
            <Text color="brand.lavender.500">Edit Note</Text>
          </Flex>
        </>
      )}
      {session.notes === '' && !isEditable && (
        <EmptyBox h="100px" title="No Notes" content="nothing to display">
          {session.status === SESSION_STATUS_OPTIONS.open && (
            <Flex align="center" p="12px 0" onClick={() => setIsEditable(true)} cursor="pointer">
              <MaterialIcon colorhex={COLORS.lavender_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
              <Text color="brand.lavender.500">add note</Text>
            </Flex>
          )}
        </EmptyBox>
      )}
      {isEditable && (
        <>
          <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
          <Flex gridGap="12px" justify="flex-end" p="12px 0">
            <Button variant="round-outline" onClick={() => setIsEditable(false)}>
              Cancel
            </Button>
            <Button
              variant="round"
              onClick={() => {
                setIsEditable(false)
                updateSession({ notes })
              }}
            >
              Update Note
            </Button>
          </Flex>
        </>
      )}
    </Box>
  )
}

export const SessionStatusButton = (props: {
  session: Session
  updateSession: (partialSession: Partial<Session>) => void
}) => {
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const {
    session: { status, id },

    updateSession,
  } = props
  const linkUrl = generatePath(ROUTES.sessions, { salonId })
  return (
    <>
      {status === SESSION_STATUS_OPTIONS.open && (
        <Link to={linkUrl}>
          <Button
            colorScheme="brand.lavender"
            variant="round"
            onClick={() => {
              updateSession({ status: SESSION_STATUS_OPTIONS.complete })
              dispatch(dispatchCompleteSession({ token: user.token, modelId: id, salonId }))
            }}
          >
            Complete Session
          </Button>
        </Link>
      )}
      {status === SESSION_STATUS_OPTIONS.complete && (
        <Center h="40px">
          <Text variant="button" color={COLORS.shades_neutral_400}>
            SESSION COMPLETE
          </Text>
        </Center>
        // <Button
        //   colorScheme="brand.lavender"
        //   variant="round-outline"
        //   onClick={() => updateSession({ status: SESSION_STATUS_OPTIONS.open })}
        // >
        //   Edit Session
        // </Button>
      )}
    </>
  )
}
