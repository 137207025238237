import React from 'react'
import { useDispatch } from 'react-redux'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'

import { MOBILE_IDENTIFIER_COLUMN_MAX_WIDTH } from '../../data/constants'

import { SessionMetaLara } from '../../data/sessions/interfaces'
import { reduceSetSelectedSessionId } from '../../data/sessions/slice'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { getColorForSessionStatus } from '../../data/sessions/utils'
import { Loading } from '../../mini-lib/loading/Loading'
import { generatePath, Link } from 'react-router-dom'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { COLORS } from '../../mini-lib/theme/colors'

import { formatCentsToDollars } from '../../mini-lib/units/money'
import { ROUTES } from '../../appRoutes'
import {  BASE_STYLES } from "../../styles";
import { UseViewSize } from "../../core/UseViewSize";
import { ReleaseOpenDay } from "../../mini-lib/flags/Release";

export const SessionList = (props: { sessions: SessionMetaLara[]; sessionsLoading: boolean }) => {
  const { sessions, sessionsLoading } = props
  const releaseOpenDay = ReleaseOpenDay()
  return (
    <>
      {releaseOpenDay && <SessionListHeader />}
      {!sessionsLoading &&
        sessions.length > 0 &&
        sessions.map((session, index) => {
          return <SessionRow index={index} session={session} key={session.id} />
        })}
      {sessionsLoading && <Loading mt="40px" color="brand.lavender.500" />}

      {!sessionsLoading && sessions && sessions.length === 0 && (
        <>
          <Box h="48px" />
          <EmptyBox
            icon={<MaterialIcon colorhex={COLORS.lavender_500} size='80px' name='today'/>}
            title='NO SESSIONS YET!'
            content='Start new session to see data.'
          />
        </>
      )}
    </>
  )
}
export const SessionListHeader = () => {
  const {isMobile} = UseViewSize()
  const detailsColWidth = isMobile ? '100%' : '250px'
  const totalColWidth = '120px'
  return (
    <Flex justify="space-between" borderRadius={BASE_STYLES.borderSquare} p='12px' bg={COLORS.shades_neutral_100} fontWeight='bold' color={COLORS.text_secondary}>
      <Box w={detailsColWidth}>Session Details</Box>
      {!isMobile && (
        <Flex justify="end">
          <Box w={totalColWidth}>
            Colors
          </Box>
          <Box w={totalColWidth}>
            Addons
          </Box>
          <Box w={totalColWidth}>
            Extensions
          </Box>
          <Box w={totalColWidth}>
            Total
          </Box>
        </Flex>
      )}
    </Flex>
  )
}
export const SessionRow = ({ index, session }: { index: number; session: SessionMetaLara }) => {
  const { salonId } = UseBaseApiParams()
  const {isMobile} = UseViewSize()
  const path = generatePath(ROUTES.sessionDetails, { salonId, sessionId: session.id })
  const detailsColWidth = isMobile ? '100%' : '250px'
  const totalColWidth = '120px'
  const releaseOpenDay = ReleaseOpenDay()
  return (
    <Link to={path}>
      <Flex justify="space-between">
        <Box w={releaseOpenDay ? detailsColWidth : '100%'}>
          <SessionDetailsColumn session={session}/>
        </Box>

        {releaseOpenDay && !isMobile && (
          <Flex justify="end">
            {/* colors total*/}
            <Flex w={totalColWidth} align="center">
              ${formatCentsToDollars(session.totalClientPurchasePriceSessionColorItemsCents)}
            </Flex>

            {/* addon total*/}
            <Flex w={totalColWidth} align="center">
              ${formatCentsToDollars(session.totalClientPurchasePriceSessionSupplyCents)}
            </Flex>

            {/* extension total*/}
            <Flex w={totalColWidth} align="center">
              ${formatCentsToDollars(session.totalClientPurchasePriceSessionExtensionItemsCents)}
            </Flex>

            {/* session total */}
            <Flex w={totalColWidth} align="center" justify="space-between" gridGap="8px">
              <Text>${formatCentsToDollars(session.totalClientPurchasePriceCents)}</Text>
              <MaterialIcon size='32px' colorhex={COLORS.lavender_500} name="chevron_right" />
            </Flex>
          </Flex>
        )}
      </Flex>
      <Divider />
    </Link>
  )
}

export const SessionDetailsColumn = (props: { session }) => {
  const { session } = props
  const dispatch = useDispatch()
  const date = new Date(session.date)
  const statusColor = getColorForSessionStatus(session.status || 'open')
  const {isMobile} = UseViewSize()
  const releaseOpenDay = ReleaseOpenDay()
  return (
      <Flex align="center" justify="space-between" p="12px" w='100%' maxW="100%" onClick={() => dispatch(reduceSetSelectedSessionId(session.id))}>
        <Flex align="center" gridGap="12px">
          <Box minH="100%" h="60px" w="4px" borderRadius="100px" bg={statusColor} />
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: MOBILE_IDENTIFIER_COLUMN_MAX_WIDTH,
              width: '100%',
              whiteSpace: 'nowrap',
            }}
          >
            <div>
              <Text color={statusColor} variant="caption2">
                {session.status}
              </Text>
              <Text variant="body">
                {session.client.firstName} {session.client.lastName}
              </Text>
              <Text variant="caption2" fontWeight="normal">
                {session.user.firstName} {session.user.lastName} {date.toLocaleDateString('en-US')}
              </Text>
            </div>
          </div>
        </Flex>
        {(!releaseOpenDay || isMobile) && (
          <Flex align="center" justify="space-between" gridGap="8px">
            <Text>${formatCentsToDollars(session.totalClientPurchasePriceCents)}</Text>
            <MaterialIcon size='32px' colorhex={COLORS.lavender_500} name="chevron_right" />
          </Flex>
        )}
      </Flex>
  )
}
