import { Flex, Tab, TabList, Tabs, Link } from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { COLORS } from '../../mini-lib/theme/colors'
import { ngBaseUrl } from '../../core/dealingWithAngular'
import { Notifications } from './AdminNotificationsPage'

export const AdminCreateTabs = () => {
    const { isMobile, isTablet } = UseViewSize()

    return (
        <>
        <Flex w="100%" direction={(isMobile || isTablet) ? 'column-reverse' : 'row'} justify="space-between" borderBottom={`1px solid ${COLORS.shades_neutral_300}`} pb="4px">
            <Tabs index={4} colorScheme="brand.lavender" border="none">
                <TabList overflowX="auto" padding="4px" border="none">
                    <Tab minW={isMobile ? '60px' : '200px'}>
                        <Link href={`${ngBaseUrl()}/admin/create-dashboard?type=user`}>{isMobile ? 'Create User' : 'Create User'}</Link>
                    </Tab>
                    <Tab minW={isMobile ? '60px' : '200px'}>
                        <Link href={`${ngBaseUrl()}/admin/create-dashboard?type=salon`}>{isMobile ? 'Create Salon' : 'Create Salon'}</Link>
                    </Tab>
                    <Tab minW={isMobile ? '80px' : '200px'}>
                        <Link href={`${ngBaseUrl()}/admin/create-dashboard?type=subscription`}>{isMobile ? 'Create Subscription' : 'Create Subscription'}</Link>
                    </Tab>
                    <Tab minW={isMobile ? '80px' : '200px'}>
                        <Link href={`${ngBaseUrl()}/admin/create-dashboard?type=email`}>{isMobile ? 'Sent Email' : 'Sent Email'}</Link>
                    </Tab>
                    <Tab minW={isMobile ? '80px' : '200px'}>{isMobile ? 'Notification' : 'Notification'}</Tab>
                </TabList>
            </Tabs>
       
        </Flex>
             <div style={{ marginTop: '2rem' }}><Notifications /></div>
             </>
    )
}
